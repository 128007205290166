import type Theme from "@onestore/hel/dist/typings/theme"
import type { BundleButtonWithPrice } from "@gatsby-plugin-generic-page/fragments/bundleButtonWithPrice"
import type { CountdownType } from "@gatsby-plugin-generic-page/fragments/countdown"
import type { CtaElement } from "@gatsby-plugin-generic-page/fragments/ctaElement"
import type { Header } from "@gatsby-plugin-generic-page/fragments/header"
import type { SectionSettings } from "@gatsby-plugin-generic-page/fragments/sectionSettings"
import type { ToggleWithLinkButtonsProps } from "@gatsby-plugin-generic-page/fragments/toggleWithLinkButtons"
import type { ComparableProduct } from "~/fragments/comparableProduct"
import type { EmbeddedAssetSquidexMetadata } from "~/fragments/image"
import type {
  Indicator,
  List,
  Pages,
  Post,
  ResponsiveImages,
  StaticTable,
} from "~/types/squidex"

export enum SectionType {
  ACCORDION_SECTION = "AccordionSection",
  ADVANCED_DOMAIN_ITEMS_SECTION = "AdvancedDomainItemsSection",
  BANNER_SECTION = "BannerSection",
  BREADCRUMBS_SECTION = "BreadcrumbsSection",
  BUNDLE_SECTION = "BundleSection",
  BUTTONS_ANIMATED_WITH_IMAGE_SECTION = "ButtonsAnimatedWithImageSection",
  CARDS_WITH_INFO_SECTION = "CardsWithInfoSection",
  CENTERED_CONTENT_WITH_IMAGE_SECTION = "CenteredContentWithImageSection",
  CENTERED_DOMAIN_SEARCH_SECTION = "CenteredDomainSearchSection",
  COLOR_CONTENT_WITH_IMAGE_SECTION = "ColorContentWithImageSection",
  COMPARISON_TILES_SECTION = "ComparisonTilesSection",
  CONTACT_SECTION = "ContactSection",
  CONTENT_WITH_FORM_SECTION = "ContentWithFormSection",
  CONTENT_WITH_IMAGE_AND_FORM_SECTION = "ContentWithImageAndFormSection",
  CONTENT_WITH_IMAGE_SECTION = "ContentWithImageSection",
  CONTENT_WITH_ITEMS_SECTION = "ContentWithItemsSection",
  CONTENT_WITH_PRODUCT_BOX_SECTION = "ContentWithProductBoxSection",
  CONTENT_WITH_SIMPLE_PRODUCT_BOX_SECTION = "ContentWithSimpleProductBoxSection",
  CORE_PRODUCTS_SECTION = "CoreProductsSection",
  COUNTER_SECTION = "CounterSection",
  CTA_SECTION = "CtaSection",
  DESCRIPTION_BOX_SECTION = "DescriptionBoxSection",
  DOMAINS_LINKS_SECTION = "DomainsLinksSection",
  DOMAIN_ITEMS_SECTION = "DomainItemsSection",
  DOMAIN_TRANSFER_SECTION = "DomainTransferSection",
  INTEGRATION_TILES_SECTION = "IntegrationTilesSection",
  FAQ_SECTION = "FaqSection",
  FLOATING_CTA_ELEMENT_SECTION = "FloatingCtaElementSection",
  FORM_SURVEY_SECTION = "FormSurveySection",
  IMAGES_TILE_LINK_SECTION = "ImagesTileLinkSection",
  IMAGE_WITH_BUTTONS_SECTION = "ImageWithButtonsSection",
  LINKS_ANIMATED_IMAGE_SECTION = "LinksAnimatedImageSection",
  LOGO_SECTION = "LogoSection",
  PACKAGES_COMPARISON_SECTION = "PackagesComparisonSection",
  PARAMETERS_TABLE_SECTION = "ParametersTableSection",
  PLACEHOLDER_SECTION = "PlaceholderSection",
  POSTS_SECTION = "PostsSection",
  PRODUCT_BOX_SECTION = "ProductBoxSection",
  PRODUCT_CARDS_SECTION = "ProductCardsSection",
  PROMO_SECTION = "PromoSection",
  RECOMMENDED_PRODUCT_SECTION = "RecomendedProductSection",
  SEARCH_SECTION = "SearchSection",
  SEARCH_WITH_IMAGE_SECTION = "SearchWithImageSection",
  SEO_CONTENT_SECTION = "SeoContentSection",
  STEP_BY_STEP_SECTION = "StepByStepSection",
  SLIDER_SECTION = "SliderSection",
  STATIC_TABLE_SECTION = "StaticTableSection",
  TABLE_HORIZONTAL_BOXES_SECTION = "TableHorizontalBoxesSection",
  TABLE_WITH_CONTENT_SECTION = "TableWithContentSection",
  TABS_SECTION = "TabsSection",
  TILES_SECTION = "TilesSection",
  TILES_PRODUCTS_PRICE_SECTION = "TilesProductsPriceSection",
  TILES_LINK_SECTION = "TilesLinkSection",
  VIDEO_SECTION = "VideoSection",
  WEB_TEMPLATES_SECTION = "WebTemplatesSection",
  HEADER_WITH_TEXT_SECTION = "HeaderWithTextSection",
  MARKETPLACE_CATEGORY_PRODUCT_CARD_SECTION = "MarketplaceCategoryProductCardSection",
  FULL_SCREEN_CONTAINER_SECTION = "FullScreenContainerSection",
  FULL_WIDTH_SLIDER_SECTION = "FullWidthSliderSection",
  PROMO_TILES_SECTION = "PromoTilesSection",
  PROMO_VIDEO_SECTION = "PromoVideoSection",
  COLOR_CONTENT_WITH_BOX_SECTION = "ColorContentWithBoxSection",
  PARALLAX_SECTION = "ParallaxSection",
}

export interface Breadcrumb {
  text: string
  title: string
  pages: Pages
}

export interface BreadcrumbsSectionFlatData {
  currentItem: string
  breadcrumbs: Breadcrumb[]
  sectionSettings?: SectionSettings
}

export interface Tile {
  title: string
  text: string
  indicators: Indicator[]
}

export interface ComparisonTilesSectionFlatData {
  header?: Header
  tiles: Tile[]
  sectionSettings?: SectionSettings
}

export interface CenteredContentWithImageSectionFlatData {
  header: Header
  toggleWithLinkButtons?: ToggleWithLinkButtonsProps
  responsiveImages?: ResponsiveImages
  list?: List[]
  buttons?: CtaElement[]
  sectionSettings?: SectionSettings
}

export interface IconButton {
  id: string
  flatData: {
    title: string
    text: string
    images: EmbeddedAssetSquidexMetadata[]
    href?: string
    pages?: Pages
    isVisibleOnSmallOnly?: boolean
  }
}

export interface InfoBox {
  id: string
  flatData: {
    title: string
    text: string
    href?: string
    pages?: Pages
    images: EmbeddedAssetSquidexMetadata[]
  }
}

export interface ContactSectionFlatData {
  title: string
  subtitle: string
  contactDetails: {
    text: string
    prefix: string
    number: string
  }[]
  buttons: IconButton[]
  images: EmbeddedAssetSquidexMetadata[]
  infoBoxes?: InfoBox[]
}

export interface ParametersTableSectionFlatData {
  comparableProducts: ComparableProduct[]
  tableHeaderTitle?: string
  hasHiddenFooter?: boolean
  hasTogglePriceType?: boolean
  title?: string
  text?: string
  isTextCentered?: boolean
  sectionSettings?: SectionSettings
}

interface LinkWithImage {
  text: string
  image: EmbeddedAssetSquidexMetadata[]
}

export interface LinksAnimatedImageSectionFlatData {
  linksWithImage: LinkWithImage[]
  linksTitle?: string
  title?: string
  sectionSettings?: SectionSettings
}

export interface DomainTransferSectionFlatData {
  title: string
  footnoteText: string
  footnoteLink: {
    title: string
    text: string
  }[]
  footnoteModalText: string
  responsiveImages: ResponsiveImages
  searchButtonColor?: { color: Theme.AccentColor }
  caption?: string
  captionIcon?: EmbeddedAssetSquidexMetadata[]
  text?: string
  hasImageStickyToBottom?: boolean
  isReversed?: boolean
  sectionSettings?: SectionSettings
}

export type Features = CountdownType

export interface SeoParagraph {
  title: string
  text: string
}

export interface SeoContentSectionFlatData {
  title: string
  paragraphs: SeoParagraph[]
  sectionSettings?: SectionSettings
}

export interface ImageWithButtonsSectionFlatData {
  title: string
  responsiveImages: ResponsiveImages
  buttons: CtaElement[]
  sectionSettings?: SectionSettings
}

export interface StaticTableSectionFlatData {
  header?: Header
  staticTable: StaticTable[]
  text?: string
  ctaElements?: CtaElement[]
  sectionSettings?: SectionSettings
}

export interface BundleButtonWithPriceFlatData {
  __typename: string
  id: string
  flatData: BundleButtonWithPrice
}

export interface DomainItemsSectionFlatData {
  activeColor?: Theme.Color | undefined
  header?: Header
  sectionSettings?: SectionSettings
}

export interface PostsSectionFlatData {
  header?: Header
  posts: Post[]
  sectionSettings?: SectionSettings
}
