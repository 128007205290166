import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import { PeriodInfo } from "@core/period-info"
import type { DomainCheck } from "@onestore/api/domainSearch"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import LowestPriceInfo from "~/components/LowestPriceInfo"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import isEmpty from "~/lib/isEmpty"

export function SpecialSubItemPrice({
  domain,
}: {
  domain: DomainCheck.Result
}): ReactElement | null {
  const periodInfo = domain.period ? new PeriodInfo(domain.period) : null
  const { currentPriceType } = usePriceTypeContext()

  if (isEmpty(periodInfo)) {
    return <></>
  }

  const periodText = ` ${_t(`periodName.searchDomainPrice.3`, {
    smart_count: periodInfo.getPeriodLength(),
  })}`

  const hasPromotion = periodInfo.hasPromotion() ?? false

  const periodInfoValues = periodInfo.usePrices(currentPriceType)

  const { priceValue, lowestPrice, lowestPricePercent } = periodInfoValues

  return (
    <AlignContentOrg
      horizontalAlign={{
        large: "right",
        medium: "right",
        small: "left",
      }}
    >
      <TextAtm
        typography="medium3_s"
        color={hasPromotion ? "primary" : undefined}
      >
        {priceValue}{" "}
        <TextAtm typography="nano1" emphasis="high">
          {periodText}
        </TextAtm>
      </TextAtm>

      {hasPromotion ? (
        <PushOrg topSpace={1}>
          <LowestPriceInfo value={lowestPrice} percent={lowestPricePercent} />
        </PushOrg>
      ) : undefined}
    </AlignContentOrg>
  )
}
