import React from "react"
import type { ReactElement } from "react"
import type { CloudblueResourceCategory } from "@gatsby-plugin-generic-page/fragments/cloudblueResourceCategory"
import { ResourceCategoryDisplayType } from "@gatsby-plugin-generic-page/hooks/useTableConfigs"
import { useConfigsHookContext } from "@gatsby-plugin-generic-page/sections/TableHorizontalBoxesSection"
import isEmpty from "~/lib/isEmpty"
import CounterResourceElement from "./CounterResourceElement"
import SelectResourceElement from "./SelectResourceElement"

type ResourcesConfigElementsProps = {
  resourceCategoryData: CloudblueResourceCategory
}

export default function ResourceConfigElement({
  resourceCategoryData,
}: ResourcesConfigElementsProps): ReactElement<ResourcesConfigElementsProps> | null {
  const { getResourceCategory } = useConfigsHookContext() ?? {}

  const resourceCategoryId = resourceCategoryData.flatData.remoteId
  const resourceCategoryName = resourceCategoryData.flatData.name

  const resourceCategory =
    resourceCategoryId && getResourceCategory
      ? getResourceCategory(resourceCategoryId.toString())
      : null

  if (isEmpty(resourceCategory)) {
    return null
  }

  if (resourceCategory.display_type === ResourceCategoryDisplayType.COUNTERS) {
    return (
      <CounterResourceElement
        resourceCategoryId={resourceCategoryId.toString()}
      />
    )
  }

  if (resourceCategory.display_type === ResourceCategoryDisplayType.SELECT) {
    return (
      <SelectResourceElement
        label={resourceCategoryName}
        resourceCategoryId={resourceCategoryId.toString()}
      />
    )
  }

  return null
}
