import buildInfo from "_/res/build.json"
import countries from "_/res/countries.json"
import Cookies from "js-cookie"
import { get, pickBy } from "lodash"
import type { CountryCode, PlanId } from "@onestore/api/types"
import SessionStorage from "~/lib/storage/SessionStorage"
import { getStoredTacosValue } from "~/lib/tacos"
import { VERSION } from "../index"
import vars from "./onestoreVars"

interface BuildInfo {
  id: string
  brand: string
}

export interface CountrySettings {
  code: CountryCode
  name: string
  callingCode: string
  postalCodeRegexp: string
  person: boolean
  company: boolean
}

export const { id: build, brand } = buildInfo as BuildInfo

interface CeneoOpinionsSettings {
  questionnaireDays: number
  accountGuid: string | null
}

export const getPopularPoolId = (): number => {
  return vars.mainPoolId
}

export const helpUrlForExtension = (extension: string): string => {
  return `${vars.helpUrl}#${extension}`
}

/**
 * Pobiera domenę w której ustawiane są ciastka mające być widoczne w domenie oraz subdomenach.
 * @returns {string} nazwa domeny
 */
export const getRootCookieDomain = (): string | undefined => {
  return vars.rootCookieDomain || undefined
}

export const isCompanyNumberCheckEnabled = (): boolean => {
  return vars.companyNumberCheck
}

export const getAffiliateCookieName = (): string => {
  return vars.affiliateCookieName
}

/**
 * Funkcja zwraca format kodu potwierdzenia SMS z onestoreVars.
 * @returns {string}
 */
export const getSmsConfirmationFormat = (): string => {
  return vars.smsConfirmationFormat
}

export const getReCaptchaSiteKey = (): string => {
  return vars.reCaptchaSiteKey
}

/**
 * Funkcja sprawdzająca czy kod potwierdzenia SMS jest zgodny z formatem zdefiniowanym w ustawieniach.
 * @param {string} code - kod do sprawdzenia.
 * @returns {boolean}
 */
export const checkSmsConfirmationFormat = (code: string): boolean => {
  const regex = new RegExp(
    `^${getSmsConfirmationFormat().replace(/#/g, "[0-9]")}$`
  )

  return regex.test(code)
}

/**
 * Funkcja pobierająca dodatkowe informacje o sesji użytkownika ze zmiennej globalnej onestoreContext.
 */
interface StoreContext {
  adBlock: boolean
  tacos: undefined | string
  tuneTransactionId: undefined | string
}

export const getStoreContext = (): StoreContext => {
  return {
    ...(window["onestoreContext"] || {}),
    adBlock: SessionStorage.get("_oab") !== "none",
    tacos: getStoredTacosValue(),
    tuneTransactionId: Cookies.get("tn_trn_id"),
  }
}

export const getLocale = (): string => vars.locale

export const getHostingHookPlanId = (): number | null =>
  vars.hostingHackPlanId ?? null

export const getSecondaryHookPlanId = (): number | null =>
  vars.secondaryHackPlanId ?? null

export const getHookPlans = (): (number | null)[] => {
  return [getHostingHookPlanId(), getSecondaryHookPlanId()].filter(
    (item) => item !== null
  )
}

export const hasHostingHookEnabled = (): boolean =>
  getHostingHookPlanId() !== null || getSecondaryHookPlanId() !== null

export const getCeneoOpinionsSettings = (): CeneoOpinionsSettings => {
  const { ceneoOpinionsQuestionnaireDays, ceneoOpinionsAccountGuid } = vars

  return {
    questionnaireDays: ceneoOpinionsQuestionnaireDays || 3,
    accountGuid: ceneoOpinionsAccountGuid || null,
  }
}

export function getCountries(
  type?: "person" | "company"
): Record<CountryCode, CountrySettings> {
  if (!type || -1 === ["person", "company"].indexOf(type)) {
    return countries
  }

  return pickBy(countries, (country: CountrySettings) => country[type])
}

export function getBrand(): string {
  return brand
}

export function getAppVersion(): string {
  return `v${VERSION}`
}

export function getBuildTimestamp(): string {
  return build
}

export function isRememberMeChecked() {
  return true
}

export const getCountryCode = (): CountryCode => {
  return vars.locale === "cs" ? "cz" : vars.locale
}

export function isSessionEnabled() {
  return vars.isSessionEnabled || false
}

export function isTacosEnabled() {
  return vars.isTacosEnabled || false
}

export const features = {
  session: get(vars, "isSessionEnabled", false),
}

export function getDomainsPeriodModalIds(): PlanId[] {
  return vars.domainsPeriodModalIds || []
}

export function isAxMonthlyPrices() {
  return vars.flags.bonusPriceType === "monthly"
}
