import type { ReactElement } from "react"
import React, { memo } from "react"
import LoaderAtm from "@onestore/hel/dist/components/atoms/LoaderAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import useHubspotFormHook from "@gatsby-plugin-generic-page/components/HubspotForm/useHubspotFormHook"

type HubspotFormProps = {
  title?: string
  formId: string
}

function HubspotForm({
  title,
  formId,
}: HubspotFormProps): ReactElement<HubspotFormProps> | null {
  const { enabled, created } = useHubspotFormHook({
    formId: formId,
    target: `#hubspotForm-${formId}`,
  })

  if (!enabled) {
    return null
  }

  return (
    <>
      {title ? (
        <TextAtm htmlTag="h2" typography="xlarge" pushSpace={4}>
          {title}
        </TextAtm>
      ) : null}

      {!created ? <LoaderAtm size={"medium"} /> : null}

      <div id={`hubspotForm-${formId}`} />
    </>
  )
}

export default memo(HubspotForm)
