import type { ThunkAction } from "redux-thunk"
import type { AppState } from "~/store/reducer"

export enum CoreActionTypes {
  UPDATE_API_VERSION = "ACTIONS.CORE.UPDATE_API_VERSION",
  UPDATE_MAINTENANCE_MODE = "ACTIONS.CORE.UPDATE_MAINTENANCE_MODE",
}

export type UpdateApiVersionAction = {
  type: CoreActionTypes.UPDATE_API_VERSION
  version: string | null
}

export type UpdateMaintenanceModeAction = {
  type: CoreActionTypes.UPDATE_MAINTENANCE_MODE
  enabled: boolean
  message: string | null
}

export type CoreAction = UpdateApiVersionAction | UpdateMaintenanceModeAction

export type CoreThunkAction = ThunkAction<void, AppState, undefined, CoreAction>

export const updateApiVersionFromRequest = (
  version: string | null
): UpdateApiVersionAction => {
  return {
    type: CoreActionTypes.UPDATE_API_VERSION,
    version,
  }
}
export const updateMaintenanceModeFromRequest = (
  enabled: boolean,
  message: string | null
): UpdateMaintenanceModeAction => {
  return {
    type: CoreActionTypes.UPDATE_MAINTENANCE_MODE,
    enabled,
    message,
  }
}
