import { useReducer } from "react"
import _t from "@core/i18n"
import { PeriodInfo } from "@core/period-info"
import { formatted, getPriceObjectWithTaxRate, getTaxRate } from "@core/pricing"
import { PriceType } from "@core/types"
import { typeMatches } from "@onestore-graphql"
import get from "lodash/get"
import type {
  BasketPatchResourceWithResourceId,
  FetchCalculateProductsPayload,
  ProductPriceResponse,
} from "@onestore/api/types"
import { IONOS_DC_PARAM_ID } from "@gatsby-plugin-bonus/components/FormBonusBoxParameters/IonosCloudDatacenterLocationSelect"
import type {
  CloudBluePeriod,
  ResourceData,
} from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import type {
  CloudBluePlan,
  Resource,
  ResourceCategoryDefinition,
} from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import type { CloudblueResourceCategoryElement } from "@gatsby-plugin-generic-page/fragments/cloudblueResourceCategoryElement"
import type { DataCenterElement } from "@gatsby-plugin-generic-page/fragments/dataCenterElement"
import type { HorizontalProductBox } from "@gatsby-plugin-generic-page/fragments/horizontalProductBox"
import type { PeriodsTableSelect } from "@gatsby-plugin-generic-page/fragments/periodsTableSelect"
import type { TableConfig } from "@gatsby-plugin-generic-page/fragments/tableConfig"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import type { DataCenterLocationProps } from "~/hooks/useIonosCloudConfigurationQuery"
import useIonosCloudConfigurationQuery from "~/hooks/useIonosCloudConfigurationQuery"
import api from "~/lib/api"
import isEmpty from "~/lib/isEmpty"
import type { PlanSettings } from "~/lib/url"
import { parsePeriod } from "../../../../lib/api"

export enum ResourceCategoryDisplayType {
  COUNTERS = 0,
  SELECT = 1,
}

export type ConfigParameters = {
  paramUrl: string
  value: string
}

export type ConfigResource = {
  categoryId: string
  id: string
  value: number
  name: string
}

export type ConfigsValues = {
  period: string | null
  parameters: ConfigParameters[]
  resources: ConfigResource[]
  modalResources: ConfigResource[]
}

type UrlConfigsValues = {
  period: string | null
  parameters: PlanSettings["parameters"]
  resources: PlanSettings["resources"]
}

type ResourceCategoryLimits = {
  min: number
  max: number
}

export type ConfigsHookResult = {
  configsValues: ConfigsValues
  isCalculateProductsLoading: boolean
  handlePeriodChange: (period: string) => void
  handleParamChange: (paramUrl: string, paramValue: string) => void
  handleResourcesChange: (
    resourcesData: {
      resourceCategoryId: string
      resourceId: string
      resourceValue: number
    }[],
    isModalValue?: boolean
  ) => void
  handleResourcesRemove: (
    resourceCategoryId: string,
    isModalValue?: boolean
  ) => void
  handleResourcesReset: () => void
  handleResourcesConfirm: () => void
  handleResourcesCancel: () => void
  getResourceCategory: (
    resourceCategoryId: string,
    productId?: string
  ) => ResourceCategoryDefinition | undefined
  getConfigResource: (resourceId: string) => ConfigResource | undefined
  getConfigModalResource: (resourceId: string) => ConfigResource | undefined
  getConfigModalResourceCategory: (
    resourceCategoryId: string
  ) => ConfigResource | undefined
  getResourceLimits: (resourceId: string) => ResourceCategoryLimits
  getProductResource: (
    productId: string,
    resourceCategoryId: string,
    resourceId: string
  ) => Resource | undefined
  getProductPeriodResource: (
    productId: string,
    resourceId: string
  ) => ResourceData | null
  getResourcePriceText: (
    resourceId: string,
    resourceCategoryId: string,
    showPricePerOneResource?: boolean
  ) => string | null
  getHasProductActiveResourceCategories: (productId: string) => boolean
  getUrlConfigsValues: (productId: string) => UrlConfigsValues
  getCalculateProductPrices: (
    productPlanId: number
  ) => ProductPriceResponse | null
}

enum ConfigsActionTypes {
  PERIOD_CHANGE = "PERIOD_CHANGE",
  PARAM_CHANGE = "PARAM_CHANGE",
  RESOURCES_CHANGE = "RESOURCES_CHANGE",
  RESOURCES_REMOVE = "RESOURCES_REMOVE",
  RESOURCES_RESET = "RESOURCES_RESET",
  RESOURCES_CONFIRM = "RESOURCES_CONFIRM",
  RESOURCES_CANCEL = "RESOURCES_CANCEL",
}

type Action<T> = {
  type: string
  payload: T
}

type PeriodChangeAction = {
  period: ConfigsValues["period"]
}

type ParamChangeAction = {
  paramUrl: ConfigParameters["paramUrl"]
  paramValue: ConfigParameters["value"]
}

type ResourceChangeAction = {
  resourcesData: {
    resourceCategoryId: ConfigResource["categoryId"]
    resourceId: ConfigResource["id"]
    resourceValue: ConfigResource["value"]
    resourceName: string
  }[]
  isModalValue?: boolean
}

type ResourcesRemoveAction = {
  resourceCategoryId: ConfigResource["categoryId"]
  isModalValue?: boolean
}

const configsReducer = (
  state: ConfigsValues,
  action:
    | Action<PeriodChangeAction>
    | Action<ParamChangeAction>
    | Action<ResourceChangeAction>
    | Action<ResourcesRemoveAction>
    | Action<{}>
): ConfigsValues => {
  switch (action.type) {
    case ConfigsActionTypes.PERIOD_CHANGE:
      const {
        payload: { period },
      } = action as Action<PeriodChangeAction>

      return {
        ...state,
        period,
      }

    case ConfigsActionTypes.PARAM_CHANGE:
      const {
        payload: { paramUrl, paramValue },
      } = action as Action<ParamChangeAction>

      const parametersWithoutCurrent = state.parameters.filter(
        (param) => param.paramUrl !== paramUrl
      )

      return {
        ...state,
        parameters: [
          ...parametersWithoutCurrent,
          {
            paramUrl,
            value: paramValue,
          },
        ],
      }

    case ConfigsActionTypes.RESOURCES_CHANGE:
      const {
        payload: { resourcesData, isModalValue },
      } = action as Action<ResourceChangeAction>

      const changeResourcesStateData = isModalValue
        ? state.modalResources
        : state.resources

      const resourcesWithoutCurrents = changeResourcesStateData.filter(
        (resource) =>
          resourcesData.findIndex(
            (payloadResorce) => resource.id === payloadResorce.resourceId
          )
      )

      const newResources = resourcesData.map((data) => ({
        categoryId: data.resourceCategoryId,
        id: data.resourceId,
        value: data.resourceValue,
        name: data.resourceName,
      }))

      return {
        ...state,
        resources: isModalValue
          ? state.resources
          : [...resourcesWithoutCurrents, ...newResources],
        modalResources: isModalValue
          ? [...resourcesWithoutCurrents, ...newResources]
          : state.modalResources,
      }

    case ConfigsActionTypes.RESOURCES_REMOVE:
      const {
        payload: {
          resourceCategoryId: removeResourceCategoryId,
          isModalValue: isRemoveModalValue,
        },
      } = action as Action<ResourcesRemoveAction>

      const removeResourceStateData = isRemoveModalValue
        ? state.modalResources
        : state.resources

      const resourcesWithoutRemoved = removeResourceStateData.filter(
        (resource) => resource.categoryId !== removeResourceCategoryId
      )

      return {
        ...state,
        resources: isRemoveModalValue
          ? state.resources
          : [...resourcesWithoutRemoved],
        modalResources: isRemoveModalValue
          ? [...resourcesWithoutRemoved]
          : state.modalResources,
      }

    case ConfigsActionTypes.RESOURCES_RESET:
      return {
        ...state,
        resources: [],
        modalResources: [],
      }

    case ConfigsActionTypes.RESOURCES_CONFIRM:
      return {
        ...state,
        resources: state.modalResources,
      }

    case ConfigsActionTypes.RESOURCES_CANCEL:
      return {
        ...state,
        modalResources: state.resources,
      }

    default:
      return state
  }
}

const getInitTableConfigs = (
  configs: TableConfig[] | undefined,
  locations: DataCenterLocationProps[]
): ConfigsValues => {
  let initConfigsValue: ConfigsValues = {
    period: null,
    parameters: [],
    resources: [],
    modalResources: [],
  }

  if (isEmpty(configs)) {
    return initConfigsValue
  }

  configs.forEach((config) => {
    if (
      typeMatches(
        config.type.__typename,
        SquidexComponentType.PERIODS_TABLE_SELECT
      )
    ) {
      const configTypeData = config.type as PeriodsTableSelect

      initConfigsValue.period = configTypeData.defaultPeriodValue.period
    } else if (
      typeMatches(
        config.type.__typename,
        SquidexComponentType.DATA_CENTER_ELEMENT
      )
    ) {
      const configTypeData = config.type as DataCenterElement

      const locationDefaultValue =
        locations.find((location) => location.isDefault)?.id || locations[0].id

      initConfigsValue.parameters = [
        ...initConfigsValue.parameters,
        {
          paramUrl: configTypeData.urlParameter,
          value: locationDefaultValue,
        },
      ]
    }
  })

  return initConfigsValue
}

const getProductsResourceCategories = (
  products: HorizontalProductBox[],
  resourceCategoryId: string
): ResourceCategoryDefinition[] => {
  const productsResourceCategories = products.flatMap(
    (product) =>
      get(
        product,
        "flatData.saleConfiguration[0].flatData.defaultCloudBluePlan[0].flatData.resourceCategories"
      ) || []
  )

  const searchedResourceCategories = productsResourceCategories.filter(
    (resourceCategory) => resourceCategory.id === resourceCategoryId
  )

  return searchedResourceCategories
}

export const getResourceValueByLimits = (
  min: number,
  max: number,
  value?: number
): number => {
  let resourceValue = value || 0

  if (resourceValue < min) {
    resourceValue = min
  } else if (resourceValue > max) {
    resourceValue = max
  }

  return resourceValue
}

const getFormatedResourcePriceValueText = (
  priceValue: number,
  periodName: string,
  priceType?: PriceType
): string => {
  const priceObject = getPriceObjectWithTaxRate(priceValue, getTaxRate())

  const price = formatted(priceObject, priceType)

  const { periodType, periodValue } = parsePeriod(periodName)

  const priceTypeText =
    priceType === PriceType.NETTO ? _t("prices.netto") : _t("prices.gross")

  const periodText = _t(`periodName.short.${periodType}`, {
    smart_count: periodValue,
  })

  return `${price.formatted} ${priceTypeText} / ${periodText}`
}

const getResourcePriceValueText = (
  products: HorizontalProductBox[],
  resourceValue: number,
  resourceCategoryId: string,
  resourceId: string,
  periodName: string,
  priceType?: PriceType,
  showPricePerOneResource?: boolean
): string | null => {
  const productWithResource = products.find((product) => {
    const productResourceCategories = get(
      product,
      "flatData.saleConfiguration[0].flatData.defaultCloudBluePlan[0].flatData.resourceCategories"
    )

    if (isEmpty(productResourceCategories)) {
      return null
    }

    const productResourceCategory = productResourceCategories.find(
      (categoryResource) => categoryResource.id === resourceCategoryId
    )

    if (isEmpty(productResourceCategory)) {
      return null
    }

    const searchedResource = productResourceCategory.resources.filter(
      (productResource) => productResource.id === resourceId
    )

    return !isEmpty(searchedResource)
  })

  const productPeriods =
    get(
      productWithResource,
      "flatData.saleConfiguration[0].flatData.defaultCloudBluePlan[0].flatData.periods"
    ) || []

  const selectedPeriod = productPeriods.find(
    (planPeriod) => planPeriod.period_name === periodName
  )

  const periodInfo = selectedPeriod ? new PeriodInfo(selectedPeriod) : undefined

  if (isEmpty(periodInfo)) {
    return null
  }

  const resourcePriceValue = periodInfo.getResourcePriceValue(
    parseInt(resourceId)
  )

  if (!resourcePriceValue) {
    return null
  }

  const resourcePriceValueFinal = showPricePerOneResource
    ? resourcePriceValue
    : resourcePriceValue * resourceValue

  return getFormatedResourcePriceValueText(
    resourcePriceValueFinal,
    periodName,
    priceType
  )
}

const getProductsQueryPayloadObject = (
  products: HorizontalProductBox[],
  configsResources: ConfigsValues["resources"],
  configPeriod: string | null
): FetchCalculateProductsPayload | null => {
  if (isEmpty(configsResources.filter((resource) => resource.value > 0))) {
    return null
  }

  const productsQueryObject = products.reduce((queryObject, product) => {
    const productPlanData: CloudBluePlan["flatData"] | undefined = get(
      product,
      "flatData.saleConfiguration[0].flatData.defaultCloudBluePlan[0].flatData"
    )

    if (isEmpty(productPlanData)) {
      return queryObject
    }

    const productPlanId = productPlanData.id

    const planPeriods: CloudBluePeriod[] = productPlanData.periods || []

    const paramIds = (productPlanData.parameters || []).map(
      (param) => param.paramId
    )

    const selectedPeriod =
      planPeriods.find((period) => period.period_name === configPeriod) ||
      undefined

    const defaultPeriod = planPeriods.find((period) => period.default)

    const periodObject = selectedPeriod || defaultPeriod

    if (isEmpty(periodObject)) {
      return queryObject
    }

    const productAllResources: Resource[] =
      productPlanData.resourceCategories.flatMap(
        (resourceCategory) => resourceCategory.resources
      )

    const activeProductResources = productAllResources.map(
      (productResource): BasketPatchResourceWithResourceId => {
        const configResource = configsResources.find(
          (configResource) => configResource.id === productResource.id
        )

        const currentPeriodResource = periodObject.resource_data.find(
          (periodResource) =>
            periodResource.resource_id ===
            parseInt(productResource.id as string)
        )

        if (isEmpty(configResource) || isEmpty(currentPeriodResource)) {
          return {
            resource: parseInt(productResource.id as string),
            quantity: 0,
          }
        }

        const resourceValue = getResourceValueByLimits(
          currentPeriodResource.lower_limit,
          currentPeriodResource.upper_limit,
          configResource.value
        )

        return resourceValue > 0
          ? {
              resource: parseInt(configResource.id),
              quantity: resourceValue,
            }
          : {
              resource: parseInt(productResource.id as string),
              quantity: 0,
            }
      }
    )

    if (isEmpty(activeProductResources)) {
      return queryObject
    }

    const defaultParameters: Record<string, string> = {}

    if (paramIds.includes(IONOS_DC_PARAM_ID)) {
      defaultParameters.ionos_dc_location = "de/txl" // Temporary fix, need to add selected value ONESTORE-651
    }
    const queryObjectItems = [
      {
        plan: productPlanId,
        planPeriod: parseInt(periodObject.id.toString()),
        resources: activeProductResources,
        quantity: 1,
        parameters: defaultParameters,
      },
    ]

    return {
      ...queryObject,
      [productPlanId]: {
        items: queryObjectItems,
      },
    }
  }, {})

  return !isEmpty(productsQueryObject) ? productsQueryObject : null
}

const getProductPeriodObject = (
  products: HorizontalProductBox[],
  configPeriod: string | null,
  productId: string
): CloudBluePeriod | null => {
  const product = products.find((product) => product.id === productId)

  const productPlanPeriods =
    get(
      product,
      "flatData.saleConfiguration[0].flatData.defaultCloudBluePlan[0].flatData.periods"
    ) || []

  const selectedPeriodObject = productPlanPeriods.find(
    (period) => period.period_name === configPeriod
  )

  const defaultPeriod = productPlanPeriods.find((period) => period.default)

  return selectedPeriodObject || defaultPeriod || null
}

/**
 * @description stores and shares the main state with current configuration information (configsValues).
 *
 * provides methods for managing the state of configsValues.
 *
 * fetch and stores current product prices.
 */
function useTableConfigs(
  configs: TableConfig[] | undefined,
  products: HorizontalProductBox[]
): ConfigsHookResult {
  const locations = useIonosCloudConfigurationQuery()
  const priceTypeContext = usePriceTypeContext()

  const [configsValues, dispatch] = useReducer(
    configsReducer,
    getInitTableConfigs(configs, locations)
  )

  const {
    data: calculateProducts,
    isLoading: isQueryLoading,
    error: calculateProductsError,
  } = api.useCalculateProductsQuery(
    getProductsQueryPayloadObject(
      products,
      configsValues.resources,
      configsValues.period
    )
  )

  if (calculateProductsError) {
    throw calculateProductsError
  }

  const handlePeriodChange = (period: string) => {
    dispatch({
      type: ConfigsActionTypes.PERIOD_CHANGE,
      payload: {
        period,
      },
    })
  }

  const handleParamChange = (paramUrl: string, paramValue: string) => {
    dispatch({
      type: ConfigsActionTypes.PARAM_CHANGE,
      payload: {
        paramUrl,
        paramValue,
      },
    })
  }

  const handleResourcesChange = (
    resourcesData: {
      resourceCategoryId: string
      resourceId: string
      resourceValue: number
    }[],
    isModalValue?: boolean
  ) => {
    const resourcesDataWithNames = resourcesData.map((resourceObject) => {
      const resourceData = getResourceCategory(
        resourceObject.resourceCategoryId
      )

      const currentResource = resourceData?.resources.find(
        (resource) => resource.id === resourceObject.resourceId
      )

      return {
        ...resourceObject,
        resourceName: currentResource?.name || "",
      }
    })

    dispatch({
      type: ConfigsActionTypes.RESOURCES_CHANGE,
      payload: {
        resourcesData: resourcesDataWithNames,
        isModalValue,
      },
    })
  }

  const handleResourcesRemove = (
    resourceCategoryId: string,
    isModalValue?: boolean
  ) => {
    dispatch({
      type: ConfigsActionTypes.RESOURCES_REMOVE,
      payload: {
        resourceCategoryId,
        isModalValue,
      },
    })
  }

  const handleResourcesReset = () => {
    dispatch({
      type: ConfigsActionTypes.RESOURCES_RESET,
      payload: {},
    })
  }

  const handleResourcesConfirm = () => {
    dispatch({
      type: ConfigsActionTypes.RESOURCES_CONFIRM,
      payload: {},
    })
  }

  const handleResourcesCancel = () => {
    dispatch({
      type: ConfigsActionTypes.RESOURCES_CANCEL,
      payload: {},
    })
  }

  const getResourceCategory = (
    resourceCategoryId: string,
    productId?: string
  ): ResourceCategoryDefinition | undefined => {
    const currentProduct = products.find((product) => product.id === productId)

    if (currentProduct) {
      const resourceCategories =
        get(
          currentProduct,
          "flatData.saleConfiguration[0].flatData.defaultCloudBluePlan[0].flatData.resourceCategories"
        ) || []

      return resourceCategories.find(
        (resourceCategory) => resourceCategory.id === resourceCategoryId
      )
    }

    const productsResourceCategories = getProductsResourceCategories(
      products,
      resourceCategoryId
    )

    return productsResourceCategories[0]
  }

  const getConfigModalResourceCategory = (resourceCategoryId: string) =>
    configsValues.modalResources.find(
      (resource) => resource.categoryId === resourceCategoryId
    )

  const getConfigResource = (resourceId: string) =>
    configsValues.resources.find((resource) => resource.id === resourceId)

  const getConfigModalResource = (resourceId: string) =>
    configsValues.modalResources.find((resource) => resource.id === resourceId)

  const getProductResource = (
    productId: string,
    resourceCategoryId: string,
    resourceId: string
  ) => {
    const productResources =
      getResourceCategory(resourceCategoryId, productId)?.resources || []

    const searchedResource = productResources.find(
      (resource) => resource.id === resourceId
    )

    return searchedResource
  }

  const getProductPeriodResource = (
    productId: string,
    resourceId: string
  ): ResourceData | null => {
    const productPeriodObject = getProductPeriodObject(
      products,
      configsValues.period,
      productId
    )

    if (isEmpty(productPeriodObject)) {
      return null
    }

    const searchedResource =
      productPeriodObject.resource_data.find(
        (resource) => resource.resource_id === parseInt(resourceId)
      ) || null

    return searchedResource
  }

  const getResourceLimits = (resourceId: string) => {
    const productsPeriodResources = products.flatMap((product) => {
      const productPeriodObject = getProductPeriodObject(
        products,
        configsValues.period,
        product.id
      )

      const productPeriodResources = productPeriodObject?.resource_data || []

      const filteredProductPeriodResources = productPeriodResources.filter(
        (productPeriodResource) => {
          return (
            productPeriodResource.resource_id.toString() ===
            resourceId.toString()
          )
        }
      )

      return filteredProductPeriodResources
    })

    const minValue = productsPeriodResources.reduce(
      (minValue, resource) =>
        resource.lower_limit > minValue ? resource.lower_limit : minValue,
      0
    )

    const maxValue = productsPeriodResources.reduce(
      (maxValue, resource) =>
        resource.upper_limit > maxValue ? resource.upper_limit : maxValue,
      0
    )

    return {
      min: minValue,
      max: maxValue,
    }
  }

  /**
   * @description to generate text, you need the period value from configsValues, which means that there must be a configuration box with periods
   */
  const getResourcePriceText = (
    resourceId: string,
    resourceCategoryId: string,
    showPricePerOneResource?: boolean
  ): string | null => {
    const resource = getConfigModalResource(resourceId)

    const resourceValue = resource?.value ?? 0

    return configsValues.period
      ? getResourcePriceValueText(
          products,
          resourceValue,
          resourceCategoryId,
          resourceId,
          configsValues.period,
          priceTypeContext?.currentPriceType,
          showPricePerOneResource
        )
      : null
  }

  /**
   * @description
   * method should return false when:
   *
   * product not have resource category that is active (exception is a category that is also displayed in the table column, such as IPv4 on the Windows VPS website, this product does not have to have category)
   *
   * product has resource category that is not active, but this only applies to categories whose configuration element is a select
   */
  const getHasProductActiveResourceCategories = (productId: string) => {
    const currentProduct = products.find((product) => product.id === productId)

    if (isEmpty(currentProduct)) {
      return false
    }

    const resourcesCellsCategoriesIds = currentProduct.flatData.cells
      .map((cell) => get(cell, "resourceCategory[0].flatData.remoteId") || null)
      .filter(Boolean)

    const configResourceCategoriesElementsIds =
      configs?.flatMap((configElement) => {
        const configResourceCategoryTypeData =
          configElement.type as CloudblueResourceCategoryElement

        const configResourceCategories =
          configResourceCategoryTypeData.resourceCategory

        if (isEmpty(configResourceCategories)) {
          return []
        }

        const configElementCategoriesIds = configResourceCategories.map(
          (resourceCategory) => resourceCategory.flatData.remoteId
        )

        return configElementCategoriesIds || []
      }) || []

    let hasActiveResourceCategories = true

    configResourceCategoriesElementsIds.forEach(
      (configResourceCategoryElementId) => {
        const resourceCategory = getResourceCategory(
          configResourceCategoryElementId.toString(),
          productId
        )

        const productHasActiveConfigResource = configsValues.resources.filter(
          (configResource) =>
            configResource.categoryId ===
            configResourceCategoryElementId.toString()
        )

        const isResourceCellCategoryId = resourcesCellsCategoriesIds.includes(
          configResourceCategoryElementId
        )

        if (
          isEmpty(resourceCategory) &&
          !isEmpty(productHasActiveConfigResource) &&
          !isResourceCellCategoryId
        ) {
          hasActiveResourceCategories = false
        }

        if (!isEmpty(resourceCategory)) {
          const isDisplayTypeSelect =
            resourceCategory.display_type === ResourceCategoryDisplayType.SELECT

          if (isDisplayTypeSelect && isEmpty(productHasActiveConfigResource)) {
            hasActiveResourceCategories = false
          }
        }
      }
    )

    return hasActiveResourceCategories
  }

  const getUrlConfigsValues = (productId: string): UrlConfigsValues => {
    let urlConfigsValues = {
      period: configsValues.period,
      parameters: {},
      resources: {},
    }

    configsValues.parameters.forEach((param) => {
      urlConfigsValues.parameters[param.paramUrl] = param.value
    })

    configsValues.resources.forEach((resource) => {
      const productResource = getProductResource(
        productId,
        resource.categoryId,
        resource.id
      )

      const productPeriodResource = getProductPeriodResource(
        productId,
        resource.id
      )

      if (isEmpty(productResource) || isEmpty(productPeriodResource)) {
        return
      }

      const resourceValue = getResourceValueByLimits(
        productPeriodResource.lower_limit,
        productPeriodResource.upper_limit,
        resource.value
      )

      if (resource.value > 0) {
        urlConfigsValues.resources[resource.id] = resourceValue
      }
    })

    return urlConfigsValues
  }

  const getCalculateProductPrices = (
    productPlanId: number
  ): ProductPriceResponse | null => {
    const calculateProductData = !isEmpty(calculateProducts)
      ? calculateProducts[productPlanId]
      : null

    return calculateProductData
  }

  const isCalculateProductsLoading =
    isQueryLoading &&
    !isEmpty(configsValues.resources.filter((resource) => resource.value > 0))

  return {
    configsValues,
    isCalculateProductsLoading,
    handlePeriodChange,
    handleParamChange,
    handleResourcesChange,
    handleResourcesRemove,
    handleResourcesReset,
    handleResourcesConfirm,
    handleResourcesCancel,
    getResourceCategory,
    getConfigResource,
    getConfigModalResource,
    getConfigModalResourceCategory,
    getResourceLimits,
    getProductResource,
    getProductPeriodResource,
    getResourcePriceText,
    getHasProductActiveResourceCategories,
    getUrlConfigsValues,
    getCalculateProductPrices,
  }
}

export default useTableConfigs
