import type { ReactElement } from "react"
import React from "react"
import FloatingButtonMol from "@onestore/hel/dist/components/molecules/FloatingButtonMol"
import type { FloatingButtonMolCommonProps } from "@gatsby-plugin-generic-page/components/FloatingCtaElement"
import type { IconPositionKeys } from "@gatsby-plugin-generic-page/fragments/floatingCtaElementSection"
import { IconPosition } from "@gatsby-plugin-generic-page/fragments/floatingCtaElementSection"
import FormModal from "~/components/FormModal"
import type { Form } from "~/fragments/form"
import { useFormHook } from "~/hooks/useFormHook"
import { EventCategory, sendGAFormEvent } from "~/lib/analytics"

export type FloatingButtonWithFormProps = FloatingButtonMolCommonProps & {
  form: Form[]
  iconPosition?: IconPositionKeys
}

function FloatingButtonWithForm({
  title,
  text,
  form,
  isVisible,
  icon,
  iconPosition,
}: FloatingButtonWithFormProps): ReactElement<FloatingButtonWithFormProps> {
  const [visible, setVisible] = React.useState(false)

  const { fetchData } = useFormHook(form[0].flatData.id)

  const onClick = (): void => {
    fetchData()
    sendGAFormEvent({
      event: EventCategory.FORM_VIEW,
      form_type: "toplayer",
      form_id: form[0].flatData.id,
    })
    setVisible(true)
  }

  return (
    <>
      <FloatingButtonMol
        icon={icon}
        iconPosition={iconPosition ? IconPosition[iconPosition] : undefined}
        title={title}
        text={text}
        onClick={onClick}
        isVisible={isVisible}
      />

      {visible ? (
        <FormModal
          headingText={form[0].flatData.title}
          id={form[0].flatData.id}
          onCloseButtonClick={() => {
            setVisible(false)
          }}
        />
      ) : null}
    </>
  )
}

export default FloatingButtonWithForm
