import type { ReactElement, ReactNode, SyntheticEvent } from "react"
import React from "react"
import type { ButtonAtmProps } from "@onestore/hel/dist/components/atoms/ButtonAtm"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import ImageLinkAtm from "@onestore/hel/dist/components/atoms/ImageLinkAtm"
import type { GaEvent } from "~/fragments/gaEvent"
import { useFormHook } from "~/hooks/useFormHook"
import { EventCategory, sendGAFormEvent } from "~/lib/analytics"
import type { FormModalProps } from "../FormModal"
import FormModal from "../FormModal"

interface ButtonProps
  extends Pick<
    ButtonAtmProps,
    | "color"
    | "variant"
    | "title"
    | "isWider"
    | "rel"
    | "size"
    | "isExpanded"
    | "isExpandedOnMobile"
  > {
  text: ReactNode | string
}

type RenderButton = {
  (onClick: { (event: SyntheticEvent): void }, isLoading: boolean): ReactElement
}

export interface FormButtonProps {
  formModal: Omit<FormModalProps, "onCloseButtonClick" | "id">
  formId: string
  button?: ButtonProps
  imageButton?: {
    image: ReactNode
    title: string
  }
  renderButton?: RenderButton
  gaEvent?: GaEvent
}

export default function FormButton({
  button,
  imageButton,
  formId,
  formModal,
  renderButton = undefined,
  gaEvent,
}: FormButtonProps): ReactElement<FormButtonProps> {
  const [visible, setVisible] = React.useState(false)
  const { isLoading, fetchData } = useFormHook(formId)

  const onClick = (event: SyntheticEvent): void => {
    event.stopPropagation()
    fetchData()
    sendGAFormEvent({
      event: EventCategory.FORM_VIEW,
      form_type: "toplayer",
      form_id: formId,
    })

    setVisible(true)
  }

  return (
    <>
      {renderButton ? renderButton(onClick, isLoading) : null}

      {!renderButton && imageButton ? (
        <ImageLinkAtm
          image={imageButton.image}
          title={imageButton.title}
          onClick={onClick}
        />
      ) : null}

      {!renderButton && button ? (
        <ButtonAtm
          data-test-id="form-button"
          title={button.title}
          variant={button.variant || "default"}
          color={button.color || "primary"}
          isWider={button.isWider}
          isExpanded={button.isExpanded}
          isExpandedOnMobile={button.isExpandedOnMobile}
          onClick={onClick}
          rel={button.rel}
          size={button.size}
        >
          {button.text}
        </ButtonAtm>
      ) : null}

      {visible ? (
        <FormModal
          headingText={formModal.headingText}
          id={formId}
          additionalData={formModal.additionalData || {}}
          button={
            formModal.button
              ? {
                  title: formModal.button.title,
                  text: formModal.button.text,
                  buttonColor: { color: formModal.button.buttonColor.color },
                }
              : undefined
          }
          onCloseButtonClick={() => {
            setVisible(false)
          }}
        />
      ) : null}
    </>
  )
}
