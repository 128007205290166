import type { ReactElement } from "react"
import React, { useContext } from "react"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import PackageBoxesSlimOrg from "@onestore/hel/dist/components/organisms/PackageBoxesSlimOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import WidthOrg from "@onestore/hel/dist/components/organisms/WidthOrg"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import Header from "@gatsby-plugin-generic-page/components/Header"
import InfoBar from "@gatsby-plugin-generic-page/components/InfoBar"
import ProductBoxSlim from "@gatsby-plugin-generic-page/components/ProductBoxSlim"
import SwitchPeriodAndTogglePriceType from "@gatsby-plugin-generic-page/components/SwitchPeriodAndTogglePriceType"
import type { ProductBoxSectionFlatData } from "@gatsby-plugin-generic-page/fragments/productBoxSection"
import getPeriodsByPeriodVariants from "@gatsby-plugin-generic-page/helpers/getPeriodsByPeriodVariants"
import hasPromotedItem from "@gatsby-plugin-generic-page/helpers/hasPromotedItem"
import { useIsomorphicLayoutEffect } from "@gatsby-plugin-generic-page/hooks/useIsomorphicLayoutEffect"
import ContentSwitcher from "~/components/ContentSwitcher"
import { HasAnyPromotionContextProvider } from "~/context/HasAnyPromotionContext"
import SwitchContentContext from "~/context/SwitchContentContext"
import isEmpty from "~/lib/isEmpty"

export default function ProductBoxPeriodsSwitcherSection({
  boxes,
  sectionSettings,
  subtitle,
  title,
  ctaElement,
  hasTogglePriceType,
  periodsSwitcher,
  hasStretchedChildren,
  infoBar,
}: ProductBoxSectionFlatData): ReactElement<ProductBoxSectionFlatData> {
  const boxesSpace = boxes.length > 2 ? 170 : 105
  const isSmallAndMediumScreen = useBreakpoint("small", "large")
  const promoTextIndexes = boxes
    .filter(
      (box) =>
        box.flatData.buttons[0].promoText &&
        box.flatData.buttons[0].color === "primary"
    )
    .map((box) => boxes.indexOf(box))

  const context = useContext(SwitchContentContext)
  const hasAnyPromotion = hasPromotedItem(boxes)
  const periods = getPeriodsByPeriodVariants(periodsSwitcher?.periods)

  useIsomorphicLayoutEffect()(() => {
    if (!isEmpty(periodsSwitcher)) {
      context?.setPeriodVariants(periodsSwitcher.periods)
    }

    if (periodsSwitcher?.defaultPeriodValue) {
      context?.setCurrentKey(periodsSwitcher.defaultPeriodValue)
    }
  }, [])

  if (!periodsSwitcher || isEmpty(periods)) {
    return <></>
  }

  return (
    <BaseSection
      {...sectionSettings}
      hasOverflowHidden={isSmallAndMediumScreen}
    >
      <BaseContainer {...sectionSettings?.containerSettings}>
        {title ? (
          <PushOrg bottomSpace={hasTogglePriceType ? 4 : 8}>
            <AlignContentOrg
              horizontalAlign="center"
              horizontalAlignType="flex"
            >
              <Header title={title} text={subtitle} />
            </AlignContentOrg>
          </PushOrg>
        ) : null}

        {hasTogglePriceType || !isEmpty(periods) ? (
          <SwitchPeriodAndTogglePriceType
            hasTogglePriceType={hasTogglePriceType}
            text={periodsSwitcher.text}
            bottomText={periodsSwitcher.bottomText}
            defaultPeriod={periodsSwitcher.defaultPeriodValue}
            initPeriods={periods}
            showAdditionalButtonDescription
          />
        ) : null}

        <ContentSwitcher
          items={periods}
          selected={context?.currentKey || periodsSwitcher.defaultPeriodValue}
        >
          {(selected) => (
            <AlignContentOrg
              horizontalAlign="center"
              horizontalAlignType="flex"
            >
              <WidthOrg widthSpace={boxesSpace}>
                <HasAnyPromotionContextProvider
                  hasAnyPromotion={hasAnyPromotion}
                >
                  <PackageBoxesSlimOrg
                    hasStretchedChildren={hasStretchedChildren}
                    promoTextIndexes={promoTextIndexes}
                  >
                    {boxes.map((box, index) => (
                      <ProductBoxSlim
                        period={selected.period}
                        key={box.id}
                        productBox={box}
                        index={index}
                      />
                    ))}
                  </PackageBoxesSlimOrg>
                </HasAnyPromotionContextProvider>
              </WidthOrg>
            </AlignContentOrg>
          )}
        </ContentSwitcher>

        {!isEmpty(ctaElement) && (
          <PushOrg topSpace={6}>
            <AlignContentOrg horizontalAlign="center">
              <CtaElement ctaElement={ctaElement} />
            </AlignContentOrg>
          </PushOrg>
        )}

        {!isEmpty(infoBar) && (
          <PushOrg topSpace={5}>
            <AlignContentOrg horizontalAlign="center">
              <WidthOrg widthSpace={120}>
                <InfoBar {...infoBar[0]} />
              </WidthOrg>
            </AlignContentOrg>
          </PushOrg>
        )}
      </BaseContainer>
    </BaseSection>
  )
}
