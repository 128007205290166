/**
 * @Module checkout/actions/office
 */
import { AxiosError } from "axios"
import type { ThunkAction } from "redux-thunk"
import type { MicrosoftAccountCheckInput } from "@onestore/api/types"
import { LegacyApiFormValidationError } from "@onestore/onestore-store-common/api"
import StoreAPI from "~/lib/api"
import { forceRedirectToIndex } from "~/lib/browser"
import {
  MicrosoftSpecialQualifications,
  MicrosoftTenantType,
} from "~/store/office/constants"
import type { AppDispatch, AppState } from "~/store/reducer"

export enum OfficeActionTypes {
  INPUT_VALUE_HAS_CHANGED = "CHECKOUT.ACTIONS.OFFICE.INPUT_VALUE_HAS_CHANGED",
  CHECK_DOMAIN_PENDING = "CHECKOUT.ACTIONS.OFFICE.CHECK_DOMAIN_PENDING",
  CHECK_DOMAIN_SUCCESS = "CHECKOUT.ACTIONS.OFFICE.CHECK_DOMAIN_SUCCESS",
  CHECK_DOMAIN_FAILURE = "CHECKOUT.ACTIONS.OFFICE.CHECK_DOMAIN_FAILURE",
  CHECK_DOMAIN_RESET = "CHECKOUT.ACTIONS.OFFICE.CHECK_DOMAIN_RESET",
  CHANGE_TENANT_TYPE = "CHECKOUT.ACTIONS.OFFICE.CHANGE_TENANT_TYPE",
  CHANGE_SPECIAL_QUALIFICATIONS = "CHECKOUT.ACTIONS.OFFICE.CHANGE_SPECIAL_QUALIFICATIONS",
}

type MicrosoftCheckDomainPendingAction = {
  type: OfficeActionTypes.CHECK_DOMAIN_PENDING
  account: MicrosoftAccountCheckInput
}

type MicrosoftCheckDomainFailureAction = {
  type: OfficeActionTypes.CHECK_DOMAIN_FAILURE
  account: MicrosoftAccountCheckInput
  response_status_code: number
}

type MicrosoftCheckDomainSuccessAction = {
  type: OfficeActionTypes.CHECK_DOMAIN_SUCCESS
  can_continue: boolean
  has_error: boolean
  domain: string
}

type MicrosoftChangeTenantTypeAction = {
  type: OfficeActionTypes.CHANGE_TENANT_TYPE
  tenant: MicrosoftTenantType
}

type MicrosoftCheckDomainResetAction = {
  type: OfficeActionTypes.CHECK_DOMAIN_RESET
}

type MicrosoftChangeSpecialQualificationsAction = {
  type: OfficeActionTypes.CHANGE_SPECIAL_QUALIFICATIONS
  value: MicrosoftSpecialQualifications
}
type MicrosoftInputValuesChangedAction = {
  type: OfficeActionTypes.INPUT_VALUE_HAS_CHANGED
}

export type OfficeAction =
  | MicrosoftInputValuesChangedAction
  | MicrosoftChangeSpecialQualificationsAction
  | MicrosoftCheckDomainResetAction
  | MicrosoftChangeTenantTypeAction
  | MicrosoftCheckDomainSuccessAction
  | MicrosoftCheckDomainFailureAction
  | MicrosoftCheckDomainPendingAction

export type OfficeThunkAction = ThunkAction<
  void,
  AppState,
  undefined,
  OfficeAction
>

export function checkDomain(account: MicrosoftAccountCheckInput) {
  return (
    dispatch: AppDispatch<OfficeThunkAction>,
    getState: { (): AppState }
  ) => {
    dispatch({
      type: OfficeActionTypes.CHECK_DOMAIN_PENDING,
      account,
    })

    const token = getState().basket.token

    if (!token) {
      forceRedirectToIndex()
    }

    return StoreAPI.checkMicrosoftSubdomainOwnership(token, account)
      .catch((error: AxiosError<LegacyApiFormValidationError>) => {
        dispatch({
          type: OfficeActionTypes.CHECK_DOMAIN_FAILURE,
          account,
          response_status_code: error.status,
        })
      })
      .then((response) => {
        if (!response) {
          return
        }

        dispatch({
          type: OfficeActionTypes.CHECK_DOMAIN_SUCCESS,
          can_continue: response.can_continue,
          has_error: response.has_error,
          domain: response.domain,
        })
      })
  }
}

/**
 * Akcja do resetowania stanu sprawdzenia domeny
 *
 * @function
 * @returns  {Promise}
 */
export function resetDomainCheck() {
  return (dispatch: AppDispatch<OfficeThunkAction>) => {
    dispatch({
      type: OfficeActionTypes.CHECK_DOMAIN_RESET,
    })
  }
}

export function changeTenantType(index: number) {
  return (dispatch: AppDispatch<OfficeThunkAction>) => {
    dispatch({
      type: OfficeActionTypes.CHANGE_TENANT_TYPE,
      tenant:
        index > 0 ? MicrosoftTenantType.EXISTING : MicrosoftTenantType.NEW,
    })
  }
}

export function setSpecialQualificationsAction(
  value: MicrosoftSpecialQualifications
) {
  return (dispatch: AppDispatch<OfficeThunkAction>) => {
    dispatch({
      type: OfficeActionTypes.CHANGE_SPECIAL_QUALIFICATIONS,
      value,
    })
  }
}

export function inputValueHasChanged() {
  return (dispatch: AppDispatch<OfficeThunkAction>) => {
    dispatch({
      type: OfficeActionTypes.INPUT_VALUE_HAS_CHANGED,
    })
  }
}
