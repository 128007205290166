import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import ContentWithImageOrg from "@onestore/hel/dist/components/organisms/ContentWithImageOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import Media from "@gatsby-plugin-generic-page/components/Media"
import Title from "@gatsby-plugin-generic-page/components/Title"
import ToggleWithLinkButtons from "@gatsby-plugin-generic-page/components/ToggleWithLinkButtons"
import UnderlineLinks from "@gatsby-plugin-generic-page/components/UnderlineLinks"
import type { SearchWithImageSectionFlatData } from "@gatsby-plugin-generic-page/fragments/searchWithImageSection"
import DomainInputField from "~/components/DomainInputField"
import isEmpty from "~/lib/isEmpty"

export default function SearchWithImageSection({
  title,
  toggleWithLinkButtons,
  htmlTag,
  content,
  domain,
  links,
  responsiveImages,
  hasImageStickyToBottom,
  isReversed,
  sectionSettings,
}: SearchWithImageSectionFlatData): ReactElement<SearchWithImageSectionFlatData> {
  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        {!isEmpty(toggleWithLinkButtons) ? (
          <PushOrg bottomSpace={{ small: 4, medium: 4, large: 5 }}>
            <AlignContentOrg horizontalAlign="center">
              <ToggleWithLinkButtons {...toggleWithLinkButtons} />
            </AlignContentOrg>
          </PushOrg>
        ) : null}

        <ContentWithImageOrg
          isReversedOnLarge={isReversed}
          image={<Media media={responsiveImages} />}
          content={
            <>
              <Title text={title} htmlTag={htmlTag} />

              {!isEmpty(content) ? (
                <MarkdownText>{content}</MarkdownText>
              ) : null}

              <PushOrg topSpace={6}>
                <DomainInputField
                  infoText={_t("domainSearch.domainInput.info")}
                  submitText={_t("domainSearch.domainInput.submit")}
                  placeholderText={_t("domainSearch.domainInput.placeholder")}
                  extension={
                    isEmpty(domain) ? undefined : domain[0].flatData.extension
                  }
                />
              </PushOrg>

              {!isEmpty(links) ? (
                <UnderlineLinks typography="small2" links={links} />
              ) : null}
            </>
          }
          hasImageStickyToBottom={hasImageStickyToBottom}
        />
      </BaseContainer>
    </BaseSection>
  )
}
