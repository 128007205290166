import { graphql, useStaticQuery } from "gatsby"

interface Query {
  GraphCmsSquidex: {
    findCheckoutConfigurationSingleton: {
      flatData: {
        hasStepsInHeader: boolean | null
        expandedTerms: { id: number }[]
        termsText: string
      }
    }
  }
}
export function useCheckoutConfigurationQuery(): Query["GraphCmsSquidex"]["findCheckoutConfigurationSingleton"]["flatData"] {
  const {
    GraphCmsSquidex: { findCheckoutConfigurationSingleton },
  } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        findCheckoutConfigurationSingleton {
          flatData {
            hasStepsInHeader
            termsText
            expandedTerms {
              id
            }
          }
        }
      }
    }
  `)

  return findCheckoutConfigurationSingleton.flatData
}
