import type { ReactNode } from "react"
import type { PeriodInfo } from "@core/period-info"
import { formatPrice } from "@core/pricing"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import isEmpty from "~/lib/isEmpty"
import { getSavingPriceTooltip } from "./getSavingPriceTooltip"
import { getSmallestPeriodInfoByPeriodName } from "./getSmallestPeriodInfoByPeriodName"

export type SavingPriceResults = {
  savingPriceFormatted: string
  savingTooltip: ReactNode | null
}

export const getSavingPrice = (
  planData: CloudBluePlan["flatData"],
  currentPeriodInfo: PeriodInfo,
  allConfigPeriodNames: string[],
  isNettoPriceType: boolean
): SavingPriceResults | null => {
  const basePeriodInfo = getSmallestPeriodInfoByPeriodName(
    planData,
    allConfigPeriodNames,
    currentPeriodInfo
  )

  const basePeriodType = basePeriodInfo.getPeriodType()
  const basePeriodLength = basePeriodInfo.getPeriodLength()
  const baseRegisterPrice = basePeriodInfo.getRegisterPriceValue()
  const baseRegisterPriceValue = isNettoPriceType
    ? baseRegisterPrice.netto
    : baseRegisterPrice.gross
  const baseRenewalPrice = basePeriodInfo.getRenewalPriceValue()

  const currentPeriodType = currentPeriodInfo.getPeriodType()
  const currentPeriodLength = currentPeriodInfo.getPeriodLength()
  const currentRegisterPrice = currentPeriodInfo.getRegisterPriceValue()
  const currentRegisterPriceValue = isNettoPriceType
    ? currentRegisterPrice.netto
    : currentRegisterPrice.gross

  if (
    !basePeriodLength ||
    !currentPeriodLength ||
    isEmpty(baseRenewalPrice) ||
    currentPeriodLength % basePeriodLength !== 0
  ) {
    return null
  }

  const baseRenewalPriceValue = isNettoPriceType
    ? baseRenewalPrice.netto
    : baseRenewalPrice.gross

  let renewalMultiplier = 0

  // base and smallest period month
  if (basePeriodType === 2) {
    renewalMultiplier = currentPeriodLength / basePeriodLength - 1

    // current period year
    if (currentPeriodType === 3) {
      renewalMultiplier = (currentPeriodLength * 12) / basePeriodLength - 1
    }
  }

  // basea and smallest period year
  if (basePeriodType === 3) {
    renewalMultiplier = currentPeriodLength / basePeriodLength - 1
  }

  const baseRenewalPriceMultiplier = baseRenewalPriceValue * renewalMultiplier

  const baseUpdatePriceValue =
    baseRegisterPriceValue + baseRenewalPriceMultiplier

  const saving = baseUpdatePriceValue - currentRegisterPriceValue

  const savingPriceFormatted = saving > 0 ? formatPrice(saving) : null

  if (isEmpty(savingPriceFormatted)) {
    return null
  }

  const savingTooltip = getSavingPriceTooltip(
    currentPeriodInfo,
    basePeriodInfo,
    renewalMultiplier,
    savingPriceFormatted,
    baseRenewalPriceMultiplier,
    baseUpdatePriceValue,
    isNettoPriceType
  )

  return {
    savingPriceFormatted,
    savingTooltip,
  }
}
