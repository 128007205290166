import type { ReactElement } from "react"
import React from "react"
import { createContext } from "react"

type HasFullScreenContainerContextProps = {
  value: boolean
  children: React.ReactNode
}

export const HasFullScreenContainerContext =
  createContext<HasFullScreenContainerContextProps["value"]>(false)

export function HasFullScreenContainerContextProvider({
  value,
  children,
}: HasFullScreenContainerContextProps): ReactElement<HasFullScreenContainerContextProps> {
  return (
    <HasFullScreenContainerContext.Provider value={value}>
      {children}
    </HasFullScreenContainerContext.Provider>
  )
}

export default HasFullScreenContainerContext
