import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import { useLocation } from "@reach/router"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import UnderlineLinkAtm from "@onestore/hel/dist/components/atoms/UnderlineLinkAtm"
import TooltipMol from "@onestore/hel/dist/components/molecules/TooltipMol"
import type { Size } from "@onestore/hel/dist/components/organisms/ContainerOrg/ContainerOrg.types"
import OverlayContainerOrg, {
  headerTypes,
  sizes,
  startPositions,
} from "@onestore/hel/dist/components/organisms/OverlayContainerOrg"
import type { CtaSectionsModal as CtaModalType } from "@gatsby-plugin-generic-page/fragments/ctaSectionsModal"
import { GenericPageSectionsRenderer } from "@gatsby-plugin-generic-page/GenericPage"
import getBaseMarkdownOverrides from "@gatsby-plugin-generic-page/helpers/getBaseMarkdownOverrides"
import useSectionsModal from "@gatsby-plugin-generic-page/hooks/useSectionsModal"
import { SquidexCtaElementType } from "@gatsby-plugin-generic-page/types/ctaElementType"
import MarkdownTitle from "../Markdown/Title"

function CtaSectionsModal({
  flatData: {
    header,
    sections,
    background,
    modalSize = sizes.FULL,
    modalStartPosition = startPositions.BOTTOM,
    modalHeaderType = headerTypes.SHADOWED,
    modalHeaderWidth = "default" as Size,
    elementType,
    text,
    title,
    color,
    variant,
    gaEvent,
  },
}: CtaModalType): ReactElement<CtaModalType> | null {
  const { isOpen, handleOpenOverlay, handleCloseOverlay } =
    useSectionsModal(gaEvent)
  const { hash } = useLocation()

  const renderCtaElement = () => {
    if (elementType === SquidexCtaElementType.LINK) {
      return (
        <UnderlineLinkAtm
          typography="small2"
          title={title}
          href={hash || "#"}
          onClick={handleOpenOverlay}
        >
          {text}
        </UnderlineLinkAtm>
      )
    } else if (elementType === SquidexCtaElementType.BUTTON) {
      return (
        <ButtonAtm
          title={title}
          onClick={handleOpenOverlay}
          color={color?.color}
          variant={variant?.variant}
        >
          {text}
        </ButtonAtm>
      )
    } else if (elementType === SquidexCtaElementType.TOOLTIP) {
      return (
        <TooltipMol
          labelTypography="nano1"
          labelEmphasis="high"
          iconSize="small"
          labelText={_t("domainSearch.mainDomainSortInfoLabel")}
          onClick={handleOpenOverlay}
        >
          ""
        </TooltipMol>
      )
    }

    return null
  }

  return (
    <>
      {renderCtaElement()}

      {isOpen ? (
        <OverlayContainerOrg
          hasSections
          size={modalSize || sizes.FULL}
          startPosition={modalStartPosition || startPositions.BOTTOM}
          headerType={modalHeaderType || headerTypes.SHADOWED}
          header={
            <MarkdownTitle
              overrides={getBaseMarkdownOverrides({
                typography: "xlarge",
              })}
            >
              {header}
            </MarkdownTitle>
          }
          headerWidth={modalHeaderWidth || "default"}
          backgroundColor={background?.background}
          onCloseButtonClick={handleCloseOverlay}
        >
          <GenericPageSectionsRenderer sections={sections} pageId={""} />
        </OverlayContainerOrg>
      ) : null}
    </>
  )
}

export default CtaSectionsModal
