import type { ReactElement } from "react"
import React, { type SyntheticEvent, useState } from "react"
import type { ButtonAtmProps } from "@onestore/hel/dist/components/atoms/ButtonAtm"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import type { FormProps } from "@gatsby-plugin-generic-page/components/Formily/Formily"
import Formily from "@gatsby-plugin-generic-page/components/Formily/Formily"
import type { Formily as FormilyType } from "@gatsby-plugin-generic-page/fragments/formily"
import ModalTemplateWrapper from "~/components/ModalTemplateWrapper"
import { EventCategory, sendGAFormEvent } from "~/lib/analytics"

type FormilyFormButtonProps = {
  button:
    | ButtonAtmProps
    | { (onClick: (event: SyntheticEvent) => void): ReactElement }
  formilyForm: FormilyType
  formAdditionalData?: FormProps["additionalData"]
}

export default function FormilyFormButton({
  button,
  formilyForm,
  formAdditionalData,
}: FormilyFormButtonProps): ReactElement<FormilyFormButtonProps> {
  const [modalOpen, setModalOpen] = useState(false)
  const { formButton, captcha, ...formilyProps } = formilyForm.flatData
  const onClick = (event: SyntheticEvent): void => {
    event.stopPropagation()

    sendGAFormEvent({
      event: EventCategory.FORM_VIEW,
      form_type: "toplayer",
      form_id: formilyForm.flatData.formId,
    })

    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      {typeof button === "function" ? (
        button(onClick)
      ) : (
        <ButtonAtm
          data-test-id="form-button"
          title={button.title}
          variant={button.variant || "default"}
          color={button.color || "primary"}
          isWider={button.isWider}
          isExpanded={button.isExpanded}
          isExpandedOnMobile={button.isExpandedOnMobile}
          onClick={onClick}
          rel={button.rel}
          size={button.size}
        >
          {button.text}
        </ButtonAtm>
      )}

      <ModalTemplateWrapper
        onCloseButtonClick={handleCloseModal}
        width="narrow"
        isOpen={modalOpen}
        content={
          <Formily
            captchaEnabled={captcha}
            button={formButton}
            onSubmitSuccess={() =>
              setTimeout(() => {
                handleCloseModal()
              }, 1000)
            }
            additionalData={formAdditionalData}
            {...formilyProps}
          />
        }
      />
    </>
  )
}
