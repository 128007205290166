import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import type { PeriodInfo } from "@core/period-info"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import CheckboxWithLabelMol from "@onestore/hel/dist/components/molecules/CheckboxWithLabelMol"
import type { TooltipMolProps } from "@onestore/hel/dist/components/molecules/TooltipMol"
import TooltipMol from "@onestore/hel/dist/components/molecules/TooltipMol"
import FlexItemOrg from "@onestore/hel/dist/components/organisms/FlexItemOrg"
import SpaceBetweenOrg from "@onestore/hel/dist/components/organisms/SpaceBetweenOrg"
import WrapOrg from "@onestore/hel/dist/components/organisms/WrapOrg"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import { productBoxClickEvent } from "~/lib/analytics"
import isEmpty from "~/lib/isEmpty"

type ProductBoxFooterProps = {
  label: string
  value?: string
  tooltip?: TooltipMolProps["children"]
  setIsChecked: () => void
  bundlePriceFormat: string
  isChecked: boolean
  isBundle?: boolean
  periodInfo?: PeriodInfo | null
}

export default function ProductBoxFooter({
  label,
  value,
  tooltip,
  setIsChecked,
  bundlePriceFormat,
  isChecked,
  isBundle,
  periodInfo,
}: ProductBoxFooterProps): ReactElement<ProductBoxFooterProps> | null {
  const { isNettoPriceType } = usePriceTypeContext()

  const onChange = () => {
    if (!isChecked) {
      productBoxClickEvent({
        productbox_type: "checkbox",
        productbox_option: label,
      })
    }
    setIsChecked()
  }

  const periodText = !isEmpty(periodInfo)
    ? `${isNettoPriceType ? _t("prices.netto") : _t("prices.brutto")} ${_t(
        `periodName.searchDomainPrice.3`,
        {
          smart_count: periodInfo?.getPeriodLength(),
        }
      )}`
    : undefined

  return (
    <>
      {isBundle ? (
        <SpaceBetweenOrg gutterSpace={2} align="start">
          <CheckboxWithLabelMol
            typography="small2"
            text={label || ""}
            tooltipText={tooltip}
            isChecked={isChecked}
            onChange={onChange}
            gutterSpace={1.5}
          />

          <FlexItemOrg flexShrink={0} alignSelf="flex-start">
            <TextAtm
              align="right"
              typography="small4"
              color={isChecked ? "success" : undefined}
            >
              {value || (bundlePriceFormat && `+ ${bundlePriceFormat}`)}
            </TextAtm>

            {isEmpty(value) && periodText ? (
              <TextAtm typography="xsmall1">{periodText}</TextAtm>
            ) : null}
          </FlexItemOrg>
        </SpaceBetweenOrg>
      ) : (
        <SpaceBetweenOrg gutterSpace={2}>
          <WrapOrg gutterSpace={1} alignCenter="horizontal">
            <TextAtm align="right" typography="small2">
              {label}
            </TextAtm>

            {tooltip ? <TooltipMol>{tooltip}</TooltipMol> : null}
          </WrapOrg>

          <FlexItemOrg flexShrink={0} alignSelf="flex-start">
            <TextAtm align="right" typography="small4" color="success">
              {value}
            </TextAtm>

            {isEmpty(value) && periodText ? (
              <TextAtm align="right" typography="xsmall1">
                {periodText}
              </TextAtm>
            ) : null}
          </FlexItemOrg>
        </SpaceBetweenOrg>
      )}
    </>
  )
}
