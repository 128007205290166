import type { ReactElement } from "react"
import React from "react"
import { typeMatches } from "@onestore-graphql"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import type { CloudblueResourceCategoryElement } from "@gatsby-plugin-generic-page/fragments/cloudblueResourceCategoryElement"
import type { DataCenterElement } from "@gatsby-plugin-generic-page/fragments/dataCenterElement"
import type { PeriodsTableSelect } from "@gatsby-plugin-generic-page/fragments/periodsTableSelect"
import type { TableConfig } from "@gatsby-plugin-generic-page/fragments/tableConfig"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import { useConfigsHookContext } from "../.."
import DataCenterConfigBox from "./elements/DataCenterConfigBox"
import PeriodsConfigBox from "./elements/PeriodsConfigBox"
import ResourcesModalConfigBox from "./elements/ResourcesModalConfigBox"

export type ConfigBoxesProps = {
  configs: TableConfig[]
}

export default function ConfigBoxes({
  configs,
}: ConfigBoxesProps): ReactElement<ConfigBoxesProps> {
  const { configsValues, handlePeriodChange, handleParamChange } =
    useConfigsHookContext() ?? {}

  const configsElements = configs.map((config) => {
    if (
      typeMatches(
        config.type.__typename,
        SquidexComponentType.PERIODS_TABLE_SELECT
      )
    ) {
      const configTypeData = config.type as PeriodsTableSelect

      return configsValues?.period && handlePeriodChange ? (
        <PeriodsConfigBox
          key={config.label}
          configLabel={config.label}
          configText={config.text}
          periodsData={configTypeData.periods}
          currentPeriod={configsValues.period}
          onChange={handlePeriodChange}
        />
      ) : null
    }

    if (
      typeMatches(
        config.type.__typename,
        SquidexComponentType.DATA_CENTER_ELEMENT
      )
    ) {
      const configTypeData = config.type as DataCenterElement
      const currentLocation = configsValues?.parameters.find(
        (param) => param.paramUrl === configTypeData.urlParameter
      )

      return currentLocation && handleParamChange ? (
        <DataCenterConfigBox
          key={config.label}
          configLabel={config.label}
          configText={config.text}
          currentLocation={currentLocation.value}
          onChange={(value: string) =>
            handleParamChange(configTypeData.urlParameter, value)
          }
        />
      ) : null
    }

    if (
      typeMatches(
        config.type.__typename,
        SquidexComponentType.RESOURCE_CATEGORY_ELEMENT
      )
    ) {
      const configTypeData = config.type as CloudblueResourceCategoryElement

      return (
        <ResourcesModalConfigBox
          key={config.label}
          label={config.label}
          text={config.text}
          resourcesCategories={configTypeData.resourceCategory}
        />
      )
    }

    return null
  })

  return (
    <GridOrg
      columns={{
        small: 1,
        medium: 1,
        large: configs.length,
      }}
      gutterSpace={{
        small: 1,
        medium: 1,
        large: 3,
      }}
    >
      {configsElements}
    </GridOrg>
  )
}
