import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import { getStaticPrices } from "@gatsby-plugin-generic-page/helpers/staticPrices"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import type { StaticPrice as StaticPriceType } from "~/fragments/staticPrice"

type StaticPriceProps = {
  staticPrice: StaticPriceType
}

function StaticPrice({
  staticPrice,
}: StaticPriceProps): ReactElement<StaticPriceProps> {
  const { currentPriceType, isNettoPriceType } = usePriceTypeContext()
  const staticRegisterPrice = getStaticPrices([staticPrice], currentPriceType)

  const periodName = Object.keys(staticRegisterPrice)[0]

  const priceValue = staticRegisterPrice[periodName].price.value

  const priceTypeText = isNettoPriceType
    ? _t("prices.netto")
    : _t("prices.gross")

  return (
    <>
      <strong>{priceValue}</strong> {priceTypeText}
    </>
  )
}

export default StaticPrice
