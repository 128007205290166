import type { ReactElement } from "react"
import React from "react"
import ConfigBoxMol from "@onestore/hel/dist/components/molecules/ConfigBoxMol"
import useIonosCloudConfigurationQuery from "~/hooks/useIonosCloudConfigurationQuery"
import isEmpty from "~/lib/isEmpty"
import SelectConfigElement from "./SelectConfigElement"

type DataCenterConfigBoxProps = {
  configLabel: string
  configText?: string
  currentLocation: string
  onChange: (value: string) => void
}

export default function DataCenterConfigBox({
  configLabel,
  configText,
  currentLocation,
  onChange,
}: DataCenterConfigBoxProps): ReactElement<DataCenterConfigBoxProps> {
  const locations = useIonosCloudConfigurationQuery()

  if (isEmpty(locations)) {
    return <></>
  }

  const selectOptions = locations.map((variant) => ({
    label: variant.label,
    value: variant.id,
    isSelected: variant.id === currentLocation,
  }))

  const selectConfigElement = (
    <SelectConfigElement
      options={selectOptions}
      onChange={(value) => onChange(value)}
    />
  )

  return (
    <ConfigBoxMol
      label={configLabel}
      text={configText}
      configElement={selectConfigElement}
    />
  )
}
