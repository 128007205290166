import { get } from "lodash"
import type { ProductBox } from "@gatsby-plugin-generic-page/types/productBoxTypes"

function hasPromotedItem(boxes: ProductBox[]): boolean {
  return boxes.some((boxItem) => {
    const periods = get(
      boxItem,
      "flatData.buttons[0].saleConfiguration[0].flatData.defaultCloudBluePlan[0].flatData.periods",
      undefined
    )

    return periods?.some((period) => period.promoted)
  })
}

export default hasPromotedItem
