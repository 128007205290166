import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import { getFormattedPeriodInfo } from "@core/period-info"
import { PriceFormat } from "@core/types"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import isEmpty from "~/lib/isEmpty"
import type { CloudBlueDomain } from "~/types/squidex"

type DomainEmbeddedPriceProps = {
  domainFlatData: CloudBlueDomain["flatData"]
}

function DomainEmbeddedPrice({
  domainFlatData,
}: DomainEmbeddedPriceProps): ReactElement<DomainEmbeddedPriceProps> | null {
  const { isNettoPriceType } = usePriceTypeContext()
  const { periods } = domainFlatData

  if (isEmpty(periods)) {
    return null
  }

  const periodDefault = periods.find((period) => period.default)

  if (isEmpty(periodDefault)) {
    return null
  }

  const price = getFormattedPeriodInfo(
    periods,
    periodDefault?.period_name,
    isNettoPriceType
      ? PriceFormat.REGISTER_NETTO_NO_PERIOD
      : PriceFormat.REGISTER_GROSS_NO_PERIOD
  )

  const priceTypeText = isNettoPriceType
    ? _t("prices.netto")
    : _t("prices.gross")

  return (
    <>
      <strong>{price}</strong> {priceTypeText}
    </>
  )
}

export default DomainEmbeddedPrice
