import type { ReactNode } from "react"
import React from "react"
import get from "lodash/get"
import {
  ParameterItem,
  ParametersGroup,
} from "@onestore/hel/dist/components/molecules/PackageBoxSlimMol/PackageBoxSlimMol"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import getBaseMarkdownOverrides from "@gatsby-plugin-generic-page/helpers/getBaseMarkdownOverrides"
import type {
  ProductBoxButtonType,
  ProductBoxParameters,
} from "@gatsby-plugin-generic-page/types/productBoxTypes"
import type { ProductBoxBundle } from "~/fragments/bundle"
import type { ProductBoxSlimContent as ProductBoxSlimContentProps } from "~/hooks/useProductBoxSlim"
import isEmpty from "~/lib/isEmpty"

export default function productBoxContent(
  boxParameters: ProductBoxParameters[],
  type: ProductBoxButtonType,
  bundle?: ProductBoxBundle[],
  isTrialBox?: boolean,
  icon?: ReactNode
): ProductBoxSlimContentProps {
  const contentParameters: ReactNode[] = []

  if (boxParameters && boxParameters.length > 0) {
    boxParameters.forEach((boxParameter) => {
      const { groupTitle, parameters } = boxParameter.flatData

      const parametersGroup = parameters.map((parameter) => (
        <ParameterItem
          key={parameter.label}
          label={{ text: parameter.label, isBold: parameter.isBoldLabel }}
          isInactive={parameter.isInactive}
          value={
            parameter.value
              ? { text: parameter.value, isBold: parameter.isBoldValue }
              : undefined
          }
          tooltip={
            !parameter.isInactive && parameter.tooltipText ? (
              <MarkdownText
                overrides={getBaseMarkdownOverrides({
                  typography: "small1",
                  boldTypography: "small4",
                })}
              >
                {parameter.tooltipText}
              </MarkdownText>
            ) : undefined
          }
          icon={isTrialBox ? icon : undefined}
          isTrialBox={isTrialBox}
        />
      ))

      contentParameters.push(
        <ParametersGroup
          key={groupTitle}
          title={groupTitle}
          hasNoBorder={isTrialBox}
        >
          {parametersGroup.map((parameter) => parameter)}
        </ParametersGroup>
      )
    })
  }

  const tooltipText: string | null = !isEmpty(bundle)
    ? get(bundle[0], "flatData.tooltip[0].flatData.text", null)
    : null

  const content: ProductBoxSlimContentProps = {
    parameters: contentParameters,
    footer: bundle?.length
      ? {
          isBundle: type === "plan" && !isEmpty(bundle[0].flatData.plan),
          label: bundle[0].flatData.text,
          value: bundle[0].flatData.value || undefined,
          tooltip: !isEmpty(tooltipText) ? (
            <MarkdownText
              overrides={getBaseMarkdownOverrides({
                typography: "small1",
                boldTypography: "small4",
              })}
            >
              {tooltipText}
            </MarkdownText>
          ) : undefined,
        }
      : undefined,
  }

  return content
}
