import { sendSiteSearchResultEvent } from "~/lib/analytics"
import { BonusDomainSearchActionType } from "../actions-domain-search-box"

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (next) => (action) => {
  if (action.type === BonusDomainSearchActionType.BONUS_DOMAIN_SEARCH_SUCCESS) {
    sendSiteSearchResultEvent(action.phrase)
  }

  return next(action)
}
