import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import type Theme from "@onestore/hel/dist/typings/theme"
import type { Size } from "@gatsby-plugin-generic-page/components/Formily/Input"

type Props = {
  buttonHref: string
  hasPromotion: boolean
  isExpanded?: boolean
  isWider?: boolean
}

const ButtonElement = ({
  buttonHref,
  hasPromotion,
  isExpanded,
  isWider,
}: Props): ReactElement<Props> => {
  const buttonData = {
    title: _t("marketplace.chooseButton"),
    href: buttonHref,
    color: hasPromotion ? ("primary" as Theme.AccentColor) : "dark",
    size: "small" as Size,
    isExpanded: isExpanded,
    isWider: isWider,
  }

  return (
    <ButtonAtm {...buttonData} data-testid="table-row-button">
      {buttonData.title}
    </ButtonAtm>
  )
}

export default ButtonElement
