import type { ReactElement, ReactNode } from "react"
import React from "react"
import { PeriodInfo } from "@core/period-info"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import useBasketActions from "@gatsby-plugin-basket/hooks/useBasketActions"
import { BasketActionSource } from "@gatsby-plugin-basket/store/actions"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import type { SaleConfiguration } from "~/fragments/saleConfigurations"
import isEmpty from "~/lib/isEmpty"
import { getSelectedPeriod } from "~/lib/plan"
import type { DrawerBundleData } from "../../ConfigDrawer"
import { addProductToCartWithRedirect } from "../../helpers/addProductToCartWithRedirect"
import { SummaryVisualElement } from "../SummaryVisualElement"

export type ProductWithBundleSummaryProps = {
  activePeriodName: string
  mainProductPlan: CloudBluePlan
  saleConfiguration: SaleConfiguration
  bundleTitleSuffix?: DrawerBundleData["bundleTitleSuffix"]
  bundlePlan: CloudBluePlan
}

export function ProductWithBundleSummary({
  activePeriodName,
  mainProductPlan,
  saleConfiguration,
  bundleTitleSuffix,
  bundlePlan,
}: ProductWithBundleSummaryProps): ReactElement<ProductWithBundleSummaryProps> | null {
  const { currentPriceType } = usePriceTypeContext()

  const mainProductPeriod = getSelectedPeriod(
    mainProductPlan.flatData,
    activePeriodName
  )
  const bundlePeriod = getSelectedPeriod(bundlePlan.flatData, undefined)

  const periodInfo =
    !isEmpty(mainProductPeriod) && !isEmpty(bundlePeriod)
      ? new PeriodInfo(mainProductPeriod, bundlePeriod)
      : null

  const { addProductToBasket, isLoading, isDone } = useBasketActions(
    mainProductPlan,
    bundlePlan,
    undefined,
    mainProductPeriod.id,
    true,
    BasketActionSource.CONFIG_DRAWER
  )

  if (isEmpty(periodInfo)) {
    return null
  }

  const bundleSuffix = ` + ${bundleTitleSuffix || bundlePlan.flatData.name}`
  const productName: ReactNode = (
    <>
      {mainProductPlan.flatData.name}
      <TextAtm color="success">{bundleSuffix}</TextAtm>
    </>
  )

  const handleOnClick = () =>
    addProductToCartWithRedirect(
      saleConfiguration,
      addProductToBasket,
      currentPriceType
    )

  return (
    <SummaryVisualElement
      data-testid="product-with-bundle-summary"
      productName={productName}
      periodInfo={periodInfo}
      onClick={handleOnClick}
      isLoading={isLoading}
      isDone={isDone}
    />
  )
}
