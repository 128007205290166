import { AxiosError } from "axios"
import type { ThunkAction } from "redux-thunk"
import type { Account } from "@onestore/api/account"
import type { Basket } from "@onestore/api/basket"
import { removeCloseConfirmation } from "@onestore/onestore-store-common/functions"
import storeApi from "~/lib/api"
import Storage from "~/lib/storage"
import type { AppDispatch, AppState } from "~/store/reducer"

export enum AccountActionTypes {
  ACTION_ACCOUNT_UPDATE = "@account/UPDATE",
  ACTION_ACCOUNT_LOGOUT = "@account/LOGOUT",
}

export interface AccountUpdateAction {
  type: AccountActionTypes.ACTION_ACCOUNT_UPDATE
  account: Account | null
}

export interface AccountLogoutAction {
  type: AccountActionTypes.ACTION_ACCOUNT_LOGOUT
}

export type AccountAction = AccountUpdateAction | AccountLogoutAction

export function createAccountUpdateAction(
  account: Account | null
): AccountUpdateAction {
  return {
    type: AccountActionTypes.ACTION_ACCOUNT_UPDATE,
    account,
  }
}

export function createAccountLogoutAction(): AccountLogoutAction {
  return {
    type: AccountActionTypes.ACTION_ACCOUNT_LOGOUT,
  }
}

export type AccountThunkAction = ThunkAction<
  void,
  AppState,
  undefined,
  AccountAction
>

export interface AccountActions {
  clearAccount: {
    (): AccountThunkAction
  }
  logoutAccount: {
    (token: string): AccountThunkAction
  }
  logoutRedirectAccount: {
    (token: Basket.Token, redirect: string): AccountThunkAction
  }
  saveSession: {
    (sessionId: string, expires: Date): AccountThunkAction
  }
  updateAccount: {
    (account: Account | null): AccountThunkAction
  }
}

export const clearAccount =
  () => (dispatch: AppDispatch<AccountThunkAction>) => {
    Storage.clearSessionId()
    dispatch(createAccountLogoutAction())
  }

export const logoutAccount =
  (token: Basket.Token, onLogout?: CallableFunction) =>
  async (dispatch: AppDispatch<AccountThunkAction>) => {
    await storeApi.deleteSession(token).catch((error: AxiosError) => {
      console.warn("Wystapił błąd podcza wylogowywania użytkownika", error)
    })

    Storage.clearSessionId()
    dispatch(createAccountLogoutAction())

    if (onLogout) {
      onLogout()
    }
  }

export const logoutRedirectAccount =
  (token: Basket.Token, redirect: string) =>
  async (dispatch: AppDispatch<AccountThunkAction>) => {
    await storeApi.deleteSession(token).catch((error: AxiosError) => {
      console.warn("Wystapił błąd podcza wylogowywania użytkownika", error)
    })

    Storage.clearSessionId()
    dispatch(createAccountLogoutAction())

    removeCloseConfirmation()
    document.location.href = redirect
  }

export const saveSession = (sessionId: string, expires: Date) => () => {
  Storage.setSession(sessionId, expires)
}

export const updateAccount =
  (account: Account | null) => (dispatch: AppDispatch<AccountThunkAction>) => {
    dispatch(createAccountUpdateAction(account))
  }
