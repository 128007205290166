import React from "react"
import type { ReactElement } from "react"
import _t from "@core/i18n"
import type { MarkdownToJSX } from "markdown-to-jsx"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import type { Texts as TextsType } from "@gatsby-plugin-generic-page/fragments/texts"
import getBaseMarkdownOverrides from "@gatsby-plugin-generic-page/helpers/getBaseMarkdownOverrides"
import isEmpty from "~/lib/isEmpty"
import MarkdownText from "../Markdown/Text"
import MarkdownTitle from "../Markdown/Title"

interface TextsProps extends TextsType {
  titleMarkdownOverrides?: MarkdownToJSX.Overrides
  textMarkdownOverrides?: MarkdownToJSX.Overrides
  textTopSpace?: number
  hasPromotionLabel?: boolean
}

function Texts({
  label,
  title,
  text,
  titleMarkdownOverrides,
  textMarkdownOverrides,
  textTopSpace = 3,
  hasPromotionLabel,
}: TextsProps): ReactElement<TextsProps> {
  const titleDefaultMarkdownOverrides = getBaseMarkdownOverrides({
    typography: "large2",
    boldTypography: "large3",
    pushSpace: 2,
  })

  const textDefaultMarkdownOverrides = getBaseMarkdownOverrides({
    typography: "small1",
    listSize: "small",
    boldTypography: "small3",
    pushSpace: 1,
  })

  return (
    <>
      {!isEmpty(label) || hasPromotionLabel ? (
        <PushOrg bottomSpace={2}>
          <FlexContainerOrg gapSpace={1}>
            {hasPromotionLabel ? (
              <LabelAtm variant="primaryOutlined" size="small">
                {_t("marketplace.discountTitle")}
              </LabelAtm>
            ) : null}

            {!isEmpty(label) ? (
              <LabelAtm variant={label.variant} size="small">
                {label.text}
              </LabelAtm>
            ) : null}
          </FlexContainerOrg>
        </PushOrg>
      ) : null}

      {!isEmpty(title) && (
        <MarkdownTitle
          overrides={titleMarkdownOverrides || titleDefaultMarkdownOverrides}
        >
          {title}
        </MarkdownTitle>
      )}

      {text ? (
        <PushOrg topSpace={textTopSpace}>
          <MarkdownText
            typography={{ default: "small1", bold: "small3" }}
            overrides={textMarkdownOverrides || textDefaultMarkdownOverrides}
          >
            {text}
          </MarkdownText>
        </PushOrg>
      ) : null}
    </>
  )
}

export default Texts
