import type { ReactElement } from "react"
import React from "react"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import type { LabelVariant } from "@onestore/hel/dist/components/atoms/LabelAtm/LabelAtm.types"
import type Theme from "@onestore/hel/dist/typings/theme"

type ProductBoxDiscountLabelProps = {
  label: string
  variant?: LabelVariant
  emphasis?: Theme.Emphasis | undefined
}

export default function ProductBoxDiscountLabel({
  label,
  variant,
  emphasis,
}: ProductBoxDiscountLabelProps): ReactElement<ProductBoxDiscountLabelProps> {
  return (
    <LabelAtm
      size="small"
      variant={variant ?? "notify"}
      emphasis={emphasis}
      wrapLabel
    >
      {label}
    </LabelAtm>
  )
}
