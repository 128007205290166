import type { ReactElement } from "react"
import React from "react"
import { typeMatches } from "@onestore-graphql"
import VideoAtm from "@onestore/hel/dist/components/atoms/VideoAtm"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import type Theme from "@onestore/hel/dist/typings/theme"
import Countdown from "@gatsby-plugin-generic-page/components/Countdown"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import DomainEmbeddedPrice from "@gatsby-plugin-generic-page/components/DomainEmbeddedPrice"
import PriceTypeContextText from "@gatsby-plugin-generic-page/components/PriceTypeContextText"
import SaleConfigurationPrice from "@gatsby-plugin-generic-page/components/SaleConfigurationPrice"
import StaticPrice from "@gatsby-plugin-generic-page/components/StaticPrice"
import type { Counter as CounterType } from "@gatsby-plugin-generic-page/fragments/counter"
import type { CtaElement as CtaElementType } from "@gatsby-plugin-generic-page/fragments/ctaElement"
import type { PriceTypeContextTextData } from "@gatsby-plugin-generic-page/fragments/priceTypeContextText"
import type { Video } from "@gatsby-plugin-generic-page/fragments/video"
import type { SaleConfiguration } from "~/fragments/saleConfigurations"
import type { StaticPrice as StaticPriceType } from "~/fragments/staticPrice"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"
import assetsSquidexToVideoObject from "~/lib/assetsSquidexToVideoObject"
import isEmpty from "~/lib/isEmpty"
import type {
  CloudBlueDomain,
  SquidexMarkdownEmbeddedContent,
} from "~/types/squidex"
import { ContentType } from "./contentType"

export type EmbeddedContentProps = SquidexMarkdownEmbeddedContent & {
  referenceId: string
  typography?: Theme.Typography
}

function EmbeddedContent({
  contents,
  referenceId,
  typography,
}: EmbeddedContentProps): ReactElement<EmbeddedContentProps> | null {
  const reference = contents?.find((content) => content.id === referenceId)

  if (
    !isEmpty(reference) &&
    typeMatches(reference.__typename, ContentType.VIDEO)
  ) {
    const { flatData } = reference as Video

    if (isEmpty(flatData.video)) {
      return null
    }

    const videoProps = {
      ...flatData,
      video: assetsSquidexToVideoObject(flatData.video),
      fallbackImg: assetsSquidexToImageObject(flatData.fallbackImage, ""),
      poster: flatData.poster ? flatData.poster[0].url : undefined,
    }

    return (
      <PushOrg bottomSpace={4} topSpace={4} hasNoReset>
        <VideoAtm {...videoProps} disablePictureInPicture />
      </PushOrg>
    )
  }

  if (
    !isEmpty(reference) &&
    typeMatches(reference.__typename, ContentType.CTA_ELEMENT)
  ) {
    return (
      <CtaElement
        typography={typography}
        ctaElement={[reference as CtaElementType]}
      />
    )
  }

  if (
    !isEmpty(reference) &&
    typeMatches(reference.__typename, ContentType.COUNTER)
  ) {
    const { flatData } = reference as CounterType

    return <Countdown isSmall {...flatData} />
  }

  if (
    !isEmpty(reference) &&
    typeMatches(reference.__typename, ContentType.SALE_CONFIGURATION)
  ) {
    const { flatData } = reference as SaleConfiguration

    return <SaleConfigurationPrice saleConfigurationFlatData={flatData} />
  }

  if (
    !isEmpty(reference) &&
    typeMatches(reference.__typename, ContentType.STATIC_PRICE)
  ) {
    const staticPrices = reference as StaticPriceType

    return <StaticPrice staticPrice={staticPrices} />
  }

  if (
    !isEmpty(reference) &&
    typeMatches(reference.__typename, ContentType.CLOUDBLUE_DOMAIN)
  ) {
    const { flatData } = reference as CloudBlueDomain

    return <DomainEmbeddedPrice domainFlatData={flatData} />
  }

  if (
    !isEmpty(reference) &&
    typeMatches(reference.__typename, ContentType.PRICE_TYPE_CONTEXT_TEXT)
  ) {
    const { flatData } = reference as PriceTypeContextTextData

    return <PriceTypeContextText flatData={flatData} />
  }

  return null
}

export default EmbeddedContent
