import type { EmailHackState } from "@gatsby-plugin-bonus/components/EmailHackBox/useEmailHackConfigurationQuery"
import type { BonusThunkAction } from "@gatsby-plugin-bonus/store/actions"
import type { AppDispatch } from "~/store/reducer"

export enum BonusEmailHackActionType {
  BONUS_EMAIL_HACK_HIDE_UPSELL = "@bonus/BONUS_EMAIL_HACK_HIDE_UPSELL",
  BONUS_ADD_EMAIL_HACK_DATA = "@bonus/ADD_EMAIL_HACK_DATA",
}

type BonusEmailHackHideUpsellAction = {
  hideUpsell: boolean
  type: BonusEmailHackActionType.BONUS_EMAIL_HACK_HIDE_UPSELL
}

type BonusAddEmailHackDataAction = {
  emailHack: EmailHackState
  type: BonusEmailHackActionType.BONUS_ADD_EMAIL_HACK_DATA
}

export type BonusEmailHackActions =
  | BonusEmailHackHideUpsellAction
  | BonusAddEmailHackDataAction

export function hideDomainUpsell() {
  return (dispatch: AppDispatch<BonusThunkAction>) => {
    dispatch({
      type: BonusEmailHackActionType.BONUS_EMAIL_HACK_HIDE_UPSELL,
      hideUpsell: true,
    } as BonusEmailHackHideUpsellAction)
  }
}

export function addEmailHackData(data: EmailHackState) {
  return (dispatch: AppDispatch<BonusThunkAction>) => {
    dispatch({
      type: BonusEmailHackActionType.BONUS_ADD_EMAIL_HACK_DATA,
      emailHack: data,
    } as BonusAddEmailHackDataAction)
  }
}
