import type { ReactElement, ReactNode } from "react"
import React from "react"
import _t from "@core/i18n"
import { PeriodInfo } from "@core/period-info"
import { PriceFormat } from "@core/types"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import AdvancedProductTileMol from "@onestore/hel/dist/components/molecules/AdvancedProductTileMol"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import Picture from "@onestore/hel/dist/components/quarks/Picture"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import type { Label } from "@gatsby-plugin-generic-page/fragments/label"
import AdvancedDomainItemPrice from "@gatsby-plugin-generic-page/sections/AdvancedDomainItemsSection/AdvancedDomainItemPrice"
import LimitTooltipLabel from "~/components/LimitTooltipLabel"
import { useCloudblueDomainInfo } from "~/hooks/useCloudblueDomainInfo"
import { selectDomainEvent } from "~/lib/analytics"
import { safeFind } from "~/lib/collection"
import isEmpty from "~/lib/isEmpty"

export interface DomainProps {
  planId: number
  link?: string
  tooltip?: string
  extension?: string
  period?: string
  label?: Label
  title?: string
  suffix?: string
  description?: ReactNode
  logo?: string
  periods: CloudBluePeriod[]
  onClick?: { (): void }
}

export default function AdvancedDomainItem({
  planId,
  extension,
  period,
  title,
  suffix,
  label,
  description,
  link,
  logo,
  periods,
}: DomainProps): ReactElement<DomainProps> | null {
  const { tooltip, limitTooltip, priceInfo } = useCloudblueDomainInfo(planId)

  const periodInfo = new PeriodInfo(
    periods.find((item) => item.period_name === period) ||
      safeFind(periods, (item) => item.default ?? false)
  )

  const hasPromotion = periodInfo.hasPromotion()
  const hasLimitTooltip = periodInfo.hasLimit()

  function SendSelectDomainEvent() {
    selectDomainEvent(
      periods,
      periodInfo.getFormatted(PriceFormat.REGISTER_NETTO_NO_CURRENCY_NO_PERIOD),
      title
    )
  }

  return (
    <AdvancedProductTileMol
      label={
        hasPromotion || hasLimitTooltip ? (
          <FlexContainerOrg gapSpace={1} wrap="wrap">
            {hasPromotion ? (
              <LabelAtm size="small" variant="primaryOutlined" emphasis="high">
                {_t("marketplace.discountTitle")}
              </LabelAtm>
            ) : !isEmpty(label) ? (
              <LabelAtm size="small" variant={label?.variant}>
                {label?.text}
              </LabelAtm>
            ) : null}

            {hasLimitTooltip && limitTooltip ? (
              <LimitTooltipLabel tooltipText={limitTooltip} />
            ) : null}
          </FlexContainerOrg>
        ) : undefined
      }
      logo={
        <Picture
          srcData={logo ? logo : ""}
          alt={`${_t("domains.domain")} .${extension}`}
        />
      }
      title={
        <TextAtm typography="large3">{title ? title : `.${extension}`}</TextAtm>
      }
      description={
        <TextAtm typography="xsmall1" emphasis="medium">
          {description}
        </TextAtm>
      }
      price={
        <AdvancedDomainItemPrice
          periodInfo={periodInfo}
          priceInfo={priceInfo}
          hasPromotion={hasPromotion}
          suffix={suffix}
          tooltip={tooltip}
        />
      }
      link={{
        title: `${_t("domains.domain")} .${extension}`,
        to: link,
      }}
      data-test-id="domain-ext-tile"
      onClick={SendSelectDomainEvent}
    />
  )
}
