import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import { PriceTypeContextTextData } from "@gatsby-plugin-generic-page/fragments/priceTypeContextText"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import MarkdownText from "../Markdown/Text"

type PriceTypeContextTextProps = {
  flatData: PriceTypeContextTextData["flatData"]
}

function PriceTypeContextText({
  flatData,
}: PriceTypeContextTextProps): ReactElement<PriceTypeContextTextProps> {
  const { isNettoPriceType } = usePriceTypeContext()
  const { grossStateText, nettoStateText } = flatData

  return (
    <MarkdownText>
      {isNettoPriceType ? nettoStateText : grossStateText}
    </MarkdownText>
  )
}

export default PriceTypeContextText
