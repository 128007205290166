import type { Draft } from "immer"
import type { BonusState } from "@gatsby-plugin-bonus/types"
import type { BonusAction } from "./actions"
import reducerBasket from "./reducer-basket"
import reducerBonus from "./reducer-bonus"
import reducerBundle from "./reducer-bundle"
import reducerDomainBox from "./reducer-domain-box"
import reducerDomainSearch from "./reducer-domain-search"
import reducerEmailHack from "./reducer-email-hack"
import reducerForm from "./reducer-form"
import reducerProduct from "./reducer-product"
import reducerUpsell from "./reducer-upsell"
import { initialState } from "./selectors"

const reducers = [
  reducerBonus,
  reducerBasket,
  reducerBundle,
  reducerDomainBox,
  reducerDomainSearch,
  reducerEmailHack,
  reducerProduct,
  reducerUpsell,
  reducerForm,
]

export default function reducer(
  state: BonusState = initialState,
  action: BonusAction
): BonusState {
  let previousState: BonusState = state
  let nextState: Draft<BonusState> = initialState
  reducers.forEach((reducer) => {
    // @ts-ignore, jedynym rozwiązaniem jest przenieść wszystkie reducery do jednego pliku
    nextState = reducer(previousState, action)

    if (nextState !== previousState) {
      previousState = nextState
    }
  })

  return nextState
}
