import _t from "@core/i18n"
import type { AxiosError } from "axios"
import type { ValidationErrors } from "final-form"
import { FORM_ERROR } from "final-form"
import { forEach, get, isObject, keyBy, mapValues } from "lodash"
import type { SelectOption } from "@onestore/hel/dist/components/molecules/SelectMol/SelectMol.types"
import type { ChoiceTypeRowProps } from "~/components/Form/ChoiceTypeRow"
import type { FormAdditionalDataItem } from "~/fragments/form"
import type {
  ChoiceTypeRowOptions,
  FormData,
  FormEntry,
  FormErrors,
  FormField,
  FormResponse,
  FormResponseField,
  FormValidationErrors,
  FormValues,
} from "../../lib/api/forms"
import isEmpty from "./isEmpty"

export function createFormValidationErrorsFromResponse(
  response: FormResponse
): FormErrors {
  const errors: FormErrors = {}

  const mainError = get(response, "errors", []).join(", ")

  if (mainError) {
    errors[FORM_ERROR] = mainError
  }

  forEach(
    get(response, "form.children", []),
    (item: FormResponseField, key: string) => {
      const fieldErrors = get(item, "errors", [])

      if (fieldErrors.length !== 0) {
        errors[key] = fieldErrors.join(", ")
      }

      forEach(item.children, (subItem: FormResponseField, subKey: string) => {
        const subFieldErrors = get(subItem, "errors", [])

        if (subFieldErrors.length !== 0) {
          errors[`${key}[${subKey}]`] = subFieldErrors.join(", ")
        }
      })
    }
  )

  return errors
}

export function getErrorsWithChildField(
  name: string,
  values: ValidationErrors,
  errors: FormValidationErrors
): FormValidationErrors {
  const parentFieldName = name.substring(0, name.indexOf("["))

  const childFieldName = name.substring(
    name.indexOf("[") + 1,
    name.indexOf("]")
  )

  if (
    values === undefined ||
    !values[parentFieldName] ||
    !values[parentFieldName][childFieldName]
  ) {
    errors[parentFieldName] = errors[parentFieldName] || {}

    errors[parentFieldName][childFieldName] = _t(
      "invoices.form.errors.notEmpty"
    )
  }

  return errors
}

export function createFormValidationErrors(required: string[]): {
  (values: ValidationErrors)
} {
  return (values: ValidationErrors) => {
    let errors: FormValidationErrors = {}

    required.forEach((name) => {
      if (name.includes("[") && name.includes("]")) {
        errors = getErrorsWithChildField(name, values, errors)
      } else if (values === undefined || !values[name]) {
        errors[name] = _t("invoices.form.errors.notEmpty")
      }
    })

    return errors
  }
}

export function handleFormSubmitErrors(error: AxiosError<FormResponse>) {
  const { response } = error

  if (response && response.status >= 400 && response.status < 500) {
    return createFormValidationErrorsFromResponse(response.data)
  }

  throw error
}

export function parseFormData(formData: FormEntry[]): FormData {
  const required: string[] = []
  let captchaField: string | null = null

  get(formData, "fields", []).forEach((field: FormField) => {
    if (field.type === "HiddenType" && field.name === "captcha") {
      captchaField = field.name
    }

    if (!isEmpty(field.children) && isObject(field.children)) {
      Object.values(field.children).forEach((child: FormField) => {
        if (child.required) {
          required.push(child.name)
        }
      })
    } else if (field.required) {
      required.push(field.name)
    }
  })

  return {
    fields: get(formData, "fields", []),
    required,
    target: get(formData, "target", ""),
    captchaField,
  }
}

export function getAdditionalDataFromSquidex(
  data: FormAdditionalDataItem[]
): Record<string, string> {
  const items = keyBy(data, (item) => item.name)

  return mapValues(items, (item) => item.value)
}

export function getChoiceTypeRowOptions(
  defaultOption: SelectOption,
  choices: ChoiceTypeRowProps["choices"],
  groups: ChoiceTypeRowProps["groups"],
  activeSecondSelect: string | null
): ChoiceTypeRowOptions {
  let options: SelectOption[] = [defaultOption]

  if (groups) {
    const groupsOptions = Object.entries(groups).map(([value, label]) => {
      return {
        value,
        label: isObject(label) ? value : label,
        isSelected: false,
      }
    })

    options = [...options, ...groupsOptions]
  } else {
    const choicesOptions = [
      ...Object.entries(choices).map(([value, label]) => {
        return {
          value,
          label,
          isSelected: false,
        }
      }),
    ]

    options = [...options, ...choicesOptions]
  }

  const secondSelectOptions: SelectOption[] | undefined =
    groups && activeSecondSelect
      ? [
          ...Object.entries(groups[activeSecondSelect]).map(
            ([value, label]) => {
              return {
                value,
                label,
                isSelected: false,
              }
            }
          ),
        ]
      : undefined

  return {
    options,
    secondSelectOptions,
  }
}

export function getValuesWithoutFormTypeElements(
  values: FormValues
): FormValues {
  let valuesWithoutFormTypeElements: FormValues = {}

  Object.entries(values).forEach(([key, value]) => {
    if (isObject(value)) {
      Object.entries(value).forEach(([childKey, childValue]) => {
        valuesWithoutFormTypeElements[`${key}[${childKey}]`] = childValue
      })
    } else {
      valuesWithoutFormTypeElements[key] = value
    }
  })

  return valuesWithoutFormTypeElements
}
