import React from "react"
import type { ReactElement } from "react"
import _t from "@core/i18n"
import type { UnderlineLinkAtmProps } from "@onestore/hel/dist/components/atoms/UnderlineLinkAtm"
import UnderlineLinkAtm from "@onestore/hel/dist/components/atoms/UnderlineLinkAtm"
import SimplePageHeaderMol from "@onestore/hel/dist/components/molecules/SimplePageHeaderMol"
import StepsMol from "@onestore/hel/dist/components/molecules/StepsMol"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import useMenuQuery from "@gatsby-plugin-onestore/hooks/useMenuQuery"
import TogglePriceType from "~/components/TogglePriceType"
import isEmpty from "~/lib/isEmpty"
import { getStepsData } from "./helpers/getStepsData"

export type HeaderWithStepsProps = {
  isBasketPage?: boolean
  isBonusPage?: boolean
  isCheckoutPage?: boolean
  hasBottomBar?: boolean
  linkData?: Pick<UnderlineLinkAtmProps, "to" | "title">
}

function HeaderWithSteps({
  isBonusPage,
  isBasketPage,
  isCheckoutPage,
  linkData,
  hasBottomBar = false,
}: HeaderWithStepsProps): ReactElement | null {
  const { menu } = useMenuQuery()

  const stepsData = getStepsData(isBonusPage, isBasketPage, isCheckoutPage)

  const linkElement = (
    <UnderlineLinkAtm
      to={!isEmpty(linkData) ? linkData.to : "/"}
      title={
        !isEmpty(linkData) ? linkData.title : _t("checkout.backToMainPage")
      }
      typography="tiny1"
    >
      {!isEmpty(linkData) ? linkData.title : _t("checkout.backToMainPage")}
    </UnderlineLinkAtm>
  )

  const stepsElement = !isEmpty(stepsData) ? (
    <StepsMol steps={stepsData} />
  ) : null

  const bottomBarElement = (
    <AlignContentOrg horizontalAlign="right" horizontalAlignType="both">
      <TogglePriceType />
    </AlignContentOrg>
  )

  return (
    <SimplePageHeaderMol
      logo={{
        title: menu.flatData.siteName,
        to: "/",
        src: menu.flatData.logo[0].url,
        alt: `Logo ${menu.flatData.siteName}`,
      }}
      mobileBottomChildren={stepsElement}
      bottomBar={hasBottomBar ? bottomBarElement : null}
    >
      {!isEmpty(stepsElement) ? (
        <BreakpointVisibilityOrg from="medium">
          {stepsElement}
        </BreakpointVisibilityOrg>
      ) : null}

      {linkElement}
    </SimplePageHeaderMol>
  )
}

export default HeaderWithSteps
