import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg/"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import type { Label } from "@gatsby-plugin-generic-page/fragments/label"

interface Props {
  cbName: string
  value?: string
  labelElement?: Label
  hasPromotion?: boolean
}

export default function NameCell({
  cbName,
  value,
  labelElement,
  hasPromotion,
}: Props): ReactElement<Props> {
  const textElement = (
    <MarkdownText
      typography={{
        default: "medium3_h",
        bold: "medium3_h",
      }}
      pushSpace={0}
      hasOverridedSmallTypography
    >
      {value || cbName}
    </MarkdownText>
  )

  const label =
    labelElement || hasPromotion ? (
      <FlexContainerOrg gapSpace={1} wrap="wrap">
        {hasPromotion ? (
          <LabelAtm size="small" variant="primaryOutlined" emphasis="high">
            {_t("packageBox.discountTitle")}
          </LabelAtm>
        ) : null}

        {labelElement ? (
          <LabelAtm size="small" variant={labelElement.variant}>
            {labelElement.text}
          </LabelAtm>
        ) : null}
      </FlexContainerOrg>
    ) : null

  return (
    <>
      {label ? <PushOrg bottomSpace={0.5}>{label}</PushOrg> : null}
      {textElement}
    </>
  )
}
