import type { ReactElement } from "react"
import React from "react"
import type { DomainCheck } from "@onestore/api/domainSearch"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import DomainAddButton from "@gatsby-plugin-domain-search/components/Button/DomainAddButton"
import { Price } from "@gatsby-plugin-domain-search/components/MainResultBox/Price"

interface AsideProps {
  mainDomain: DomainCheck.Result
  eventSource?: string
}
export function Aside({ mainDomain, eventSource }: AsideProps): ReactElement {
  return (
    <AlignContentOrg
      horizontalAlign={{
        large: "right",
        medium: "right",
        small: "left",
      }}
    >
      <PushOrg bottomSpace={2}>
        <AlignContentOrg
          horizontalAlign={{
            large: "right",
            medium: "right",
            small: "left",
          }}
        >
          <Price domain={mainDomain} />
        </AlignContentOrg>
      </PushOrg>

      <DomainAddButton
        color="success"
        isWider
        domain={mainDomain}
        eventSource={eventSource}
      />
    </AlignContentOrg>
  )
}
