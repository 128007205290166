import { useState } from "react"
import _t from "@core/i18n"
import { clone, filter, get } from "lodash"
import type { HeaderProps } from "@onestore/hel/dist/components/molecules/TableMol/Header"
import { getUpsellData } from "@gatsby-plugin-generic-page/helpers/getUpsellData"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import type { ComparableProduct } from "~/fragments/comparableProduct"
import useLinkProperties from "~/hooks/useLinkProperties"
import useLinkPropertiesCollection from "~/hooks/useLinkPropertiesCollection"
import url from "~/lib/url"
import type { ComparableProductsPackage, PeriodName } from "~/types/squidex"

export default function useCurrentProducts(
  products: ComparableProduct[],
  packages?: ComparableProductsPackage[],
  showOnlyTwoColumns?: boolean,
  selectedPeriod?: PeriodName
): [ComparableProduct[], HeaderProps["columns"]] {
  const headerColumns: HeaderProps["columns"] = []
  const comparableProducts: ComparableProduct[][] = []
  const activeComparableProductsPackage: ComparableProduct[] = []

  const { currentPriceType } = usePriceTypeContext()

  packages &&
    packages.forEach((productsPackage) => {
      const {
        flatData: { externalProducts },
      } = productsPackage

      activeComparableProductsPackage.push(externalProducts[0])
      comparableProducts.push(externalProducts)
    })

  const [activePackagesProducts, setActivePackagesProducts] = useState<
    ComparableProduct[]
  >(activeComparableProductsPackage)

  const handleOnClick = (newProduct, productId) => {
    const newPackagesProducts = activeComparableProducts.map((product) =>
      productId === product.id ? newProduct : product
    )

    setActivePackagesProducts(newPackagesProducts)
  }

  const items = packages
    ? comparableProducts[0].concat(comparableProducts[1], comparableProducts[2])
    : comparableProducts

  let activePackages = clone(activePackagesProducts)

  if (showOnlyTwoColumns) {
    activePackages = activePackages.splice(0, 2)
  }

  const urls = useLinkPropertiesCollection(
    activePackagesProducts
      .map((product): string | undefined =>
        get(product, "flatData.pages.0.flatData.url")
      )
      .filter((url): url is string => typeof url === "string")
  )

  activePackagesProducts.forEach((product, index) => {
    const {
      id,
      flatData: { name, pages, saleConfigurations },
    } = product

    let continueUrl

    const productProperties =
      pages && pages.length > 0 && urls[pages[0].flatData.url]

    const defaultCloudBluePlan =
      saleConfigurations &&
      saleConfigurations[0]?.flatData.defaultCloudBluePlan[0]?.flatData.alias

    if (defaultCloudBluePlan) {
      const bonusUrl = url.generateBonusUrl({
        p: defaultCloudBluePlan,
        ax: getUpsellData(saleConfigurations[0].flatData.upsell),
        period: selectedPeriod,
        priceType: currentPriceType,
      })

      // eslint-disable-next-line react-hooks/rules-of-hooks
      continueUrl = useLinkProperties(bonusUrl)
    }

    const filtredItems = filter(items, (item: ComparableProduct) => {
      if (item.id === id) {
        return item
      }

      return !activePackages.includes(item)
    })

    const headerData = {
      title: name,
      titleHref: productProperties ? productProperties.href : undefined,
      titleOnClick: productProperties ? productProperties.onClick : undefined,
      button: continueUrl
        ? {
            title: name,
            onClick: continueUrl.onClick,
            href: continueUrl.href,
            text: _t("marketplace.chooseButton"),
          }
        : undefined,
      dropdown: {
        label: _t("marketplace.changePackage"),
        items: Object.keys(filtredItems).map((itemId) => ({
          label: filtredItems[itemId].flatData.name,
          isActive: filtredItems[itemId].id === id,
          onClick: () => handleOnClick(filtredItems[itemId], id),
        })),
      },
    }

    headerColumns.push(headerData)
  })

  const activeComparableProducts = packages ? activePackagesProducts : products

  return [activeComparableProducts, headerColumns]
}
