import _t from "@core/i18n"
import { PeriodInfo } from "@core/period-info"
import { getCurrencySign } from "@core/pricing"
import { PriceType } from "@core/types"
import { PriceFormat } from "@core/types"
import { typeMatches } from "@onestore-graphql"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import type { BundleButtonWithPrice } from "@gatsby-plugin-generic-page/fragments/bundleButtonWithPrice"
import type { StaticPrice } from "~/fragments/staticPrice"
import isEmpty from "~/lib/isEmpty"
import { getBundlePricesFromDefinitionForType } from "~/lib/pricing"
import type { CloudBlueDomain, SquidexPriceType } from "~/types/squidex"
import { SquidexReferenceType } from "../types/referenceType"
import getPriceFromPlan from "./getPriceFromPlan"
import { getStaticPrices } from "./staticPrices"

export interface PriceData {
  price: {
    discount?: string
    prefix?: string
    value: string
    currency: string
    suffix: string
  }
  renewPrice?: {
    value: string
    currency: string
    suffix: string
  }
}

function getPriceBySquidexType(
  price: SquidexPriceType,
  priceType: PriceType
): PriceData | null {
  if (isEmpty(price)) {
    return null
  }

  const { __typename, flatData } = price[0]
  const currency = getCurrencySign()
  let priceData

  if (typeMatches(__typename, SquidexReferenceType.CLOUDBLUE_PLAN)) {
    const { periods } = flatData as CloudBluePlan["flatData"]

    if (isEmpty(periods)) {
      return null
    }

    const defaultPeriod = periods.find((period) => period.default)

    if (!defaultPeriod) {
      return null
    }

    const isPriceTypeNetto = priceType === PriceType.NETTO

    const periodInfo = new PeriodInfo(defaultPeriod)
    const suffix = periodInfo.getFormatted(
      isPriceTypeNetto
        ? PriceFormat.NETTO_PRICE_TYPE_WITH_PERIOD_NAME
        : PriceFormat.GROSS_PRICE_TYPE_WITH_PERIOD_NAME
    )

    priceData = {
      price: {
        value: periodInfo.getFormatted(
          isPriceTypeNetto
            ? PriceFormat.REGISTER_NETTO_NO_CURRENCY_NO_PERIOD
            : PriceFormat.REGISTER_GROSS_NO_CURRENCY_NO_PERIOD
        ),
        currency,
        suffix,
      },
      renewPrice: periodInfo.periodValue.price_renew && {
        value: periodInfo.getFormatted(
          isPriceTypeNetto
            ? PriceFormat.RENEW_NETTO_NO_CURRENCY_NO_PERIOD
            : PriceFormat.RENEW_GROSS_NO_CURRENCY_NO_PERIOD
        ),
        currency,
        suffix,
      },
    }
  }

  if (typeMatches(__typename, SquidexReferenceType.STATIC_PRICE)) {
    const prices = getStaticPrices(price as StaticPrice[], priceType, true)

    if (!prices) {
      return null
    }

    const { period } = price[0].flatData as StaticPrice["flatData"]

    const { prefix, value, suffix } = prices[period].price
    const renewalPrice = prices[period].renewPrice

    priceData = {
      price: {
        prefix,
        value,
        currency,
        suffix,
      },
      renewPrice: renewalPrice
        ? {
            value: renewalPrice.value,
            suffix: renewalPrice.suffix,
            currency,
          }
        : undefined,
    }
  }

  if (typeMatches(__typename, SquidexReferenceType.BUNDLE_BUTTON_WITH_PRICE)) {
    const { calculatedPrice } = price[0]
      .flatData as BundleButtonWithPrice["flatData"]

    const bundlePrice = getBundlePricesFromDefinitionForType(
      calculatedPrice[0],
      priceType
    )

    const periodText =
      bundlePrice.periodType &&
      bundlePrice.periodValue &&
      bundlePrice.periodValue > 0
        ? _t(`periodName.short.${bundlePrice.periodType}`, {
            smart_count: bundlePrice.periodValue,
          })
        : null

    const suffix =
      priceType === PriceType.NETTO
        ? ` / ${periodText}` || ""
        : `${_t(`prices.${priceType}`)} ${periodText ? ` / ${periodText}` : ""}`

    priceData = {
      price: {
        discount: bundlePrice.discount,
        value: bundlePrice.primaryTypeRegularPrice.full,
        currency,
        suffix,
      },
      renewPrice: bundlePrice.primaryTypeRenewPrice && {
        value: bundlePrice.primaryTypeRenewPrice.full,
        currency,
        suffix,
      },
    }
  }

  if (typeMatches(__typename, SquidexReferenceType.CLOUDBLUE_DOMAIN)) {
    const { flatData } = price[0] as CloudBlueDomain
    const defaultPeriod = flatData.periods.find((period) => period.default)
    const isPriceTypeNetto = priceType === PriceType.NETTO

    if (defaultPeriod) {
      const periodInfo = new PeriodInfo(defaultPeriod)

      const { suffix, value } = getPriceFromPlan(periodInfo, isPriceTypeNetto)

      priceData = {
        price: {
          value,
          currency,
          suffix,
        },
      }
    }
  }

  return priceData
}

export default getPriceBySquidexType
