import type { BasketLastItem, BasketResponseItem } from "@onestore/api/basket"
import type {
  BasketPatchItem,
  BasketPatchResource,
  TaxRate,
  Price,
  JSONData,
} from "@onestore/api/types"
import type { HTTP } from "@onestore/onestore-store-common"
import { itemsBasketPrice } from "@onestore/onestore-store-common/api/operations"
import { HTTP_STATUS } from "@onestore/onestore-store-common/http"
import {
  BasketActionSource,
  updateItemsInBasket,
} from "@gatsby-plugin-basket/store/actions"
import { BasketActions } from "@gatsby-plugin-basket/store/constants"
import { normalizeMaxQuantity } from "@gatsby-plugin-bonus/lib/normalizers"
import type {
  BonusPeriod,
  BonusProduct,
  BonusResource,
  BonusResources,
} from "@gatsby-plugin-bonus/types"
import { isBonusProductWithRemoteStateType } from "@gatsby-plugin-bonus/types"
import api from "~/lib/api"
import type { AppState, AppDispatch } from "~/store/reducer"
import type { BonusThunkAction } from "./actions"
import type { BonusBasketResource } from "./actions-bonus"
import {
  addBonusItemToBasket,
  changeAdvancedBonusItemResourcesGroup,
  changeBonusItemInBasket,
  changeBonusItemPrice,
  changeBonusItemResource,
  getProductBasketWithResources,
} from "./actions-bonus"
import {
  getBonusPromoCode,
  getProduct,
  isBonusParameterFormValid,
} from "./selectors"

export enum BonusProductActionsType {
  BONUS_PARAMETERS_FORM_UPDATE = "@bonus/BONUS_FORM_UPDATE",
  BONUS_CHANGE_PRODUCT_PERIOD_REQUEST = "@bonus/CHANGE_PRODUCT_PERIOD_REQUEST",
  BONUS_CHANGE_PRODUCT_PERIOD_SUCCESS = "@bonus/CHANGE_PRODUCT_PERIOD_SUCCESS",
  BONUS_CHANGE_PRODUCT_PERIOD_FAILURE = "@bonus/CHANGE_PRODUCT_PERIOD_FAILURE",

  BONUS_UPDATE_PRODUCT_PRICE_REQUEST = "@bonus/UPDATE_PRODUCT_PRICE_REQUEST",
  BONUS_UPDATE_PRODUCT_PRICE_SUCCESS = "@bonus/UPDATE_PRODUCT_PRICE_SUCCESS",
  BONUS_UPDATE_PRODUCT_PRICE_FAILURE = "@bonus/UPDATE_PRODUCT_PRICE_FAILURE",

  BONUS_PRODUCT_ADD_TO_BASKET_REQUEST = "@bonus/PRODUCT_ADD_TO_BASKET_REQUEST",
  BONUS_PRODUCT_ADD_TO_BASKET_SUCCESS = "@bonus/PRODUCT_ADD_TO_BASKET_SUCCESS",
  BONUS_PRODUCT_ADD_TO_BASKET_FAILURE = "@bonus/PRODUCT_ADD_TO_BASKET_FAILURE",

  BONUS_PRODUCT_CHANGE_BASKET_REQUEST = "@bonus/PRODUCT_CHANGE_BASKET_REQUEST",
  BONUS_PRODUCT_CHANGE_BASKET_SUCCESS = "@bonus/PRODUCT_CHANGE_BASKET_SUCCESS",
  BONUS_PRODUCT_CHANGE_BASKET_FAILURE = "@bonus/PRODUCT_CHANGE_BASKET_FAILURE",

  BONUS_CHANGE_PRODUCT_RESOURCE_REQUEST = "@bonus/CHANGE_PRODUCT_RESOURCE_REQUEST",
  BONUS_CHANGE_PRODUCT_RESOURCE_SUCCESS = "@bonus/CHANGE_PRODUCT_RESOURCE_SUCCESS",
  BONUS_CHANGE_PRODUCT_RESOURCE_FAILURE = "@bonus/CHANGE_PRODUCT_RESOURCE_FAILURE",

  BONUS_PRODUCT_CHANGE_ADVANCED_RESOURCE_REQUEST = "@bonus/PRODUCT_CHANGE_ADVANCED_RESOURCE_REQUEST",
  BONUS_PRODUCT_CHANGE_ADVANCED_RESOURCE_SUCCESS = "@bonus/PRODUCT_CHANGE_ADVANCED_RESOURCE_SUCCESS",
  BONUS_PRODUCT_CHANGE_ADVANCED_RESOURCE_FAILURE = "@bonus/PRODUCT_CHANGE_ADVANCED_RESOURCE_FAILURE",

  BONUS_GET_PRODUCT_PRICE_REQUEST = "@bonus/GET_PRODUCT_PRICE_REQUEST",
  BONUS_GET_PRODUCT_PRICE_SUCCESS = "@bonus/GET_PRODUCT_PRICE_SUCCESS",
  BONUS_GET_PRODUCT_PRICE_FAILURE = "@bonus/GET_PRODUCT_PRICE_FAILURE",

  BONUS_CHANGE_PRODUCT_PRICE = "@bonus/CHANGE_PRODUCT_PRICE",

  BONUS_VALIDATE_PRODUCT_REQUEST = "@bonus/VALIDATE_PRODUCT_REQUEST",
  BONUS_VALIDATE_PRODUCT_SUCCESS = "@bonus/VALIDATE_PRODUCT_SUCCESS",
  BONUS_VALIDATE_PRODUCT_FAILURE = "@bonus/VALIDATE_PRODUCT_FAILURE",

  BONUS_PRODUCT_NEEDS_UPDATE = "@bonus/PRODUCT_NEEDS_UPDATE",
  BONUS_CHANGE_PERIOD_CLICK = "@bonus/CHANGE_PERIOD_CLICK",
}

interface BonusChangeProductPeriodRequestAction {
  status: HTTP.Status
  period: BonusPeriod
  type: BonusProductActionsType.BONUS_CHANGE_PRODUCT_PERIOD_REQUEST
}

interface BonusChangeProductPeriodSuccessAction {
  status: number
  promoPrice: Price | null
  regularPrice: Price
  type: BonusProductActionsType.BONUS_CHANGE_PRODUCT_PERIOD_SUCCESS
  lastItems?: BasketLastItem[]
  basketItemId?: number
}

interface BonusChangeProductPeriodFailureAction {
  error: string | undefined
  status: HTTP.Status
  type: BonusProductActionsType.BONUS_CHANGE_PRODUCT_PERIOD_FAILURE
}

interface BonusUpdateProductPriceRequestAction {
  type: BonusProductActionsType.BONUS_UPDATE_PRODUCT_PRICE_REQUEST
}

interface BonusUpdateProductPriceSuccessAction {
  totalPrices: {
    regularPrice: Price
    promoPrice: Price
  }
  type: BonusProductActionsType.BONUS_UPDATE_PRODUCT_PRICE_SUCCESS
}

interface BonusUpdateProductPriceFailureAction {
  type: BonusProductActionsType.BONUS_UPDATE_PRODUCT_PRICE_FAILURE
}

interface BonusProductAddToBasketRequestAction {
  status: HTTP.Status
  automaticAdd: boolean
  type: BonusProductActionsType.BONUS_PRODUCT_ADD_TO_BASKET_REQUEST
}

interface BonusProductAddToBasketSuccessAction {
  resources: BonusResources
  basketItemId: number
  lastItems: BasketLastItem[]
  status: HTTP.Status
  chosenPeriodId: number
  chosenPeriodName: string
  basketItemTaxRate: TaxRate
  basketItemPrice: number
  type: BonusProductActionsType.BONUS_PRODUCT_ADD_TO_BASKET_SUCCESS
}

interface BonusProductAddToBasketFailureAction {
  status: HTTP.Status
  error: JSONData
  type: BonusProductActionsType.BONUS_PRODUCT_ADD_TO_BASKET_FAILURE
}

interface BonusProductChangeBasketRequestAction {
  type: BonusProductActionsType.BONUS_PRODUCT_CHANGE_BASKET_REQUEST
  status: HTTP.Status
}

interface BonusProductChangeBasketSuccessAction {
  [x: string]: any
  type: BonusProductActionsType.BONUS_PRODUCT_CHANGE_BASKET_SUCCESS
  lastItems: BasketLastItem[]
}

interface BonusProductChangeBasketFailureAction {
  type: BonusProductActionsType.BONUS_PRODUCT_CHANGE_BASKET_FAILURE
}

export interface BonusChangeProductResourceRequestAction {
  resource: BonusResource
  quantity: number
  status: HTTP.Status
  type: BonusProductActionsType.BONUS_CHANGE_PRODUCT_RESOURCE_REQUEST
}

interface BonusChangeProductResourceSuccessAction {
  resources: BonusResources
  status: HTTP.Status
  type: BonusProductActionsType.BONUS_CHANGE_PRODUCT_RESOURCE_SUCCESS
}

interface BonusChangeProductResourceFailureAction {
  type: BonusProductActionsType.BONUS_CHANGE_PRODUCT_RESOURCE_FAILURE
}

interface BonusProductChangeAdvancedResourceRequestAction {
  type: BonusProductActionsType.BONUS_PRODUCT_CHANGE_ADVANCED_RESOURCE_REQUEST
}

export interface BonusProductChangeAdvancedResourceSuccessAction {
  resources: BonusBasketResource[]
  promoPrice: Price | null
  regularPrice: Price
  type: BonusProductActionsType.BONUS_PRODUCT_CHANGE_ADVANCED_RESOURCE_SUCCESS
}

interface BonusProductChangeAdvancedResourceFailureAction {
  type: BonusProductActionsType.BONUS_PRODUCT_CHANGE_ADVANCED_RESOURCE_FAILURE
}

interface BonusGetProductPriceRequestAction {
  status: HTTP.Status
  type: BonusProductActionsType.BONUS_GET_PRODUCT_PRICE_REQUEST
}

interface BonusGetProductPriceSuccessAction {
  status: HTTP.Status
  type: BonusProductActionsType.BONUS_GET_PRODUCT_PRICE_SUCCESS
}

interface BonusGetProductPriceFailureAction {
  error: string | undefined
  status: HTTP.Status
  type: BonusProductActionsType.BONUS_GET_PRODUCT_PRICE_FAILURE
}

export interface BonusChangeProductPriceAction {
  periods: BonusPeriod[]
  type: BonusProductActionsType.BONUS_CHANGE_PRODUCT_PRICE
}

interface BonusValidateProductRequestAction {
  type: BonusProductActionsType.BONUS_VALIDATE_PRODUCT_REQUEST
}

interface BonusValidateProductSuccessAction {
  errors: Record<string, string>
  isValid: boolean
  type: BonusProductActionsType.BONUS_VALIDATE_PRODUCT_SUCCESS
}

interface BonusValidateProductFailureAction {
  errors: Record<string, string>
  isValid: boolean
  type: BonusProductActionsType.BONUS_VALIDATE_PRODUCT_FAILURE
}

interface BonusProductNeedsUpdateAction {
  type: BonusProductActionsType.BONUS_PRODUCT_NEEDS_UPDATE
}

interface BonusChangeProductPeriodClickAction {
  status: number
  promoPrice: Price | null
  regularPrice: Price
  type: BonusProductActionsType.BONUS_CHANGE_PERIOD_CLICK
  lastItems?: BasketLastItem[]
  basketItemId?: number
}

export type BonusProductActions =
  | BonusChangeProductPeriodRequestAction
  | BonusChangeProductPeriodSuccessAction
  | BonusChangeProductPeriodFailureAction
  | BonusUpdateProductPriceRequestAction
  | BonusUpdateProductPriceSuccessAction
  | BonusUpdateProductPriceFailureAction
  | BonusProductAddToBasketRequestAction
  | BonusProductAddToBasketSuccessAction
  | BonusProductAddToBasketFailureAction
  | BonusProductChangeBasketRequestAction
  | BonusProductChangeBasketSuccessAction
  | BonusProductChangeBasketFailureAction
  | BonusChangeProductResourceRequestAction
  | BonusChangeProductResourceSuccessAction
  | BonusChangeProductResourceFailureAction
  | BonusProductChangeAdvancedResourceRequestAction
  | BonusProductChangeAdvancedResourceSuccessAction
  | BonusProductChangeAdvancedResourceFailureAction
  | BonusGetProductPriceRequestAction
  | BonusGetProductPriceSuccessAction
  | BonusGetProductPriceFailureAction
  | BonusChangeProductPriceAction
  | BonusValidateProductRequestAction
  | BonusValidateProductSuccessAction
  | BonusValidateProductFailureAction
  | BonusProductNeedsUpdateAction
  | BonusChangeProductPeriodClickAction

/**
 * Tworzy obiekt elementu koszyka do wysłania do api.
 *
 * @param {object} product
 */
function createProductItem(product: BonusProduct): BasketPatchItem[] {
  return product.periods.map((period) => {
    const item: BasketPatchItem = {
      plan: product.planId,
      planPeriod: period.id,
      quantity: 1,
    }

    if (product.resources) {
      const itemResources: BasketPatchResource[] = []

      Object.values(product.resources)
        .filter((resource) => 0 < resource.basketQuantity)
        .forEach((resource) => {
          itemResources.push({
            id: resource.id,
            quantity: resource.basketQuantity,
          })
        })

      item.resources = itemResources
    }

    return item
  })
}

async function getProductItemPrice(product: BonusProduct) {
  const items = createProductItem(product)
  const promises = items.map((item) =>
    api.calculateBasket({ items: itemsBasketPrice([item]) })
  )
  const results = await Promise.all(promises)

  return Promise.resolve(results.map((result) => result.items[0]))
}

export function changeProductPrice(
  product: BonusProduct,
  items: BasketResponseItem[] | BasketResponseItem[][]
) {
  return (
    dispatch: AppDispatch<BonusThunkAction>
  ): Promise<BonusProduct["periods"]> => {
    const periods = changeBonusItemPrice(product, items)

    dispatch({
      type: BonusProductActionsType.BONUS_CHANGE_PRODUCT_PRICE,
      periods,
    } as BonusChangeProductPriceAction)

    return Promise.resolve(periods)
  }
}

/**
 * Zmiana okresu głównego produktu w przed-koszyku.
 * @todo     wydzielić do osobnej funkcji zmianę okresu produktu/upsellu
 *
 * @param   {Product} product  ID elementu koszyka, któremu zmieniamy okres.
 * @param   {object}  period   Okres na jaki chcemy zmienić.
 * @returns {Promise.<object>} Zwraca zmodyfikowany element koszyka.
 */

export enum ActionType {
  PERIOD_CHANGE = "period_change",
}

export function requestChangeProductPeriod(
  product: BonusProduct,
  period,
  quantity: undefined | number = undefined,
  action?: ActionType,
  type?: BasketActions
) {
  return async (dispatch: AppDispatch<BonusThunkAction>) => {
    if (action === ActionType.PERIOD_CHANGE) {
      const totalPrices = await getProductBasketWithResources(
        product,
        period.id
      )
      dispatch({
        type: BonusProductActionsType.BONUS_CHANGE_PERIOD_CLICK,
        status: HTTP_STATUS.OK,
        period,
        ...totalPrices,
      } as BonusChangeProductPeriodClickAction)
    }

    if (!isBonusProductWithRemoteStateType(product)) {
      return
    }

    dispatch({
      type: BonusProductActionsType.BONUS_CHANGE_PRODUCT_PERIOD_REQUEST,
      status: HTTP_STATUS.CONTINUE,
      itemId: product.basketItemState.id,
      period,
    } as BonusChangeProductPeriodRequestAction)

    if (!product.addedToBasket) {
      const totalPrices = await getProductBasketWithResources(
        product,
        period.id
      )

      return dispatch({
        type: BonusProductActionsType.BONUS_CHANGE_PRODUCT_PERIOD_SUCCESS,
        status: HTTP_STATUS.OK,
        period,
        ...totalPrices,
      } as BonusChangeProductPeriodSuccessAction)
    }

    try {
      const changedItems: BasketLastItem[] = await dispatch(
        updateItemsInBasket(
          [
            {
              id: product.basketItemState.id,
              planPeriod: period.id,
              quantity,
            },
          ],
          BasketActionSource.BONUS,
          () => {},
          type || BasketActions.BASKET_CHANGE_TERM
        )
      )

      const { regularPrice, promoPrice } = await getProductBasketWithResources(
        product,
        period.id
      )

      dispatch({
        type: BonusProductActionsType.BONUS_CHANGE_PRODUCT_PERIOD_SUCCESS,
        status: HTTP_STATUS.OK,
        period,
        regularPrice,
        promoPrice,
        lastItems: changedItems,
        basketItemId: product.basketItemState.id,
      } as BonusChangeProductPeriodSuccessAction)

      return Promise.resolve(changedItems[0])
    } catch (error) {
      dispatch({
        type: BonusProductActionsType.BONUS_CHANGE_PRODUCT_PERIOD_FAILURE,
        status: HTTP_STATUS.BAD_REQUEST,
        error,
      } as BonusChangeProductPeriodFailureAction)

      return Promise.reject(error)
    }
  }
}

/**
 * Edytuje dany zasób w głównym produkcie.
 *
 * @param {object} product   Obiekt głównego produktu.
 * @param {object} resource Zasób, który jest edytowany.
 * @param {number} quantity Wartość, o którą ma się zwiekszyć/zmniejszyć ilość danego zasobu.
 */
export function requestChangeProductResource(
  resource: BonusResource,
  quantity: number
) {
  return async (
    dispatch: AppDispatch<AppState>,
    getState: { (): AppState }
  ): Promise<void> => {
    const newQuantity = resource.quantity + quantity

    if (
      newQuantity > normalizeMaxQuantity(resource.maxQuantity) ||
      newQuantity < resource.minQuantity
    ) {
      return
    }

    await dispatch({
      type: BonusProductActionsType.BONUS_CHANGE_PRODUCT_RESOURCE_REQUEST,
      status: HTTP_STATUS.CONTINUE,
      resource,
      quantity,
    } as BonusChangeProductResourceRequestAction)

    const product = getProduct(getState())

    try {
      await dispatch(requestGetProductPrice())

      const currentResources = await changeBonusItemResource(
        dispatch,
        {
          addedToBasket: product?.addedToBasket || false,
          alias: product?.alias,
          basketItemState: product?.basketItemState,
          resources: product?.resources || {},
        },
        resource,
        newQuantity
      )

      dispatch({
        type: BonusProductActionsType.BONUS_CHANGE_PRODUCT_RESOURCE_SUCCESS,
        status: HTTP_STATUS.OK,
        product,
        resources: currentResources || {},
      } as BonusChangeProductResourceSuccessAction)
    } catch (error) {
      dispatch({
        type: BonusProductActionsType.BONUS_CHANGE_PRODUCT_RESOURCE_FAILURE,
        product,
        quantity,
        error,
      } as BonusChangeProductResourceFailureAction)

      return Promise.reject(error)
    }
  }
}

/**
 * Akcja dodająca główny produkt przed-koszyka do koszyka użytkownika.
 *
 * @param   {string} promoCode  Kod promocyjny.
 * @returns {Promise.<object>} Zwraca dodany element koszyka.
 */
export function requestAddProductToBasket(
  promoCode?: string,
  automatic?: boolean
) {
  return async (
    dispatch: AppDispatch<BonusThunkAction>,
    getState: { (): AppState }
  ): Promise<BasketLastItem | undefined> => {
    dispatch({
      type: BonusProductActionsType.BONUS_PRODUCT_ADD_TO_BASKET_REQUEST,
      status: HTTP_STATUS.CONTINUE,
      automaticAdd: automatic || false,
    } as BonusProductAddToBasketRequestAction)

    try {
      const result = await dispatch(requestValidateProduct())

      if (!result.isValid) {
        return Promise.resolve(undefined)
      }

      const product = getProduct(getState())

      if (!product) {
        return Promise.resolve(undefined)
      }

      const { addedItem, resources, lastItems } = await addBonusItemToBasket(
        dispatch,
        getState,
        product,
        null,
        promoCode
      )

      if ("plan_id" in addedItem) {
        dispatch({
          type: BonusProductActionsType.BONUS_PRODUCT_ADD_TO_BASKET_SUCCESS,
          basketItemId: addedItem.id,
          lastItems,
          basketItemPrice: addedItem.price,
          basketItemTaxRate: addedItem.tax_rate,
          status: HTTP_STATUS.OK,
          chosenPeriodId: product.chosenPeriodId,
          chosenPeriodName: addedItem.period_name,
          resources,
        } as BonusProductAddToBasketSuccessAction)
      }

      return Promise.resolve(addedItem)
    } catch (error) {
      dispatch({
        type: BonusProductActionsType.BONUS_PRODUCT_ADD_TO_BASKET_FAILURE,
        status: error.code || HTTP_STATUS.BAD_REQUEST,
        error,
      } as BonusProductAddToBasketFailureAction)

      return Promise.resolve(undefined)
    }
  }
}

export function requestChangeProductInBasket(product: BonusProduct) {
  return async (dispatch: AppDispatch<AppState>): Promise<void> => {
    if (!isBonusProductWithRemoteStateType(product)) {
      return
    }

    const result = await dispatch(requestValidateProduct())

    if (!result.isValid) {
      return
    }

    dispatch({
      type: BonusProductActionsType.BONUS_PRODUCT_CHANGE_BASKET_REQUEST,
      status: HTTP_STATUS.CONTINUE,
      product,
    } as BonusProductChangeBasketRequestAction)

    try {
      const { chosenPeriodId, changedResources, lastItems } = await dispatch(
        changeBonusItemInBasket(product)
      )

      dispatch({
        type: BonusProductActionsType.BONUS_PRODUCT_CHANGE_BASKET_SUCCESS,
        status: HTTP_STATUS.OK,
        chosenPeriodId,
        changedResources,
        lastItems,
      } as BonusProductChangeBasketSuccessAction)
    } catch (error) {
      dispatch({
        type: BonusProductActionsType.BONUS_PRODUCT_CHANGE_BASKET_FAILURE,
        status: error.code || HTTP_STATUS.BAD_REQUEST,
        error,
        product,
      } as BonusProductChangeBasketFailureAction)
    }
  }
}

export function changeAdvancedProductResourcesGroup(
  product: BonusProduct,
  resourcesList: number[],
  chosenResourceId: number,
  basketQuantity: number,
  promoCode: string | null | undefined = null
) {
  return async (dispatch: AppDispatch<BonusThunkAction>) => {
    dispatch({
      type: BonusProductActionsType.BONUS_PRODUCT_CHANGE_ADVANCED_RESOURCE_REQUEST,
    } as BonusProductChangeAdvancedResourceRequestAction)

    try {
      const result = await dispatch(
        changeAdvancedBonusItemResourcesGroup(
          product,
          resourcesList,
          chosenResourceId,
          basketQuantity,
          promoCode
        )
      )

      if (result === null) {
        return
      }

      const {
        totalPrice: { regularPrice, promoPrice },
        resources,
      } = result

      return dispatch({
        type: BonusProductActionsType.BONUS_PRODUCT_CHANGE_ADVANCED_RESOURCE_SUCCESS,
        resources,
        regularPrice,
        promoPrice,
      } as BonusProductChangeAdvancedResourceSuccessAction)
    } catch (error) {
      return dispatch({
        type: BonusProductActionsType.BONUS_PRODUCT_CHANGE_ADVANCED_RESOURCE_FAILURE,
      } as BonusProductChangeAdvancedResourceFailureAction)
    }
  }
}

export function requestGetProductPrice() {
  return async (
    dispatch: AppDispatch<BonusThunkAction>,
    getState: { (): AppState }
  ) => {
    dispatch({
      type: BonusProductActionsType.BONUS_GET_PRODUCT_PRICE_REQUEST,
      status: HTTP_STATUS.CONTINUE,
    } as BonusGetProductPriceRequestAction)

    const product = getProduct(getState())

    if (!product) {
      return Promise.reject("Missing product")
    }
    try {
      const items = await getProductItemPrice(product)
      const updates = await dispatch(changeProductPrice(product, items))

      dispatch({
        type: BonusProductActionsType.BONUS_GET_PRODUCT_PRICE_SUCCESS,
        status: HTTP_STATUS.OK,
      } as BonusGetProductPriceSuccessAction)

      return Promise.resolve(updates)
    } catch (error) {
      dispatch({
        type: BonusProductActionsType.BONUS_GET_PRODUCT_PRICE_FAILURE,
        status: error.code,
        error,
      } as BonusGetProductPriceFailureAction)

      return Promise.reject(error)
    }
  }
}

export function requestValidateProduct() {
  return async (
    dispatch: AppDispatch<BonusThunkAction>,
    getState: { (): AppState }
  ) => {
    const state = getState()
    const product = getProduct(state)

    if (!product) {
      return {
        isValid: true,
        errors: [],
      }
    }

    dispatch({
      type: BonusProductActionsType.BONUS_VALIDATE_PRODUCT_REQUEST,
      product,
    } as BonusValidateProductRequestAction)

    const isValid = isBonusParameterFormValid(product.alias)(state)

    if (isValid) {
      dispatch({
        type: BonusProductActionsType.BONUS_VALIDATE_PRODUCT_SUCCESS,
        isValid,
      } as BonusValidateProductSuccessAction)

      return {
        isValid,
      }
    }

    dispatch({
      type: BonusProductActionsType.BONUS_VALIDATE_PRODUCT_FAILURE,
      isValid,
    } as BonusValidateProductFailureAction)

    return {
      isValid,
    }
  }
}

export function productNeedsUpdate() {
  return (dispatch: AppDispatch<BonusThunkAction>) =>
    dispatch({
      type: BonusProductActionsType.BONUS_PRODUCT_NEEDS_UPDATE,
    } as BonusProductNeedsUpdateAction)
}

export function updateProductPrice() {
  return async (
    dispatch: AppDispatch<BonusThunkAction>,
    getState: { (): AppState }
  ) => {
    dispatch({
      type: BonusProductActionsType.BONUS_UPDATE_PRODUCT_PRICE_REQUEST,
    } as BonusUpdateProductPriceRequestAction)
    try {
      const state = getState()
      const product = getProduct(state)

      if (!product) {
        dispatch({
          type: BonusProductActionsType.BONUS_UPDATE_PRODUCT_PRICE_FAILURE,
        } as BonusUpdateProductPriceFailureAction)

        return
      }
      const promoCode = getBonusPromoCode(state)

      const totalPrices = await getProductBasketWithResources(
        product,
        product?.chosenPeriodId,
        Object.values(product?.resources || {}),
        promoCode
      )

      dispatch({
        type: BonusProductActionsType.BONUS_UPDATE_PRODUCT_PRICE_SUCCESS,
        totalPrices,
      } as BonusUpdateProductPriceSuccessAction)
    } catch (error) {
      dispatch({
        type: BonusProductActionsType.BONUS_UPDATE_PRODUCT_PRICE_FAILURE,
      } as BonusUpdateProductPriceFailureAction)
    }
  }
}
