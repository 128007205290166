import { PriceType } from "@core/types"
import { useStaticQuery, graphql } from "gatsby"

enum SquidexSelectPriceType {
  netto = "NETTO",
  brutto = "GROSS",
}

type Query = {
  GraphCmsSquidex: {
    findPriceConfigurationSingleton: {
      flatData: {
        showPriceTypeSwitcher: boolean | null
        showDetailedPrices: boolean | null
        defaultPriceType: keyof typeof SquidexSelectPriceType
        defaultMarketplacePriceType: keyof typeof SquidexSelectPriceType
        defaultPriceListPagePriceType: keyof typeof SquidexSelectPriceType
      }
    }
  }
}

export default function usePriceConfigurationQuery() {
  const { GraphCmsSquidex } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        findPriceConfigurationSingleton {
          flatData {
            showPriceTypeSwitcher
            showDetailedPrices
            defaultPriceType
            defaultMarketplacePriceType
            defaultPriceListPagePriceType
          }
        }
      }
    }
  `)

  if (GraphCmsSquidex.findPriceConfigurationSingleton) {
    const { flatData } = GraphCmsSquidex.findPriceConfigurationSingleton

    const defaultPriceTypeValue =
      SquidexSelectPriceType[flatData.defaultPriceType]
    const defaultMarketplacePriceTypeValue =
      SquidexSelectPriceType[flatData.defaultMarketplacePriceType]
    const defaultPriceListPagePriceTypeValue =
      SquidexSelectPriceType[flatData.defaultPriceListPagePriceType]

    return {
      showPriceTypeSwitcher: flatData.showPriceTypeSwitcher ?? false,
      showDetailedPrices: flatData.showDetailedPrices ?? false,
      defaultPriceType: PriceType[defaultPriceTypeValue],
      defaultMarketplacePriceType: PriceType[defaultMarketplacePriceTypeValue],
      defaultPriceListPagePriceType:
        PriceType[defaultPriceListPagePriceTypeValue],
    }
  }

  return {
    showPriceTypeSwitcher: false,
    showDetailedPrices: false,
    defaultPriceType: PriceType.GROSS,
    defaultMarketplacePriceType: PriceType.GROSS,
    defaultPriceListPagePriceType: PriceType.GROSS,
  }
}
