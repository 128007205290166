import type { ReactElement, ReactNode } from "react"
import React from "react"
import _t from "@core/i18n"
import type { PeriodInfo } from "@core/period-info"
import { PriceFormat } from "@core/types"
import CartIcon from "@onestore-icons/cart-drawer-icon.svg"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import FlexItemOrg from "@onestore/hel/dist/components/organisms/FlexItemOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import { usePriceTypeContext } from "~/context/PriceTypeContext"

export type SummaryVisualElementProps = {
  productName: ReactNode
  periodInfo: PeriodInfo
  onClick: () => void
  isLoading?: boolean
  isDone?: boolean
}

export function SummaryVisualElement({
  productName,
  periodInfo,
  onClick,
  isLoading,
  isDone,
  ...restProps
}: SummaryVisualElementProps): ReactElement<SummaryVisualElementProps> | null {
  const { currentPriceType, isNettoPriceType } = usePriceTypeContext()

  const prefixElement = (
    <TextAtm
      typography={{
        small: "xsmall1",
        medium: "small2",
        large: "small2",
      }}
    >
      {_t("configDrawer.summary.total")}
    </TextAtm>
  )

  const price = periodInfo.getFormatted(
    isNettoPriceType
      ? PriceFormat.REGISTER_NETTO_NO_PERIOD
      : PriceFormat.REGISTER_GROSS_NO_PERIOD
  )
  const priceTypeText = _t(`prices.${currentPriceType}`)

  const productNameAndPriceElement = (
    <FlexContainerOrg justifyContent="space-between" alignItems="baseline">
      <FlexItemOrg flexGrow={1} flexShrink={1} flexBasis={0}>
        <FlexContainerOrg gapSpace={1}>
          <CartIcon />
          <TextAtm typography="big4">{productName}</TextAtm>
        </FlexContainerOrg>
      </FlexItemOrg>

      <div>
        <TextAtm
          typography={{
            small: "medium3_s",
            medium: "big4",
            large: "big4",
          }}
        >
          <span data-test-id="drawer-total-price">{price}</span>{" "}
          <TextAtm typography="small2">{priceTypeText}</TextAtm>
        </TextAtm>
      </div>
    </FlexContainerOrg>
  )

  const buttonElement = (
    <ButtonAtm
      onClick={onClick}
      title={_t("configDrawer.summary.addCart")}
      color="success"
      isLoading={isLoading}
      isExpanded
      isDisabled={isDone}
    >
      {isDone
        ? _t("configDrawer.summary.added")
        : _t("configDrawer.summary.addCart")}
    </ButtonAtm>
  )

  return (
    <div {...restProps}>
      <PushOrg
        bottomSpace={{
          small: 0.5,
          medium: 1,
          large: 1,
        }}
      >
        {prefixElement}
      </PushOrg>

      <PushOrg
        bottomSpace={{
          small: 2,
          medium: 3,
          large: 3,
        }}
      >
        {productNameAndPriceElement}
      </PushOrg>

      {buttonElement}
    </div>
  )
}
