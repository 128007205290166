import type { ReactElement } from "react"
import React from "react"
import FullWidthSliderMol from "@onestore/hel/dist/components/molecules/FullWidthSliderMol"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import Section from "@gatsby-plugin-generic-page/components/Section"
import type { FullWidthSliderSectionFlatData } from "@gatsby-plugin-generic-page/fragments/fullWidthSliderSection"
import SquidexToolbarSectionWrapper from "@gatsby-plugin-squidex/components/SquidexToolbarSectionWrapper"

export default function FullWidthSliderSection({
  sections,
  slideTime,
  sectionSettings,
}: FullWidthSliderSectionFlatData): ReactElement<FullWidthSliderSectionFlatData> {
  return (
    <BaseSection {...sectionSettings}>
      <FullWidthSliderMol
        slides={sections.map((section) => ({
          key: section.id,
          slide: (
            <SquidexToolbarSectionWrapper
              urlToSection={section.url}
              key={section.id}
            >
              <Section data={section} />
            </SquidexToolbarSectionWrapper>
          ),
        }))}
        autoPlay
        autoplaySpeed={slideTime || 8000}
      />
    </BaseSection>
  )
}
