import { useState } from "react"
import useDomainSerachAiExamplePromptsQuery from "@gatsby-plugin-domain-search/hooks/useDomainSerachAiExamplePromptsQuery"
import { sendAiSearchExamplePromptEvent } from "~/lib/analytics"

export default function useDomainSearchPromptState() {
  const { getRandomPrompt } = useDomainSerachAiExamplePromptsQuery()
  const [value, setValue] = useState<string>("")

  return {
    value,
    setValue,
    setValueToRandomPrompt: () => {
      const prompt = getRandomPrompt(value)

      if (prompt) {
        setValue(prompt)
        sendAiSearchExamplePromptEvent(prompt)
      }
    },
  }
}
