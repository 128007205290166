import type { SyntheticEvent } from "react"
import { useEffect, useState } from "react"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import type { HorizontalProductBox } from "@gatsby-plugin-generic-page/fragments/horizontalProductBox"
import type { HorizontalTableCategory } from "@gatsby-plugin-generic-page/fragments/horizontalTableCategory"
import isEmpty from "~/lib/isEmpty"

const useHorizontalTableFilter = (
  categoryTabs: HorizontalTableCategory[],
  products: HorizontalProductBox[]
) => {
  const isSmallOnly = useBreakpoint("small", "medium")
  const hasSetActiveTab = !isEmpty(categoryTabs)
    ? categoryTabs.findIndex((tab) => tab.flatData.isActive)
    : 1
  const activeTab = hasSetActiveTab === -1 ? 1 : hasSetActiveTab

  const [filteredProducts, setFilteredProducts] =
    useState<typeof products>(products)
  const [activeIndex, setActiveIndex] = useState<number>(activeTab)

  const handleTabItemClick = (event: SyntheticEvent, index: number): void => {
    event.preventDefault()
    setActiveIndex(index)
  }

  useEffect(() => {
    if (isEmpty(categoryTabs)) return

    if (activeIndex === 0 && isSmallOnly) {
      setActiveIndex(1)
    }

    const activeCategoryName = categoryTabs[activeIndex].flatData.name

    if (activeIndex === 0) {
      setFilteredProducts(products)
    } else {
      const newFilteredProducts = products.filter(
        (product) =>
          product.flatData.category?.[0]?.flatData?.name === activeCategoryName
      )
      setFilteredProducts(newFilteredProducts)
    }
  }, [
    activeIndex,
    categoryTabs,
    products,
    isSmallOnly,
    setActiveIndex,
    activeTab,
  ])

  return { filteredProducts, activeIndex, handleTabItemClick }
}

export default useHorizontalTableFilter
