import type { ReactElement } from "react"
import React from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import SelectListOptionMol from "@onestore/hel/dist/components/molecules/SelectListOptionMol"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import { useConfigsHookContext } from "@gatsby-plugin-generic-page/sections/TableHorizontalBoxesSection"
import isEmpty from "~/lib/isEmpty"

type SelectResourceElementProps = {
  label: string
  resourceCategoryId: string
}

export default function SelectResourceElement({
  label,
  resourceCategoryId,
}: SelectResourceElementProps): ReactElement<SelectResourceElementProps> {
  const {
    handleResourcesRemove,
    handleResourcesChange,
    getResourceCategory,
    getResourcePriceText,
    getConfigModalResourceCategory,
  } = useConfigsHookContext() ?? {}

  const activeResourceCategory = getConfigModalResourceCategory
    ? getConfigModalResourceCategory(resourceCategoryId)
    : null

  const resourceCategory = getResourceCategory
    ? getResourceCategory(resourceCategoryId)
    : null

  if (isEmpty(resourceCategory)) {
    return <></>
  }

  const { resources } = resourceCategory

  const handleOnChange = (resourceId: string | null): void => {
    if (isEmpty(resourceId) && handleResourcesRemove) {
      handleResourcesRemove(resourceCategoryId, true)

      return
    }

    const currentResource = resources.find(
      (resource) => resource.id.toString() === resourceId
    )

    if (currentResource && handleResourcesRemove && handleResourcesChange) {
      handleResourcesRemove(resourceCategoryId, true)

      handleResourcesChange(
        [
          {
            resourceCategoryId,
            resourceId: currentResource.id.toString(),
            resourceValue: 1,
          },
        ],
        true
      )
    }
  }

  const selectOptions = resources.map((resource) => {
    const optionValue = resource.id.toString()
    const isChecked = !isEmpty(activeResourceCategory)
      ? resource.id === activeResourceCategory.id
      : false

    const content = (
      <AlignContentOrg horizontalAlign="right" horizontalAlignType="both">
        <TextAtm typography="small2">{resource.name}</TextAtm>

        {getResourcePriceText ? (
          <TextAtm
            typography="small3"
            color={isChecked ? "success" : undefined}
          >
            {getResourcePriceText(
              resource.id.toString(),
              resourceCategoryId,
              true
            )}
          </TextAtm>
        ) : null}
      </AlignContentOrg>
    )

    return {
      key: `select-resource-${resource.name}-${optionValue}`,
      value: optionValue,
      isChecked,
      onChange: () => {
        handleOnChange(optionValue)
      },
      content,
    }
  })

  const selectOptionsWithBlankOption = [
    {
      key: `select-resource-blank-${label}`,
      value: null,
      isChecked: isEmpty(activeResourceCategory),
      onChange: () => {
        handleOnChange(null)
      },
      content: <TextAtm typography="small2">brak</TextAtm>,
    },
    ...selectOptions,
  ]

  return (
    <>
      <PushOrg bottomSpace={1.5}>
        <TextAtm typography="small3">{label}</TextAtm>
      </PushOrg>

      <GridOrg columns={1} gutterSpace={1}>
        {selectOptionsWithBlankOption.map((optionData) => (
          <SelectListOptionMol
            key={optionData.key}
            content={optionData.content}
            isChecked={optionData.isChecked}
            onChange={optionData.onChange}
          />
        ))}
      </GridOrg>
    </>
  )
}
