import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import { getFormattedPeriodInfo } from "@core/period-info"
import { PriceFormat } from "@core/types"
import ItemPriceLinkMol from "@onestore/hel/dist/components/molecules/ItemPriceLinkMol"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import HorizontalScrollItemsOrg from "@onestore/hel/dist/components/organisms/HorizontalScrollItemsOrg"
import type { DomainLink } from "@gatsby-plugin-domain-search/hooks/useDomainLinksQuery"
import { usePriceTypeContext } from "~/context/PriceTypeContext"

interface DomainPriceLinkProps {
  domainLinks: DomainLink[]
}

function DomainLinks({
  domainLinks,
}: DomainPriceLinkProps): ReactElement<DomainPriceLinkProps> {
  const { isNettoPriceType } = usePriceTypeContext()

  return (
    <FlexContainerOrg justifyContent="center">
      <HorizontalScrollItemsOrg gutterSpace={3}>
        {domainLinks.map(({ extension, to, periods }) => (
          <ItemPriceLinkMol
            key={extension}
            text={`.${extension}`}
            price={getFormattedPeriodInfo(
              periods,
              undefined,
              isNettoPriceType
                ? PriceFormat.REGISTER_NETTO
                : PriceFormat.REGISTER_GROSS
            )}
            link={{
              to: to,
              title: `${_t("basket.productType.domain")} .${extension}`,
            }}
          />
        ))}
      </HorizontalScrollItemsOrg>
    </FlexContainerOrg>
  )
}

export default DomainLinks
