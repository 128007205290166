import type { ReactElement } from "react"
import React from "react"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import ContentWithAsideOrg from "@onestore/hel/dist/components/organisms/ContentWithAsideOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import WidthOrg from "@onestore/hel/dist/components/organisms/WidthOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import Header from "@gatsby-plugin-generic-page/components/Header"
import Paragraph from "@gatsby-plugin-generic-page/components/Paragraph"
import SimpleProductBox from "@gatsby-plugin-generic-page/components/SimpleProductBox"
import type { ContentWithSimpleProductBoxSectionFlatData } from "@gatsby-plugin-generic-page/fragments/contentWithSimpleProductBoxSection"
import TogglePriceType from "~/components/TogglePriceType"
import isEmpty from "~/lib/isEmpty"

function ContentWithSimpleProductBoxSection({
  header,
  paragraphs,
  ctaElements,
  boxes,
  hasTogglePriceType,
  isReversed,
  isCentered,
  sectionSettings,
}: ContentWithSimpleProductBoxSectionFlatData): ReactElement<ContentWithSimpleProductBoxSectionFlatData> {
  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <ContentWithAsideOrg
          isReversed={isReversed}
          isCentered={isCentered}
          asideWidthSpace={50}
          gutterSizeSpace={22.5}
          content={
            <>
              {!isEmpty(header) && (
                <PushOrg bottomSpace={4}>
                  <Header {...header} />
                </PushOrg>
              )}

              {paragraphs.map((paragraph, index) => (
                <PushOrg key={index} bottomSpace={4}>
                  <Paragraph {...paragraph} />
                </PushOrg>
              ))}

              {!isEmpty(ctaElements) && <CtaElement ctaElement={ctaElements} />}
            </>
          }
          aside={
            <AlignContentOrg
              horizontalAlign="center"
              horizontalAlignType="flex"
            >
              <WidthOrg widthSpace={50}>
                {hasTogglePriceType ? (
                  <PushOrg bottomSpace={2}>
                    <AlignContentOrg horizontalAlign="right">
                      <TogglePriceType />
                    </AlignContentOrg>
                  </PushOrg>
                ) : null}

                <SimpleProductBox {...boxes[0]} />
              </WidthOrg>
            </AlignContentOrg>
          }
        />
      </BaseContainer>
    </BaseSection>
  )
}

export default ContentWithSimpleProductBoxSection
