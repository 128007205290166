import { useEffect, useState } from "react"
import * as Sentry from "@sentry/browser"
import useFormsConfigurationQuery from "~/hooks/useFormsConfigurationQuery"
import log from "~/lib/log"

declare global {
  interface Window {
    hbspt?: {
      forms: {
        create: { (options: unknown) }
      }
    }
  }
}

type HubspotFormHook = {
  formId: string
  target: string
}

type HubspotFormHookResult = {
  enabled: boolean
  created: boolean
}

export default function useHubspotFormHook(
  formSettings: HubspotFormHook
): HubspotFormHookResult {
  const { enabled, hubspotRegion, hubspotPortalId } =
    useFormsConfigurationQuery()

  const [formCreated, setFormCreated] = useState(false)

  useEffect(() => {
    if (!enabled) {
      return
    }

    const form = {
      region: hubspotRegion,
      portalId: hubspotPortalId,
      ...formSettings,
    }

    const script = document.createElement("script")
    script.src = "https://js.hsforms.net/forms/embed/v2.js"

    document.body.appendChild(script)

    script.addEventListener("load", () => {
      if (window.hbspt && !formCreated) {
        log([`Hubspot script load: ${formSettings.formId}`, form], "hubspot")
        try {
          window.hbspt.forms.create(form)
          setFormCreated(true)
        } catch (e) {
          log(["Hubspot failed to create form", e], "hubspot")
          setFormCreated(false)

          if (Sentry) {
            Sentry.captureException(e)
          }
        }
      }
    })
  }, [enabled, formSettings.formId, formCreated, setFormCreated])

  return {
    enabled,
    created: formCreated,
  }
}
