import type { ReactElement } from "react"
import { useState } from "react"
import React from "react"
import { isObject } from "lodash"
import type { FieldInputProps, FieldMetaState } from "react-final-form"
import SelectMol from "@onestore/hel/dist/components/molecules/SelectMol"
import type { SelectOption } from "@onestore/hel/dist/components/molecules/SelectMol/SelectMol.types"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import { useFormFieldError } from "~/hooks/useFormFieldError"
import { getChoiceTypeRowOptions } from "~/lib/forms"
import isEmpty from "~/lib/isEmpty"

export interface ChoiceTypeRowProps {
  input: FieldInputProps<string>
  meta: FieldMetaState<string>
  placeholder: string
  label: string
  choices: Record<string, string>
  groups?: Record<string, string | Record<string, string>>
}

function ChoiceTypeRow({
  input,
  meta,
  placeholder,
  choices,
  groups,
}: ChoiceTypeRowProps): ReactElement<ChoiceTypeRowProps> {
  const { hasError, errorMessage } = useFormFieldError(input, meta)
  const [activeSecondSelect, setActiveSecondSelect] = useState<string | null>(
    null
  )

  const defaultOption: SelectOption = {
    value: "",
    label: placeholder,
    isDisabled: true,
    isSelected: true,
  }

  const { options, secondSelectOptions } = getChoiceTypeRowOptions(
    defaultOption,
    choices,
    groups,
    activeSecondSelect
  )

  const handleOnChange = (value: string): void => {
    if (groups && isObject(groups[value])) {
      setActiveSecondSelect(value)
      input.onChange(Object.keys(groups[value])[0])
    } else {
      setActiveSecondSelect(null)
      input.onChange(value)
    }
  }

  return (
    <>
      <SelectMol
        {...input}
        options={options}
        hasError={hasError}
        errorMessage={errorMessage}
        onChange={handleOnChange}
      />

      {!isEmpty(secondSelectOptions) ? (
        <PushOrg topSpace={2}>
          <SelectMol
            {...input}
            options={secondSelectOptions}
            hasError={hasError}
            errorMessage={errorMessage}
          />
        </PushOrg>
      ) : null}
    </>
  )
}

export default React.memo(ChoiceTypeRow)
