import _t from "@core/i18n"
import type { StepAtmProps } from "@onestore/hel/dist/components/atoms/StepAtm"

const stepsTitles = {
  shop: _t("purchasingSteps.shop"),
  bonus: _t("purchasingSteps.bonus"),
  basket: _t("purchasingSteps.basket"),
  checkout: _t("purchasingSteps.checkout"),
}

export const getStepsData = (
  isBonusPage?: boolean,
  isBasketPage?: boolean,
  isCheckoutPage?: boolean
): StepAtmProps[] | null => {
  if (!isCheckoutPage && !isBasketPage && !isBonusPage) {
    return null
  }

  if (isBonusPage) {
    return [
      {
        title: stepsTitles.shop,
        state: "completed",
      },
      {
        title: stepsTitles.bonus,
        state: "active",
      },
      {
        title: stepsTitles.basket,
        state: "next",
      },
      {
        title: stepsTitles.checkout,
        state: "next",
      },
    ]
  }

  if (isBasketPage) {
    return [
      {
        title: stepsTitles.shop,
        state: "completed",
      },
      {
        title: stepsTitles.bonus,
        state: "completed",
      },
      {
        title: stepsTitles.basket,
        state: "active",
      },
      {
        title: stepsTitles.checkout,
        state: "next",
      },
    ]
  }

  if (isCheckoutPage) {
    return [
      {
        title: stepsTitles.shop,
        state: "completed",
      },
      {
        title: stepsTitles.bonus,
        state: "completed",
      },
      {
        title: stepsTitles.basket,
        state: "completed",
      },
      {
        title: stepsTitles.checkout,
        state: "active",
      },
    ]
  }

  return null
}
