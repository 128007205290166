import { AxiosError } from "axios"
import type { JSONPatch } from "@onestore/api/types"
import type {
  LegacyApiErrorsError,
  LegacyApiFormValidationError,
  LegacyApiSingleErrors,
} from "@onestore/onestore-store-common/api"
import { HTTP_STATUS } from "@onestore/onestore-store-common/http"
import type {
  BasketSuccessAction,
  BasketThunkAction,
} from "@gatsby-plugin-basket/store/actions"
import { BasketActions } from "@gatsby-plugin-basket/store/constants"
import api from "~/lib/api"
import type { AppDispatch, AppState } from "./reducer"

function handleBasketResponse(
  dispatch,
  promise,
  basketItemsIds: number[] = []
) {
  return promise
    .catch(
      (
        error: AxiosError<
          | LegacyApiSingleErrors
          | LegacyApiErrorsError
          | LegacyApiFormValidationError
        >
      ) => {
        const errorData = api.getNormalizedError(error)
        dispatch({
          type: BasketActions.BASKET_ERROR,
          response_status_code:
            error.status || HTTP_STATUS.INTERNAL_SERVER_ERROR,
          message: "message" in errorData ? errorData.message : "",
          basketItemsIds,
        })
      }
    )
    .then((result) => {
      if (result === undefined) {
        return Promise.reject()
      }

      dispatch({
        type: BasketActions.BASKET_SUCCESS,
        result,
        basketItemsIds,
      } as BasketSuccessAction)

      return Promise.resolve(result)
    })
}

export function patchBasket(
  operations: JSONPatch[],
  basketItemsIds: number[] = []
) {
  return (
    dispatch: AppDispatch<BasketThunkAction>,
    getState: { (): AppState }
  ) => {
    dispatch({
      type: BasketActions.BASKET,
      basketItemsIds,
    })
    const state = getState()

    return handleBasketResponse(
      dispatch,
      api.basketPatch(state.basket.token, operations),
      basketItemsIds
    )
  }
}
