import { graphql, useStaticQuery } from "gatsby"
import type { DomainExtensionCategory } from "~/types/squidex"

interface Query {
  GraphCmsSquidex: {
    categories: DomainExtensionCategory[]
  }
}

export default function useDomainExtensionsByCategories(): DomainExtensionCategory[] {
  const {
    GraphCmsSquidex: { categories },
  } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        categories: queryDomainExtensionCategoryContents(
          orderby: "data/priority/iv asc"
        ) {
          id
          flatData {
            name
            slug
            domains {
              flatData {
                id
                title
                tooltip
                label {
                  ...Label
                }
                description
                pages {
                  ... on GraphCmsSquidex_GenericPage {
                    id
                    flatData {
                      url
                    }
                  }
                }
                extension
                periods {
                  ...CloudBluePeriodValue
                }
                promotionPriceTooltip {
                  ...Tooltip
                }
                logo {
                  id
                  url
                  fileName
                }
                priceInfo {
                  ...PriceInfo
                }
              }
            }
          }
        }
      }
    }
  `)

  return categories
}
