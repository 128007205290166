import type { ReactElement, SyntheticEvent } from "react"
import React from "react"
import type { ButtonAtmProps } from "@onestore/hel/dist/components/atoms/ButtonAtm"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import type { GaEvent } from "~/fragments/gaEvent"
import useAnchorProperties from "~/hooks/useAnchorProperties"
import { sendGaEvent } from "~/lib/analytics"

interface Props
  extends Pick<
    ButtonAtmProps,
    | "title"
    | "text"
    | "color"
    | "variant"
    | "isWider"
    | "isExpanded"
    | "isExpandedOnMobile"
    | "isOverlappingRelativeContainer"
    | "onClick"
    | "rel"
    | "size"
  > {
  anchor: string
  gaEvent?: GaEvent
}

function AnchorButton({
  text,
  anchor,
  gaEvent,
  ...restProps
}: Props): ReactElement<Props> {
  const { onClick } = useAnchorProperties(anchor)

  const handleOnClick = (event: SyntheticEvent) => {
    sendGaEvent(gaEvent)

    if (onClick) {
      onClick(event)
    }
  }

  return (
    <ButtonAtm
      {...useAnchorProperties(anchor)}
      {...restProps}
      onClick={handleOnClick}
    >
      {text}
    </ButtonAtm>
  )
}

export default AnchorButton
