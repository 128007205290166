import React from "react"
import type { ReactElement } from "react"
import _t from "@core/i18n"
import { typeMatches } from "@onestore-graphql"
import PackageBoxSimpleMol from "@onestore/hel/dist/components/molecules/PackageBoxSimpleMol"
import getLinkProps from "@gatsby-plugin-generic-page/helpers/getLinkProps"
import getPriceBySquidexType from "@gatsby-plugin-generic-page/helpers/getPriceBySquidexType"
import { SquidexReferenceType } from "@gatsby-plugin-generic-page/types/referenceType"
import type { SimpleProductBox as SimpleProductBoxType } from "@gatsby-plugin-generic-page/types/simpleProductBoxTypes"
import { SimpleProductBoxPriceType } from "@gatsby-plugin-generic-page/types/simpleProductBoxTypes"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import isEmpty from "~/lib/isEmpty"

function SimpleProductBox(box: SimpleProductBoxType): ReactElement | null {
  const {
    type,
    plan,
    bundlePlan,
    staticPrice,
    button,
    title,
    parameters,
    tag,
    additionalText,
  } = box.flatData
  const { currentPriceType } = usePriceTypeContext()

  let priceData

  if (
    !isEmpty(bundlePlan) &&
    typeMatches(
      bundlePlan[0].__typename,
      SquidexReferenceType.BUNDLE_BUTTON_WITH_PRICE
    ) &&
    type === SimpleProductBoxPriceType.BUNDLE
  ) {
    priceData = getPriceBySquidexType(bundlePlan, currentPriceType)
  }

  if (
    !isEmpty(staticPrice) &&
    typeMatches(staticPrice[0].__typename, SquidexReferenceType.STATIC_PRICE) &&
    type === SimpleProductBoxPriceType.STATIC
  ) {
    priceData = getPriceBySquidexType(staticPrice, currentPriceType)
  }

  if (
    !isEmpty(plan) &&
    typeMatches(plan[0].__typename, SquidexReferenceType.CLOUDBLUE_PLAN) &&
    type === SimpleProductBoxPriceType.PLAN
  ) {
    priceData = getPriceBySquidexType(plan, currentPriceType)
  }

  if (isEmpty(priceData)) {
    return null
  }

  const link = getLinkProps(button.link)

  return (
    <PackageBoxSimpleMol
      title={title}
      parameters={parameters.map(({ name, value }) => ({
        name,
        value: value || true,
      }))}
      label={
        tag ||
        (priceData.price.discount
          ? `${_t("packageBox.discountTitle")} -${priceData.price.discount}%`
          : undefined)
      }
      price={{
        prefix: priceData.price.prefix,
        value: priceData.price.value,
        currency: priceData.price.currency,
        suffix: priceData.price.suffix,
      }}
      renewPrice={
        priceData.renewPrice
          ? {
              label: _t("prices.renewal"),
              value: priceData.renewPrice.value,
              suffix: priceData.renewPrice.suffix,
            }
          : undefined
      }
      additionalInfo={additionalText}
      button={{
        ...link,
      }}
      buttonColor={button.color}
      buttonVariant={button.variant}
    />
  )
}

export default SimpleProductBox
