import React from "react"
import type { ReactElement, SyntheticEvent } from "react"
import _t from "@core/i18n"
import Tick from "@onestore-icons/tick.svg"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import { PriceVariantType } from "@onestore/hel/dist/components/molecules/PriceMol/priceMol.types"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper/index"
import useBasketActions from "@gatsby-plugin-basket/hooks/useBasketActions"
import type { BundleButtonWithPrice as BundleButtonWithPriceProps } from "@gatsby-plugin-generic-page/fragments/bundleButtonWithPrice"
import getBundlePriceData from "@gatsby-plugin-generic-page/helpers/getBundlePriceData"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import usePriceConfigurationQuery from "~/hooks/usePriceConfigurationQuery"
import { sendGaEvent } from "~/lib/analytics"
import isEmpty from "~/lib/isEmpty"
import { getBundlePricesFromDefinitionForType } from "~/lib/pricing"
import DetailedStaticPrice from "../DetailedStaticPrice"

function BundleButtonWithPrice({
  flatData: {
    calculatedPrice,
    plan,
    bundlePlan,
    code,
    text,
    hidePrice,
    title,
    gaEvent,
    buttonColor,
    buttonVariant,
  },
}: BundleButtonWithPriceProps): ReactElement<BundleButtonWithPriceProps> | null {
  const { showDetailedPrices } = usePriceConfigurationQuery()
  const { currentPriceType, isNettoPriceType } = usePriceTypeContext()

  const bundlePrices = getBundlePricesFromDefinitionForType(
    calculatedPrice[0],
    currentPriceType
  )
  const { addProductToBasket, isLoading, isDone, goToBasket } =
    useBasketActions(plan[0], bundlePlan[0], code ?? undefined)

  const handleOnClick = (event: SyntheticEvent) => {
    event.preventDefault()

    sendGaEvent(gaEvent)

    addProductToBasket()
  }

  const price = bundlePrices.primaryTypePromoPrice
    ? bundlePrices.primaryTypePromoPrice.formatted
    : bundlePrices.primaryTypeRegularPrice.formatted

  const periodText = isNettoPriceType ? _t("prices.netto") : _t("prices.gross")
  const buttonObject = {
    icon: isDone ? Tick : undefined,
    isLoading: isLoading,
    color: buttonColor?.color,
    variant: buttonVariant?.variant,
    title: isDone ? _t("button.genericPageSuccess") : title,
    onClick: isDone ? goToBasket : handleOnClick,
  }

  let priceElement = (
    <>
      <TextAtm typography="sgiant">
        <TextAtm color="primary">{price}</TextAtm>{" "}
      </TextAtm>

      <PushOrg
        bottomSpace={{
          large: 4,
          medium: 4,
          small: 3,
        }}
      >
        <FlexContainerOrg alignItems="center">
          <TextAtm typography="xsmall1" emphasis="medium">
            {periodText}
          </TextAtm>
        </FlexContainerOrg>
      </PushOrg>
    </>
  )

  if (showDetailedPrices) {
    const primaryPlanData = !isEmpty(plan) ? plan[0] : undefined
    const bundlePlanData = !isEmpty(bundlePlan) ? bundlePlan[0] : undefined

    const calculatedPriceData = !isEmpty(calculatedPrice)
      ? calculatedPrice[0]
      : null

    const period = !isEmpty(calculatedPriceData)
      ? calculatedPriceData.periodName
      : null

    if (
      isEmpty(primaryPlanData) ||
      isEmpty(bundlePlanData) ||
      isEmpty(calculatedPriceData)
    ) {
      return null
    }

    const bundlePriceData = getBundlePriceData(
      calculatedPriceData,
      primaryPlanData,
      bundlePlanData,
      period,
      currentPriceType
    )

    if (isEmpty(bundlePriceData)) {
      return null
    }

    const hasPromotion = bundlePriceData.bundleData?.hasPromotion

    priceElement = (
      <PushOrg
        bottomSpace={{
          small: hasPromotion ? 4 : 2,
          medium: hasPromotion ? 2 : 1,
          large: hasPromotion ? 2 : 1,
        }}
        hasNoReset
      >
        <DetailedStaticPrice
          variant={{
            small: {
              type: PriceVariantType.SMALL,
              align: "left",
            },
            medium: {
              type: PriceVariantType.MEDIUM,
              align: "left",
            },
            large: {
              type: PriceVariantType.MEDIUM,
              align: "left",
            },
          }}
          {...bundlePriceData}
        />
      </PushOrg>
    )
  }

  return (
    <div data-testid="bundle-button-with-price">
      <ConditionalWrapper
        condition={!hidePrice}
        wrapper={(children) => (
          <div>
            {priceElement}
            {children}
          </div>
        )}
      >
        <BreakpointVisibilityOrg from="medium">
          <ButtonAtm {...buttonObject} isWider isExpanded>
            {isDone ? _t("button.genericPageSuccess") : text}
          </ButtonAtm>
        </BreakpointVisibilityOrg>

        <BreakpointVisibilityOrg from="small" to="medium">
          <ButtonAtm {...buttonObject} isExpanded>
            {isDone ? _t("button.genericPageSuccess") : text}
          </ButtonAtm>
        </BreakpointVisibilityOrg>
      </ConditionalWrapper>
    </div>
  )
}

export default BundleButtonWithPrice
