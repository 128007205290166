import type { ReactElement } from "react"
import React from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import getPriceBySquidexType from "@gatsby-plugin-generic-page/helpers/getPriceBySquidexType"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import isEmpty from "~/lib/isEmpty"
import type { TextWithPrice as TextWithPriceType } from "~/types/squidex"
import MarkdownText from "../Markdown/Text"

export default function TextWithPrice({
  text,
  price,
}: TextWithPriceType): ReactElement<TextWithPriceType> | null {
  const { currentPriceType } = usePriceTypeContext()
  const priceData = getPriceBySquidexType(price, currentPriceType)

  if (isEmpty(priceData)) {
    return null
  }

  return (
    <TextAtm typography="medium2_h">
      <MarkdownText typography={{ default: "medium2_h", bold: "medium4_h" }}>
        {text}
      </MarkdownText>

      <br />

      {priceData.price.prefix ? (
        <TextAtm>{`${priceData.price.prefix} `}</TextAtm>
      ) : null}

      <TextAtm isBold>
        {priceData.price.value} {priceData.price.currency}
      </TextAtm>

      <TextAtm> {priceData.price.suffix}</TextAtm>
    </TextAtm>
  )
}
