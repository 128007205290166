import type { AccountType } from "@onestore/api/types"
import {
  BasketActions,
  BASKET_STATUS,
} from "@gatsby-plugin-basket/store/constants"
import type { PaymentSuccessAction } from "@gatsby-plugin-checkout/store/actions"
import { sendUserLoginEvent } from "~/lib/analytics"
import {
  sendBasketChangePaymentEvent,
  sendBasketItemRemovedEvent,
  sendOrderProceedEvent,
} from "~/lib/events"
import { CheckoutActions } from "../constants"

const externalEvents = () => (next) => (action) => {
  if (action.type === CheckoutActions.PAYMENT_SUCCESS) {
    const {
      result: { id, number },
      basket,
    } = action as PaymentSuccessAction

    sendOrderProceedEvent({
      items: basket.items,
      totalValue: basket.totalValue,
      accountType: basket.accountType as AccountType,
      vatValue: basket.vatValue,
      savings: basket.savings,
      status: basket.status,
      orderId: id,
      orderNumber: number,
    })
  }

  if (action.type === BasketActions.BASKET_SUCCESS) {
    const {
      result: { status, order_id },
    } = action

    if (BASKET_STATUS.ARCHIVED === status && order_id !== null) {
      sendOrderProceedEvent({
        items: action.result.items,
        totalValue: action.result.total_net_price,
        vatValue: action.result.total_vat_value,
        savings: action.result.savings,
        status: action.result.status,
        orderId: action.result.order_id,
        orderNumber: action.result.order_number,
        accountType: action.result.account_type,
      })
    }
  }

  if (action.type === BasketActions.BASKET_CHANGE_PAYMENT) {
    sendBasketChangePaymentEvent({
      payment_type: action.paymentMethodId,
      item_id: action.item_id,
      name: action.name,
      price: action.price,
      quantity: action.quantity,
      index: action.index,
      affiliation: action.affiliation,
      item_category: action.item_category,
      item_variant: action.item_variant,
      cart_id: action.cart_id,
    })
  }

  if (action.type === BasketActions.BASKET_ITEM_REMOVED) {
    sendBasketItemRemovedEvent({
      item_id: action.item_id,
      name: action.name,
      price: action.price,
      quantity: action.quantity,
      index: action.index,
      affiliation: action.affiliation,
      item_category: action.item_category,
      item_variant: action.item_variant,
      cart_id: action.cart_id,
    })
  }

  if (action.type === CheckoutActions.USER_SUCCESS) {
    if (action.is_after_login) {
      sendUserLoginEvent(action.result.user_id)
    }
  }

  return next(action)
}

export default externalEvents
