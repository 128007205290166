import React from "react"
import type { ReactElement } from "react"
import SelectWithLinksMol from "@onestore/hel/dist/components/molecules/SelectWithLinksMol"
import type { OptionSelectMenu } from "@gatsby-plugin-generic-page/fragments/optionSelectMenu"
import getLinkProps from "@gatsby-plugin-generic-page/helpers/getLinkProps"
import Media from "../Media"

export interface SelectMenuProps {
  options: OptionSelectMenu[]
}

function SelectMenu({
  options,
}: SelectMenuProps): ReactElement<SelectMenuProps> {
  const selectMenuOptions = options.map((option) => {
    const flatData = option.flatData

    const { to, href, title, openInNewTab, onClick, text } = getLinkProps(
      flatData.link
    )

    return {
      label: text,
      link: { to, href, title, openInNewTab, onClick },
      icon: {
        backgroundColor: flatData.icon[0].backgroundColor,
        image: <Media media={flatData.icon[0].image} />,
      },
    }
  })

  return <SelectWithLinksMol options={selectMenuOptions} />
}

export default SelectMenu
