import { sendSiteSearchResultEvent } from "~/lib/analytics"
import { PmailCheckAction } from "../constants"

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (next) => (action) => {
  if (action.type === PmailCheckAction.SUCCESS) {
    sendSiteSearchResultEvent(action.phrase)
  }

  return next(action)
}
