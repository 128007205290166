import type { ReactElement } from "react"
import React from "react"
import { createContext } from "react"

type HasAnyPromotionContextProps = {
  hasAnyPromotion: boolean
  children: React.ReactNode
}

export const HasAnyPromotionContext =
  createContext<HasAnyPromotionContextProps["hasAnyPromotion"]>(false)

export function HasAnyPromotionContextProvider({
  hasAnyPromotion,
  children,
}: HasAnyPromotionContextProps): ReactElement<HasAnyPromotionContextProps> {
  return (
    <HasAnyPromotionContext.Provider value={hasAnyPromotion}>
      {children}
    </HasAnyPromotionContext.Provider>
  )
}

export default HasAnyPromotionContext
