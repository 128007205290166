import type Theme from "@onestore/hel/dist/typings/theme"

export enum SquidexFormType {
  FORM = "Form",
  FORM_SALES_MANAGO = "FormSalesManago",
  FORMILY_FORM = "FormilyForm",
  HUBSPOT_FORM = "HubspotForm",
  FORMILY_FORM_WITH_THANK_YOU = "FormilyFormWithThankYou",
}

export type FormButtonColor = Theme.AccentColor
