import { graphql, useStaticQuery } from "gatsby"
import { get } from "lodash"
import type { Tooltip } from "@gatsby-plugin-generic-page/fragments/tooltip"
import type { PriceInfo } from "~/fragments/priceInfo"

export type Result = {
  domainLabel?: string | null
  tooltip?: string
  limitTooltip?: string
  priceInfo?: PriceInfo
}

export type DomainInfo = {
  flatData: {
    id: number
    promoLabel: string | null
    promotionPriceTooltip: Tooltip[] | null | undefined
    limitTooltip: Tooltip[] | null | undefined
    priceInfo: PriceInfo | null | undefined
  }
}

type Query = {
  GraphCmsSquidex: {
    queryCloudblueDomainContents: DomainInfo[]
  }
}

export function useCloudblueDomainInfo(domainPlanId: number | null): Result {
  const {
    GraphCmsSquidex: { queryCloudblueDomainContents },
  } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        queryCloudblueDomainContents {
          id
          flatData {
            id
            promoLabel
            promotionPriceTooltip {
              ...Tooltip
            }
            limitTooltip {
              ...Tooltip
            }
            priceInfo {
              ...PriceInfo
            }
          }
        }
      }
    }
  `)

  const domainInfo = queryCloudblueDomainContents?.find(
    (domain) => domainPlanId === domain.flatData.id && domain
  )

  return {
    domainLabel: get(domainInfo, "flatData.promoLabel", undefined),
    tooltip: get(
      domainInfo,
      "flatData.promotionPriceTooltip[0].flatData.text",
      undefined
    ),
    limitTooltip: get(
      domainInfo,
      "flatData.limitTooltip[0].flatData.text",
      undefined
    ),
    priceInfo: get(domainInfo, "flatData.priceInfo", undefined),
  }
}
