import type { MarkOptional, MarkRequired } from "ts-essentials"
import type { BasketLastItem, BasketResponseItem } from "@onestore/api/basket"
import type { DomainCheck } from "@onestore/api/domainSearch"
import type {
  PeriodName,
  PlanPeriodNumericId,
  Price,
  ProductAlias,
} from "@onestore/api/types"
import type { HTTP } from "@onestore/onestore-store-common"
import type { EmailHackState } from "@gatsby-plugin-bonus/components/EmailHackBox/useEmailHackConfigurationQuery"
import type { ProductPrice } from "@gatsby-plugin-bonus/lib/bonus-definitions"
import type { ParentPromotionPeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import type { NormalizedBonusResource } from "@gatsby-plugin-definitions/lib/normalizers"
import type { FormErrors } from "../../../lib/api/forms"
import type { QuantityResource } from "../../../lib/api/product"
import type { PeriodInfoPeriod } from "../../../lib/core/period-info"

export interface BonusPeriod
  extends MarkRequired<PeriodInfoPeriod, "resource_data"> {
  id: number
  trial: boolean
  price: ProductPrice
  parent_promotions: ParentPromotionPeriod[]
  plan_id: number
}

export interface BonusResource {
  id: number
  basketItemId?: number
  maxQuantity: number
  quantity: number
  basketQuantity: number
  minQuantity: number
  includedValue: number
}

export type BonusResourceWithBasketItem = MarkRequired<
  BonusResource,
  "basketItemId"
>
export type ResourceId = number
export type BonusResources = Record<ResourceId, BonusResource>

export interface BonusProductParameter {
  id: string
  name: string
  description: string
  required: boolean
  alias: string | null
  type: string
}

export interface BonusProduct {
  alias: ProductAlias
  planId: number
  name: string
  definitionLoaded: boolean
  advancedConfiguration: boolean
  quantityResource: QuantityResource | null
  configurationSaved: boolean
  periods: BonusPeriod[]
  hasQuantity: boolean
  minQuantity: number
  totalPrices: {
    regularPrice: Price | null
    promoPrice: Price | null
  }
  chosenPeriodId: PlanPeriodNumericId
  previousPeriodId: PlanPeriodNumericId
  defaultPeriodName: PeriodName | null
  basketItemState?: BasketResponseItem
  addedToBasket: boolean
  autoAddedToBasket: boolean
  addToBasketStatus: number
  addToBasketError: undefined | FormErrors
  changePeriodStatus: number
  changePeriodError: undefined | string
  getPriceStatus: number
  getPriceError: undefined | string
  isPriceLoading: boolean
  isAddToBasketLoading: boolean
  isChangePeriodLoading: boolean
  resources: BonusResources
  resourceCategories: BonusResourceCategory[]
  resourceLabels: Record<ResourceId, string>
  isChangeResourceLoading: boolean
  changeResourceStatus: number
  isValid: boolean
  removedFromBasket: boolean
  errors: Record<string, string>
  parameters: Record<string, BonusProductParameter>
  removeFromBasketStatus: HTTP.Status
  initialParamFormData: Record<string, string>
}

export function isBonusProductWithRemoteStateType(
  product: BonusProduct | BonusProductWithRemoteState
): product is BonusProductWithRemoteState {
  return product.basketItemState !== undefined
}

export type BonusProductWithRemoteState = MarkRequired<
  BonusProduct,
  "basketItemState"
>

export interface InitialDomainBonusProduct {
  alias: ProductAlias
  name: string
  planId: number | null
  definitionLoaded: boolean
  advancedConfiguration: boolean
  configurationSaved: boolean
  periods: BonusPeriod[]
  hasQuantity: boolean
  minQuantity: number
  totalPrices: {
    regularPrice: Price | null
    promoPrice: Price | null
  }
  chosenPeriodId: PlanPeriodNumericId | null
  previousPeriodId: PlanPeriodNumericId | null
  defaultPeriodName: PeriodName | null
  basketItemState?: BasketResponseItem
  addedToBasket: boolean
  autoAddedToBasket: boolean
  addToBasketStatus: number
  addToBasketError: undefined | FormErrors
  changePeriodStatus: number
  changePeriodError: undefined | string
  getPriceStatus: number
  getPriceError: undefined | string
  isPriceLoading: boolean
  isAddToBasketLoading: boolean
  isChangePeriodLoading: boolean
  resourceCategories: BonusResourceCategory[]
  resourceLabels: Record<ResourceId, string>
  isChangeResourceLoading: boolean
  changeResourceStatus: number
  isValid: boolean
  removedFromBasket: boolean
  errors: Record<string, string>
  parameters: Record<string, BonusProductParameter>
  removeFromBasketStatus: HTTP.Status
  pool?: string
}

export interface DomainBonusProduct extends InitialDomainBonusProduct {
  planId: number
  chosenPeriodId: PlanPeriodNumericId
  previousPeriodId: PlanPeriodNumericId
}

export interface ParametersPatchData {
  patchParameters?: {
    domain?: string
  }
}

export type BonusProductForUpdate = MarkOptional<
  Pick<
    BonusProductWithRemoteState,
    "alias" | "resources" | "addedToBasket" | "basketItemState"
  >,
  "basketItemState"
>

export interface UpsellDomainsProducts {
  [alias: string]: BonusProduct
}

export interface UpsellDomains {
  results?: DomainCheck.Result[]
  status: HTTP.Status
}

export type UpsellDefinitions = UpsellDomainsProducts

export interface BonusProductUpgrade {}

export interface BonusFormInstance {
  valid: boolean
  values: Record<string, string>
}

export type BonusForm = Record<ProductAlias, BonusFormInstance>

export type BonusDefinitions = {
  readonly special: string[]
  readonly promoCode?: string
  readonly chosenPeriodName?: PeriodName | null
  readonly hidePeriods?: boolean
  readonly checkoutMode: "basket" | "finalize"
  readonly definitionsLoaded: boolean
  readonly loaded: boolean
  readonly upsell: UpsellDefinitions
  readonly upsellDomains: UpsellDomains
  readonly product?: BonusProduct
  readonly bundled?: BonusProduct
  readonly upgrade?: BonusProductUpgrade
  readonly forms: BonusForm
  readonly domain?: DomainBonusProduct | InitialDomainBonusProduct
  readonly domainSearch?: string
  readonly domainsSearch?: {
    status?: HTTP.Status
    results?: DomainCheck.Result[]
    nextStep?: boolean
    hideSearch?: boolean
    bundleDomains?: DomainCheck.DomainSearchBundle[] | null
  }
  readonly hideUpsell?: boolean
  readonly emailHack?: EmailHackState
  calculation: {
    product: BasketLastItem | undefined
    bundled: BasketLastItem | undefined
  }
}

export interface DomainBoxUrlParams {
  pool: number
  extensions: string
  limit: number
}

export interface SpecialBoxDefinition {
  alias: string
  template?: string
  parameters?: DomainBoxUrlParams
}

export interface PeriodPrice {
  regularPrice: Price
  pricePerMonth: Price | null | undefined
  promoPrice?: Price
}

export interface Period {
  id: number
  name: string
  price: PeriodPrice
  trial: boolean
  type: number
  period: number
}

export type Periods = Period[]

export interface ProductResource {
  alias?: string
  id?: number
  description?: string
  quantity: number
  minQuantity: number
  maxQuantity: number
  includedValue?: number
  basketItemId?: number
}

export interface BonusProductErrors {
  parameters: Record<string, string>
}

export interface Parameter {
  id: string
  type: string
  name: string
  alias: string | null
  description: string
  required: boolean
}

export interface ProductConfigCheck {
  id: number | string
  type?: "radio" | "checkbox"
  name: string
  primaryLabel: string
  secondaryLabel?: string
  isDisabled?: boolean
  isChecked?: boolean
  isLoading?: boolean
  value: string
}

export interface PasswordValidatorItem {
  pattern: RegExp
  label: string
}

export interface Resource {
  id: number
  alias?: string | null
  description?: string
  name?: string
  measureUnit?: string
}

export interface ResourceCategory {
  id: number
  name: string
  resources?: Resource[]
  noneOption: boolean
  description: string
  displayType: number
  sortOrder: number
  parent?: number
}

export interface BonusResourceCategory {
  id: number
  name: string
  resources: NormalizedBonusResource[]
  parent: number | null | undefined
  description: string
  noneOption: boolean
  displayType: number
  sortOrder: number
  defaultResourceId: number | null
}

export type BonusState = BonusDefinitions
