import { typeMatches } from "@onestore-graphql"
import type { LinkAtmProps } from "@onestore/hel/dist/components/atoms/LinkAtm"
import type { InternalLink } from "@gatsby-plugin-generic-page/fragments/internalLink"
import type { LinkElement } from "@gatsby-plugin-generic-page/fragments/linkElement"
import type { LinkElementWrapper } from "@gatsby-plugin-generic-page/fragments/linkElementWrapper"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import { sendGaEvent } from "~/lib/analytics"
import isEmpty from "~/lib/isEmpty"
import type {
  AnchorId,
  ExternalLink,
  SquidexMarkdownEmbeddedContent,
} from "~/types/squidex"
import { getAnchorProperties } from "../../../../src/lib/dom"

type LinkPropsType = Omit<LinkAtmProps, "typography"> & {
  textWithEmbeddedContent: SquidexMarkdownEmbeddedContent
}

function getLinkProps(data: LinkElement | LinkElementWrapper): LinkPropsType {
  let text
  let textWithEmbeddedContent
  const { title, gaEvent, link } = data

  if (typeMatches(data.__typename, SquidexComponentType.LINK)) {
    const linkElementData = data as LinkElement
    text = linkElementData.text.text
    textWithEmbeddedContent = linkElementData.text
  }

  let href, to, openInNewTab, onClick

  if (typeMatches(link.__typename, SquidexComponentType.EXTERNAL_LINK)) {
    const { href: hrefLink, openInNewTab: openNewTab } = link as ExternalLink

    href = hrefLink
    openInNewTab = openNewTab
  }

  if (typeMatches(link.__typename, SquidexComponentType.ANCHOR_ID)) {
    const { anchorId } = link as AnchorId
    const anchorProperties = getAnchorProperties(anchorId)

    href = anchorProperties.href
    onClick = anchorProperties.onClick
  }

  if (typeMatches(link.__typename, SquidexComponentType.INTERNAL_LINK)) {
    const { pages, openInNewTab: openNewTab } = link as InternalLink

    if (!isEmpty(pages)) {
      to = !openNewTab ? pages[0].flatData.url : undefined
      href = openNewTab ? pages[0].flatData.url : undefined
      openInNewTab = openNewTab
    }
  }

  const handleOnClick =
    onClick || gaEvent
      ? (event) => {
          if (onClick) {
            onClick(event)
          }

          sendGaEvent(gaEvent)
        }
      : undefined

  return {
    to,
    href,
    text,
    textWithEmbeddedContent,
    title,
    openInNewTab,
    onClick: handleOnClick,
  }
}

export default getLinkProps
