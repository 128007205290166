import type { ReactElement } from "react"
import React from "react"
import { PeriodInfo } from "@core/period-info"
import { PriceFormat } from "@core/types"
import type { RegularPriceMolProps } from "@onestore/hel/dist/components/molecules/RegularPriceMol/RegularPriceMol"
import RegularPriceMol from "@onestore/hel/dist/components/molecules/RegularPriceMol/RegularPriceMol"
import type { Typography } from "@onestore/hel/dist/contexts/TypographyContext"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import isEmpty from "~/lib/isEmpty"
import type { CloudBlueDomain } from "../../../../../src/types/squidex"

type ProductPriceProps = Pick<
  RegularPriceMolProps,
  "isSmall" | "isVertical" | "hasTextColor"
> & {
  plan?: CloudBluePlan[] | CloudBlueDomain[]
  priceTypography?: Typography
  currencyTypography?: Typography
  suffixTypography?: Typography
}

export default function ProductPrice({
  plan,
  isSmall,
  isVertical,
  hasTextColor,
  priceTypography,
  currencyTypography,
  suffixTypography,
}: ProductPriceProps): ReactElement<ProductPriceProps> | null {
  if (isEmpty(plan) || isEmpty(plan[0].flatData.periods)) {
    return null
  }

  const defaultPeriod = plan[0].flatData.periods.find(
    (period) => period.default
  )

  if (!defaultPeriod) {
    return null
  }

  const priceInfo = new PeriodInfo(defaultPeriod)

  const priceContent = {
    integer: priceInfo.getFormatted(PriceFormat.REGISTER_GROSS_DECIMALS),
    fraction: priceInfo.getFormatted(
      PriceFormat.SEPARATED_REGISTER_GROSS_CENTS
    ),
    currency: priceInfo.getFormatted(PriceFormat.CURRENCY),
    suffix: priceInfo.getFormatted(PriceFormat.GROSS_WITH_PERIOD),
  }

  return (
    <RegularPriceMol
      isVertical={isVertical}
      hasTextColor={hasTextColor}
      isSmall={isSmall}
      priceTypography={priceTypography}
      currencyTypography={currencyTypography}
      suffixTypography={suffixTypography}
      {...priceContent}
    />
  )
}
