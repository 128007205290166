import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import { scrollToAnchor } from "~/lib/dom"
import { COMPONENT_ID } from "~/types/component"
import AsideButtonWrapper from "./AsideButtonWrapper"

export default function ScrollToCategoriesButton({}): ReactElement {
  const isMediumUp = useBreakpoint("medium")

  const buttonText = <>{_t("domainSearch.button.seeOtherExtensions")}</>

  return (
    <AsideButtonWrapper>
      <ButtonAtm
        title={_t("domainSearch.button.seeOtherExtensions")}
        onClick={() => {
          scrollToAnchor(COMPONENT_ID.DOMAIN_SEARCH_SECTION_SECONDARY)
        }}
        color="success"
        size={isMediumUp ? "small" : "default"}
        isExpandedOnMobile
        isWider
      >
        {buttonText}
      </ButtonAtm>
    </AsideButtonWrapper>
  )
}
