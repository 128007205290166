import type { MarkdownToJSX } from "markdown-to-jsx"
import type { NestedLinkAtmProps } from "@onestore/hel/dist/components/atoms/NestedLinkAtm"
import NestedLinkAtm from "@onestore/hel/dist/components/atoms/NestedLinkAtm"
import getBaseMarkdownOverrides from "@gatsby-plugin-generic-page/helpers/getBaseMarkdownOverrides"

export const mainSearchPromoText: MarkdownToJSX.Overrides = {
  ...getBaseMarkdownOverrides({
    typography: "xsmall1",
    boldTypography: "xsmall3",
  }),
  a: {
    component: NestedLinkAtm,
    props: {
      openInNewTab: true,
    } as NestedLinkAtmProps,
  },
}
