import type { ReactElement, ReactNode } from "react"
import React from "react"
import ArrowLinkAtm from "@onestore/hel/dist/components/atoms/ArrowLinkAtm"
import LinkAtm from "@onestore/hel/dist/components/atoms/LinkAtm"
import UnderlineLinkAtm from "@onestore/hel/dist/components/atoms/UnderlineLinkAtm"
import type Theme from "@onestore/hel/dist/typings/theme"
import { SquidexLinkType } from "@gatsby-plugin-generic-page/types/ctaElementType"
import { useFormHook } from "~/hooks/useFormHook"
import { EventCategory, sendGAFormEvent } from "~/lib/analytics"
import type { FormButton } from "~/types/squidex"
import type { FormModalProps } from "../FormModal"
import FormModal from "../FormModal"

export interface FormLinkProps
  extends Omit<FormModalProps, "onCloseButtonClick"> {
  title: string
  text: string
  typography?: Theme.Typography
  formButtonTitle: FormButton["title"]
  formButtonText: FormButton["text"]
  formButtonColor: FormButton["buttonColor"]["color"]
  headingText: ReactNode
  additionalData?: Record<string, string>
  linkType?: SquidexLinkType
}

export default function FormLink({
  title,
  text,
  typography = "small2",
  formButtonTitle,
  formButtonText,
  formButtonColor,
  headingText,
  id,
  additionalData = {},
  linkType,
}: FormLinkProps): ReactElement<FormLinkProps> {
  const [visible, setVisible] = React.useState(false)
  const { fetchData } = useFormHook(id)

  const onClick = (event): void => {
    event.stopPropagation()
    fetchData()
    sendGAFormEvent({
      event: EventCategory.FORM_VIEW,
      form_type: "toplayer",
      form_id: id,
    })
    setVisible(true)
  }

  return (
    <>
      {linkType === SquidexLinkType.DEFAULT && (
        <LinkAtm
          data-test-id="form-button"
          title={title}
          typography={typography}
          onClick={onClick}
        >
          {text}
        </LinkAtm>
      )}

      {linkType === SquidexLinkType.UNDERLINE && (
        <UnderlineLinkAtm
          data-test-id="form-button"
          title={title}
          typography={typography}
          onClick={onClick}
        >
          {text}
        </UnderlineLinkAtm>
      )}

      {linkType === SquidexLinkType.ARROW && (
        <ArrowLinkAtm
          data-test-id="form-button"
          title={title}
          typography={typography}
          onClick={onClick}
        >
          {text}
        </ArrowLinkAtm>
      )}

      {visible ? (
        <FormModal
          headingText={headingText}
          id={id}
          additionalData={additionalData}
          button={{
            title: formButtonTitle || formButtonText,
            text: formButtonText,
            buttonColor: { color: formButtonColor },
          }}
          onCloseButtonClick={() => {
            setVisible(false)
          }}
        />
      ) : null}
    </>
  )
}
