import type { AxiosResponse, InternalAxiosRequestConfig } from "axios"
import { getAppVersion, getBuildTimestamp } from "~/lib/config"
import isEmpty from "~/lib/isEmpty"
import { getMetricsPayload } from "~/lib/metrics"
import { parse } from "~/lib/semver"
import Storage from "~/lib/storage"
import type { ApiConfig } from "./index"
import { HEADER_ACTIONS, HEADER_APP_VERSION } from "./index"

function dispatchEvent(name: string, detail: unknown) {
  const event = new CustomEvent(name, {
    detail,
  })

  window.document.dispatchEvent(event)
}

export const PURGE_CLIENT_TOKENS = "purge-client-tokens"

export function actionsInterceptor(response: AxiosResponse): AxiosResponse {
  const actions: string[] | string = response.headers[HEADER_ACTIONS]

  let actionsList: string[] = []

  if (typeof actions === "string") {
    actionsList.push(actions)
  }

  if (Array.isArray(actions)) {
    actionsList = [...actionsList, ...actions]
  }

  actionsList.forEach((action) => {
    if (action === PURGE_CLIENT_TOKENS) {
      dispatchEvent(PURGE_CLIENT_TOKENS, {})
    }
  })

  return response
}

export function appVersionInterceptor(response: AxiosResponse): AxiosResponse {
  const version = response.headers[HEADER_APP_VERSION]

  if (!isEmpty(version)) {
    dispatchEvent("api_version_changed", {
      version: parse(version),
    })
  }

  return response
}

export function clearSessionIdInterceptor(
  response: AxiosResponse
): AxiosResponse {
  if (response.status === 401) {
    Storage.clearSessionId()
  }

  return response
}

export function apiRequestInterceptor(apiConfig: ApiConfig): {
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig
} {
  return (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    const metricsPayload = getMetricsPayload()

    if (metricsPayload) {
      config.headers["X-DID"] = metricsPayload
    }

    config.headers["X-Visit-Referrer"] = document.referrer // client visit referrer
    config.headers["X-Page-Referrer"] = document.location.toString() // full visit referrer with query string
    config.headers["X-Build-Id"] = `${getAppVersion()}@${getBuildTimestamp()}`

    if (apiConfig.useSession) {
      const sessionId = Storage.getSessionId()

      if (sessionId) {
        config.headers[`session-id`] = sessionId
      }
    }

    return config
  }
}
