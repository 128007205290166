import React from "react"
import type { FieldValidator } from "final-form"
import { Field } from "react-final-form"
import SelectMol from "@onestore/hel/dist/components/molecules/SelectMol"

export interface Option {
  value: string
  label: string
  isDisabled?: boolean
  isSelected?: boolean
}

export interface FinalFormSelectProps {
  name: string
  externalError?: string

  readonly?: boolean
  isInactive?: boolean
  options: Option[]
  onValidate?: FieldValidator<string>
  initialValue?: string
}

export default function Select({
  options,
  externalError,
  name,
  isInactive,
  onValidate,
  initialValue,
  ...restProps
}: FinalFormSelectProps) {
  return (
    <Field
      initialValue={initialValue}
      validate={onValidate}
      name={name}
      render={({ input, meta }) => (
        <SelectMol
          {...restProps}
          options={options.map((item: Option) => {
            return {
              ...item,
              isSelected: item.value === input.value,
            }
          })}
          onChange={input.onChange}
          isInactive={isInactive}
          hasError={
            (meta.touched && (meta.error || meta.submitError)) || externalError
          }
          errorMessage={
            meta.touched && (meta.error || meta.submitError)
              ? meta.error || meta.submitError
              : externalError || undefined
          }
        />
      )}
    />
  )
}
