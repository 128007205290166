import type { ReactElement } from "react"
import React from "react"
import { PeriodInfo } from "@core/period-info"
import type { ProductPriceResponse } from "@onestore/api/types"
import ListMol from "@onestore/hel/dist/components/molecules/ListMol"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import FlexItemOrg from "@onestore/hel/dist/components/organisms/FlexItemOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import SurfaceOrg from "@onestore/hel/dist/components/organisms/SurfaceOrg"
import WidthOrg from "@onestore/hel/dist/components/organisms/WidthOrg"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import BundleButtonWithPrice from "@gatsby-plugin-generic-page/components/BundleButtonWithPrice"
import Header from "@gatsby-plugin-generic-page/components/Header"
import ResponsiveImage from "@gatsby-plugin-generic-page/components/ResponsiveImage"
import Texts from "@gatsby-plugin-generic-page/components/Texts"
import type { BundleSectionFlatData } from "@gatsby-plugin-generic-page/fragments/bundleSection"
import getBaseMarkdownOverrides from "@gatsby-plugin-generic-page/helpers/getBaseMarkdownOverrides"
import usePriceConfigurationQuery from "~/hooks/usePriceConfigurationQuery"
import isEmpty from "~/lib/isEmpty"
import { getSelectedPeriod } from "~/lib/plan"
import ImageContainer from "./components/ImageContainer"

export const checkBundlePromotion = (
  period: string | null,
  plan: CloudBluePlan,
  bundlePlan: CloudBluePlan
): boolean => {
  const primarySelectedPeriod: CloudBluePeriod = getSelectedPeriod(
    plan.flatData,
    period
  )
  const bundleSelectedPeriod: CloudBluePeriod = getSelectedPeriod(
    bundlePlan.flatData,
    period
  )

  const primaryPeriodInfo = new PeriodInfo(primarySelectedPeriod)
  const bundlePeriodInfo = new PeriodInfo(bundleSelectedPeriod)

  return primaryPeriodInfo.hasPromotion() && bundlePeriodInfo.hasPromotion()
}

function BundleSection({
  header,
  texts,
  buttonWithPrice,
  responsiveImages,
  sectionSettings,
}: BundleSectionFlatData): ReactElement<BundleSectionFlatData> | null {
  const { showDetailedPrices } = usePriceConfigurationQuery()

  const buttonWithPriceData = !isEmpty(buttonWithPrice)
    ? buttonWithPrice[0].flatData
    : undefined
  const calculatedPrice: ProductPriceResponse[] =
    buttonWithPriceData.calculatedPrice

  if (
    isEmpty(calculatedPrice) ||
    isEmpty(buttonWithPriceData.plan) ||
    isEmpty(buttonWithPriceData.bundlePlan)
  ) {
    return null
  }

  const hasPromotion =
    showDetailedPrices &&
    checkBundlePromotion(
      calculatedPrice[0].periodName,
      buttonWithPriceData.plan[0],
      buttonWithPriceData.bundlePlan[0]
    )

  const imageJsx = (
    <ImageContainer>
      <BreakpointVisibilityOrg from="medium">
        <WidthOrg widthSpace={16}>
          <ResponsiveImage images={responsiveImages} />
        </WidthOrg>
      </BreakpointVisibilityOrg>

      <BreakpointVisibilityOrg from="small" to="medium">
        <ResponsiveImage images={responsiveImages} />
      </BreakpointVisibilityOrg>
    </ImageContainer>
  )

  const textMarkdownOverrides = {
    ...getBaseMarkdownOverrides({
      typography: "small1",
      boldTypography: "small3",
      pushSpace: 1,
    }),
    ul: {
      component: ({ children }) => (
        <PushOrg bottomSpace={50}>
          <ListMol
            items={children.map((child: ReactElement) => child.props.children)}
            type="tick"
            size={"small"}
            iconRightSpace={3}
            iconVerticalCenter
            reverseIconMobile
          />
        </PushOrg>
      ),
    },
  }

  const contentDefaultSpace = {
    large: 5,
    medium: 5,
    small: 3,
  }

  const bundleContentJsx = (
    <>
      {imageJsx}

      <FlexItemOrg flexBasis={0} flexGrow={1} flexShrink={1}>
        <PushOrg
          topSpace={contentDefaultSpace}
          bottomSpace={{
            large: 5,
            medium: 5,
            small: 4,
          }}
          rightSpace={contentDefaultSpace}
          leftSpace={contentDefaultSpace}
          hasNoReset
        >
          <BreakpointVisibilityOrg from="medium">
            <FlexContainerOrg
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <FlexItemOrg flexBasis={0} flexGrow={1} flexShrink={1}>
                <Texts
                  {...texts}
                  textMarkdownOverrides={textMarkdownOverrides}
                  hasPromotionLabel={hasPromotion}
                />
              </FlexItemOrg>

              <PushOrg leftSpace={5}>
                <BundleButtonWithPrice {...buttonWithPrice[0]} />
              </PushOrg>
            </FlexContainerOrg>
          </BreakpointVisibilityOrg>

          <BreakpointVisibilityOrg from="small" to="medium">
            <Texts
              {...texts}
              textMarkdownOverrides={textMarkdownOverrides}
              hasPromotionLabel={hasPromotion}
            />

            <PushOrg topSpace={3}>
              <BundleButtonWithPrice {...buttonWithPrice[0]} />
            </PushOrg>
          </BreakpointVisibilityOrg>
        </PushOrg>
      </FlexItemOrg>
    </>
  )

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        {!isEmpty(header) ? (
          <PushOrg bottomSpace={4}>
            <AlignContentOrg
              horizontalAlign={{
                small: "left",
                medium: "center",
                large: "center",
              }}
            >
              <Header {...header} />
            </AlignContentOrg>
          </PushOrg>
        ) : null}

        <AlignContentOrg horizontalAlign="center" horizontalAlignType="flex">
          <WidthOrg widthSpace={110.5}>
            <SurfaceOrg
              borderRadiusSize="xlarge"
              color="backgroundLight"
              shadow="small"
              hasBorder
              borderEmphasis="extraLow"
              borderWidth={1}
              spacingSpace={0}
            >
              <BreakpointVisibilityOrg from="medium">
                <FlexContainerOrg alignItems="center">
                  {bundleContentJsx}
                </FlexContainerOrg>
              </BreakpointVisibilityOrg>

              <BreakpointVisibilityOrg from="small" to="medium">
                {bundleContentJsx}
              </BreakpointVisibilityOrg>
            </SurfaceOrg>
          </WidthOrg>
        </AlignContentOrg>
      </BaseContainer>
    </BaseSection>
  )
}

export default BundleSection
