import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import { getSelectedPeriodInfo } from "@core/period-info"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import CheckboxWithLabelMol from "@onestore/hel/dist/components/molecules/CheckboxWithLabelMol"
import TooltipMol from "@onestore/hel/dist/components/molecules/TooltipMol"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import FlexItemOrg from "@onestore/hel/dist/components/organisms/FlexItemOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import { zIndex } from "@onestore/hel/dist/settings"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import isEmpty from "~/lib/isEmpty"
import type { DrawerBundleData } from "../../ConfigDrawer"
import { getBundlePrice } from "../../helpers/getBundlePrice"

export type BundleProps = {
  activePeriodName: string
  isChecked: boolean
  setIsChecked: (value: boolean) => void
  bundleData: DrawerBundleData
  mainProductPlanData: CloudBluePlan["flatData"]
}

export function Bundle({
  activePeriodName,
  isChecked,
  setIsChecked,
  bundleData,
  mainProductPlanData,
}: BundleProps): ReactElement<BundleProps> | null {
  const {
    bundlePlan,
    hasChangedDefaultPlan,
    bundleProductName,
    bundleTooltip,
    bundleValue,
  } = bundleData

  const { currentPriceType } = usePriceTypeContext()

  const bundlePeriodInfo = !isEmpty(bundlePlan)
    ? getSelectedPeriodInfo(bundlePlan.flatData.periods, undefined)
    : null

  const price = !isEmpty(bundlePlan)
    ? getBundlePrice(
        bundlePlan.flatData,
        mainProductPlanData,
        activePeriodName,
        hasChangedDefaultPlan,
        currentPriceType
      )
    : null

  const handleOnChange = () => setIsChecked(!isChecked)

  const bundleNameText = bundleProductName || bundlePlan?.flatData.name

  const bundleTooltipElement = bundleTooltip ? (
    <PushOrg leftSpace={0.5} hasNoReset>
      <TooltipMol
        iconSize="small"
        iconTouchAreaSpace={0.5}
        zIndex={zIndex.FloatingAside}
      >
        <MarkdownText
          typography={{
            bold: "small3",
            default: "small2",
          }}
        >
          {bundleTooltip}
        </MarkdownText>
      </TooltipMol>
    </PushOrg>
  ) : null

  if (isEmpty(bundleNameText)) {
    return null
  }

  if (isEmpty(bundlePlan) && !isEmpty(bundleValue)) {
    return (
      <FlexContainerOrg
        gapSpace={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <FlexContainerOrg alignItems="center">
          <TextAtm typography="small2">{bundleNameText}</TextAtm>

          {bundleTooltipElement}
        </FlexContainerOrg>

        <FlexItemOrg flexGrow={1} flexShrink={1} flexBasis={0}>
          <TextAtm
            typography={{
              small: "nano3",
              medium: "small4",
              large: "small4",
            }}
            color="success"
            align="right"
          >
            {bundleValue}
          </TextAtm>
        </FlexItemOrg>
      </FlexContainerOrg>
    )
  }

  if (isEmpty(bundlePlan)) {
    return null
  }

  return (
    <FlexContainerOrg
      gapSpace={3}
      justifyContent="space-between"
      alignItems="center"
    >
      <FlexContainerOrg alignItems="center">
        <CheckboxWithLabelMol
          typography="small2"
          text={bundleNameText}
          isChecked={isChecked}
          onChange={handleOnChange}
          gutterSpace={1.5}
        />

        {bundleTooltipElement}
      </FlexContainerOrg>

      <FlexItemOrg flexGrow={1} flexShrink={1} flexBasis={0}>
        <FlexContainerOrg
          wrap="wrap"
          justifyContent="flex-end"
          alignItems="baseline"
        >
          <TextAtm
            typography={{
              small: "nano3",
              medium: "small4",
              large: "small4",
            }}
            color={isChecked ? "success" : undefined}
          >
            + {price}
          </TextAtm>

          <PushOrg leftSpace={0.5} hasNoReset>
            <TextAtm typography="xsmall1">
              {`${_t(`prices.${currentPriceType}`)} ${bundlePeriodInfo?.periodText(false, true)}`}
            </TextAtm>
          </PushOrg>
        </FlexContainerOrg>
      </FlexItemOrg>
    </FlexContainerOrg>
  )
}
