import { getCurrencySign, getPriceObject, getTaxRate } from "@core/pricing"
import type { FormState } from "final-form"
import { flatten, merge, isEmpty } from "lodash"
import type { MarkOptional } from "ts-essentials"
import type {
  BasketLastItem,
  BasketResponseItem,
  BasketResponseResource,
} from "@onestore/api/basket"
import type {
  BasketPatchItem,
  BasketPatchResource,
  JSONPatch,
  Price,
  ProductAlias,
} from "@onestore/api/types"
import {
  addBasketResource,
  itemsBasketPrice,
  removeBasketResource,
  replaceItemParameter,
  replaceItemResources,
} from "@onestore/onestore-store-common/api/operations"
import {
  addItemsToBasket,
  BasketActionSource,
} from "@gatsby-plugin-basket/store/actions"
import { getLastItemsFromBasket } from "@gatsby-plugin-basket/store/utils"
import { IONOS_DC_PARAM_ID } from "@gatsby-plugin-bonus/components/FormBonusBoxParameters/IonosCloudDatacenterLocationSelect"
import {
  CLOUD_BLUE_RESOURCE_QUANTITY_UNLIMITED,
  normalizeMaxQuantity,
} from "@gatsby-plugin-bonus/lib/normalizers"
import { BonusProductActionsType } from "@gatsby-plugin-bonus/store/actions-product"
import { getChosenPeriod } from "@gatsby-plugin-bonus/store/utils"
import type {
  BonusPeriod,
  BonusProduct,
  BonusProductForUpdate,
  BonusProductParameter,
  BonusProductWithRemoteState,
  BonusResource,
  BonusResourceCategory,
  BonusResources,
  BonusResourceWithBasketItem,
} from "@gatsby-plugin-bonus/types"
import { createDefinitionResourceCategory } from "@gatsby-plugin-definitions/store/selectors"
import api from "~/lib/api"
import { updatePeriodPrice } from "~/lib/pricing"
import { patchBasket } from "~/store/actions"
import type { AppState, AppDispatch } from "~/store/reducer"
import { getResource } from "../lib/bonus-definitions"
import type { BonusThunkAction } from "./actions"
import type { ParametersFormData } from "./selectors"
import { getBonusParametersFormValues } from "./selectors"

function getResourceFromProduct(
  resourceCategories: BonusResourceCategory[],
  resourceId: number,
  chosenPeriod: BonusPeriod
): BonusResource | undefined {
  const resource = resourceCategories.map((category) =>
    category.resources.map((item) =>
      getResource(
        item.id,
        chosenPeriod.resource_data.find((data) => data.resource_id === item.id)
      )
    )
  )
  const flattenedArray: BonusResource[] = flatten(resource).filter(
    (item) => item.id === resourceId
  )

  return flattenedArray.shift()
}

function isResourceQuantityValid(
  resource: BonusResource | undefined,
  quantity: number
): boolean {
  if (!resource) {
    return false
  }

  if (
    quantity === 0 ||
    resource.maxQuantity === CLOUD_BLUE_RESOURCE_QUANTITY_UNLIMITED
  ) {
    return true
  }

  if (
    resource.includedValue === quantity &&
    resource.maxQuantity === quantity
  ) {
    return true
  }

  const realQuantity = resource.includedValue + quantity

  return (
    realQuantity <= normalizeMaxQuantity(resource.maxQuantity) &&
    realQuantity >= resource.minQuantity
  )
}

interface Parameter {
  required: boolean
  id: string
}

export type BonusItemProduct = MarkOptional<
  Pick<
    BonusProduct,
    | "planId"
    | "alias"
    | "chosenPeriodId"
    | "resources"
    | "parameters"
    | "hasQuantity"
    | "minQuantity"
    | "initialParamFormData"
  >,
  | "chosenPeriodId"
  | "alias"
  | "parameters"
  | "resources"
  | "hasQuantity"
  | "minQuantity"
  | "initialParamFormData"
>

function getNormalizedKey(
  key: string,
  parameters: Record<string, BonusProductParameter> | undefined
): string | null {
  for (const param in parameters) {
    if (parameters[param].alias === key) {
      return parameters[param].id
    }
    return key
  }

  return null
}

function normalizeAliasedBonusParameters(
  values: Record<string, string> | undefined,
  parameters: Record<string, BonusProductParameter> | undefined
) {
  const result: Record<string, string> = {}
  Object.entries(values ?? {}).forEach(([key, value]) => {
    const normalizedKey = getNormalizedKey(key, parameters)
    if (normalizedKey) {
      result[normalizedKey] = value
    }
  })

  return result
}

/**
 * Dodanie produktu z boxa ax do koszyka.
 */
export async function addBonusItemToBasket(
  dispatch,
  getState,
  bonusItem: BonusItemProduct,
  parent: BonusProduct | undefined | null = undefined,
  promoCode: string | null = null,
  customParameterValue: Record<string, string> = {},
  ignoreHack?: boolean
) {
  const item: BasketPatchItem = {
    plan: bonusItem.planId,
    planPeriod: bonusItem.chosenPeriodId,
    resources: [],
    parameters:
      normalizeAliasedBonusParameters(
        bonusItem.initialParamFormData,
        bonusItem.parameters
      ) || {},
    quantity: bonusItem.minQuantity || 1,
  }

  if (ignoreHack !== undefined) {
    item.ignoreHack = ignoreHack
  }

  const resourcesList: BasketPatchResource[] = []

  if (bonusItem.resources) {
    Object.values(bonusItem.resources)
      .filter((resource: BonusResource) => 0 < resource.basketQuantity)
      .forEach((resource: BonusResource) => {
        resourcesList.push({
          id: resource.id,
          quantity: resource.basketQuantity,
        })
      })
  }

  item.resources = resourcesList

  if (bonusItem.parameters) {
    const parametersFormData = bonusItem.alias
      ? getBonusParametersFormValues(bonusItem.alias)(getState())
      : {}

    const parametersList: ParametersFormData = {}

    if (!isEmpty(parametersFormData)) {
      Object.values(bonusItem.parameters)
        .filter(
          (parameter: Parameter) =>
            parameter.required || parametersFormData[parameter.id]
        )
        .forEach((parameter: Parameter) => {
          parametersList[parameter.id] =
            parametersFormData[parameter.id] || null
        })
      item.parameters = parametersList
    }
  } else {
    item.parameters = customParameterValue
  }

  if (parent) {
    item.parent = parent.basketItemState?.id
  }

  const lastItems: BasketLastItem[] = await dispatch(
    addItemsToBasket(
      [item],
      null,
      BasketActionSource.BONUS,
      () => {},
      promoCode
    )
  )
  const addedItem = lastItems[0]
  const resources = merge({}, bonusItem.resources) // TODO pobyć się merge ...

  if ("resources" in addedItem) {
    addedItem.resources.forEach((resource) => {
      if (resources[resource.alias || resource.resource_id]) {
        resources[resource.alias || resource.resource_id].basketItemId =
          resource.id
      }
    })
  }

  if (
    "hidden_resources" in addedItem &&
    Array.isArray(addedItem.hidden_resources)
  ) {
    addedItem.hidden_resources.forEach((hiddenResource) => {
      const key = hiddenResource.alias || hiddenResource.resource_id

      if (resources[key]) {
        resources[key].basketItemId = hiddenResource.id
      }
    })
  }

  return {
    lastItems,
    addedItem,
    resources,
  }
}

/**
 * Edycja zasobu z boxa ax.
 */
export async function changeBonusItemResource(
  dispatch: AppDispatch<BonusThunkAction>,
  bonusItem: MarkOptional<BonusProductForUpdate, "alias">,
  resource: Pick<BonusResourceWithBasketItem, "includedValue" | "id">,
  quantity: number
): Promise<BonusResources> {
  const currentResources: BonusResources = {}

  Object.entries(bonusItem.resources).forEach(([key, value]) => {
    currentResources[key] = { ...value }
  })

  if (bonusItem.addedToBasket && bonusItem.basketItemState?.id) {
    const currentResource = bonusItem.resources[resource.id]
    let operations

    if (!currentResource.basketItemId) {
      operations = [
        addBasketResource(
          bonusItem.basketItemState.id,
          currentResource.id,
          currentResource.basketQuantity
        ),
      ]
    } else {
      operations = replaceItemResources(bonusItem.basketItemState.id, [
        {
          id: currentResource.basketItemId,
          quantity: currentResource.basketQuantity,
        },
      ])
    }

    const basket = await dispatch(
      patchBasket(operations, [bonusItem.basketItemState?.id])
    )
    let basketItem

    basket.items.forEach((item) => {
      if (basketItem) {
        return
      }

      if (item.id !== bonusItem.basketItemState?.id) {
        basketItem = item.children.filter(
          (child) => child.id === bonusItem.basketItemState?.id
        )[0]
      } else {
        basketItem = item
      }
    })

    if (basketItem) {
      let basketResource = basketItem.resources.filter(
        (resourceItem) => resourceItem.resource_id === resource.id
      )[0]

      if (!basketResource && basketItem.hidden_resources) {
        basketResource = basketItem.hidden_resources.filter(
          (resourceItem) => resourceItem.resource_id === resource.id
        )[0]
      }

      if (basketResource) {
        currentResources[resource.id].basketItemId = basketResource.id
      }
    }
  }

  if (
    0 === quantity - resource.includedValue &&
    currentResources[resource.id].basketItemId
  ) {
    currentResources[resource.id].basketItemId = undefined
  }

  return currentResources
}

export function changeBonusItemPrice(
  bonusItem: BonusProduct,
  items: BasketResponseItem[] | BasketResponseItem[][]
): BonusProduct["periods"] {
  const updatedItems: Record<number, BasketResponseItem> = {}

  items.forEach((item: BasketResponseItem[] | BasketResponseItem) => {
    if (Array.isArray(item)) {
      item.forEach((subItem) => {
        updatedItems[subItem.plan_period_id] = subItem
      })
    } else {
      updatedItems[item.plan_period_id] = item
    }
  })

  return bonusItem.periods.map((period) =>
    updatedItems[period.id]
      ? updatePeriodPrice(
          period,
          updatedItems[period.id],
          bonusItem.minQuantity
        )
      : period
  )
}

/**
 * @TODO: Uwspólnić dodawanie/zmianę okresu i zasobów dla product boxa, upsell boxa i bundle boxa.
 * Logika jest w gruncie rzeczy taka sama a róźnią sie tylko wysyłanaymi eventami.
 */

interface BonusItemPeriodResource {
  id: number
  quantity: number
}
interface BonusItemPeriod {
  plan: number
  planPeriod: number
  quantity: number
  resources: BonusItemPeriodResource[]
  parameters?: Record<string, string | number | boolean | null>
}

export function createBonusItemPeriod(
  bonusItem: BonusProduct,
  period: { id: number }
) {
  const paramValues = Object.values(bonusItem.parameters)

  let requestParameters: Record<string, string> = {}

  if (paramValues.length > 0) {
    // ONESTORE-651 temporary vps parameter fix
    const paramNames = paramValues.map((param) => param.id)

    if (paramNames.includes(IONOS_DC_PARAM_ID)) {
      requestParameters = {
        [IONOS_DC_PARAM_ID]:
          "initialParamFormData" in bonusItem
            ? bonusItem.initialParamFormData?.[IONOS_DC_PARAM_ID] || "de/txl"
            : "de/txl",
      }
    }
  }

  const item: BonusItemPeriod = {
    plan: bonusItem.planId,
    quantity: 1,
    planPeriod: period.id,
    resources: [],
    parameters: requestParameters,
  }

  if (bonusItem.resources) {
    Object.values(bonusItem.resources)
      .filter((resource) => 0 < resource.basketQuantity)
      .forEach((resource) => {
        item.resources.push({
          id: resource.id,
          quantity: resource.basketQuantity,
        })
      })
  }

  return item
}

export function changeBonusItemInBasket(
  bonusItem: BonusProductWithRemoteState
) {
  return async (
    dispatch: AppDispatch<BonusThunkAction>,
    getState: { (): AppState }
  ) => {
    let chosenPeriodId: null | number = null
    const operations: JSONPatch[] = []

    if (bonusItem.chosenPeriodId !== bonusItem.basketItemState.plan_period_id) {
      operations.push({
        op: "replace",
        path: `/items/${bonusItem.basketItemState.id}`,
        value: { planPeriod: bonusItem.chosenPeriodId },
      })
      chosenPeriodId = bonusItem.chosenPeriodId
    }

    const changedResources = {}

    Object.keys(bonusItem.resources).forEach((key) => {
      const resource = bonusItem.resources[key]

      if (resource.basketItemId) {
        if (resource.basketQuantity > 0) {
          operations.push({
            op: "replace",
            path: `/items/${bonusItem.basketItemState.id}/resources/${resource.basketItemId}`,
            value: { quantity: resource.basketQuantity },
          })
        } else {
          changedResources[resource.id] = {
            basketItemId: null,
          }

          operations.push(
            removeBasketResource(
              bonusItem.basketItemState.id,
              resource.basketItemId
            )
          )
        }
      } else if (resource.basketQuantity > 0) {
        operations.push(
          addBasketResource(
            bonusItem.basketItemState.id,
            resource.id,
            resource.basketQuantity
          )
        )
      }
    })

    const parametersData = getBonusParametersFormValues(bonusItem.alias)(
      getState()
    )

    if (parametersData) {
      Object.entries(parametersData).forEach(([id, value]) => {
        operations.push(
          replaceItemParameter(bonusItem.basketItemState.id, id, value)
        )
      })
    }

    const basket = await dispatch(patchBasket(operations))
    const lastItems = getLastItemsFromBasket(basket, true)

    lastItems.forEach((item) => {
      if ((item as BasketResponseResource).resource_id) {
        changedResources[(item as BasketResponseResource).resource_id] = {
          basketItemId: (item as BasketResponseResource).id,
        }
      }
    })

    return {
      lastItems,
      chosenPeriodId,
      changedResources,
    }
  }
}

export interface ProductBasketPriceWithResourcesResult {
  regularPrice: Price
  promoPrice: Price
  item: BasketResponseItem | null
}

export async function getProductBasketWithResources(
  product: BonusProduct,
  planPeriod: BonusPeriod["id"],
  resources: BonusBasketResource[] = [],
  promoCode: string | null = null
): Promise<ProductBasketPriceWithResourcesResult> {
  const basketProduct: {
    plan: number
    planPeriod: number
    quantity: number
    resources: Pick<BonusBasketResource, "id" | "quantity">[]
    parameters?: Record<string, string | number>
  } = {
    plan: product.planId,
    planPeriod,
    resources: [],
    quantity: product.minQuantity,
  }

  const paramValues = Object.values(product.parameters)

  if (paramValues.length > 0) {
    const paramNames = paramValues.map((param) => param.id)

    if (paramNames.includes(IONOS_DC_PARAM_ID)) {
      basketProduct.parameters = {
        [IONOS_DC_PARAM_ID]:
          product.initialParamFormData?.[IONOS_DC_PARAM_ID] || "de/txl",
      }
    }
  }
  const currentResources: Record<number, BonusBasketResource> = Object.assign(
    {},
    product.resources
  )

  resources.forEach((item) => {
    currentResources[item.id] = item
  })

  if (currentResources) {
    Object.values(currentResources)
      .filter((resource) => 0 < resource.basketQuantity)
      .forEach((resource) => {
        basketProduct.resources.push({
          id: resource.id,
          quantity: resource.basketQuantity,
        })
      })
  }
  const basket = await api.calculateBasket({
    items: itemsBasketPrice([basketProduct]),
    promo_code: promoCode,
  })
  const regularPrice = getPriceObject(
    basket.total_net_price + basket.savings,
    1,
    getTaxRate()
  )
  const promoPrice = {
    netto: basket.total_net_price,
    gross: basket.total_net_price + basket.total_vat_value,
    taxRate: 0,
    currency: getCurrencySign(),
  }

  return {
    regularPrice,
    promoPrice,
    item: basket.items[0] ?? null,
  }
}

export interface BonusBasketResource {
  id: number
  quantity: number
  basketQuantity: number
}

export interface ResourceGroupChangeResult {
  totalPrice: ProductBasketPriceWithResourcesResult
  resources: BonusBasketResource[]
}
export function changeAdvancedBonusItemResourcesGroup(
  bonusItem: BonusProduct,
  resourcesList: number[],
  chosenResourceId: number,
  basketQuantity: number,
  promoCode: string | null = null
) {
  return async (
    dispatch: AppDispatch<BonusThunkAction>,
    getState: { (): AppState }
  ): Promise<ResourceGroupChangeResult | null> => {
    const resourceCategories = createDefinitionResourceCategory(
      bonusItem.alias
    )(getState())

    if (resourceCategories === null) {
      return null
    }

    const chosenPeriod = getChosenPeriod(bonusItem)

    if (!chosenPeriod) {
      throw Error(`Missing chosenPeriod in bonusItem periods array`)
    }

    if (chosenResourceId) {
      const resource = getResourceFromProduct(
        resourceCategories,
        chosenResourceId,
        chosenPeriod
      )

      if (!isResourceQuantityValid(resource, basketQuantity)) {
        throw new Error("resource quantity is not valid")
      }
    }

    const resources: BonusBasketResource[] = resourcesList.map((resourceId) => {
      const itemQuantity = chosenResourceId === resourceId ? basketQuantity : 0
      const resource = getResourceFromProduct(
        resourceCategories,
        resourceId,
        chosenPeriod
      )

      if (!resource) {
        throw new Error("Null resource found")
      }

      return {
        id: resourceId,
        quantity: itemQuantity + resource.includedValue,
        basketQuantity: itemQuantity,
      }
    })

    const totalPrice = await getProductBasketWithResources(
      bonusItem,
      bonusItem.chosenPeriodId,
      resources,
      promoCode
    )

    return {
      totalPrice,
      resources,
    }
  }
}

export interface UpdateBonusParametersFormStateAction {
  type: BonusProductActionsType.BONUS_PARAMETERS_FORM_UPDATE
  values: Record<string, string>
  valid: boolean
  alias: ProductAlias
}

export const updateParametersFormState = (
  alias: ProductAlias,
  state: FormState<any>
): UpdateBonusParametersFormStateAction => ({
  type: BonusProductActionsType.BONUS_PARAMETERS_FORM_UPDATE,
  values: state.values,
  valid: state.valid,
  alias,
})
