import { typeMatches } from "@onestore-graphql"
import { isEmpty } from "lodash"
import type { TypographyBreakpoint } from "@onestore/hel/dist/components/atoms/TextAtm/TextAtm.props"
import type { Typography } from "@onestore/hel/dist/contexts/TypographyContext"
import type { TypographyElement } from "@gatsby-plugin-generic-page/fragments/typography"
import type { TypographyBreakpointElement } from "@gatsby-plugin-generic-page/fragments/typographyBreakpoint"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"

const useTitleTypography = (
  cmsTitleTypography:
    | TypographyElement
    | TypographyBreakpointElement
    | undefined
): Typography | TypographyBreakpoint | undefined => {
  if (isEmpty(cmsTitleTypography) || isEmpty(cmsTitleTypography.__typename)) {
    return undefined
  }

  if (
    typeMatches(
      cmsTitleTypography.__typename,
      SquidexComponentType.TYPOGRAPHY_BREAKPOINT_ELEMENT
    )
  ) {
    return {
      small: (cmsTitleTypography as TypographyBreakpointElement)?.small
        ?.typography,
      medium: (cmsTitleTypography as TypographyBreakpointElement)?.medium
        ?.typography,
      large: (cmsTitleTypography as TypographyBreakpointElement)?.large
        ?.typography,
      huge: (cmsTitleTypography as TypographyBreakpointElement)?.huge
        ?.typography,
    } as TypographyBreakpoint
  }

  if (
    typeMatches(
      cmsTitleTypography.__typename,
      SquidexComponentType.TYPOGRAPHY_ELEMENT
    )
  ) {
    return (cmsTitleTypography as TypographyElement).typography as Typography
  }

  return undefined
}

export default useTitleTypography
