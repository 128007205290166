import { useStaticQuery, graphql } from "gatsby"
import type { Gatsby } from "~/types/Gatsby"

interface Query {
  site: {
    siteMetadata: Gatsby.SiteMetadata
  }
}

export default function useSiteMetadataQuery(): Gatsby.SiteMetadata {
  const { site } = useStaticQuery<Query>(graphql`
    {
      site {
        siteMetadata {
          siteUrl
          siteVersion
          siteBuild
          ogSiteName
          twitterSiteName
          locale
          fullLocale
          cookiesTermsUrl
          cookiesSiteUrl
          brand
          facebookIframeUrl
          robots
          facebookDomainVerification
          googleSiteVerification
          tileColor
          themeColor
          wikiUrl
          helpContactUrl
          panelUrl
          priceListPath
          transferPagePath
          payments {
            path
          }
          defaultMetadata {
            flatData {
              metaTitle
              metaDescription
              metaKeywords
              ogTitle
              ogDescription
              twitterTitle
              twitterDescription
            }
          }
          headerData {
            logo {
              width
              height
            }
          }
          hasTogglePriceType
        }
      }
    }
  `)

  return site.siteMetadata
}
