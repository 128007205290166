import type { ReactNode } from "react"
import React from "react"
import _t from "@core/i18n"
import type { PeriodInfo } from "@core/period-info"
import { formatPrice } from "@core/pricing"
import { PriceFormat, PriceType } from "@core/types"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import isEmpty from "~/lib/isEmpty"

export const getSavingPriceTooltip = (
  periodInfo: PeriodInfo,
  basePeriodInfo: PeriodInfo,
  renewalMultiplier: number,
  savingPriceFormatted: string,
  baseRenewalPriceMultiplier: number,
  baseCalculatedPrice: number,
  isNettoPriceType: boolean
): ReactNode | null => {
  const priceTypeText = _t(
    `prices.${isNettoPriceType ? PriceType.NETTO : PriceType.GROSS}`
  )

  // Current periodInfo values
  const periodType = periodInfo.getPeriodType()
  const periodLength = periodInfo.getPeriodLength()

  const periodText = _t(`periodName.short.${periodType}`, {
    smart_count: periodLength,
  })

  const periodNumericText = _t(`periodName.basket.${periodType}`, {
    smart_count: periodLength,
  })

  const periodPriceFormatted = periodInfo.getFormatted(
    isNettoPriceType
      ? PriceFormat.REGISTER_NETTO_NO_PERIOD_WITH_PRICE_TYPE
      : PriceFormat.REGISTER_GROSS_NO_PERIOD_WITH_PRICE_TYPE
  )
  //

  // Base periodInfo values
  const basePeriodType = basePeriodInfo.getPeriodType()
  const basePeriodLength = basePeriodInfo.getPeriodLength()

  const baseRegisterPeriodNumericText = _t(
    `periodName.basket.${basePeriodType}`,
    {
      smart_count: basePeriodLength,
    }
  )

  const baseRenewalPeriodTextLong = _t(`periodName.long.${basePeriodType}`, {
    smart_count: basePeriodLength,
  })

  const baseRegisterPrice = basePeriodInfo.getRegisterPriceValue()
  const baseRegisterPriceValue = isNettoPriceType
    ? baseRegisterPrice.netto
    : baseRegisterPrice.gross

  const baseRegisterPriceFormatted = basePeriodInfo.getFormatted(
    isNettoPriceType
      ? PriceFormat.REGISTER_NETTO_NO_PERIOD_WITH_PRICE_TYPE
      : PriceFormat.REGISTER_GROSS_NO_PERIOD_WITH_PRICE_TYPE
  )

  const baseRenewalPrice = basePeriodInfo.getRenewalPriceValue()

  if (isEmpty(baseRenewalPrice)) {
    return null
  }

  const baseRenewalPriceValue = isNettoPriceType
    ? baseRenewalPrice.netto
    : baseRenewalPrice.gross
  const baseRenewalPriceFormatted = formatPrice(baseRenewalPriceValue)

  const baseRenewalPriceMultiplierFormatted = formatPrice(
    baseRenewalPriceMultiplier
  )
  const baseCalculatedPriceFormatted = formatPrice(baseCalculatedPrice)
  //

  let calculationsText = _t(
    "configDrawer.savingPriceTooltip.calculationsWithRegisterPrice",
    {
      base_renewal_multiplier:
        renewalMultiplier > 1 ? `${renewalMultiplier} x ` : "",
      base_register_price: baseRegisterPriceFormatted,
      base_renewal_price_multiplier: `${baseRenewalPriceMultiplierFormatted} ${priceTypeText}`,
      period_numeric_text: baseRegisterPeriodNumericText,
      base_calculated_price: `${baseCalculatedPriceFormatted} ${priceTypeText}`,
    }
  )

  if (baseRegisterPriceValue === baseRenewalPriceValue) {
    const baseRenewalPeriodTextShort = _t(
      `periodName.short.${basePeriodType}`,
      {
        smart_count: renewalMultiplier + 1,
      }
    )

    calculationsText = _t("configDrawer.savingPriceTooltip.calculations", {
      base_renewal_period_text: baseRenewalPeriodTextLong,
      base_renewal_period_multiplier: baseRenewalPeriodTextShort,
      base_renewal_price: `${baseRenewalPriceFormatted} ${priceTypeText}`,
      period_numeric_text: periodNumericText,
      base_calculated_price: `${baseCalculatedPriceFormatted} ${priceTypeText}`,
    })
  }
  //

  return (
    <TextAtm typography="small2">
      <TextAtm isBold>{_t("configDrawer.savingPriceTooltip.title")}</TextAtm>
      {`
        ${_t("configDrawer.savingPriceTooltip.contractInfo", {
          period_text: periodText,
          period_price: periodPriceFormatted,
        })}
        ${calculationsText}
      `}
      <TextAtm isBold>
        {_t("configDrawer.savingPriceTooltip.savingSummary", {
          period_text: periodText,
          saving_price: `${savingPriceFormatted} ${priceTypeText}`,
        })}
      </TextAtm>
    </TextAtm>
  )
}
