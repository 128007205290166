import { useState } from "react"
import { get } from "lodash"
import { SORT_STATUS } from "@onestore/hel/dist/components/molecules/HorizontalTableMol/HorizontalTableMol.types"
import type { HorizontalProductBox } from "@gatsby-plugin-generic-page/fragments/horizontalProductBox"
import type { TableHeader } from "@gatsby-plugin-generic-page/fragments/tableHeader"
import isEmpty from "~/lib/isEmpty"

type activeSortedData = {
  sortedColumnName: string
  sortedStatus: SORT_STATUS
}

type HorizontalTableSortHookResult = {
  activeSortedColumnName: string | null
  activeSortedStatus: SORT_STATUS | null
  sortedProducts: HorizontalProductBox[] | null
  columnSortClik: (columnName: string) => void
}

function useHorizontalTableSort(
  header: TableHeader,
  products: HorizontalProductBox[]
): HorizontalTableSortHookResult {
  const [activeSortedData, setActiveSortedData] =
    useState<activeSortedData | null>(null)

  const columnSortClik = (columnName: string): void => {
    if (
      isEmpty(activeSortedData) ||
      activeSortedData.sortedColumnName !== columnName
    ) {
      setActiveSortedData({
        sortedColumnName: columnName,
        sortedStatus: SORT_STATUS.DESC,
      })

      return
    }

    if (activeSortedData.sortedStatus === SORT_STATUS.DESC) {
      setActiveSortedData({
        sortedColumnName: columnName,
        sortedStatus: SORT_STATUS.ASC,
      })

      return
    }

    if (activeSortedData.sortedStatus === SORT_STATUS.ASC) {
      setActiveSortedData(null)

      return
    }
  }

  const getProductCellSortValue = (product: HorizontalProductBox): number => {
    const sortedColumnIndex = header.columns.findIndex(
      (column) => column.label === activeSortedData?.sortedColumnName
    )

    const cellSortValue = get(
      product,
      `flatData.cells[${sortedColumnIndex}].sortWeight`,
      0
    )

    return cellSortValue
  }

  const getSortedProducts = () => {
    const productsToSort = [...products]

    productsToSort.sort((firstProduct, secondProduct) => {
      const activeSortStatus = !isEmpty(activeSortedData)
        ? activeSortedData.sortedStatus
        : null

      if (!activeSortStatus) {
        return 0
      }

      const firstProductCellValue = getProductCellSortValue(firstProduct)

      const secondProductCellValue = getProductCellSortValue(secondProduct)

      if (activeSortStatus === SORT_STATUS.DESC) {
        return secondProductCellValue - firstProductCellValue
      }

      if (activeSortStatus === SORT_STATUS.ASC) {
        return firstProductCellValue - secondProductCellValue
      }

      return 0
    })

    return productsToSort
  }

  const activeSortedColumnName = !isEmpty(activeSortedData)
    ? activeSortedData.sortedColumnName
    : null

  const activeSortedStatus = !isEmpty(activeSortedData)
    ? activeSortedData.sortedStatus
    : null

  const sortedProducts = !isEmpty(activeSortedData) ? getSortedProducts() : null

  return {
    activeSortedColumnName,
    activeSortedStatus,
    sortedProducts,
    columnSortClik,
  }
}

export default useHorizontalTableSort
