import type { ReactElement } from "react"
import React from "react"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import type { SaleConfiguration } from "~/fragments/saleConfigurations"
import isEmpty from "~/lib/isEmpty"
import type { DrawerBundleData } from "../../ConfigDrawer"
import { ProductSummary } from "../ProductSummary"
import { ProductWithBundleSummary } from "../ProductWithBundleSummary"

type SummaryProps = {
  activePeriodName: string
  saleConfiguration: SaleConfiguration
  mainProductPlan: CloudBluePlan
  isBundleChecked: boolean
  bundleData?: DrawerBundleData
}

const Summary = ({
  activePeriodName,
  saleConfiguration,
  mainProductPlan,
  isBundleChecked,
  bundleData,
}: SummaryProps): ReactElement<SummaryProps> | null => {
  const commonSummaryProps = {
    activePeriodName,
    saleConfiguration,
  }

  if (!isBundleChecked || isEmpty(bundleData)) {
    return <ProductSummary {...commonSummaryProps} plan={mainProductPlan} />
  }

  const { bundlePlan, bundleValue, hasChangedDefaultPlan, bundleTitleSuffix } =
    bundleData

  if (!isEmpty(bundleValue)) {
    return <ProductSummary {...commonSummaryProps} plan={mainProductPlan} />
  }

  if (!isEmpty(bundlePlan) && hasChangedDefaultPlan) {
    return <ProductSummary {...commonSummaryProps} plan={bundlePlan} />
  }

  if (!isEmpty(bundlePlan) && !hasChangedDefaultPlan) {
    return (
      <ProductWithBundleSummary
        {...commonSummaryProps}
        mainProductPlan={mainProductPlan}
        bundleTitleSuffix={bundleTitleSuffix}
        bundlePlan={bundlePlan}
      />
    )
  }

  return null
}

export default Summary
