/* eslint-disable no-param-reassign */
import type { Draft } from "immer"
import { produce } from "immer"
import type { OfficeState } from "@onestore/onestore-store-common"
import { HTTP_STATUS } from "@onestore/onestore-store-common/http"
import {
  MicrosoftSpecialQualifications,
  MicrosoftTenantType,
} from "~/store/office/constants"
import type { OfficeAction } from "./actions"
import { OfficeActionTypes } from "./actions"

export const initialState: OfficeState = {
  domain_request: HTTP_STATUS.NONE,
  domain_name: "",
  errors: [],
  account_request: HTTP_STATUS.NONE,
  can_continue: undefined,
  has_error: false,
  tenant: MicrosoftTenantType.NEW,
  special_qualifications: MicrosoftSpecialQualifications.NONE,
}

export default function reducer(
  state: OfficeState = initialState,
  action: OfficeAction
): OfficeState {
  return produce<OfficeState, OfficeState>(
    state,
    (nextState: Draft<OfficeState>) => {
      switch (action.type) {
        case OfficeActionTypes.CHANGE_TENANT_TYPE:
          nextState.tenant = action.tenant
          nextState.can_continue = undefined
          nextState.domain_request = HTTP_STATUS.NONE
          nextState.has_error = false
          nextState.domain_name = ""
          nextState.special_qualifications = MicrosoftSpecialQualifications.NONE

          return

        case OfficeActionTypes.CHANGE_SPECIAL_QUALIFICATIONS:
          nextState.special_qualifications = action.value

          return
        case OfficeActionTypes.INPUT_VALUE_HAS_CHANGED:
          nextState.can_continue = undefined

          return
        case OfficeActionTypes.CHECK_DOMAIN_PENDING:
          nextState.domain_request = HTTP_STATUS.CONTINUE

          return

        case OfficeActionTypes.CHECK_DOMAIN_FAILURE:
          nextState.domain_request = action.response_status_code
          nextState.can_continue = false
          nextState.has_error = false
          nextState.domain_name = ""

          return

        case OfficeActionTypes.CHECK_DOMAIN_SUCCESS:
          nextState.domain_request = HTTP_STATUS.OK
          nextState.can_continue = action.can_continue
          nextState.has_error = action.has_error
          nextState.domain_name = action.domain

          return

        case OfficeActionTypes.CHECK_DOMAIN_RESET:
          nextState.domain_request = HTTP_STATUS.NONE
          nextState.can_continue = undefined
          nextState.has_error = false
          nextState.domain_name = ""
          nextState.special_qualifications = MicrosoftSpecialQualifications.NONE

          return
      }
    }
  )
}
