import type { ReactElement } from "react"
import React, { useContext } from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import FlexItemOrg from "@onestore/hel/dist/components/organisms/FlexItemOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import SwitchPeriodAndTogglePriceType from "@gatsby-plugin-generic-page/components/SwitchPeriodAndTogglePriceType"
import getPeriodsByPeriodVariants from "@gatsby-plugin-generic-page/helpers/getPeriodsByPeriodVariants"
import type { ParametersTableSectionFlatData } from "@gatsby-plugin-generic-page/types/sectionType"
import ContentSwitcher from "~/components/ContentSwitcher"
import ProductParameters from "~/components/ProductParameters"
import SwitchContentContext from "~/context/SwitchContentContext"
import isEmpty from "~/lib/isEmpty"
import type { PeriodName } from "~/types/squidex"

export default function ParametersTableSection({
  title,
  comparableProducts,
  tableHeaderTitle,
  hasHiddenFooter,
  hasTogglePriceType,
  text,
  isTextCentered,
  sectionSettings,
}: ParametersTableSectionFlatData): ReactElement<ParametersTableSectionFlatData> {
  const context = useContext(SwitchContentContext)

  const { periodVariants } = context || {}

  const periods = getPeriodsByPeriodVariants(periodVariants)

  const hasPeriodSwitcher = !isEmpty(periods)

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <BreakpointVisibilityOrg from="small" to="medium">
          {title ? (
            <TextAtm
              htmlTag="h2"
              typography="xhuge"
              pushSpace={hasPeriodSwitcher ? 2 : 1}
            >
              {title}
            </TextAtm>
          ) : null}

          {hasTogglePriceType || hasPeriodSwitcher ? (
            <SwitchPeriodAndTogglePriceType
              hasTogglePriceType={hasTogglePriceType}
              hasNoMarginBottom
            />
          ) : null}
        </BreakpointVisibilityOrg>

        <BreakpointVisibilityOrg from="medium">
          <FlexContainerOrg alignItems="center" wrap="wrap" gapSpace={1}>
            <FlexItemOrg flexGrow={1}>
              {title ? (
                <TextAtm htmlTag="h2" typography="xhuge">
                  {title}
                </TextAtm>
              ) : null}
            </FlexItemOrg>

            {hasTogglePriceType || hasPeriodSwitcher ? (
              <SwitchPeriodAndTogglePriceType
                hasTogglePriceType={hasTogglePriceType}
                hasNoMarginBottom
              />
            ) : null}
          </FlexContainerOrg>
        </BreakpointVisibilityOrg>

        {context && hasPeriodSwitcher ? (
          <ContentSwitcher items={periods} selected={context.currentKey || ""}>
            {(selected) => {
              return (
                <PushOrg topSpace={4}>
                  <ProductParameters
                    hasHiddenFooter={hasHiddenFooter}
                    tableHeaderTitle={tableHeaderTitle}
                    products={comparableProducts}
                    selectedPeriod={selected.period as PeriodName}
                  />
                </PushOrg>
              )
            }}
          </ContentSwitcher>
        ) : (
          <PushOrg topSpace={4}>
            <ProductParameters
              hasHiddenFooter={hasHiddenFooter}
              tableHeaderTitle={tableHeaderTitle}
              products={comparableProducts}
            />
          </PushOrg>
        )}

        {text ? (
          <PushOrg topSpace={4}>
            <AlignContentOrg
              horizontalAlign={isTextCentered ? "center" : "left"}
            >
              <MarkdownText>{text}</MarkdownText>
            </AlignContentOrg>
          </PushOrg>
        ) : null}
      </BaseContainer>
    </BaseSection>
  )
}
