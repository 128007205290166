import {
  calculateDiscountPercent,
  getTaxRate,
  normalizePrice,
} from "@core/pricing"
import type { PriceType } from "@core/types"
import type { ProductPriceResponse } from "@onestore/api/types"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import type { DetailedStaticPriceProps } from "@gatsby-plugin-generic-page/components/DetailedStaticPrice"
import { checkBundlePromotion } from "@gatsby-plugin-generic-page/sections/BundleSection"
import isEmpty from "~/lib/isEmpty"
import { getSelectedPeriod } from "~/lib/plan"

const normalizeBundlePrice = (priceValue: number) =>
  parseFloat(normalizePrice(priceValue))

const getBundlePriceData = (
  calculatedPriceData: ProductPriceResponse,
  primaryPlanData: CloudBluePlan,
  bundlePlanData: CloudBluePlan,
  period: string | null,
  priceType: PriceType
): Omit<DetailedStaticPriceProps, "variant"> | null => {
  const priceInfo = !isEmpty(primaryPlanData)
    ? primaryPlanData.flatData.priceInfo
    : undefined

  if (isEmpty(calculatedPriceData)) {
    return null
  }

  const hasPromotion = checkBundlePromotion(
    period,
    primaryPlanData,
    bundlePlanData
  )

  const regularPrice = calculatedPriceData.regularPrice
  const promoPrice = calculatedPriceData.promoPrice
  const discount = calculatedPriceData.discount

  const registerPrice = !isEmpty(promoPrice) ? promoPrice : regularPrice

  const lowestPrice = calculatedPriceData.lowestPurchasePrice
  const lowestPricePercent = !isEmpty(lowestPrice)
    ? calculateDiscountPercent(
        {
          ...registerPrice,
          taxRate: registerPrice.taxRate || getTaxRate(),
        },
        {
          ...lowestPrice,
          taxRate: lowestPrice.taxRate || getTaxRate(),
        },
        priceType
      )
    : undefined

  const hasRenewalPrice = !isEmpty(priceInfo)
    ? priceInfo.hasRenewalPriceText
    : undefined
  const renewalPrice = calculatedPriceData.renewalPrice

  const hasMonthlyPrice = !isEmpty(priceInfo)
    ? priceInfo.hasMonthlyPriceText
    : undefined

  const primaryPlanPeriod = getSelectedPeriod(primaryPlanData.flatData, period)

  const hidePeriodText = isEmpty(period)

  return {
    staticPrices: [
      {
        __typename: "GraphCmsSquidex_StaticPrice",
        id: "",
        flatData: {
          name: "",
          registerNetto: normalizeBundlePrice(registerPrice.netto),
          registerGross: normalizeBundlePrice(registerPrice.gross),
          promoNetto: !isEmpty(promoPrice)
            ? normalizeBundlePrice(promoPrice.netto)
            : undefined,
          promoGross: !isEmpty(promoPrice)
            ? normalizeBundlePrice(promoPrice.gross)
            : undefined,
          regularPricePercent: discount,
          lowestPriceNetto: !isEmpty(lowestPrice)
            ? normalizeBundlePrice(lowestPrice.netto)
            : undefined,
          lowestPriceGross: !isEmpty(lowestPrice)
            ? normalizeBundlePrice(lowestPrice.gross)
            : undefined,
          lowestPricePercent: lowestPricePercent,
          renewalNetto:
            !isEmpty(renewalPrice) && hasRenewalPrice
              ? normalizeBundlePrice(renewalPrice.netto)
              : undefined,
          renewalGross:
            !isEmpty(renewalPrice) && hasRenewalPrice
              ? normalizeBundlePrice(renewalPrice.gross)
              : undefined,
          hasMonthlyPrice,
          period: primaryPlanPeriod.period_name,
        },
      },
    ],
    period: primaryPlanPeriod.period_name,
    bundleData: {
      hasPromotion,
      priceInfo,
      hidePeriodText,
    },
  }
}

export default getBundlePriceData
