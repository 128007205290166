import { PeriodInfo } from "@core/period-info"
import get from "lodash/get"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import type { SaleConfiguration } from "@gatsby-plugin-definitions/lib/sale-configuration"
import type { OptionMapping } from "@gatsby-plugin-marketplace/fragments/optionMapping"
import { getSelectedPeriod } from "~/lib/plan"
import type { ProductPriceNested } from "~/lib/pricing"
import type { PeriodName } from "../lib/api/types"

export function getSelectedPlanSettings(
  optionsMapping: OptionMapping[],
  options: SaleConfiguration.Options
): OptionMapping | null {
  if (Object.keys(optionsMapping || {}).length === 0) {
    return null
  }

  const optionsKeys: Array<string> = []

  Object.keys(options || {})
    .sort()
    .forEach((key: string) => {
      optionsKeys.push(`${key}[${options[key]}]`)
    })

  const keyPath: string = optionsKeys.join(";")

  const optionsMappingValues = {}

  Object.values(optionsMapping).forEach((option) => {
    if (option.value === keyPath) {
      optionsMappingValues[option.value] = option
    }
  })

  if (optionsMappingValues[keyPath]) {
    return optionsMappingValues[keyPath]
  }

  return null
}

type PrepareComponentValuesResult = {
  priceValues: ProductPriceNested
  period?: PeriodName
  periodText: string
  selectedPeriodInfo: PeriodInfo
  selectedPriceInfo: CloudBluePlan["flatData"]["priceInfo"]
}

export function prepareComponentValues(
  optionsMapping: OptionMapping[],
  options: SaleConfiguration.Options,
  defaultPlan: CloudBluePlan
): PrepareComponentValuesResult | null {
  try {
    const selectedPlanSettings = getSelectedPlanSettings(
      optionsMapping,
      options
    )

    const productData: CloudBluePlan["flatData"] = get(
      selectedPlanSettings,
      "cloudBluePlan[0].flatData",
      defaultPlan.flatData
    )

    const selectedPeriodName = selectedPlanSettings?.period?.period

    const selectedPeriod: CloudBluePeriod = getSelectedPeriod(
      productData,
      selectedPeriodName
    )
    const selectedPeriodInfo = new PeriodInfo(selectedPeriod)

    const usePrices = selectedPeriodInfo.usePrices()
    const hasPromotion = selectedPeriodInfo?.hasPromotion() ?? false

    const priceValues: ProductPriceNested = {
      price: selectedPeriodInfo.getRegisterPriceValue(),
      period: selectedPeriodInfo.getPeriodName(),
      regularPrice: selectedPeriodInfo.getRegularPriceValue(false),
      renewPrice: selectedPeriodInfo.getRenewalPriceValue(),
      priceValue: usePrices.priceValue,
      lowestPrice: usePrices.lowestPrice,
      lowestPricePercent: usePrices.lowestPricePercent,
      hasPromotion: hasPromotion,
    }

    return {
      priceValues,
      period: selectedPeriodName,
      periodText: selectedPeriodInfo.periodText(),
      selectedPeriodInfo,
      selectedPriceInfo: productData.priceInfo,
    }
  } catch (error) {
    return null
  }
}
