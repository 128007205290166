import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import WidthOrg from "@onestore/hel/dist/components/organisms/WidthOrg"
import ButtonElement from "./ButtonElement"

type Props = {
  hasPromotion: boolean
  priceValue: string
  lowestPrice: string
  lowestPricePercent: string
  currency: string
  priceTypeText: string
  buttonHref: string
}

export default function PriceCell({
  hasPromotion,
  priceValue,
  lowestPrice,
  lowestPricePercent,
  currency,
  priceTypeText,
  buttonHref,
}: Props): ReactElement<Props> {
  const priceElement = (
    <PushOrg
      bottomSpace={{
        small: hasPromotion ? 0.5 : 2,
        medium: hasPromotion ? 0.5 : 2,
        large: hasPromotion ? 0.5 : 1,
      }}
    >
      <TextAtm
        color={hasPromotion ? "primary" : undefined}
        typography="big3"
        dataTestId="product-box-price-price"
      >
        {priceValue}{" "}
        <TextAtm typography="xsmall1">{` ${currency} ${priceTypeText}`}</TextAtm>
      </TextAtm>
    </PushOrg>
  )

  const lowestPriceElement = (
    <PushOrg
      bottomSpace={{
        small: 2,
        medium: 2,
        large: 1,
      }}
      hasForcedFullWidth
      hasNoReset
    >
      <TextAtm typography="nano1">
        <TextAtm pushSpace={{ right: 0.5 }}>
          {_t("prices.lowerPriceText")} {lowestPrice}
        </TextAtm>

        <LabelAtm size="small" variant="notifyLight" htmlTag="span">
          {lowestPricePercent}
        </LabelAtm>
      </TextAtm>
    </PushOrg>
  )

  const priceCellElement = (
    <>
      <BreakpointVisibilityOrg from="small" to="large" hasForcedFullWidth>
        {priceElement}

        {hasPromotion ? (
          <AlignContentOrg horizontalAlign="center">
            <WidthOrg widthSpace={24.5}>{lowestPriceElement}</WidthOrg>
          </AlignContentOrg>
        ) : null}

        <ButtonElement
          buttonHref={buttonHref}
          hasPromotion={hasPromotion}
          isExpanded
        />
      </BreakpointVisibilityOrg>

      <BreakpointVisibilityOrg from="large">
        <FlexContainerOrg direction="column" alignItems="flex-end">
          {priceElement}

          {hasPromotion ? (
            <AlignContentOrg horizontalAlign="right">
              {lowestPriceElement}
            </AlignContentOrg>
          ) : null}

          <ButtonElement
            buttonHref={buttonHref}
            hasPromotion={hasPromotion}
            isWider
          />
        </FlexContainerOrg>
      </BreakpointVisibilityOrg>
    </>
  )

  return priceCellElement
}
