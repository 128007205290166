import type { ReactElement } from "react"
import React from "react"
import FullScreenContainerOrg from "@onestore/hel/dist/components/organisms/FullScreenContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import {
  headerBottomBarHeight,
  headerHeight,
} from "@onestore/hel/dist/settings"
import Media from "@gatsby-plugin-generic-page/components/Media"
import type { FullScreenContainerSectionFlatData } from "@gatsby-plugin-generic-page/fragments/fullScreenContainerSection"
import { GenericPageSectionsRenderer } from "@gatsby-plugin-generic-page/GenericPage"
import getBackground from "@gatsby-plugin-generic-page/helpers/getBackground"
import getLogoContainerWidth from "@gatsby-plugin-generic-page/sections/FullScreenContainerSection/getLogoContainerWidth"
import { HasFullScreenContainerContextProvider } from "~/context/HasFullScreenContainerContext"
import usePriceConfigurationQuery from "~/hooks/usePriceConfigurationQuery"

const logoMarginUnit = { small: 6, medium: 12, large: 12 }

export default function FullScreenContainerSection({
  logo,
  color,
  sections,
}: FullScreenContainerSectionFlatData): ReactElement<FullScreenContainerSectionFlatData> {
  const { showPriceTypeSwitcher } = usePriceConfigurationQuery()

  const { backgroundThemeColor, themeAccentColor } = getBackground(color)

  const fullScreenOffset = showPriceTypeSwitcher
    ? headerHeight + headerBottomBarHeight
    : headerHeight

  return (
    <HasFullScreenContainerContextProvider value={true}>
      <FullScreenContainerOrg
        offset={fullScreenOffset}
        logo={
          <PushOrg
            bottomUnit={logoMarginUnit}
            leftUnit={logoMarginUnit}
            hasNoReset
          >
            <Media media={logo} />
          </PushOrg>
        }
        width={getLogoContainerWidth(logo)}
        color={backgroundThemeColor || themeAccentColor || "white"}
      >
        <GenericPageSectionsRenderer sections={sections} pageId={""} />
      </FullScreenContainerOrg>
    </HasFullScreenContainerContextProvider>
  )
}
