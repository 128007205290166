import type { ReactElement } from "react"
import React from "react"
import { typeMatches } from "@onestore-graphql"
import TileLinkMol from "@onestore/hel/dist/components/molecules/TileLinkMol"
import type { ButtonElement } from "@gatsby-plugin-generic-page/fragments/buttonElement"
import type { LinkElement } from "@gatsby-plugin-generic-page/fragments/linkElement"
import type { TileLink as TileLinkType } from "@gatsby-plugin-generic-page/fragments/tileLink"
import getBackground from "@gatsby-plugin-generic-page/helpers/getBackground"
import getLinkProps from "@gatsby-plugin-generic-page/helpers/getLinkProps"
import { sendContentShowEvent } from "~/lib/analytics"
import { SquidexComponentType } from "../../types/componentType"
import MarkdownText from "../Markdown/Text"
import Media from "../Media"

export interface Props extends TileLinkType {
  isImageShow?: boolean
}

export default function TileLink({
  title,
  text,
  link,
  responsiveImages,
  color,
  isImageShow,
}: Props): ReactElement<Props> {
  let linkElement, buttonElement

  const { color: buttonColor, variant } = link as ButtonElement

  if (typeMatches(link.__typename, SquidexComponentType.LINK)) {
    linkElement = getLinkProps(link as LinkElement)
  }

  if (typeMatches(link.__typename, SquidexComponentType.BUTTON)) {
    buttonElement = getLinkProps(link.link as LinkElement)
  }

  const { customColor } = getBackground(color)

  return (
    <TileLinkMol
      title={title}
      text={
        <MarkdownText
          typographyBreakpoint={{
            default: {
              small: "medium2_s",
              medium: "medium2_s",
              large: "small2",
              huge: "medium2_s",
            },
            bold: {
              small: "medium4_s",
              medium: "medium4_s",
              large: "small4",
              huge: "medium4_s",
            },
          }}
        >
          {text}
        </MarkdownText>
      }
      link={
        linkElement
          ? {
              ...linkElement,
            }
          : undefined
      }
      button={
        buttonElement
          ? {
              to: buttonElement.to,
              href: buttonElement.href,
              onClick: buttonElement.onClick,
              title: buttonElement.title,
              text: buttonElement.text,
              color: buttonColor,
              variant,
            }
          : undefined
      }
      image={responsiveImages ? <Media media={responsiveImages} /> : undefined}
      isImageShow={isImageShow}
      customColor={customColor}
      openOnClick={sendContentShowEvent}
    />
  )
}
