import type { PeriodName, PeriodType, PeriodValue } from "./types"

export function sanitizePhrase(phrase: string): string {
  return encodeURIComponent(phrase.replace(/\//g, ""))
}

const periodTypes = ["h", "d", "m", "y"]

export enum PeriodTypeEnum {
  HOUR,
  DAY,
  MONTH,
  YEAR,
}

interface PeriodData {
  periodName: PeriodName
  periodType: PeriodType
  periodValue: PeriodValue
}

export function parsePeriod(periodName: string): PeriodData {
  const periodValue = parseInt(periodName, 10)

  const periodType = periodTypes.indexOf(periodName.slice(-1)) as PeriodType

  if (periodType < 0) {
    throw new Error(`PeriodParse error ${periodName}`)
  }

  return {
    periodName,
    periodType,
    periodValue,
  }
}
