/**
 * @module DomainSearch
 * @category State
 */
import { flatten, uniq, uniqBy } from "lodash"
import type {
  BasketParamListItem,
  BasketParamResponseItem,
} from "@onestore/api/basket"
import type { DomainCheck } from "@onestore/api/domainSearch"
import type { DomainBundlePricing } from "@onestore/api/types"
import {
  isResponseFailed,
  isResponseNone,
  isResponsePending,
  isResponseSuccessful,
} from "@onestore/onestore-store-common/http"
import {
  filterItemsByDomainType,
  getFlatBasketViewItems,
} from "@gatsby-plugin-basket/store/selectors"
import type { BasketViewItem } from "@gatsby-plugin-basket/types"
import type { ChangeDomainPeriodModal } from "@gatsby-plugin-domain-search/types"
import { getPopularPoolId } from "~/lib/config"
import type { AppState } from "~/store/reducer"
import { getUserDataReady } from "~/store/selectors"
import type { DomainSearchState } from "./reducer"

/* Transfer domeny wyświetlamy jeżeli znaleziona domena nie jest dostępna, oraz wyszukiwana była pełna nazwa (np. home.pl).*/
export const isTransferHidden = (domainSearch: DomainSearchState): boolean => {
  return (
    !isResponseSuccessful(domainSearch.currentCheck.request_state) ||
    !!domainSearch.currentCheck.available ||
    domainSearch.currentCheck.domain === null
  )
}

export const getDomainParametersItemsList = (
  state: AppState
): BasketViewItem[] => {
  const params = Object.values(state.checkout.params || {}).map(
    (param) => param.item_id
  )

  const domains = filterItemsByDomainType(getFlatBasketViewItems(state))

  return Object.values(domains).filter((item: BasketViewItem) =>
    params.includes(item.itemId)
  )
}

export const getDomainParametersList = (
  state: AppState
): BasketParamListItem[] => {
  return Object.values(state.checkout.params ?? {}).filter(
    (item) => item.type === "domain"
  )
}

export const getUniqueDomainParameters = (
  state: AppState
): BasketParamResponseItem[] => {
  const paramValues: BasketParamResponseItem[][] = getDomainParametersList(
    state
  ).map((item: BasketParamListItem): BasketParamResponseItem[] =>
    Object.values(item.params)
  )

  return uniqBy(
    flatten(paramValues),
    (item: BasketParamResponseItem) => item.paramId
  )
}

export const getBasketDomains = (state: AppState): DomainCheck.FQDN[] =>
  state.basket.domains

// Pobieramy z wlacsciwosci domains ponieważ  na wyszukiwarce zawiera juz liste dodanych wczesniej domen
export const getBasketDomainExtensions = (
  state: AppState
): DomainCheck.Extension[] => {
  const extensions = getBasketDomains(state).map((item) =>
    item.split(".").splice(1).join(".")
  )

  return uniq<DomainCheck.Extension>(extensions)
}

export const changePeriodModal = (state: AppState): ChangeDomainPeriodModal =>
  state.domainSearch.modal.changePeriod

interface DomainSearchResultsForDataLayer {
  suggestedDomains: DomainCheck.Result[]
  poolCheckDomains: DomainCheck.Result[]
  mainResultDone: boolean
  domain: DomainCheck.Result | null
  suggestedDone: boolean
  poolDone: boolean
}

export const getDomainSearchResultsForDataLayer = ({
  domainSearch,
}: AppState): DomainSearchResultsForDataLayer => ({
  domain: domainSearch.currentCheck.domain,
  suggestedDomains: domainSearch.suggestedCheck.domains,
  poolCheckDomains: domainSearch.poolCheck.domains,
  mainResultDone: isResponseSuccessful(domainSearch.currentCheck.request_state),
  suggestedDone: isResponseSuccessful(
    domainSearch.suggestedCheck.request_state
  ),
  poolDone:
    domainSearch.poolCheck.pool === getPopularPoolId() &&
    isResponseSuccessful(domainSearch.suggestedCheck.request_state),
})

export const isDomainSearchReady = (state: AppState) =>
  isResponsePending(state.domainSearch.currentCheck.request_state) ||
  getUserDataReady(state)

export const isDomainSearchLoading = (state: AppState) =>
  isResponsePending(state.domainSearch.currentCheck.request_state)

export const isDomainSearchPristine = (state: AppState) =>
  isResponseNone(state.domainSearch.currentCheck.request_state)

export const isDomainSearchFailed = (state: AppState) =>
  isResponseFailed(state.domainSearch.currentCheck.request_state)

export const isDomainSearchValid = (state: AppState) =>
  state.domainSearch.validation.valid

export const isDomainSearchError = (state: AppState) =>
  !isDomainSearchPristine(state) &&
  isDomainSearchFailed(state) &&
  isDomainSearchValid(state)

export const getDomainSearchPageType = (state: AppState) =>
  state.domainSearch.pageType

export const getDomainSearchPhrase = (state: AppState) =>
  state.domainSearch.currentCheck.phrase

export const getProductSearchPhrase = (state: AppState) =>
  state.domainSearch.rawPhrase

export const getDomainSearchFqdn = (state: AppState) =>
  state.domainSearch.currentCheck.domain?.fqdn

export type DomainSearchBundleState = {
  pricing: null | DomainBundlePricing
  domains: DomainCheck.Result[]
  id: string | number
  buttonId: string
  isLoading: boolean
  isPartialBundleInBasket: boolean
  isWholeBundleInBasket: boolean
}

export const getDomainSearchBundle = (
  state: AppState
): DomainSearchBundleState[] =>
  state.domainSearch.currentCheck.bundles.map((bundle) => ({
    ...bundle,
    buttonId: `domains-bundle-${bundle.id}`,
    isLoading: isResponsePending(
      state.basket.buttons[`domains-bundle-${bundle.id}`]
    ),
    isPartialBundleInBasket: bundle.domains.some((domain) =>
      state.basket.domains.includes(domain.fqdn)
    ),
    isWholeBundleInBasket: bundle.domains.every((domain) =>
      state.basket.domains.includes(domain.fqdn)
    ),
  }))

export const isDomainSearchBundleVisible = (state: AppState): boolean =>
  getDomainSearchBundle(state)
    .map(
      (bundle) =>
        bundle.isWholeBundleInBasket || !bundle.isPartialBundleInBasket
    )
    .some((value) => value)

export const getDomainSearchAlternativeDomain = (state: AppState) =>
  state.domainSearch.currentCheck.alternative

export const getUnavailableExtensions = (state: AppState) =>
  state.domainSearch.currentCheck.unavailable_extensions

export const getDomainSearchDomain = (state: AppState) =>
  state.domainSearch.currentCheck.domain

export const getDomainSearchPremium = (state: AppState) =>
  state.domainSearch.currentCheck.premium

export const getDomainSearchSpecialDomain = (state: AppState) =>
  state.domainSearch.currentCheck.special

export const isCurrentCheckDomainAvailable = (state: AppState) =>
  state.domainSearch.currentCheck.available || false

export const isPhraseIdenticalToFqdn = (state: AppState) =>
  state.domainSearch.currentCheck.phrase.toLowerCase() ===
  state.domainSearch.currentCheck.domain?.fqdn.toLowerCase()

export const getPoolDomains = ({ domainSearch }: AppState) =>
  domainSearch.poolCheck.domains

export const getActivePool = (state: AppState) =>
  state.domainSearch.poolCheck?.pool

export const isPoolLoading = ({ domainSearch }: AppState) =>
  isResponsePending(domainSearch.poolCheck.request_state)

export const getIsOpenAIPromptFooterModal = (state: AppState): boolean =>
  state.domainSearch.modal.aIPromptFooter.isOpen
