import type { ReactElement } from "react"
import React from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import { useConfigsHookContext } from "@gatsby-plugin-generic-page/sections/TableHorizontalBoxesSection"
import isEmpty from "~/lib/isEmpty"
import CounterInput from "./CounterInput"

type CounterResourceElementProps = {
  resourceCategoryId: string
}

export default function CounterResourceElement({
  resourceCategoryId,
}: CounterResourceElementProps): ReactElement<CounterResourceElementProps> {
  const {
    getResourceCategory,
    getResourceLimits,
    handleResourcesChange,
    handleResourcesRemove,
    getConfigModalResource,
    getResourcePriceText,
  } = useConfigsHookContext() ?? {}

  const resourceCategory = getResourceCategory
    ? getResourceCategory(resourceCategoryId)
    : null

  if (isEmpty(resourceCategory) || isEmpty(resourceCategory.resources)) {
    return <></>
  }

  const handleCounterChange = (
    resourceId: string,
    resourceValue: number,
    isIncrement?: boolean,
    inputValue?: number
  ): void => {
    let resourceUpdateValue = isIncrement
      ? resourceValue + 1
      : resourceValue - 1

    if (typeof inputValue === "number") {
      resourceUpdateValue = inputValue || 0
    }

    if (handleResourcesRemove && resourceUpdateValue === 0) {
      handleResourcesRemove(resourceCategoryId, true)

      return
    }

    if (handleResourcesChange) {
      handleResourcesChange(
        [
          {
            resourceCategoryId,
            resourceId,
            resourceValue: resourceUpdateValue,
          },
        ],
        true
      )
    }
  }

  return (
    <div>
      {resourceCategory.resources.map((resource) => {
        const { name, id } = resource

        const getConfigModalResourceData = getConfigModalResource
          ? getConfigModalResource(id.toString())
          : null

        const resourceValue = getConfigModalResourceData?.value || 0

        const limitsValues = getResourceLimits
          ? getResourceLimits(id as string)
          : null

        const resourcePriceText = getResourcePriceText
          ? getResourcePriceText(id.toString(), resourceCategoryId)
          : null

        return limitsValues ? (
          <PushOrg topSpace={3}>
            <PushOrg bottomSpace={1.5}>
              <TextAtm typography="small3">{name}</TextAtm>
            </PushOrg>

            <FlexContainerOrg
              justifyContent="space-between"
              alignItems="center"
            >
              <CounterInput
                key={name}
                value={resourceValue}
                minValue={limitsValues.min}
                maxValue={limitsValues.max}
                onChange={(value) =>
                  handleCounterChange(
                    id.toString(),
                    resourceValue,
                    false,
                    value
                  )
                }
                handleIncrementButton={() =>
                  handleCounterChange(id.toString(), resourceValue, true)
                }
                handleDecrementButton={() =>
                  handleCounterChange(id.toString(), resourceValue)
                }
                onClear={
                  handleResourcesRemove && resourceValue > 0
                    ? () => handleResourcesRemove(resourceCategoryId, true)
                    : undefined
                }
              />

              {resourcePriceText ? (
                <TextAtm
                  typography="small3"
                  color={resourceValue > 0 ? "success" : undefined}
                >
                  {resourcePriceText}
                </TextAtm>
              ) : null}
            </FlexContainerOrg>
          </PushOrg>
        ) : null
      })}
    </div>
  )
}
