import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import { PeriodInfo } from "@core/period-info"
import {
  formatted,
  getLowestPriceDiscountPercent,
  getTaxRate,
} from "@core/pricing"
import { PriceFormat, PriceType } from "@core/types"
import { typeMatches } from "@onestore-graphql"
import get from "lodash/get"
import LoaderAtm from "@onestore/hel/dist/components/atoms/LoaderAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import HorizontalProductBoxMol from "@onestore/hel/dist/components/molecules/HorizontalProductBoxMol"
import { PriceVariantType } from "@onestore/hel/dist/components/molecules/PriceMol/priceMol.types"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import DetailedPrice from "@gatsby-plugin-generic-page/components/DetailedPrice"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import type {
  Cell,
  HorizontalProductBox,
} from "@gatsby-plugin-generic-page/fragments/horizontalProductBox"
import { CellType } from "@gatsby-plugin-generic-page/fragments/horizontalProductBox"
import type { TableHeader } from "@gatsby-plugin-generic-page/fragments/tableHeader"
import { getUpsellData } from "@gatsby-plugin-generic-page/helpers/getUpsellData"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import isEmpty from "~/lib/isEmpty"
import url from "~/lib/url"
import { useConfigsHookContext } from "../.."
import ButtonElement from "./cells/ButtonElement"
import NameCell from "./cells/NameCell"
import PriceCell from "./cells/PriceCell"
import ResourceCategoryCell from "./cells/ResourceCategoryCell"
import TextCell from "./cells/TextCell"

export type Props = {
  header: TableHeader
  productData: HorizontalProductBox
}

export default function TableRow({
  header,
  productData,
}: Props): ReactElement<Props> {
  const { currentPriceType } = usePriceTypeContext()

  //TODO ONESTORE-785 - fix prices with resources
  const showDetailedPrices = false
  ///
  const {
    configsValues,
    isCalculateProductsLoading,
    getHasProductActiveResourceCategories,
    getCalculateProductPrices,
    getUrlConfigsValues,
  } = useConfigsHookContext() ?? {}

  const { saleConfiguration, cells, labelElement } = productData.flatData

  if (
    !getUrlConfigsValues ||
    !getHasProductActiveResourceCategories ||
    !getHasProductActiveResourceCategories(productData.id)
  ) {
    return <></>
  }

  const urlConfigsValues = getUrlConfigsValues(productData.id)

  const columnsCount = header.columns.length

  if (cells.length !== columnsCount || isEmpty(saleConfiguration)) {
    return <></>
  }

  const plan = get(saleConfiguration[0], "flatData.defaultCloudBluePlan[0]")

  if (isEmpty(plan)) {
    return <></>
  }

  const calculatePrices = getCalculateProductPrices
    ? getCalculateProductPrices(plan.flatData.id)
    : null

  const productPlanPeriods = plan.flatData.periods

  const selectedPeriodObject = configsValues
    ? productPlanPeriods.find(
        (period) => period.period_name === configsValues.period
      )
    : null

  const periodInfo = selectedPeriodObject
    ? new PeriodInfo(selectedPeriodObject)
    : undefined

  if (!periodInfo) {
    return <></>
  }

  const priceInfo = plan.flatData.priceInfo || undefined

  const currency = periodInfo.getFormatted(PriceFormat.CURRENCY)

  const { priceValue, lowestPrice, lowestPricePercent, renewPrice } =
    periodInfo.usePrices(currentPriceType, true)

  const hasPromotion = periodInfo.hasPromotion() ?? false

  const periodName = periodInfo.period.period_name

  const priceTypeText =
    currentPriceType === PriceType.NETTO
      ? _t("prices.netto")
      : _t("prices.gross")

  const renewSuffix = `${_t(
    `periodName.renewPrice.${periodInfo.getPeriodType()}`,
    {
      smart_count: periodInfo.getPeriodLength(),
    }
  )}`

  const updateRenewalPriceValue =
    !isEmpty(calculatePrices) && !isEmpty(calculatePrices.renewalPrice)
      ? formatted(calculatePrices.renewalPrice, currentPriceType).full
      : renewPrice

  const renewalPriceElement = isCalculateProductsLoading ? null : (
    <TextAtm typography="nano1" emphasis="medium">
      {`${_t("prices.renewal")}: ${updateRenewalPriceValue} ${priceTypeText} ${renewSuffix}`}
    </TextAtm>
  )

  const buttonHref = url.generateBonusUrl({
    p: {
      alias: plan.flatData.alias,
      parameters: !isEmpty(urlConfigsValues.parameters)
        ? urlConfigsValues.parameters
        : undefined,
      resources: !isEmpty(urlConfigsValues.resources)
        ? urlConfigsValues.resources
        : undefined,
    },
    ax: getUpsellData(saleConfiguration[0].flatData.upsell),
    period: periodName,
    priceType: currentPriceType,
  })

  const cellTextPlaceholder = (
    <MarkdownText
      typography={{
        default: "small2",
        bold: "small3",
      }}
      pushSpace={0}
    >
      -
    </MarkdownText>
  )

  const cellsElements = cells.map((cell: Cell, index: number) => {
    let cellObject = {
      title: header.columns[index].label,
      text: cellTextPlaceholder,
      widthPercent: header.columns[index].widthPercent,
    }

    if (typeMatches(cell.type, CellType.NAME) && !isEmpty(plan)) {
      cellObject.text = (
        <NameCell
          cbName={plan.flatData.name}
          value={cell.value}
          labelElement={labelElement}
          hasPromotion={hasPromotion}
        />
      )
    } else if (typeMatches(cell.type, CellType.PRICE)) {
      const hasCalulatePrices = !isEmpty(calculatePrices)

      const updatePiceValue = hasCalulatePrices
        ? formatted(calculatePrices.regularPrice, currentPriceType).full
        : priceValue

      const updatePromoValue =
        hasCalulatePrices && !isEmpty(calculatePrices.promoPrice)
          ? formatted(calculatePrices.promoPrice, currentPriceType).full
          : null

      const updateLowestPrice =
        hasCalulatePrices && !isEmpty(calculatePrices.lowestPurchasePrice)
          ? formatted(calculatePrices.lowestPurchasePrice, currentPriceType)
              .full
          : lowestPrice

      const updateLowestPricePercent =
        hasCalulatePrices && !isEmpty(calculatePrices.lowestPurchasePrice)
          ? getLowestPriceDiscountPercent(
              calculatePrices.promoPrice
                ? {
                    ...calculatePrices.promoPrice,
                    taxRate: calculatePrices.promoPrice.taxRate || getTaxRate(),
                  }
                : {
                    ...calculatePrices.regularPrice,
                    taxRate:
                      calculatePrices.regularPrice.taxRate || getTaxRate(),
                  },
              {
                ...calculatePrices.lowestPurchasePrice,
                taxRate:
                  calculatePrices.lowestPurchasePrice.taxRate || getTaxRate(),
              }
            )
          : lowestPricePercent

      const priceItem = showDetailedPrices ? (
        <>
          <PushOrg bottomSpace={2} hasForcedFullWidth>
            <DetailedPrice
              periodInfo={periodInfo}
              priceInfo={priceInfo}
              variant={{
                small: {
                  type: PriceVariantType.SMALL,
                  align: "center",
                },
                medium: {
                  type: PriceVariantType.SMALL,
                  align: "center",
                },
                large: {
                  type: PriceVariantType.SMALL,
                  align: "right",
                },
              }}
            />
          </PushOrg>

          <AlignContentOrg
            horizontalAlign={{
              small: "center",
              medium: "center",
              large: "right",
            }}
          >
            <ButtonElement
              buttonHref={buttonHref}
              hasPromotion={hasPromotion}
              isWider
            />
          </AlignContentOrg>
        </>
      ) : (
        <PriceCell
          hasPromotion={hasPromotion}
          priceValue={updatePromoValue || updatePiceValue}
          lowestPrice={updateLowestPrice}
          lowestPricePercent={updateLowestPricePercent}
          buttonHref={buttonHref}
          currency={currency}
          priceTypeText={priceTypeText}
        />
      )

      cellObject.text = isCalculateProductsLoading ? (
        <AlignContentOrg horizontalAlign="right">
          <LoaderAtm size="small" />
        </AlignContentOrg>
      ) : (
        priceItem
      )
    } else if (typeMatches(cell.type, CellType.TEXT) && cell.value) {
      cellObject.text = <TextCell value={cell.value} icon={cell.icon} />
    } else if (
      typeMatches(cell.type, CellType.RESOURCE_CATEGORY) &&
      !isEmpty(cell.resourceCategory)
    ) {
      const resourceCategoryId = cell.resourceCategory[0].flatData.remoteId

      cellObject.text = (
        <ResourceCategoryCell
          productId={productData.id}
          resourceCategoryId={resourceCategoryId.toString()}
        />
      )
    }

    return cellObject
  })

  return (
    <HorizontalProductBoxMol
      items={cellsElements}
      promotionText={productData.flatData.promoText}
      renewalPrice={showDetailedPrices ? undefined : renewalPriceElement}
    />
  )
}
