import { useStaticQuery, graphql } from "gatsby"

interface Query {
  GraphCmsSquidex: {
    findDomainSearchAiPageSingleton: {
      flatData: {
        enabled: boolean
        url: string
      }
    }
  }
}

type Hook = {
  enabled: boolean
  url: string
}
export default function useDomainSearchAiSettingsQuery(): Hook {
  const {
    GraphCmsSquidex: { findDomainSearchAiPageSingleton },
  } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        findDomainSearchAiPageSingleton {
          flatData {
            enabled
            url
          }
        }
      }
    }
  `)

  return {
    enabled: findDomainSearchAiPageSingleton.flatData.enabled ?? false,
    url: findDomainSearchAiPageSingleton.flatData.url,
  }
}
