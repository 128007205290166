import type { SyntheticEvent, ReactElement } from "react"
import { useState, useEffect } from "react"
import React, { useCallback } from "react"
import _t from "@core/i18n"
import CookieMol from "@onestore/hel/dist/components/molecules/CookieMol"
import useCookieStorage from "~/hooks/useCookieStorage"
import useSiteMetadataQuery from "~/hooks/useSiteMetadataQuery"
import { getBrand } from "~/lib/config"
import isExternalUrl from "~/lib/isExternalUrl"
import isServer from "~/lib/isServer"

export default function Cookie(): ReactElement | null {
  const metadata = useSiteMetadataQuery()
  const cookieTermsLink = metadata.cookiesSiteUrl
  const { value, setValue } = useCookieStorage("cookieInfo", 365)
  const [visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    if (!isServer()) {
      setVisible(true)
    }
  }, [])

  const handleClick = useCallback(() => {
    setValue(`${Math.floor(Date.now() / 1000)}`)
  }, [setValue])

  if (getBrand() !== "strefa") {
    // Wyłączone na AZ COOKIES-47
    // Wyłączone na home COOKIES-51
    // Wyłączone na homecloud COOKIES-134
    // Wyłączone na ionos COOKIES-

    return null
  }

  let href, to

  if (isExternalUrl(cookieTermsLink)) {
    href = cookieTermsLink
  }

  if (!isExternalUrl(cookieTermsLink)) {
    to = cookieTermsLink
  }

  return visible && value === undefined ? (
    <CookieMol
      title={`${_t("cookie.title")} `}
      text={`${_t("cookie.text")} `}
      link={{
        text: _t("cookie.viewDetails"),
        title: _t("cookie.viewDetails"),
        href: href,
        to: to,
        onClick: () => {
          handleClick()
        },
      }}
      close={{
        text: _t("cookie.close"),
        title: _t("cookie.close"),
        onClick: (event: SyntheticEvent<HTMLButtonElement>) => {
          event.preventDefault()
          handleClick()
        },
      }}
    />
  ) : null
}
