import type { ReactElement } from "react"
import React from "react"
import { useState } from "react"
import _t from "@core/i18n"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import ToggleSwitchAtm from "@onestore/hel/dist/components/atoms/ToggleSwitchAtm"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import WrapOrg from "@onestore/hel/dist/components/organisms/WrapOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import Header from "@gatsby-plugin-generic-page/components/Header"
import type { PackagesComparisonSectionFlatData } from "@gatsby-plugin-generic-page/fragments/packagesComparisonSection"
import ProductParameters from "~/components/ProductParameters"
import TogglePriceType from "~/components/TogglePriceType"
import isEmpty from "~/lib/isEmpty"

export default function PackagesComparisonSection({
  header,
  tableHeaderTitle,
  hasToggleDifferences,
  hasTogglePriceType,
  additionalText,
  isTextCentered,
  comparableProductsPackages,
  sectionSettings,
}: PackagesComparisonSectionFlatData): ReactElement<PackagesComparisonSectionFlatData> {
  const [showOnlyDifferences, setShowOnlyDifferences] = useState(
    hasToggleDifferences || false
  )

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        {!isEmpty(header) && (
          <PushOrg bottomSpace={4}>
            <AlignContentOrg
              horizontalAlign={{
                small: "left",
                medium: "center",
                large: "center",
              }}
            >
              <Header {...header} />
            </AlignContentOrg>
          </PushOrg>
        )}

        <PushOrg
          topSpace={{ small: 3, medium: 4, large: 12.5 }}
          bottomSpace={4.5}
        >
          <FlexContainerOrg
            alignItems="baseline"
            justifyContent="space-between"
            wrap="wrap"
            gapSpace={2}
          >
            <WrapOrg gutterSpace={2}>
              <TextAtm typography="medium2_s">
                {_t("toggleLabel.differences")}
              </TextAtm>

              <ToggleSwitchAtm
                id="comparison-diff-toggle"
                isChecked={showOnlyDifferences}
                onClick={() => setShowOnlyDifferences((value) => !value)}
              />
            </WrapOrg>

            {hasTogglePriceType ? <TogglePriceType /> : null}
          </FlexContainerOrg>
        </PushOrg>

        <ProductParameters
          products={[]}
          packages={comparableProductsPackages}
          tableHeaderTitle={tableHeaderTitle}
          showOnlyDifferences={showOnlyDifferences}
          hasPromoPrice
          hasRenewalPrice
          hasHiddenGroupLabel
          hasHiddenFooter
        />

        {!isEmpty(additionalText) && (
          <PushOrg topSpace={4}>
            <TextAtm
              align={isTextCentered ? "center" : undefined}
              typography="small1"
            >
              {additionalText}
            </TextAtm>
          </PushOrg>
        )}
      </BaseContainer>
    </BaseSection>
  )
}
