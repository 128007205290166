import { typeMatches } from "@onestore-graphql"
import { isEmpty } from "lodash"
import useBoldTypography from "@onestore/hel/dist/hooks/useBoldTypography"
import type {
  TextTypographyBreakpoint,
  TextTypographySingle,
} from "@gatsby-plugin-generic-page/fragments/header"
import type { TypographyElement } from "@gatsby-plugin-generic-page/fragments/typography"
import type { TypographyBreakpointElement } from "@gatsby-plugin-generic-page/fragments/typographyBreakpoint"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"

const useTextTypography = (
  cmsTextTypography: TypographyElement | TypographyBreakpointElement | undefined
): TextTypographyBreakpoint | TextTypographySingle | undefined => {
  const singleBoldTypography = useBoldTypography(
    (cmsTextTypography as TypographyElement)?.typography
  )

  const smallBoldTypography = useBoldTypography(
    (cmsTextTypography as TypographyBreakpointElement)?.small?.typography
  )

  const mediumBoldTypography = useBoldTypography(
    (cmsTextTypography as TypographyBreakpointElement)?.medium?.typography
  )

  const largeBoldTypography = useBoldTypography(
    (cmsTextTypography as TypographyBreakpointElement)?.large?.typography
  )

  const hugeBoldTypography = useBoldTypography(
    (cmsTextTypography as TypographyBreakpointElement)?.huge?.typography
  )

  if (isEmpty(cmsTextTypography) || isEmpty(cmsTextTypography.__typename)) {
    return undefined
  }

  if (
    typeMatches(
      cmsTextTypography.__typename,
      SquidexComponentType.TYPOGRAPHY_BREAKPOINT_ELEMENT
    )
  ) {
    return {
      default: {
        small: (cmsTextTypography as TypographyBreakpointElement).small
          .typography,
        medium: (cmsTextTypography as TypographyBreakpointElement).medium
          ?.typography,
        large: (cmsTextTypography as TypographyBreakpointElement).large
          ?.typography,
        huge: (cmsTextTypography as TypographyBreakpointElement).huge
          ?.typography,
      },
      bold: {
        small: smallBoldTypography,
        medium: mediumBoldTypography,
        large: largeBoldTypography,
        huge: hugeBoldTypography,
      },
    } as TextTypographyBreakpoint
  }

  if (
    typeMatches(
      cmsTextTypography.__typename,
      SquidexComponentType.TYPOGRAPHY_ELEMENT
    )
  ) {
    return {
      default: (cmsTextTypography as TypographyElement).typography,
      bold: singleBoldTypography,
    } as TextTypographySingle
  }

  return undefined
}

export default useTextTypography
