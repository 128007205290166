import React from "react"
import { FieldValidator } from "final-form"
import type { Parameter } from "@gatsby-plugin-bonus/types"
import { FormItemLayout } from "@gatsby-plugin-generic-page/components/Formily/FormItemLayout"
import Select from "~/components/FinalForm/Select"
import useIonosCloudConfigurationQuery from "~/hooks/useIonosCloudConfigurationQuery"

interface IonosCloudDatacenterLocationSelectProps {
  param: Parameter
  externalError: string | undefined
  onValidate: FieldValidator<string>
  initialValue?: string
}

export const IONOS_DC_PARAM_ID = "ionos_dc_location"
export function IonosCloudDatacenterLocationSelect({
  param,
  onValidate,
  externalError,
  initialValue,
}: IonosCloudDatacenterLocationSelectProps) {
  const locations = useIonosCloudConfigurationQuery()

  const initialSelectValue =
    initialValue ||
    locations.find((location) => location.isDefault)?.id ||
    locations[0].id

  return (
    <FormItemLayout
      label={param.name}
      description={param.description}
      required={param.required}
    >
      <Select
        initialValue={initialSelectValue}
        options={locations.map((item) => ({
          isSelected: item.id === initialSelectValue,
          value: item.id,
          label: item.label,
        }))}
        externalError={externalError}
        key={param.id}
        name={param.id}
        data-label={param.name}
        onValidate={onValidate}
      />
    </FormItemLayout>
  )
}
