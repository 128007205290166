import { getSelectedPeriodInfo } from "@core/period-info"
import { formatPriceObject } from "@core/pricing"
import type { PriceType } from "@core/types"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import { calculateBundlePrice } from "~/hooks/useProductBoxSlim"
import isEmpty from "~/lib/isEmpty"

export const getBundlePrice = (
  bundlePlanData: CloudBluePlan["flatData"],
  mainProductPlanData: CloudBluePlan["flatData"],
  periodName: string,
  hasChangedDefaultPlan: boolean,
  priceType: PriceType
): string | null => {
  const bundlePeriodInfo = getSelectedPeriodInfo(
    bundlePlanData.periods,
    undefined
  )
  let bundlePrice = bundlePeriodInfo
    ? bundlePeriodInfo.getRegisterPriceValue()
    : null

  const mainProductPeriodInfo = getSelectedPeriodInfo(
    mainProductPlanData.periods,
    periodName
  )
  const mainProductPrice = mainProductPeriodInfo
    ? mainProductPeriodInfo.getRegisterPriceValue()
    : null

  if (
    isEmpty(bundlePeriodInfo) ||
    isEmpty(bundlePrice) ||
    isEmpty(mainProductPeriodInfo) ||
    isEmpty(mainProductPrice)
  ) {
    return null
  }

  if (hasChangedDefaultPlan) {
    const calculatedBundlePrice = calculateBundlePrice(
      bundlePrice,
      mainProductPrice
    )

    const bundlePriceFormatted = !isEmpty(calculatedBundlePrice)
      ? formatPriceObject(calculatedBundlePrice, priceType)
      : null

    return bundlePriceFormatted
  }

  const bundleSpecialPrice = bundlePeriodInfo.getPromoPriceForPlanPrice(
    mainProductPlanData.id
  )

  if (bundleSpecialPrice) {
    bundlePrice = bundleSpecialPrice
  }

  return formatPriceObject(bundlePrice, priceType)
}
