import { useStaticQuery, graphql } from "gatsby"

type Query = {
  GraphCmsSquidex: {
    findFormsConfigurationSingleton: {
      flatData: {
        enabled: boolean | null
        hubspotPortalId: string | null | undefined
        hubspotRegion: string | null | undefined
      }
    }
  }
}

export default function useFormsConfigurationQuery() {
  const { GraphCmsSquidex } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        findFormsConfigurationSingleton {
          flatData {
            enabled
            hubspotPortalId
            hubspotRegion
          }
        }
      }
    }
  `)

  if (GraphCmsSquidex.findFormsConfigurationSingleton) {
    const { flatData } = GraphCmsSquidex.findFormsConfigurationSingleton

    return {
      enabled: flatData.enabled ?? false,
      hubspotPortalId: flatData.hubspotPortalId ?? "",
      hubspotRegion: flatData.hubspotRegion ?? "",
    }
  }

  return {
    enabled: false,
    hubspotPortalId: "",
    hubspotRegion: "",
  }
}
