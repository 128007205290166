import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import { PeriodInfo } from "@core/period-info"
import { navigate } from "gatsby"
import type { DomainCheck } from "@onestore/api/domainSearch"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import DomainSearchResultItemMol from "@onestore/hel/dist/components/molecules/DomainSearchResultItemMol"
import SearchResultPrice from "@gatsby-plugin-domain-search/components/ResultsOrgSingle/SearchResultPrice"
import useButtonLoadingState from "@gatsby-plugin-domain-search/hooks/useButtonLoadingState"
import { DOMAIN_STATUS } from "@gatsby-plugin-domain-search/store/constants"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import { useCloudblueDomainInfo } from "~/hooks/useCloudblueDomainInfo"
import url from "~/lib/url"

interface BonusSearchContentRowProps {
  onClick: {
    (
      fqdn: DomainCheck.FQDN,
      planId: number,
      buttonId: string,
      ignoreHack: boolean
    ): void
  }
  onNextStepClick?: { (): void }
  result: DomainCheck.Result
  basketDomains: string[]
}

export default function BonusSearchContentRow({
  result: { plan_id, status, period, fqdn, extension, name },
  onClick,
  onNextStepClick = undefined,
  basketDomains = [],
}: BonusSearchContentRowProps): ReactElement<BonusSearchContentRowProps> {
  const buttonId = `bonus-search-button-${fqdn}`
  const isLoading = useButtonLoadingState(buttonId)
  const { domainLabel, tooltip } = useCloudblueDomainInfo(plan_id)
  const { currentPriceType } = usePriceTypeContext()

  const periodInfo = period ? new PeriodInfo(period) : null
  const hasPromotion = periodInfo?.hasPromotion() ?? false

  if (!period) {
    return <></>
  }

  const available = DOMAIN_STATUS.AVAILABLE === status

  const isInBasket = fqdn !== undefined && basketDomains.indexOf(fqdn) > -1

  const onButtonClick = () => {
    if (isInBasket) {
      if (onNextStepClick) {
        onNextStepClick()

        return
      }

      navigate(url.getBasketUrl())
    }

    if (!fqdn || basketDomains.indexOf(fqdn) > -1 || plan_id === null) {
      return
    }
    onClick(fqdn, plan_id, buttonId, true)
  }

  let buttonText = available
    ? _t("domainSearch.addToBasket")
    : _t("domainSearch.domainUnvailable")

  if (isInBasket) {
    buttonText = onNextStepClick
      ? _t("preBasket.nextStep")
      : _t("button.success")
  }

  if (!available && !isLoading) {
    buttonText = _t("domainSearch.domainUnvailable")
  }

  const periodText =
    period === null
      ? ""
      : ` ${_t(`periodName.searchDomainPrice.3`, {
          smart_count: periodInfo?.getPeriodLength(),
        })}`

  return (
    <DomainSearchResultItemMol
      domain={{
        name: name,
        extension: `.${extension}`,
      }}
      price={
        <SearchResultPrice
          periodInfo={periodInfo}
          priceType={currentPriceType}
          periodText={periodText}
          tooltip={tooltip}
        />
      }
      isLoading={isLoading}
      label={
        domainLabel || hasPromotion ? (
          <LabelAtm size="small" variant="notify" emphasis="medium">
            {hasPromotion ? _t("marketplace.discountTitle") : domainLabel}
          </LabelAtm>
        ) : undefined
      }
      isDisabled={!available && !isLoading}
      disabledMessage={
        !available && !isLoading
          ? _t("domainSearch.domainUnvailable")
          : undefined
      }
      hasSuccess={isInBasket}
      button={{
        text: buttonText,
        title: buttonText,
        onClick: onButtonClick,
      }}
    />
  )
}
