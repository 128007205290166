import { useCallback, useState } from "react"
import Cookies from "js-cookie"

interface CookieStorageHook {
  value: string | undefined
  setValue: { (value: string): void }
}

export default function useCookieStorage(
  key: string,
  expires: number
): CookieStorageHook {
  const [value, setInnerValue] = useState<string | undefined>(Cookies.get(key))

  const setValue = useCallback(
    (value: string): void => {
      Cookies.set(key, value, {
        expires,
      })
      setInnerValue(value)
    },
    [key, expires]
  )

  return {
    value,
    setValue,
  }
}
