import type { ReactElement } from "react"
import React from "react"
import NumberInputMol from "@onestore/hel/dist/components/molecules/NumberInputMol"

type CounterInputProps = {
  value: number
  minValue: number
  maxValue: number
  isDisable?: boolean
  handleIncrementButton: () => void
  handleDecrementButton: () => void
  onChange: (value: number) => void
  onClear?: () => void
}

export default function CounterInput({
  value,
  minValue,
  maxValue,
  handleIncrementButton,
  handleDecrementButton,
  onChange,
  onClear,
}: CounterInputProps): ReactElement<CounterInputProps> {
  const handleOnChange = (value: number) => {
    if (value > maxValue) {
      onChange(maxValue)

      return
    }

    if (value < minValue) {
      onChange(minValue)

      return
    }

    onChange(value)
  }

  const onIncrementButtonClick = () => {
    if (value < maxValue) {
      handleIncrementButton()
    }
  }

  const onDecrementButtonClick = () => {
    if (value > minValue) {
      handleDecrementButton()
    }
  }

  return (
    <NumberInputMol
      onIncrementButtonClick={onIncrementButtonClick}
      onDecrementButtonClick={onDecrementButtonClick}
      value={value.toString()}
      onChange={(event) => handleOnChange(parseInt(event.currentTarget.value))}
      onClear={onClear}
      isBig
    />
  )
}
