import get from "lodash/get"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import type { SaleConfiguration } from "~/fragments/saleConfigurations"

export const getDefaultPeriodName = (
  saleConfigurationData: SaleConfiguration["flatData"],
  planData: CloudBluePlan["flatData"]
): string | null => {
  const defaultPeriodFromSaleConfiguration =
    saleConfigurationData.defaultPlanPeriod?.period

  const planDefaultPeriod = planData.periods.find(
    (period) => period.default
  )?.period_name

  const planFirstPeriod = get(planData, "periods[0].period_name", null)

  return (
    defaultPeriodFromSaleConfiguration || planDefaultPeriod || planFirstPeriod
  )
}
