import { useCallback, useState } from "react"
import type { GaEvent } from "~/fragments/gaEvent"
import { sendGaEvent } from "~/lib/analytics"

interface ModalStateHook {
  isOpen: boolean
  open: { (): void }
  close: { (): void }
}

export default function useModalState(gaEvent?: GaEvent): ModalStateHook {
  const [isOpen, setIsOpen] = useState(false)
  const open = useCallback(() => {
    setIsOpen(true)
    sendGaEvent(gaEvent)
  }, [isOpen])

  const close = useCallback(() => {
    setIsOpen(false)
  }, [isOpen])

  return { isOpen, open, close }
}
