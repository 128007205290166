import type { ReactElement } from "react"
import React from "react"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import ContentWithImageOrg from "@onestore/hel/dist/components/organisms/ContentWithImageOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CopyToClipboardButtonWithText from "@gatsby-plugin-generic-page/components/CopyToClipboardButtonWithText"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import Header from "@gatsby-plugin-generic-page/components/Header"
import Media from "@gatsby-plugin-generic-page/components/Media"
import Paragraph from "@gatsby-plugin-generic-page/components/Paragraph"
import TextWithPrice from "@gatsby-plugin-generic-page/components/TextWithPrice"
import ToggleWithLinkButtons from "@gatsby-plugin-generic-page/components/ToggleWithLinkButtons"
import type { ContentWithImageSectionFlatData } from "@gatsby-plugin-generic-page/fragments/contentWithImageSection"
import isEmpty from "~/lib/isEmpty"

function ContentWithImageSection({
  header,
  toggleWithLinkButtons,
  paragraphs,
  responsiveImages,
  isReversed,
  hasImageStickyToBottom,
  textWithPrice,
  ctaElement,
  copyToClipboardButtonWithText,
  isReversedOnMobile,
  hasReversedButtonsOnMobile,
  sectionSettings,
}: ContentWithImageSectionFlatData): ReactElement<ContentWithImageSectionFlatData> {
  const headerMobileBottomSpace = hasReversedButtonsOnMobile ? 3 : 4
  const headerMobileTopSpace =
    isReversedOnMobile && hasImageStickyToBottom ? 4 : 0

  const buttonsJSX = (
    <div>
      {textWithPrice ? (
        <PushOrg bottomSpace={6}>
          <TextWithPrice {...textWithPrice} />
        </PushOrg>
      ) : null}

      {ctaElement ? (
        <CtaElement ctaElement={ctaElement} align={header.align} />
      ) : null}

      {copyToClipboardButtonWithText ? (
        <CopyToClipboardButtonWithText {...copyToClipboardButtonWithText} />
      ) : null}
    </div>
  )

  const contentOnLarge = (
    <BreakpointVisibilityOrg from="large">
      {!isEmpty(toggleWithLinkButtons) ? (
        <PushOrg bottomSpace={5}>
          <ToggleWithLinkButtons {...toggleWithLinkButtons} />
        </PushOrg>
      ) : null}

      <PushOrg bottomSpace={4}>
        <Header {...header} />
      </PushOrg>

      {!isEmpty(paragraphs) &&
        paragraphs.map((paragraph, index) => (
          <PushOrg
            bottomSpace={4}
            key={`paragraph-${paragraph.content.text}-${index}`}
          >
            <Paragraph key={index} {...paragraph} />
          </PushOrg>
        ))}

      {buttonsJSX}
    </BreakpointVisibilityOrg>
  )

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <ContentWithImageOrg
          hasImageStickyToBottom={hasImageStickyToBottom}
          isReversedOnLarge={isReversed}
          isReversedOnMobile={isReversedOnMobile}
          image={<Media media={responsiveImages} />}
          content={
            <ConditionalWrapper
              condition={!isEmpty(header.align)}
              wrapper={(children) => (
                <AlignContentOrg
                  horizontalAlign={{
                    large: header.align?.large.horizontalAlign || "default",
                    medium: header.align?.medium.horizontalAlign || "default",
                    small: header.align?.small.horizontalAlign || "default",
                  }}
                  horizontalAlignType="both"
                >
                  {children}
                </AlignContentOrg>
              )}
            >
              {contentOnLarge}

              <BreakpointVisibilityOrg
                from="small"
                to="large"
                hasForcedFullWidth
              >
                <PushOrg
                  bottomSpace={headerMobileBottomSpace}
                  topSpace={headerMobileTopSpace}
                  hasNoReset={!!headerMobileTopSpace}
                >
                  {!isEmpty(toggleWithLinkButtons) ? (
                    <PushOrg bottomSpace={4}>
                      <ToggleWithLinkButtons {...toggleWithLinkButtons} />
                    </PushOrg>
                  ) : null}

                  <Header {...header} />
                </PushOrg>

                <ConditionalWrapper
                  condition={!!hasReversedButtonsOnMobile}
                  wrapper={(children) => (
                    <FlexContainerOrg direction="column-reverse">
                      {children}
                    </FlexContainerOrg>
                  )}
                >
                  {!isEmpty(paragraphs) &&
                    paragraphs.map((paragraph, index) => (
                      <PushOrg
                        bottomSpace={hasReversedButtonsOnMobile ? undefined : 4}
                        topSpace={hasReversedButtonsOnMobile ? 3 : undefined}
                        hasNoReset={hasReversedButtonsOnMobile}
                        key={`paragraph-${paragraph.content.text}-${index}`}
                      >
                        <Paragraph key={index} {...paragraph} />
                      </PushOrg>
                    ))}

                  {buttonsJSX}
                </ConditionalWrapper>
              </BreakpointVisibilityOrg>
            </ConditionalWrapper>
          }
        />
      </BaseContainer>
    </BaseSection>
  )
}

export default ContentWithImageSection
