import { typeMatches } from "@onestore-graphql"
import type { Rel } from "@onestore/hel/dist/typings/types"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import type { CtaAXLink } from "@gatsby-plugin-generic-page/fragments/ctaAXLink"
import type { CtaButton } from "@gatsby-plugin-generic-page/fragments/ctaButton"
import type { CtaCloudBluePlan } from "@gatsby-plugin-generic-page/fragments/ctaCloudBluePlan"
import type { CtaElement } from "@gatsby-plugin-generic-page/fragments/ctaElement"
import type { CtaFastCheckout } from "@gatsby-plugin-generic-page/fragments/ctaFastCheckout"
import type { CtaForm } from "@gatsby-plugin-generic-page/fragments/ctaForm"
import type { CtaImageButton } from "@gatsby-plugin-generic-page/fragments/ctaImageButton"
import type { CtaLink } from "@gatsby-plugin-generic-page/fragments/ctaLink"
import type { CtaModal as CtaModalType } from "@gatsby-plugin-generic-page/fragments/ctaModal"
import type { InternalLink } from "@gatsby-plugin-generic-page/fragments/internalLink"
import type { Texts } from "@gatsby-plugin-generic-page/fragments/texts"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import type { GaEvent } from "~/fragments/gaEvent"
import type { AnchorId, ExternalLink, Forms, Pages } from "~/types/squidex"

type ActionTypeData = {
  anchorId: string
  form: Forms[]
  href: string
  openInNewTab?: boolean
  pages: Pages
  modalTitle?: string
  texts: Texts
  plan?: CloudBluePlan[]
  fastCheckoutPlan?: CloudBluePlan[]
  saleConfiguration?: CtaAXLink["saleConfiguration"]
}

type CtaBaseType = {
  actionTypeName?: string
  title: string
  gaEvent?: GaEvent
}

type CtaButtonType = Omit<CtaButton, "actionType" | "__typename"> &
  ActionTypeData &
  CtaBaseType & {
    rel?: Rel
  }

type CtaLinkType = Omit<CtaLink, "actionType" | "__typename"> &
  ActionTypeData &
  CtaBaseType

type CtaImageButtonType = Omit<CtaImageButton, "actionType" | "__typename"> &
  ActionTypeData &
  CtaBaseType

export function getActionTypeData(
  actionType:
    | AnchorId
    | CtaForm
    | ExternalLink
    | InternalLink
    | CtaModalType
    | CtaCloudBluePlan
    | CtaFastCheckout
    | CtaAXLink
): ActionTypeData {
  const { anchorId } = actionType as AnchorId
  const { form } = actionType as CtaForm
  const { href, openInNewTab } = actionType as ExternalLink
  const { pages } = actionType as InternalLink
  const { title: modalTitle, texts } = actionType as CtaModalType
  let plan: CloudBluePlan[] | undefined
  let fastCheckoutPlan: CloudBluePlan[] | undefined

  if (typeMatches(actionType.__typename, SquidexComponentType.ADD_TO_BASKET)) {
    plan = (actionType as CtaCloudBluePlan).plan
  }

  if (typeMatches(actionType.__typename, SquidexComponentType.FAST_CHECKOUT)) {
    fastCheckoutPlan = (actionType as CtaFastCheckout).plan
  }

  const { saleConfiguration } = actionType as CtaAXLink

  return {
    anchorId,
    form,
    href,
    openInNewTab,
    pages,
    modalTitle,
    texts,
    plan,
    fastCheckoutPlan,
    saleConfiguration,
  }
}

export function getCtaButtonFlatData(cta: CtaElement): CtaButtonType {
  const { ctaType, title, gaEvent, rel } = cta.flatData
  const {
    actionType,
    text,
    variant,
    buttonColor,
    isWider,
    isExpanded,
    isExpandedOnMobile,
    size,
    showPrice,
    hasSurface,
  } = ctaType as CtaButton
  const actionTypeName = actionType?.__typename

  return {
    actionTypeName,
    text,
    title,
    variant,
    buttonColor,
    isWider,
    isExpanded,
    isExpandedOnMobile,
    size,
    showPrice,
    hasSurface,
    gaEvent,
    rel,
    ...getActionTypeData(actionType),
  }
}

export function getCtaLinkFlatData(cta: CtaElement): CtaLinkType {
  const { ctaType, title, gaEvent } = cta.flatData
  const { actionType, text, linkType } = ctaType as CtaLink
  const actionTypeName = actionType?.__typename

  return {
    actionTypeName,
    text,
    linkType,
    title,
    gaEvent,
    ...getActionTypeData(actionType),
  }
}

export function getCtaImageButtonFlatData(cta: CtaElement): CtaImageButtonType {
  const { ctaType, title, gaEvent } = cta.flatData
  const { image, actionType } = ctaType as CtaImageButton
  const actionTypeName = actionType?.__typename

  return {
    actionTypeName,
    image,
    title,
    gaEvent,
    ...getActionTypeData(actionType),
  }
}
