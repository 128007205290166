import type { ReactElement } from "react"
import React from "react"
import type { PeriodInfo } from "@core/period-info"
import type { PriceType } from "@core/types"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import {
  PriceVariant,
  PriceVariantType,
} from "@onestore/hel/dist/components/molecules/PriceMol/priceMol.types"
import TooltipMol from "@onestore/hel/dist/components/molecules/TooltipMol"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import DetailedPrice from "@gatsby-plugin-generic-page/components/DetailedPrice"
import MarkdownTile from "@gatsby-plugin-generic-page/components/Markdown/Tile"
import LowestPriceInfo from "~/components/LowestPriceInfo"
import { PriceInfo } from "~/fragments/priceInfo"
import usePriceConfigurationQuery from "~/hooks/usePriceConfigurationQuery"

type SearchResultPriceProps = {
  periodInfo?: PeriodInfo | null
  priceType?: PriceType
  periodText?: string
  tooltip?: string
  overridePrice?: string
  isMainResult?: boolean
  priceInfo?: PriceInfo
  priceVariant?: PriceVariant
}

export default function SearchResultPrice({
  periodInfo,
  priceType,
  periodText,
  tooltip,
  overridePrice,
  isMainResult,
  priceInfo,
  priceVariant,
}: SearchResultPriceProps): ReactElement<SearchResultPriceProps> {
  const hasPromotion = periodInfo?.hasPromotion() ?? false

  const periodInfoValues = periodInfo
    ? periodInfo.usePrices(priceType)
    : {
        priceValue: "",
        lowestPrice: "",
        lowestPricePercent: "",
        renewPrice: "",
      }

  const { priceValue, lowestPrice, lowestPricePercent } = periodInfoValues
  const { showDetailedPrices } = usePriceConfigurationQuery()

  return showDetailedPrices && periodInfo ? (
    <DetailedPrice
      periodInfo={periodInfo}
      priceInfo={priceInfo}
      variant={
        priceVariant || { type: PriceVariantType.MEDIUM, align: "right" }
      }
    />
  ) : (
    <>
      <FlexContainerOrg alignItems="center" justifyContent="flex-end">
        <TextAtm
          typography="medium3_s"
          color={hasPromotion ? "primary" : undefined}
        >
          {overridePrice || priceValue}{" "}
          <TextAtm typography="nano1" emphasis="high">
            {periodText}
          </TextAtm>
        </TextAtm>

        {tooltip && hasPromotion ? (
          <PushOrg leftSpace={0.5}>
            <TooltipMol iconSize="large">
              <AlignContentOrg horizontalAlign="left">
                <MarkdownTile>{tooltip}</MarkdownTile>
              </AlignContentOrg>
            </TooltipMol>
          </PushOrg>
        ) : null}
      </FlexContainerOrg>

      {hasPromotion ? (
        <PushOrg
          topSpace={
            isMainResult
              ? 2
              : {
                  large: 1,
                  medium: 1,
                  small: 2,
                }
          }
        >
          <LowestPriceInfo value={lowestPrice} percent={lowestPricePercent} />
        </PushOrg>
      ) : undefined}
    </>
  )
}
