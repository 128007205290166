import type { SyntheticEvent } from "react"
import { useState } from "react"
import { navigate } from "gatsby"
import { isResponsePending } from "@onestore/onestore-store-common/http"
import {
  addItemsToBasket,
  BasketActionSource,
} from "@gatsby-plugin-basket/store/actions"
import {
  getBasketItems,
  getButtonsStates,
} from "@gatsby-plugin-basket/store/selectors"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import url from "~/lib/url"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import type { BasketPatchItem } from "../../../../lib/api/types"

export type BasketPatchItemHookResult = {
  addProductToBasket: (onSuccess?: () => void) => void
  goToBasket: { (event: SyntheticEvent): void }
  isLoading: boolean
  isDone: boolean
}

function createBasketPatchItem(
  plans: CloudBluePlan[],
  children: CloudBluePlan[] | undefined,
  planPeriodId?: number
): BasketPatchItem[] {
  return plans.map((plan) => ({
    plan: plan.flatData.id,
    planPeriod: planPeriodId,
    quantity:
      plan.flatData.hasQuantity && plan.flatData.quantityResource
        ? plan.flatData.minQuantity || 1
        : 1,
    children: children ? createBasketPatchItem(children, undefined) : undefined,
  }))
}

export default function useBasketActions(
  plan: CloudBluePlan,
  bundledPlan: CloudBluePlan | undefined = undefined,
  code: string | undefined = undefined,
  planPeriod: number | undefined = undefined,
  isNotRememberDoneStatus: boolean | undefined = undefined,
  addToCartSource: BasketActionSource | undefined = undefined
): BasketPatchItemHookResult {
  const dispatch = useAppDispatch()
  const buttons = useAppSelector(getButtonsStates)
  const basketPlans = useAppSelector(getBasketItems)

  const planId = plan.flatData.id
  const bundledPlanId = bundledPlan ? bundledPlan.flatData.id : undefined

  const buttonId = `generic-add-button-${planId}x${bundledPlanId ?? 0}`

  const [isAddedNow, setIsAddedNow] = useState(false)
  const [isButtonLoading, setButtonLoading] = useState(false)

  const basketItem = basketPlans.find((item) => {
    if (bundledPlanId) {
      return (
        item.plan_id === planId &&
        item.children.find((child) => child.plan_id === bundledPlanId)
      )
    }

    return item.plan_id === planId
  })

  const isLoading = isResponsePending(buttons[buttonId]) || isButtonLoading
  const isDone: boolean =
    !isLoading &&
    (isNotRememberDoneStatus ? isAddedNow : basketItem !== undefined)

  const addProductToBasket = (onSuccess?: () => void): void => {
    setButtonLoading(true)

    dispatch(
      addItemsToBasket(
        createBasketPatchItem(
          [plan],
          bundledPlan ? [bundledPlan] : undefined,
          planPeriod
        ),
        null,
        addToCartSource || BasketActionSource.GENERIC_PAGE,
        () => {
          setButtonLoading(false)

          if (isNotRememberDoneStatus) {
            setIsAddedNow(true)
          }

          if (onSuccess) {
            onSuccess()
          }
        },
        code
      )
    )
  }

  return {
    addProductToBasket,
    isLoading,
    isDone,
    goToBasket: (event: SyntheticEvent) => {
      event.preventDefault()
      navigate(url.getBasketUrl())
    },
  }
}
