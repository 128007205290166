import { graphql, useStaticQuery } from "gatsby"
import queryString from "query-string"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import isEmpty from "~/lib/isEmpty"
import url, { getSettingsString } from "~/lib/url"

interface Hook {
  forMiniCart: string | null
  forDomainButtons: string
}

interface Query {
  GraphCmsSquidex: {
    pages: {
      flatData: {
        upsell: CloudBluePlan[]
      }
    }
  }
}

export default function useDomainHookUrl(): Hook {
  const {
    GraphCmsSquidex: { pages },
  } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        pages: findDomainSearchPageSingleton {
          flatData {
            upsell {
              ...CloudBluePlan
            }
          }
        }
      }
    }
  `)

  const { currentPriceType } = usePriceTypeContext()

  const isSmallOnly = useBreakpoint("small", "medium")
  const upsell = pages.flatData.upsell

  const ax: string[] = !isEmpty(upsell)
    ? upsell.map((upsellPlan: CloudBluePlan) => {
        const upsellDefaultPeriod = upsellPlan.flatData.upsellDefaultPeriod

        if (!isEmpty(upsellDefaultPeriod)) {
          const planWithParam = {
            alias: upsellPlan.flatData.alias,
            period: upsellDefaultPeriod.period,
          }

          return getSettingsString(planWithParam)
        } else {
          return getSettingsString(upsellPlan.flatData.alias)
        }
      })
    : []

  const axUrl = queryString.stringify({ ax: ax }, { arrayFormat: "index" })

  const domainsBonusUrl = url.getDomainsBonusUrl()
  const domainsBonusUrlWithAx = `${domainsBonusUrl}&${axUrl}`

  const bonusUrl = isSmallOnly ? domainsBonusUrl : domainsBonusUrlWithAx
  const bonusUrlWithPriceType = `${bonusUrl}&priceType=${currentPriceType}`

  return {
    forMiniCart: bonusUrlWithPriceType,
    forDomainButtons: bonusUrlWithPriceType || url.getBasketUrl(),
  }
}
