import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import ProductBoxMol from "@onestore/hel/dist/components/molecules/ProductBoxMol"
import ExpandableContentOrg from "@onestore/hel/dist/components/organisms/ExpandableContentOrg"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import type { IntegrationTilesSectionFlatData } from "@gatsby-plugin-generic-page/fragments/integrationTilesSection"
import { sendContentShowEvent } from "~/lib/analytics"
import assetsSquidexToImageObject from "~/lib/assetsSquidexToImageObject"

function IntegrationTilesSection({
  integrations,
  columns,
  sectionSettings,
}: IntegrationTilesSectionFlatData): ReactElement<IntegrationTilesSectionFlatData> {
  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <ExpandableContentOrg
          showMoreDetails={{
            text: _t("showMore"),
            title: _t("showMore"),
            onClick: sendContentShowEvent,
          }}
        >
          <GridOrg
            columns={{ small: 1, medium: 2, large: columns }}
            gutterSpace={2}
          >
            {integrations.map(
              ({
                id,
                flatData: {
                  images,
                  description,
                  title,
                  label,
                  labelVariant,
                  ctaElement,
                },
              }) => (
                <ProductBoxMol
                  key={id}
                  logo={assetsSquidexToImageObject(images, description)}
                  title={
                    <MarkdownText
                      typography={{ bold: "medium4_h", default: "medium4_h" }}
                      pushSpace={1}
                    >
                      {title}
                    </MarkdownText>
                  }
                  text={description}
                  label={label}
                  variant={labelVariant}
                  button={
                    ctaElement ? (
                      <CtaElement ctaElement={ctaElement} />
                    ) : undefined
                  }
                />
              )
            )}
          </GridOrg>
        </ExpandableContentOrg>
      </BaseContainer>
    </BaseSection>
  )
}

export default IntegrationTilesSection
