import type { ReactElement } from "react"
import React from "react"
import SelectMol from "@onestore/hel/dist/components/molecules/SelectMol/SelectMol"
import type { SelectOption } from "@onestore/hel/dist/components/molecules/SelectMol/SelectMol.types"
import { OptionsAlign } from "@onestore/hel/dist/components/molecules/SelectMol/SelectMol.types"
import FlexItemOrg from "@onestore/hel/dist/components/organisms/FlexItemOrg"
import isEmpty from "~/lib/isEmpty"

type SelectConfigElementProps = {
  options: SelectOption[]
  isDisable?: boolean
  onChange: (value: string) => void
}

export default function SelectConfigElement({
  options,
  isDisable,
  onChange,
}: SelectConfigElementProps): ReactElement<SelectConfigElementProps> {
  if (isEmpty(options)) {
    return <></>
  }

  return (
    <FlexItemOrg flexGrow={1} flexShrink={1} flexBasis="0%">
      <SelectMol
        size="small"
        borderRadius="mlarge"
        options={options}
        isInactive={isDisable}
        onChange={(value) => onChange(value)}
        optionsAlign={{
          small: OptionsAlign.RIGHT,
          medium: OptionsAlign.RIGHT,
          large: OptionsAlign.LEFT,
        }}
        hasTextEllipsis
        hasOptionsNonBreakingSpace
        hasSelectedOptionWithIcon
      />
    </FlexItemOrg>
  )
}
