import CookieStorage from "~/lib/storage/CookieStorage"
import LocalStorage from "~/lib/storage/LocalStorage"
import SessionStorage from "~/lib/storage/SessionStorage"

export default class DurableStorage {
  static get(key: string): string | null {
    const values = [
      LocalStorage.get(key),
      CookieStorage.get(key),
      SessionStorage.get(key),
    ].filter((value) => !!value)

    const value = values.pop() || null

    if (values.length < 2 && value !== null) {
      DurableStorage.set(key, value)
    }

    return value
  }

  static set(key: string, value: string): void {
    LocalStorage.set(key, value)
    SessionStorage.set(key, value)
    CookieStorage.set(key, value, { expires: 365 * 100 })
  }

  static remove(key: string): void {
    LocalStorage.remove(key)
    SessionStorage.remove(key)
    CookieStorage.remove(key)
  }

  static keep(key: string, generator: { (): string }): string {
    let value = DurableStorage.get(key)

    if (!value) {
      value = generator()
      DurableStorage.set(key, value)
    }

    return value
  }
}
