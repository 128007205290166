import type { ReactElement } from "react"
import React, { useContext } from "react"
import { typeMatches } from "@onestore-graphql"
import ImageLinkAtm from "@onestore/hel/dist/components/atoms/ImageLinkAtm"
import CtaModal from "@gatsby-plugin-generic-page/components/CtaModal"
import ImageAnchorButton from "@gatsby-plugin-generic-page/components/ImageAnchorButton"
import ImageFormButton from "@gatsby-plugin-generic-page/components/ImageFormButton"
import Media from "@gatsby-plugin-generic-page/components/Media"
import type { CtaElement } from "@gatsby-plugin-generic-page/fragments/ctaElement"
import { getCtaImageButtonFlatData } from "@gatsby-plugin-generic-page/helpers/getCtaElementFlatData"
import { replaceLinkValues } from "@gatsby-plugin-generic-page/helpers/linkParser"
import { SquidexComponentType } from "@gatsby-plugin-generic-page/types/componentType"
import SearchUrlParametersContext from "~/context/SearchUrlParametersContext"
import useModalState from "~/hooks/useModalState"
import { sendGaEvent } from "~/lib/analytics"
import isEmpty from "~/lib/isEmpty"

interface Props {
  button: CtaElement
}

function ImageButton({ button }: Props): ReactElement | null {
  const {
    actionTypeName,
    image,
    title,
    gaEvent,
    anchorId,
    form,
    href,
    openInNewTab,
    pages,
    modalTitle,
    texts,
  } = getCtaImageButtonFlatData(button)
  const { isOpen, open, close } = useModalState(gaEvent)
  const currentSearchUrlParameters = useContext(SearchUrlParametersContext)

  if (isEmpty(actionTypeName)) {
    return null
  }

  const commonProps = {
    image: <Media media={image} />,
    title,
  }

  if (
    typeMatches(actionTypeName, SquidexComponentType.SCROLL_TO_SECTION) &&
    !isEmpty(anchorId)
  ) {
    return (
      <ImageAnchorButton {...commonProps} anchor={anchorId} gaEvent={gaEvent} />
    )
  }

  if (
    typeMatches(actionTypeName, SquidexComponentType.OPEN_FORM) &&
    !isEmpty(form)
  ) {
    return <ImageFormButton {...commonProps} form={form} gaEvent={gaEvent} />
  }

  if (
    typeMatches(actionTypeName, SquidexComponentType.EXTERNAL_LINK) &&
    !isEmpty(href)
  ) {
    return (
      <ImageLinkAtm
        {...commonProps}
        onClick={() => sendGaEvent(gaEvent)}
        href={replaceLinkValues(href, currentSearchUrlParameters)}
        openInNewTab={openInNewTab}
      />
    )
  }

  if (
    typeMatches(actionTypeName, SquidexComponentType.INTERNAL_LINK) &&
    !isEmpty(pages)
  ) {
    return (
      <ImageLinkAtm
        {...commonProps}
        onClick={() => sendGaEvent(gaEvent)}
        to={pages[0].flatData.url}
      />
    )
  }

  if (
    typeMatches(actionTypeName, SquidexComponentType.OPEN_MODAL) &&
    !isEmpty(texts)
  ) {
    return (
      <>
        <ImageLinkAtm {...commonProps} onClick={open} />

        <CtaModal
          isOpen={isOpen}
          texts={texts}
          title={modalTitle}
          onCloseButtonClick={close}
        />
      </>
    )
  }

  return null
}

export default ImageButton
