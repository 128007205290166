import type { ReactElement, ReactNode } from "react"
import React from "react"
import _t from "@core/i18n"
import { PeriodInfo } from "@core/period-info"
import { getCurrencySign } from "@core/pricing"
import { PriceFormat } from "@core/types"
import PlainLinkAtm from "@onestore/hel/dist/components/atoms/PlainLinkAtm"
import ProductTileMol from "@onestore/hel/dist/components/molecules/ProductTileMol"
import type { TooltipMolProps } from "@onestore/hel/dist/components/molecules/TooltipMol"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import type { Label } from "@gatsby-plugin-generic-page/fragments/label"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import { safeFind } from "~/lib/collection"
import { getBrand } from "~/lib/config"
import isEmpty from "~/lib/isEmpty"

export type DomainProps = {
  link?: string
  tooltip?: TooltipMolProps["children"]
  extension?: string
  period?: string
  label?: Label
  title?: string
  suffix?: string
  description?: ReactNode
  logo?: string
  periods: CloudBluePeriod[]
}

export default function DomainItem({
  tooltip,
  extension,
  period,
  title,
  suffix,
  label,
  description,
  link,
  logo,
  periods,
}: DomainProps): ReactElement<DomainProps> | null {
  const { isNettoPriceType } = usePriceTypeContext()
  const periodInfo = new PeriodInfo(
    periods.find((item) => item.period_name === period) ||
      safeFind(periods, (item) => item.default ?? false)
  )

  const priceFormatDecimals = isNettoPriceType
    ? PriceFormat.REGISTER_NETTO_DECIMALS
    : PriceFormat.REGISTER_GROSS_DECIMALS
  const priceFormatCents = isNettoPriceType
    ? PriceFormat.REGISTER_NETTO_CENTS
    : PriceFormat.REGISTER_GROSS_CENTS
  const priceFormatSuffix = isNettoPriceType
    ? PriceFormat.NETTO_PRICE_TYPE_WITH_PERIOD_NAME
    : PriceFormat.GROSS_PRICE_TYPE_WITH_PERIOD_NAME

  const productTileLabel = !isEmpty(label)
    ? {
        children: label.text,
        variant: label.variant,
      }
    : undefined

  return (
    <ProductTileMol
      tooltip={tooltip ? tooltip : undefined}
      label={productTileLabel}
      logo={{
        src: logo ? logo : "",
        alt: `${_t("domains.domain")} .${extension}`,
      }}
      title={
        <>
          <BreakpointVisibilityOrg from="small" to="medium">
            <PlainLinkAtm
              typography="big4"
              title={title ? title : `.${extension}`}
              to={link}
              type="opacity"
            >
              {title ? title : `.${extension}`}
            </PlainLinkAtm>
          </BreakpointVisibilityOrg>

          <BreakpointVisibilityOrg from="medium">
            <PlainLinkAtm
              typography="large3"
              title={title ? title : `.${extension}`}
              to={link}
              type="opacity"
            >
              {title ? title : `.${extension}`}
            </PlainLinkAtm>
          </BreakpointVisibilityOrg>
        </>
      }
      description={description}
      price={{
        integer: periodInfo.getFormatted(priceFormatDecimals),
        fraction: `,${periodInfo.getFormatted(priceFormatCents)}`,
        currency: getCurrencySign(),
        suffix: suffix || periodInfo.getFormatted(priceFormatSuffix),
      }}
      button={{
        title: `${_t("domains.domain")} .${extension}`,
        text: _t("marketplace.checkProduct"),
        to: link,
        dataTestId: "domain-ext-tile-button",
      }}
      buttonColor={getBrand() === "home" ? "secondary" : "primary"}
      buttonVariant={getBrand() === "home" ? "transparent" : "default"}
      data-test-id="domain-ext-tile"
    />
  )
}
