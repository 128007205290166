import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import type { PeriodInfo } from "@core/period-info"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import { TooltipWrapper } from "@onestore/hel/dist/components/molecules/AdvancedProductTileMol/AdvancedProductTileMol.styled"
import { PriceVariantType } from "@onestore/hel/dist/components/molecules/PriceMol/priceMol.types"
import TooltipMol from "@onestore/hel/dist/components/molecules/TooltipMol"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import WrapOrg from "@onestore/hel/dist/components/organisms/WrapOrg"
import DetailedPrice from "@gatsby-plugin-generic-page/components/DetailedPrice"
import MarkdownTile from "@gatsby-plugin-generic-page/components/Markdown/Tile"
import LowestPriceInfo from "~/components/LowestPriceInfo"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import type { PriceInfo } from "~/fragments/priceInfo"
import usePriceConfigurationQuery from "~/hooks/usePriceConfigurationQuery"

export type AdvancedDomainItemPriceProps = {
  periodInfo: PeriodInfo
  priceInfo?: PriceInfo
  hasPromotion: boolean
  tooltip?: string
  suffix?: string
}

const AdvancedDomainItemPrice = ({
  periodInfo,
  priceInfo,
  hasPromotion,
  suffix,
  tooltip,
}: AdvancedDomainItemPriceProps): ReactElement<AdvancedDomainItemPriceProps> => {
  const { showDetailedPrices } = usePriceConfigurationQuery()
  const { currentPriceType } = usePriceTypeContext()
  const { priceValue, lowestPrice, lowestPricePercent } =
    periodInfo.usePrices(currentPriceType)

  const priceSuffix = `${_t(`prices.${currentPriceType}`)} ${periodInfo.periodText()}`

  if (showDetailedPrices) {
    return (
      <DetailedPrice
        periodInfo={periodInfo}
        priceInfo={priceInfo}
        variant={{ type: PriceVariantType.MEDIUM, align: "left" }}
      />
    )
  }

  return (
    <>
      <WrapOrg gutterSpace={0.5} alignCenter="vertical">
        <FlexContainerOrg alignItems="baseline">
          <TextAtm
            typography="big3"
            color={hasPromotion ? "primary" : undefined}
          >
            {priceValue}
          </TextAtm>

          <PushOrg leftSpace={0.5}>
            <TextAtm typography="tiny2" emphasis="medium">
              {suffix || priceSuffix}
            </TextAtm>
          </PushOrg>
        </FlexContainerOrg>

        {tooltip ? (
          <TooltipWrapper>
            <TooltipMol iconSize="large">
              <MarkdownTile>{tooltip}</MarkdownTile>
            </TooltipMol>
          </TooltipWrapper>
        ) : undefined}
      </WrapOrg>

      {hasPromotion && lowestPrice ? (
        <PushOrg topSpace={2}>
          <LowestPriceInfo value={lowestPrice} percent={lowestPricePercent} />
        </PushOrg>
      ) : null}
    </>
  )
}

export default AdvancedDomainItemPrice
