import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import { PeriodInfo } from "@core/period-info"
import type { DomainCheck } from "@onestore/api/domainSearch"
import { PriceVariant } from "@onestore/hel/dist/components/molecules/PriceMol/priceMol.types"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import { useCloudblueDomainInfo } from "~/hooks/useCloudblueDomainInfo"
import SearchResultPrice from "../ResultsOrgSingle/SearchResultPrice"

type PriceProps = {
  domain: DomainCheck.Result
  priceVariant?: PriceVariant
}

export function Price({
  domain,
  priceVariant,
}: PriceProps): ReactElement | null {
  const info = domain.period ? new PeriodInfo(domain.period) : null
  const { currentPriceType } = usePriceTypeContext()
  const { tooltip, priceInfo } = useCloudblueDomainInfo(domain.plan_id)

  const periodText = !info
    ? ""
    : ` ${_t(`periodName.searchDomainPrice.3`, {
        smart_count: info.getPeriodLength(),
      })}`

  return (
    <SearchResultPrice
      periodInfo={info}
      priceType={currentPriceType}
      periodText={periodText}
      tooltip={tooltip}
      priceInfo={priceInfo}
      isMainResult
      priceVariant={priceVariant}
    />
  )
}
