import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import type { ImageTileLink as ImageTileLinkData } from "@onestore-graphql/Model"
import ExpandableContentOrg from "@onestore/hel/dist/components/organisms/ExpandableContentOrg"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import HorizontalScrollItemsOrg from "@onestore/hel/dist/components/organisms/HorizontalScrollItemsOrg"
import ConditionalWrapper from "@onestore/hel/dist/helpers/ConditionalWrapper"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import { sendContentShowEvent } from "~/lib/analytics"
import ImageTileLink from "../ImageTileLink"

interface ImagesTileLinkProps {
  imagesTileLink: ImageTileLinkData[]
  isExpandable?: boolean
  expandableText?: string
}

function ImagesTileLink({
  imagesTileLink,
  isExpandable,
  expandableText,
}: ImagesTileLinkProps): ReactElement<ImagesTileLinkProps> {
  const isSmallScreen = useBreakpoint("small", "medium")
  const isMediumUp = useBreakpoint("medium")
  const isHugeScreen = useBreakpoint("huge")

  return (
    <ConditionalWrapper
      condition={!!isExpandable || !isSmallScreen}
      wrapper={(children) => (
        <ExpandableContentOrg
          heightBreakpoint="medium"
          showMoreDetails={{
            text: expandableText || _t("showMore"),
            title: expandableText || _t("showMore"),
            onClick: sendContentShowEvent,
          }}
          showMoreType="link"
          hasCenteredShowMore
        >
          {children}
        </ExpandableContentOrg>
      )}
    >
      <ConditionalWrapper
        condition={isSmallScreen || isMediumUp}
        wrapper={(children) => {
          if (isSmallScreen) {
            return (
              <HorizontalScrollItemsOrg gutterSpace={3} children={children} />
            )
          }

          return (
            <GridOrg
              columns={{ small: 2, medium: 2, large: isHugeScreen ? 4 : 3 }}
              gutterSpace={3}
            >
              {children}
            </GridOrg>
          )
        }}
      >
        {imagesTileLink.map((imageTileLink, index) => (
          <ImageTileLink
            key={`image-tile-link-${index}`}
            imageTileLink={imageTileLink}
            imageTileLinkIndex={index}
          />
        ))}
      </ConditionalWrapper>
    </ConditionalWrapper>
  )
}

export default ImagesTileLink
