import type { ReactElement } from "react"
import React from "react"
import { createContext, useState } from "react"

type HeaderContextProps = {
  headerHeight: number
  setHeaderHeight: { (height: number): void }
  headerInitHeight: number
  setHeaderInitHeight: { (height: number): void }
  hasHeaderBottomBar: boolean
}

type HeaderContextProviderProps = {
  children: ReactElement
  hasHeaderBottomBar: boolean
}

export const HeaderContext = createContext<HeaderContextProps>({
  headerHeight: 0,
  headerInitHeight: 0,
  setHeaderHeight: () => {},
  setHeaderInitHeight: () => {},
  hasHeaderBottomBar: false,
})

export function HeaderContextProvider({
  children,
  hasHeaderBottomBar,
}: HeaderContextProviderProps) {
  const [headerHeight, setHeaderHeight] = useState(0)
  const [headerInitHeight, setHeaderInitHeight] = useState(0)

  return (
    <HeaderContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        headerInitHeight,
        setHeaderInitHeight,
        hasHeaderBottomBar,
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export default HeaderContext
