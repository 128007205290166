import type { UseQueryResult } from "@tanstack/react-query"
import { useQuery } from "@tanstack/react-query"
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import axios from "axios"
import { isEmpty } from "lodash"
import "whatwg-fetch"
import type { z } from "zod"
import type {
  CompanyData,
  PersonData,
  UserResponse,
  PatchUserResponse,
} from "@onestore/api/account"
import type {
  Basket,
  BasketResponse,
  BasketParamsResponse,
} from "@onestore/api/basket"
import type { DomainCheck } from "@onestore/api/domainSearch"
import type {
  CompanyResponse,
  UserRegistrationResponse,
} from "@onestore/onestore-store-common/api/shapes"
import { CompanyResponseSchema } from "@onestore/onestore-store-common/api/shapes"
import { captureErrorMessage } from "@onestore/onestore-store-common/debug"
import { HTTP_STATUS } from "@onestore/onestore-store-common/http"
import { CheckoutConflictTypes } from "@gatsby-plugin-checkout/store/constants"
import { DomainBundleHook } from "@gatsby-plugin-domain-search/hooks/useMainDomainBundleQuery"
import { EmptyTokenException } from "~/lib/exceptions"
import Storage, { STORAGE } from "~/lib/storage"
import LocalStorage from "~/lib/storage/LocalStorage"
import SessionStorage from "~/lib/storage/SessionStorage"
import { sanitizePhrase } from "../../api"
import type {
  CalculateBasketPayload,
  ProductPriceResponse,
  Query,
  JSONResponse,
  NoContentResponse,
  CheckDomainsResponse,
  CheckMainDomainResponse,
  CheckoutResponse,
  CheckPmailResponse,
  CheckSuggestionsResponse,
  CheckUserPasswordResponse,
  InvoiceOrderApiResponse,
  JSONPatch,
  TermResponse,
  UserDataResponse,
  FetchCalculateProductsPayload,
  FetchCalculateProductsResponse,
  CheckMicrosoftSubdomainResponse,
  MicrosoftAccountCheckInput,
} from "../../api/types"
import {
  appVersionInterceptor,
  actionsInterceptor,
  clearSessionIdInterceptor,
  apiRequestInterceptor,
} from "./interceptors"

export const PRODUCT_CALCULATE_CACHE_TTL = 60 * 5

export const HEADER_APP_VERSION = "x-app-version"
export const HEADER_ACTIONS = "x-app-actions"

type AiRecommendationsMutationRequestBody = {
  prompt: string
  tlds: string[]
  model?: string
}

function checkToken(token: Basket.Token): void {
  if (undefined === token || token === null || token === "") {
    throw new EmptyTokenException()
  }
}

export function urlWithBundleQueryString(
  url: string,
  bundleDefinitions: DomainBundleHook[]
): string {
  if (bundleDefinitions.length === 0) {
    return url
  }

  const queryParts: string[] = []

  for (const bundle of bundleDefinitions) {
    for (const extension of bundle.extensions) {
      queryParts.push(`bundles[${bundle.id}][]=${extension}`)
    }
  }

  return `${url}?${queryParts.join("&")}`
}

export interface ApiConfig {
  useSession?: boolean
}

export type LegacyApiErrorsError = {
  code: number
  errors: string[] | string
  visible?: boolean
}

export type LegacyApiSingleErrors = {
  error: {
    code: number
    message: string
    visible?: boolean
  }
}

export type LegacyApiRegistrationConflictError = {
  type: string
}

export type LegacyApiFormValidationError = {
  form: unknown // Form validation result can differ between requests
  errors: string[]
}

type GenericApiProblem = {
  type: "https://home.pl/onestore/generic-error"
  title: string
  status: number
  detail?: string
  errors: string[]
  visible?: boolean
}

export type FormValidationProblem = {
  type: "https://home.pl/onestore/form-validation-error"
  title: string
  status: number
  detail?: string
  form: unknown
  errors: string[]
}

type AddressConfirmationProblem = {
  type: "https://home.pl/onestore/address-confirmation-needed"
  title: string
  status: number
}

type ApiProblemResponse =
  | GenericApiProblem
  | FormValidationProblem
  | AddressConfirmationProblem

export function createApi(apiHost: string, apiConfig: ApiConfig = {}) {
  const axiosClient = axios.create({})

  axiosClient.interceptors.request.use(apiRequestInterceptor(apiConfig))
  axiosClient.interceptors.response.use(appVersionInterceptor)
  axiosClient.interceptors.response.use(actionsInterceptor)
  axiosClient.interceptors.response.use(clearSessionIdInterceptor)

  async function fetchApiResponse<T>(
    url: string,
    config: AxiosRequestConfig
  ): Promise<T> {
    const response: AxiosResponse = await axiosClient.request({
      baseURL: apiHost,
      url,
      ...config,
    })

    return response.data
  }

  const apiUrl = (path: string, query: Query = {}) => {
    const url = new URL(path, apiHost)

    Object.entries(query).forEach(([key, value]) => {
      if (value) {
        url.searchParams.append(key, value)
      }
    })

    return url.toString()
  }

  function validateSchema<T extends z.Schema>(
    dto: unknown,
    schema: T
  ): z.infer<T> {
    const { data, success, error } = schema.safeParse(dto)

    if (success) {
      return data
    } else {
      captureErrorMessage(`API Validation Error`, {
        dto: dto,
        error: error.message,
        issues: error.issues,
      })

      throw error
    }
  }

  async function fetchValidated<T extends z.Schema>(
    url: string,
    schema: T,
    params: AxiosRequestConfig
  ): Promise<z.infer<T>> {
    const res = await fetchApiResponse(url, params)

    return validateSchema<T>(await res, schema)
  }

  const checkMainDomain = (
    phrase: DomainCheck.Phrase,
    bundleExtensions: DomainBundleHook[],
    extension: DomainCheck.Extension | null = null
  ): Promise<CheckMainDomainResponse> =>
    fetchApiResponse(
      urlWithBundleQueryString(
        `/domains/${sanitizePhrase(phrase)}/search/`,
        bundleExtensions
      ),
      {
        params: {
          extension,
        },
      }
    )

  const getCompanyInfo = (
    countryCode: string,
    companyNumber: string
  ): Promise<CompanyResponse> =>
    fetchValidated<typeof CompanyResponseSchema>(
      apiUrl(`/companies/${countryCode}/${companyNumber}`),
      CompanyResponseSchema,
      {}
    )

  const checkMainDomainFromPool = (
    phrase: DomainCheck.Phrase,
    pool: DomainCheck.PoolId,
    bundles: DomainBundleHook[],
    extension: DomainCheck.Extension | null = null
  ): Promise<CheckMainDomainResponse> =>
    fetchApiResponse(
      urlWithBundleQueryString(
        `/domains/${sanitizePhrase(phrase)}/search/pools/${pool}/`,
        bundles
      ),
      {
        params: {
          extension,
        },
      }
    )

  const checkDomains = (domains: string[]): Promise<CheckDomainsResponse> =>
    fetchApiResponse(`/domains/search`, {
      params: {
        domains: domains.join(","),
      },
    })

  const checkPool = (
    phrase: DomainCheck.Phrase,
    pool: DomainCheck.PoolId
  ): Promise<DomainCheck.Result[]> =>
    fetchApiResponse(`/domains/${sanitizePhrase(phrase)}/pools/${pool}`, {
      params: {
        available: "1",
      },
    })

  const checkPMail = (
    phrase: DomainCheck.Phrase
  ): Promise<CheckPmailResponse> =>
    fetchApiResponse(`/pmails/${sanitizePhrase(phrase)}/check`, {})

  const checkSuggestions = (
    phrase: DomainCheck.Phrase
  ): Promise<CheckSuggestionsResponse> =>
    fetchApiResponse(`/domains/${sanitizePhrase(phrase)}/suggestions`, {})

  const checkSuggestionsForExtension = (
    phrase: DomainCheck.Phrase,
    extension: DomainCheck.Extension
  ): Promise<CheckSuggestionsResponse> =>
    fetchApiResponse(
      `/domains/${sanitizePhrase(phrase)}/suggestions/extensions/${extension}`,
      {}
    )

  const loadUserData = (
    token: Basket.Token = ""
  ): Promise<UserDataResponse> => {
    const userData: Promise<UserDataResponse> = fetchApiResponse("/userdata", {
      params: {
        allow: "true",
        token,
      },
    }) as Promise<UserDataResponse>

    userData.then((response: UserDataResponse): any => {
      LocalStorage.set(
        STORAGE.BASKET_INFO,
        JSON.stringify({
          [STORAGE.BASKET_INFO_CNT]: response.basket.items_count,
          [STORAGE.BASKET_INFO_NET_PRICE]: response.basket.total_net_price,
          [STORAGE.BASKET_INFO_VAT_VALUE]: response.basket.total_vat_value,
        })
      )
    })

    return userData
  }

  const registerUser = (
    user: CompanyData | PersonData,
    token: Basket.Token
  ): Promise<UserRegistrationResponse> => {
    checkToken(token)

    return fetchApiResponse(`/baskets/${token}/users`, {
      method: "POST",
      data: {
        ...user,
        electronic_invoice: true, // TODO ONESTORE-5907 - wycofać z API to pole po przeniesieniu strefy
      },
    })
  }

  const basketPatch = (
    token: Basket.Token,
    data: JSONPatch[],
    showDomainUpsell: boolean = false,
    headers: Record<string, string> = {}
  ): Promise<BasketResponse> => {
    checkToken(token)

    return fetchApiResponse(`/baskets/${token}`, {
      method: "PATCH",
      data,
      headers: {
        [`onestore-sig`]: Storage.getSig(),
        ...headers,
      },
      params: {
        show_domain_upsell: showDomainUpsell ? "1" : "0",
        show_upsell: "0",
      },
    }).then((response: BasketResponse): BasketResponse => {
      LocalStorage.set(
        STORAGE.BASKET_INFO,
        JSON.stringify({
          [STORAGE.BASKET_INFO_CNT]: response.items_count,
          [STORAGE.BASKET_INFO_NET_PRICE]: response.total_net_price,
          [STORAGE.BASKET_INFO_VAT_VALUE]: response.total_vat_value,
        })
      )

      return response
    })
  }

  const basketGet = (
    token: Basket.Token,
    showUpsell: boolean = true
  ): Promise<BasketResponse> => {
    checkToken(token)

    return fetchApiResponse(`/baskets/${token}`, {
      params: {
        show_domain_upsell: showUpsell ? "1" : "0",
        show_upsell: "0",
      },
    })
  }

  const checkUserPassword = (
    token: Basket.Token,
    login: string,
    password: string,
    rememberMe: boolean = false
  ): Promise<CheckUserPasswordResponse> => {
    checkToken(token)

    return fetchApiResponse(`/baskets/${token}/user`, {
      method: "PUT",
      data: { login, password, remember_me: rememberMe },
    })
  }

  const checkout = (
    token: Basket.Token,
    params: any
  ): Promise<CheckoutResponse> => {
    checkToken(token)

    return fetchApiResponse(`/baskets/${token}/checkout`, {
      headers: {
        "onestore-sig": Storage.getSig(),
      },
      method: "PUT",
      data: params,
    })
  }

  const getUser = (token: Basket.Token): Promise<UserResponse> => {
    checkToken(token)

    return fetchApiResponse(`/baskets/${token}/user`, {
      method: "GET",
      headers: {
        [`onestore-sig`]: Storage.getSig(),
      },
    })
  }

  const patchUser = (
    token: Basket.Token,
    data: Partial<CompanyData> | Partial<PersonData>
  ): Promise<PatchUserResponse> => {
    checkToken(token)

    return fetchApiResponse(`/baskets/${token}/user`, {
      headers: {
        "onestore-sig": Storage.getSig(),
      },
      method: "PATCH",
      data: data,
    })
  }

  const getTerms = (token: Basket.Token): Promise<TermResponse> => {
    checkToken(token)

    return fetchApiResponse(`/baskets/${token}/terms`, {
      method: "GET",
      headers: {
        [`onestore-sig`]: Storage.getSig(),
      },
    })
  }

  /**
   * Pobranie parametrów potrzebnych dla elementów w koszyku
   *
   * @param {string} token Token koszyka.
   *
   * @returns {Promise}
   */
  const getBasketParams = (
    token: Basket.Token
  ): Promise<BasketParamsResponse> =>
    fetchApiResponse(`/baskets/${token}/params`, {
      method: "GET",
    })

  const checkMicrosoftSubdomainOwnership = (
    token: Basket.Token,
    account: MicrosoftAccountCheckInput
  ): Promise<CheckMicrosoftSubdomainResponse> =>
    fetchApiResponse(`/baskets/${token}/microsoft/check_ownership`, {
      method: "POST",
      data: account,
    }) as Promise<CheckMicrosoftSubdomainResponse>

  const saveNps = (
    token: Basket.Token,
    value: number,
    comment: string = ""
  ): Promise<JSONResponse> => {
    checkToken(token)

    return fetchApiResponse(`/baskets/${token}/nps`, {
      method: "PUT",

      data: {
        value,
        comment,
      },
    })
  }

  const calculateBasket = (
    items: CalculateBasketPayload
  ): Promise<BasketResponse> =>
    fetchApiResponse<BasketResponse>("/baskets/calculate", {
      method: "POST",
      data: items,
    })

  const calculateProduct = (
    items: CalculateBasketPayload
  ): Promise<ProductPriceResponse> =>
    fetchApiResponse<ProductPriceResponse>("/products/calculate", {
      method: "POST",
      data: items,
    })

  const deleteSession = (
    token: string | null = null
  ): Promise<NoContentResponse> =>
    fetchApiResponse("/session", {
      method: "DELETE",
      params: {
        token,
      },
    })

  async function calculateProductWithCache(
    items: CalculateBasketPayload,
    useCache: boolean = true
  ) {
    if (!useCache) {
      return await calculateProduct(items)
    }
    const key = `_api_${JSON.stringify(items)}`

    const data = SessionStorage.getSerialized(key, {})

    if (!isEmpty(data)) {
      return data
    }

    const response = await calculateProduct(items)

    SessionStorage.setSerialized(key, response, PRODUCT_CALCULATE_CACHE_TTL)

    return response
  }

  const invoicesOrder = (
    type: string,
    terms: boolean,
    captcha: string,
    from?: string,
    to?: string,
    accountId?: string,
    email?: string
  ): Promise<InvoiceOrderApiResponse> =>
    fetchApiResponse<InvoiceOrderApiResponse>("/invoices/order", {
      method: "POST",
      data: {
        accountId,
        email,
        type,
        terms,
        from,
        to,
        captcha,
      },
    })

  const fetchCities = async ({ queryKey }): Promise<Array<string>> => {
    const countryCode = queryKey[1]
    const postcode = queryKey[2]

    if (!countryCode || !postcode) {
      return []
    }

    return fetchApiResponse(`/postcode/cities/${countryCode}/${postcode}`, {})
  }

  const useCitiesQuery = (countryCode: string, postcode: string) => {
    return useQuery({
      queryKey: ["cities", countryCode, postcode],
      queryFn: fetchCities,
      staleTime: Infinity,
    })
  }

  const fetchCalculateProducts = ({
    queryKey,
  }): Promise<ProductPriceResponse> => {
    const productsData = queryKey[1]

    if (productsData === null) {
      return new Promise((resolve) => {
        resolve({} as ProductPriceResponse)
      })
    }

    return fetchApiResponse(`/calculate/multiple`, {
      method: "POST",
      data: productsData,
    })
  }

  const useCalculateProductsQuery = (
    productsData: FetchCalculateProductsPayload | null
  ): UseQueryResult<FetchCalculateProductsResponse> => {
    return useQuery({
      queryKey: ["products", productsData],
      queryFn: fetchCalculateProducts,
      staleTime: Infinity,
    })
  }

  const aiRecommendationsMutation = (
    data: AiRecommendationsMutationRequestBody
  ): Promise<DomainCheck.Results> => {
    return fetchApiResponse(`/domains/ai-recommendations`, {
      method: "POST",
      data,
    })
  }

  const domainSearchMutation = (
    domains: DomainCheck.FQDN[]
  ): Promise<DomainCheck.Results> => {
    return fetchApiResponse(`/domains/search`, {
      method: "POST",
      data: domains,
    })
  }

  /**
   * Function normalizes current api errors to one formatin the future API responses will match normalized errors.
   */
  const getNormalizedError = (
    error: AxiosError<
      | LegacyApiErrorsError
      | LegacyApiSingleErrors
      | LegacyApiRegistrationConflictError
      | LegacyApiFormValidationError
    >
  ): ApiProblemResponse => {
    const data:
      | LegacyApiErrorsError
      | LegacyApiSingleErrors
      | LegacyApiFormValidationError
      | LegacyApiRegistrationConflictError
      | {} = error.response?.data || {}

    if (
      "type" in data &&
      data.type === CheckoutConflictTypes.ADDRESS_CONFIRMATION_NEEDED
    ) {
      return {
        type: "https://home.pl/onestore/address-confirmation-needed",
        title: "Address confirmation needed",
        status: error.status || HTTP_STATUS.CONFLICT,
      }
    }

    if ("form" in data) {
      return {
        type: "https://home.pl/onestore/form-validation-error",
        title: "Validation error",
        detail: error.message,
        status: error.status || HTTP_STATUS.INTERNAL_SERVER_ERROR,
        form: data.form,
        errors: data.errors,
      }
    }

    if ("error" in data) {
      return {
        type: "https://home.pl/onestore/generic-error",
        title: "API error",
        detail: data.error.message,
        status: error.status || HTTP_STATUS.INTERNAL_SERVER_ERROR,
        errors: [data.error.message],
      }
    }

    if ("errors" in data) {
      return {
        type: "https://home.pl/onestore/generic-error",
        title: "API error",
        status: error.status || HTTP_STATUS.INTERNAL_SERVER_ERROR,
        errors: Array.isArray(data.errors) ? data.errors : [data.errors],
      }
    }

    return {
      type: "https://home.pl/onestore/generic-error",
      title: "API error",
      detail: error.message,
      status: HTTP_STATUS.INTERNAL_SERVER_ERROR,
      errors: [],
    }
  }

  return {
    isApiError: axios.isAxiosError,
    getNormalizedError,
    apiUrl,
    calculateBasket,
    saveNps,
    checkMicrosoftSubdomainOwnership,
    getBasketParams,
    getTerms,
    patchUser,
    getUser,
    checkout,
    getCompanyInfo,
    registerUser,
    checkMainDomain,
    checkMainDomainFromPool,
    basketPatch,
    basketGet,
    checkUserPassword,
    checkDomains,
    checkPool,
    checkPMail,
    checkSuggestions,
    checkSuggestionsForExtension,
    loadUserData,
    deleteSession,
    invoicesOrder,
    calculateProduct: calculateProductWithCache,
    useCalculateProductsQuery,
    useCitiesQuery,
    aiRecommendationsMutation,
    domainSearchMutation,
  }
}
