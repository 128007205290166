import type { ReactNode, ReactElement } from "react"
import React, { useEffect } from "react"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import type Theme from "@onestore/hel/dist/typings/theme"
import { useFormHook } from "~/hooks/useFormHook"
import FormBuilder from "../Form/FormBuilder"
import ModalTemplateWrapper from "../ModalTemplateWrapper"

export interface FormModalProps {
  id: string
  headingText?: ReactNode
  additionalData?: Record<string, string>
  button?: {
    title: string
    text: string
    buttonColor: { color: Theme.AccentColor }
  }
  onCloseButtonClick?: { (): void }
}

export default function FormModal({
  id,
  headingText,
  additionalData = {},
  button,
  onCloseButtonClick,
}: FormModalProps): ReactElement<FormModalProps> {
  const { isLoading, result, fetchData } = useFormHook(id)

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <ModalTemplateWrapper
      onCloseButtonClick={onCloseButtonClick}
      width="narrow"
      isLoading={isLoading}
      buttons={[]}
      isOpen
      content={
        <>
          {headingText ? (
            <TextAtm typography="xlarge" pushSpace={4}>
              {headingText}
            </TextAtm>
          ) : null}

          <FormBuilder
            form={{
              data: result,
              buttonTitle: button?.title,
              buttonText: button?.text,
              buttonColor: button?.buttonColor.color,
            }}
            name={id}
            additionalData={additionalData}
            onSuccess={() => {
              onCloseButtonClick &&
                setTimeout(() => {
                  onCloseButtonClick()
                }, 1000)
            }}
          />
        </>
      }
    />
  )
}
