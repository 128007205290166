import type { BasketResponseAction } from "@gatsby-plugin-basket/store/actions"
import { BasketActions } from "@gatsby-plugin-basket/store/constants"
import { BonusProductActionsType } from "@gatsby-plugin-bonus/store/actions-product"
import { BonusUpsellActionType } from "@gatsby-plugin-bonus/store/actions-upsell"
import { CheckoutActions } from "@gatsby-plugin-checkout/store/constants"
import {
  basketAddItemsResultsEvent,
  basketItemRemoved,
  changePaymentMethod,
  orderProceedEvent,
  productBoxClickEvent,
  basketAddPromoCodeEvent,
  basketRemovePromoCodeEvent,
} from "~/lib/analytics"

const middleware = () => (next) => (action: BasketResponseAction) => {
  if (action.type === BasketActions.BASKET_CHANGE_PAYMENT) {
    changePaymentMethod(
      action.value,
      action.payment_type,
      action.system_id,
      action.basketOrderedItems,
      action.cart_id
    )
  }

  if (action.type === BasketActions.BASKET_ITEM_REMOVED) {
    basketItemRemoved(
      action.cart_id,
      action.basketOrderedItems,
      action.id,
      action.item_id ? action.item_id : action.name,
      action.name,
      action.price,
      action.quantity,
      action.affiliation,
      action.item_category,
      action.item_variant
    )
  }

  if (action.type === CheckoutActions.PAYMENT_SUCCESS) {
    const paymentMethod = action.paymentMethod

    const systemId = action.paymentMethods.find(
      (method) => method.id === paymentMethod
    )?.system_id

    orderProceedEvent(
      action.result.number,
      action.basket.totalValue,
      action.basketOrderedItems,
      action.basket.token,
      paymentMethod,
      systemId
    )
  }

  if (action.type === BasketActions.BASKET_SUCCESS) {
    basketAddItemsResultsEvent(
      action.result,
      action.basketOrderedItems,
      action.source
    )
  }

  if (action.type === BasketActions.BASKET_ADD_PROMO_CODE) {
    basketAddPromoCodeEvent(action.result)
  }

  if (action.type === BasketActions.BASKET_REMOVE_PROMO_CODE) {
    basketRemovePromoCodeEvent(action.result)
  }

  if (action.type === BonusProductActionsType.BONUS_CHANGE_PERIOD_CLICK) {
    productBoxClickEvent({
      productbox_type: "select",
      productbox_option: "period",
      productbox_checked_item: action.period.id,
      productboxbox_time: action.period.period_name,
    })
  }

  if (action.type === BonusUpsellActionType.BONUS_UPSELL_CHANGE_PERIOD_CLICK) {
    productBoxClickEvent({
      productbox_type: "select",
      productbox_option: "period",
      productbox_checked_item: action.upsell.planId,
    })
  }

  return next(action)
}

export default middleware
