import type { SyntheticEvent } from "react"

export function scrollToAnchor(anchor: string): void {
  const element = document.getElementById(anchor.replace("#", ""))

  if (!element) {
    return
  }

  element.scrollIntoView({
    behavior: "smooth",
  })
}

export function animatedScrollToAnchor(
  anchor: string,
  duration: number = 600
): void {
  const element = document.getElementById(anchor.replace("#", ""))

  if (!element) {
    return
  }

  const offset = 400
  const start = window.scrollY
  const end =
    element.offsetTop - window.innerHeight / 2 + element.offsetHeight / 2
  const startTime = performance.now()
  let animationId: number | null = null

  if (start + offset > end) {
    return
  }

  function animate(currentTime: number) {
    const elapsed = currentTime - startTime
    const progress = Math.min(elapsed / duration, 1)
    const ease = 1 - Math.pow(1 - progress, 3)
    const newPosition = start + (end - start) * ease

    window.scrollTo(0, newPosition)

    if (progress < 1) {
      animationId = requestAnimationFrame(animate)
    } else {
      element?.focus()
    }
  }

  function stopAnimation() {
    if (animationId) {
      cancelAnimationFrame(animationId)
      animationId = null
    }
  }

  window.addEventListener("wheel", stopAnimation, { once: true })
  window.addEventListener("touchmove", stopAnimation, { once: true })

  animationId = requestAnimationFrame(animate)

  setTimeout(() => {
    stopAnimation()
  }, duration + 100)
}

export type AnchorProperties = {
  href: string
  onClick: { (event: SyntheticEvent) }
}

export function getAnchorProperties(anchor: string): AnchorProperties {
  const href: string = `#${anchor}`
  const onClick = (event: SyntheticEvent): void => {
    event.preventDefault()

    scrollToAnchor(anchor)
  }

  return {
    href,
    onClick,
  }
}
