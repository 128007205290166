import React from "react"
import type { ReactElement } from "react"
import _t from "@core/i18n"
import FiltersIcon from "@onestore-icons/filters-icon.svg"
import PlusIcon from "@onestore-icons/plus-vps-icon.svg"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import DividerAtm from "@onestore/hel/dist/components/atoms/DividerAtm"
import IconButtonAtm from "@onestore/hel/dist/components/atoms/IconButtonAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import ConfigBoxMol from "@onestore/hel/dist/components/molecules/ConfigBoxMol"
import OverlayContainerOrg, {
  headerTypes,
  sizes,
  startPositions,
} from "@onestore/hel/dist/components/organisms/OverlayContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import type { CloudblueResourceCategory } from "@gatsby-plugin-generic-page/fragments/cloudblueResourceCategory"
import { useConfigsHookContext } from "@gatsby-plugin-generic-page/sections/TableHorizontalBoxesSection"
import useModalState from "~/hooks/useModalState"
import isEmpty from "~/lib/isEmpty"
import ResourceConfigElement from "./ResourceConfigElement"

type ResourcesModalConfigBoxProps = {
  label: string
  text?: string
  resourcesCategories: CloudblueResourceCategory[]
}

const mobileContentHorizontalMargin = {
  small: 2,
  medium: 0,
  large: 0,
}

const ResourcesModalConfigBox = ({
  label,
  text,
  resourcesCategories,
}: ResourcesModalConfigBoxProps): ReactElement<ResourcesModalConfigBoxProps> => {
  const { isOpen, open, close } = useModalState()

  const {
    configsValues,
    handleResourcesConfirm,
    handleResourcesCancel,
    isCalculateProductsLoading,
  } = useConfigsHookContext() ?? {}

  const resourcesConfigsElements = resourcesCategories.map(
    (resourceCategory, index) => (
      <PushOrg topSpace={3}>
        {index !== 0 ? (
          <PushOrg bottomSpace={3} hasNoReset>
            <DividerAtm type="solid" marginSpace={0} emphasis="extraLow" />
          </PushOrg>
        ) : null}

        <ResourceConfigElement resourceCategoryData={resourceCategory} />
      </PushOrg>
    )
  )

  const handleModalConfirmButtonClick = () => {
    if (handleResourcesConfirm) {
      handleResourcesConfirm()
    }

    close()
  }

  const handleModalClose = () => {
    if (handleResourcesCancel) {
      handleResourcesCancel()
    }

    close()
  }

  const hasResources =
    !isEmpty(configsValues) && configsValues.resources.length > 0

  return (
    <>
      <ConfigBoxMol
        label={label}
        text={text}
        onClick={open}
        configElement={
          <IconButtonAtm
            icon={hasResources ? FiltersIcon : PlusIcon}
            text={hasResources ? _t("vps.edit") : undefined}
            onClick={() => null}
            sizeSpace={2.5}
            backgroundColor="backgroundLight"
            borderRadius="mlarge"
            emphasis="extraLow"
            paddingSpace={1.5}
            iconColor={hasResources ? "success" : "primary"}
            borderColor={hasResources ? "success" : undefined}
            hasBorder
            hasTextToRight
            hasShadowOnHover
          />
        }
      />

      {isOpen ? (
        <OverlayContainerOrg
          header={
            <TextAtm
              typography={{
                small: "big2",
                medium: "big2",
                large: "large2",
              }}
            >
              {_t("vps.additionalResources")}
            </TextAtm>
          }
          onCloseButtonClick={handleModalClose}
          backgroundColor="background"
          startPosition={startPositions.RIGHT}
          size={sizes.MEDIUM}
          headerType={headerTypes.SHADOWED}
          footer={
            <ButtonAtm
              color="dark"
              size="big"
              isExpanded
              title={_t("vps.confirm")}
              onClick={handleModalConfirmButtonClick}
              isLoading={isCalculateProductsLoading}
              isDisabled={isCalculateProductsLoading}
            >
              {_t("vps.confirm")}
            </ButtonAtm>
          }
        >
          <PushOrg
            leftSpace={mobileContentHorizontalMargin}
            rightSpace={mobileContentHorizontalMargin}
            hasNoReset
          >
            {resourcesConfigsElements}
          </PushOrg>
        </OverlayContainerOrg>
      ) : null}
    </>
  )
}

export default ResourcesModalConfigBox
