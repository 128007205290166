import { find, get, isEmpty } from "lodash"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import { parsePeriod } from "../../lib/api"
import type { RemoteProduct } from "../../lib/api/product"
import type { PeriodName } from "../../lib/api/types"

export const getPeriodByName = (
  periods: CloudBluePeriod[],
  chosenPeriod: PeriodName
) => {
  return find(periods, (period) => period.period_name === chosenPeriod)
}

export function getSelectedPeriod(
  product: RemoteProduct | CloudBluePlan["flatData"],
  periodName?: PeriodName | null
): CloudBluePeriod {
  const periods = get(product, "periods", [])

  if (!isEmpty(periodName)) {
    const result = periods
      .filter((item: CloudBluePeriod) => item.period_name === periodName)
      .shift()

    if (result) {
      return { ...result }
    }
  }

  const defaultResult = periods.find((item: CloudBluePeriod) => item.default)

  if (defaultResult) {
    return { ...defaultResult }
  }

  return { ...periods[0] }
}

export function getMainProduct(
  plan: CloudBluePlan["flatData"],
  bundle: CloudBluePlan["flatData"][]
): CloudBluePlan["flatData"] {
  return bundle.length > 0 ? [...bundle].shift() || plan : plan
}

export function getBundledProduct(
  bundle: CloudBluePlan["flatData"][]
): CloudBluePlan["flatData"] | null {
  return (bundle.length > 0 ? [...bundle].pop() : null) || null
}

export function sortPlanPeriods(
  periods: CloudBluePeriod[] | Record<number, CloudBluePeriod>
): CloudBluePeriod[] {
  const data = Array.isArray(periods) ? periods : Object.values(periods)

  return [...data].sort((a, b) => {
    const periodA = parsePeriod(a.period_name)
    const periodB = parsePeriod(b.period_name)

    if (periodA.periodType !== periodB.periodType) {
      return periodA.periodType < periodB.periodType ? -1 : 1
    }

    if (periodA.periodValue !== periodB.periodValue) {
      return periodA.periodValue < periodB.periodValue ? -1 : 1
    }

    return 0
  })
}
