import type { Draft } from "immer"
import { produce } from "immer"
import type { BonusEmailHackActions } from "@gatsby-plugin-bonus/store/actions-email-hack"
import { BonusEmailHackActionType } from "@gatsby-plugin-bonus/store/actions-email-hack"
import { initialState } from "@gatsby-plugin-bonus/store/selectors"
import type { BonusState } from "@gatsby-plugin-bonus/types"

export default function reducer(
  state: BonusState = initialState,
  action: BonusEmailHackActions
) {
  return produce(state, (nextState: Draft<BonusState>) => {
    switch (action.type) {
      case BonusEmailHackActionType.BONUS_EMAIL_HACK_HIDE_UPSELL:
        nextState.hideUpsell = action.hideUpsell

        return

      case BonusEmailHackActionType.BONUS_ADD_EMAIL_HACK_DATA:
        nextState.emailHack = action.emailHack

        return
    }
  })
}
