import { graphql, useStaticQuery } from "gatsby"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import type { SquidexMarkdownEmbeddedContent } from "~/types/squidex"

type EmailHackConfigurationType = {
  enabled: boolean | undefined
  emailHackPlan: CloudBluePlan[] | undefined
  emailHackBlackList: CloudBluePlan[] | undefined
  pageTitle: string | undefined
  boxTitle: string | undefined
  boxText: string | undefined
  bannerText: SquidexMarkdownEmbeddedContent | undefined
  basketText: string | undefined
}

export type EmailHackState = Pick<
  EmailHackConfigurationType,
  "enabled" | "basketText"
> & {
  emailHackPlanId: CloudBluePlan["flatData"]["id"] | undefined
}

type Query = {
  GraphCmsSquidex: {
    pages: {
      flatData: EmailHackConfigurationType
    }
  }
}

export default function useEmailHackConfigurationQuery(): EmailHackConfigurationType {
  const {
    GraphCmsSquidex: { pages },
  } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        pages: findEmailHackConfigurationSingleton {
          flatData {
            enabled
            emailHackPlan {
              ...CloudBluePlan
            }
            emailHackBlackList {
              ...CloudBluePlan
            }
            pageTitle
            boxTitle
            boxText
            bannerText {
              text
              contents {
                ... on GraphCmsSquidex_StaticPrice {
                  ...StaticPrice
                }
                ... on GraphCmsSquidex_MarketplaceSaleConfiguration {
                  ...SaleConfigurations
                }
                ... on GraphCmsSquidex_CloudblueDomain {
                  ...CloudBlueDomain
                }
              }
            }
            basketText
          }
        }
      }
    }
  `)

  return {
    enabled: pages.flatData.enabled,
    emailHackPlan: pages.flatData.emailHackPlan,
    emailHackBlackList: pages.flatData.emailHackBlackList,
    pageTitle: pages.flatData.pageTitle,
    boxTitle: pages.flatData.boxTitle,
    boxText: pages.flatData.boxText,
    bannerText: pages.flatData.bannerText,
    basketText: pages.flatData.basketText,
  }
}
