import type { ReactElement, ReactNode } from "react"
import React from "react"
import _t from "@core/i18n"
import type { UsePrices } from "@core/period-info"
import { PeriodInfo } from "@core/period-info"
import { typeMatches } from "@onestore-graphql"
import { omit } from "lodash"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import ProductListItemMol from "@onestore/hel/dist/components/molecules/ProductListItemMol/ProductListItemMol"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import WidthOrg from "@onestore/hel/dist/components/organisms/WidthOrg"
import type { LinkProps } from "@onestore/hel/dist/components/quarks/Link"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import BaseMarkdown from "@gatsby-plugin-generic-page/components/Markdown/Base"
import { CustomTextColors } from "@gatsby-plugin-generic-page/fragments/customColor"
import { ModalItemProductType } from "@gatsby-plugin-generic-page/fragments/modalItem"
import type { PromoTileFlatData } from "@gatsby-plugin-generic-page/fragments/promoTile"
import getBonusUrl from "@gatsby-plugin-generic-page/helpers/getBonusUrl"
import getLinkProps from "@gatsby-plugin-generic-page/helpers/getLinkProps"
import getResponsiveBackgroundImage from "@gatsby-plugin-generic-page/helpers/getResponsiveBackgroundImage"
import {
  getStaticLowestPrice,
  getStaticPrices,
} from "@gatsby-plugin-generic-page/helpers/staticPrices"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import { sendGaEvent } from "~/lib/analytics"
import isEmpty from "~/lib/isEmpty"

export function PromoTile({
  title,
  description,
  responsiveImages,
  productType,
  productSaleConfiguration,
  domain,
  staticPrice,
  alternativeText,
  link,
  textColorType,
  gaEvent,
}: PromoTileFlatData): ReactElement<PromoTileFlatData> {
  const backgroundImage = getResponsiveBackgroundImage(
    responsiveImages,
    undefined,
    true
  )
  const textColor = !isEmpty(textColorType)
    ? CustomTextColors[textColorType]
    : CustomTextColors.ciemny
  const { currentPriceType, isNettoPriceType } = usePriceTypeContext()
  const externalLink = !isEmpty(link)
  const hasProduct =
    typeMatches(productType, ModalItemProductType.PLAN) &&
    !isEmpty(productSaleConfiguration) &&
    !isEmpty(productSaleConfiguration[0].flatData)
  const hasDomain =
    typeMatches(productType, ModalItemProductType.DOMAIN) && !isEmpty(domain)
  const hasStaticPrice =
    typeMatches(productType, ModalItemProductType.STATIC_PRICE) &&
    !isEmpty(staticPrice)
  const hasIndividualPrice =
    typeMatches(productType, ModalItemProductType.INDIVIDUAL_PRICE) &&
    !isEmpty(alternativeText)
  let period: CloudBluePeriod | undefined
  let product: CloudBluePlan
  let prices:
    | Omit<UsePrices, "renewPrice" | "oldPriceValue" | "promotionPercent">
    | undefined
  let periodText: string = ""
  let alternativeContent: ReactNode

  if (hasProduct) {
    product = productSaleConfiguration[0].flatData.plan[0]
    period = product?.flatData.periods.find((period) => {
      if (!isEmpty(productSaleConfiguration[0].flatData.period)) {
        return (
          period.period_name ===
          productSaleConfiguration[0].flatData.period.period
        )
      } else {
        return period.default
      }
    })
  } else if (hasDomain) {
    period = domain[0].flatData.periods.find((period) => period.default)
  } else if (hasStaticPrice) {
    const staticRegisterPrice = getStaticPrices(staticPrice, currentPriceType)
    const staticLowestPrice = getStaticLowestPrice(
      staticPrice,
      currentPriceType
    )

    const periodName = Object.keys(staticRegisterPrice)[0]

    const priceValue = staticRegisterPrice[periodName].price.value

    const lowestPrice = staticLowestPrice
      ? staticLowestPrice[periodName].price
      : priceValue

    const lowestPricePercent = staticLowestPrice
      ? staticLowestPrice[periodName].percent
      : "0%"

    prices = {
      priceValue,
      lowestPrice,
      lowestPricePercent,
    }

    periodText = staticRegisterPrice[periodName].price.suffix
  } else if (hasIndividualPrice) {
    alternativeContent = (
      <TextAtm
        typography={{ large: "large2", medium: "large2", small: "big2" }}
      >
        <BaseMarkdown
          options={{
            wrapper: ({ children }) => (
              <TextAtm typography="nano2">{children}</TextAtm>
            ),
            forceWrapper: true,
          }}
        >
          {alternativeText}
        </BaseMarkdown>
      </TextAtm>
    )
  }

  if (hasProduct || hasDomain) {
    const periodInfo = period ? new PeriodInfo(period) : undefined

    const periodPrices = periodInfo ? periodInfo.usePrices() : undefined

    const priceTypeText = isNettoPriceType
      ? _t("prices.netto")
      : _t("prices.gross")

    const periodInfoText = periodInfo
      ? `${priceTypeText} ${periodInfo.periodText()}`
      : ""

    prices = {
      priceValue: periodPrices?.priceValue || "",
      lowestPrice: periodPrices?.lowestPrice || "",
      lowestPricePercent: periodPrices?.lowestPricePercent || "",
    }

    periodText = periodInfoText
  }

  const descriptionContent = (
    <WidthOrg widthSpace={44}>
      <TextAtm
        typography={{ small: "xhuge", medium: "sgiant", large: "sgiant" }}
        pushSpace={2}
        emphasis="medium"
      >
        {title}
      </TextAtm>

      <TextAtm
        typography={{ small: "medium2_s", medium: "big2", large: "big2" }}
        emphasis="medium"
      >
        {description}
      </TextAtm>
    </WidthOrg>
  )

  const price = (
    <PushOrg bottomSpace={3}>
      <TextAtm typography="medium2_h" emphasis="medium" pushSpace={0.5}>
        {_t("prices.promotion")}
      </TextAtm>

      <TextAtm
        typography={{ small: "xhuge", medium: "sgiant", large: "sgiant" }}
      >
        {prices?.priceValue || ""}
      </TextAtm>

      <TextAtm typography="xsmall1">{periodText}</TextAtm>
    </PushOrg>
  )

  const lowestPrice = (
    <FlexContainerOrg alignItems="center">
      <TextAtm typography="xsmall1">
        {_t("prices.lowerPriceText")}
        <TextAtm typography="xsmall3"> {prices?.lowestPrice}</TextAtm>

        <TextAtm pushSpace={{ left: 0.5 }}>
          <LabelAtm size="small" variant="notifyLight" htmlTag="span">
            {prices?.lowestPricePercent}
          </LabelAtm>
        </TextAtm>
      </TextAtm>
    </FlexContainerOrg>
  )

  let linkElement: Omit<LinkProps, "children"> = {
    href: "",
    to: "",
    title: "",
    openInNewTab: false,
  }

  if (externalLink) {
    linkElement = omit(getLinkProps(link), ["text", "onClick"])
  } else if (hasProduct) {
    linkElement.href = getBonusUrl(
      productSaleConfiguration[0].flatData,
      productSaleConfiguration[0].url,
      currentPriceType
    )
    linkElement.title = title
  }

  linkElement.onClick = () => {
    sendGaEvent(gaEvent)
  }

  return (
    <ProductListItemMol
      descriptionElement={{
        content: descriptionContent,
        backgroundImage,
        textOnColor: textColor,
      }}
      price={price || alternativeContent}
      lowestPrice={lowestPrice}
      link={linkElement}
      externalLink={externalLink}
    ></ProductListItemMol>
  )
}
