import type { ReactElement } from "react"
import React, { createContext, useEffect } from "react"
import type { Basket } from "@onestore/api/basket"
import { loadUserDataForToken } from "@gatsby-plugin-basket/store/actions"
import { getBasketToken } from "@gatsby-plugin-basket/store/selectors"
import useEmailHackConfigurationQuery from "@gatsby-plugin-bonus/components/EmailHackBox/useEmailHackConfigurationQuery"
import { addEmailHackData } from "@gatsby-plugin-bonus/store/actions-email-hack"
import isEmpty from "~/lib/isEmpty"
import { useAppSelector, useAppDispatch } from "~/store/hooks"
import { getUserDataReady } from "~/store/selectors"

const UserdataContext = createContext(false)

export default function ({ children }): ReactElement {
  const dispatch = useAppDispatch()
  const token = useAppSelector<Basket.Token>(getBasketToken)
  const isReady = useAppSelector<boolean>(getUserDataReady)
  const { enabled, emailHackPlan, basketText } =
    useEmailHackConfigurationQuery()

  useEffect(() => {
    dispatch(loadUserDataForToken(token))
    dispatch(
      addEmailHackData({
        enabled,
        emailHackPlanId: !isEmpty(emailHackPlan)
          ? emailHackPlan[0].flatData.id
          : undefined,
        basketText,
      })
    )
  }, [])

  return (
    <UserdataContext.Provider value={isReady}>
      {children}
    </UserdataContext.Provider>
  )
}
