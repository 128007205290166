import React, { useCallback, useEffect, useRef } from "react"
import _t from "@core/i18n"
import CartAdd from "@onestore-icons/cart-add.svg"
import Tick from "@onestore-icons/tick.svg"
import type { DomainCheck } from "@onestore/api/domainSearch"
import type { ButtonAtmVariant } from "@onestore/hel/dist/components/atoms/ButtonAtm"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import LoaderAtm from "@onestore/hel/dist/components/atoms/LoaderAtm"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import type Theme from "@onestore/hel/dist/typings/theme"
import useButtonLoadingState from "@gatsby-plugin-domain-search/hooks/useButtonLoadingState"
import useDomainActionsDispatch from "@gatsby-plugin-domain-search/hooks/useDomainActionsDispatch"
import useDomainHookUrl from "@gatsby-plugin-domain-search/hooks/useDomainHookUrl"
import { setAddCartSource } from "~/lib/basket"
import isEmpty from "~/lib/isEmpty"
import { useAppSelector } from "~/store/hooks"
import type { AppState } from "~/store/reducer"

interface DomainAddButtonProps {
  domain: DomainCheck.Result
  color: Theme.AccentColor
  variant?: ButtonAtmVariant
  isWider?: boolean
  eventSource?: string
}

export default function DomainAddButton({
  domain,
  color,
  variant,
  isWider = false,
  eventSource,
}: DomainAddButtonProps) {
  const successButtonRef = useRef<HTMLButtonElement>(null)
  const isSmallOnly = useBreakpoint("small", "medium")
  const isMediumUp = useBreakpoint("medium")
  const { fqdn } = domain
  const hasIcon = isSmallOnly || false
  const buttonId = `search-${domain.plan_id}`
  const isLoading = useButtonLoadingState(buttonId)
  const isDomainInBasket = useAppSelector((state: AppState) =>
    state.basket.domains.includes(fqdn)
  )
  const { forDomainButtons } = useDomainHookUrl()

  const { addDomainsToBasket } = useDomainActionsDispatch()
  const domains = [
    {
      fqdn,
      plan: domain.plan_id,
    },
  ]

  const onBasketAddClick = useCallback(() => {
    if (isLoading) {
      return
    }

    if (isDomainInBasket) {
      document.location.replace(forDomainButtons)

      return
    }

    setAddCartSource(
      domains && domains[0].plan ? domains[0].plan : 0,
      eventSource || ""
    )

    addDomainsToBasket(domains, [], buttonId, false)
  }, [domains, buttonId, isLoading, isDomainInBasket])

  useEffect(() => {
    if (isDomainInBasket && !isEmpty(successButtonRef.current) && !isLoading) {
      successButtonRef.current?.focus()
    }
  }, [isDomainInBasket, successButtonRef, isLoading])

  if (isLoading || domain.plan_id === null) {
    return <LoaderAtm size="tiny" />
  }

  if (isDomainInBasket) {
    return (
      <ButtonAtm
        ref={successButtonRef}
        title={_t("button.success")}
        color="success"
        variant="text"
        icon={Tick}
        onClick={onBasketAddClick}
        isExpanded={isSmallOnly}
        isWider={isMediumUp || isWider}
        size={isMediumUp ? "small" : "default"}
      >
        {_t("button.success")}
      </ButtonAtm>
    )
  }

  return (
    <ButtonAtm
      title={_t("domainSearch.button.addToCart")}
      color={color}
      variant={variant}
      icon={!isWider && hasIcon ? CartAdd : undefined}
      isWider={isMediumUp || isWider}
      onClick={onBasketAddClick}
      isExpanded={isSmallOnly ? isWider : undefined}
      size={isMediumUp ? "small" : "default"}
    >
      {isMediumUp || isWider ? _t("domainSearch.button.addToCart") : null}
    </ButtonAtm>
  )
}
