import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import { PeriodInfo } from "@core/period-info"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import type { SaleConfiguration } from "~/fragments/saleConfigurations"
import isEmpty from "~/lib/isEmpty"

type SaleConfigurationPriceProps = {
  saleConfigurationFlatData: SaleConfiguration["flatData"]
}

function SaleConfigurationPrice({
  saleConfigurationFlatData,
}: SaleConfigurationPriceProps): ReactElement<SaleConfigurationPriceProps> | null {
  const { currentPriceType, isNettoPriceType } = usePriceTypeContext()
  const { defaultPlanPeriod, defaultCloudBluePlan } = saleConfigurationFlatData

  if (isEmpty(defaultCloudBluePlan)) {
    return null
  }

  const period = defaultCloudBluePlan[0].flatData.periods.find(
    (period) =>
      period.period_name === defaultPlanPeriod?.period || period.default
  )

  const periodInfo = !isEmpty(period) ? new PeriodInfo(period) : null

  if (isEmpty(periodInfo)) {
    return null
  }

  const periodPrices = periodInfo.usePrices(currentPriceType)
  const priceTypeText = isNettoPriceType
    ? _t("prices.netto")
    : _t("prices.gross")

  return (
    <>
      <strong>{periodPrices?.priceValue}</strong> {priceTypeText}
    </>
  )
}

export default SaleConfigurationPrice
