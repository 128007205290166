import type { ReactElement } from "react"
import React, { useState } from "react"
import AccordionMol from "@onestore/hel/dist/components/molecules/AccordionMol"
import ContentToggleMol from "@onestore/hel/dist/components/molecules/ContentToggleMol"
import ContentWithImageOrg from "@onestore/hel/dist/components/organisms/ContentWithImageOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import CtaElement from "@gatsby-plugin-generic-page/components/CtaElement"
import Header from "@gatsby-plugin-generic-page/components/Header"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import ResponsiveImage from "@gatsby-plugin-generic-page/components/ResponsiveImage"
import type { AccordionSectionFlatData } from "@gatsby-plugin-generic-page/fragments/accordionSection"
import { sendContentShowEvent } from "~/lib/analytics"
import isEmpty from "~/lib/isEmpty"

function AccordionSection({
  header,
  accordions,
  isFirstElementOpen,
  responsiveImages,
  hasCenteredImage,
  isReversed,
  sectionSettings,
}: AccordionSectionFlatData): ReactElement<AccordionSectionFlatData> {
  const [newAccordions, setNewAccordions] = useState(accordions)

  const [activeElement, setActiveElement] = useState<number>(0)

  const accordionImages = newAccordions.map((accordion) => {
    const { responsiveImages } = accordion

    if (responsiveImages) {
      return <ResponsiveImage images={responsiveImages} />
    }

    return null
  })

  const handleOnClick = (accordion) => {
    const updatedAccordions = newAccordions.map((newAccordion, index) => {
      if (accordion === newAccordion) {
        sendContentShowEvent()
        setActiveElement(index)
        newAccordion.isOpen = true
      } else {
        newAccordion.isOpen = false
      }

      return newAccordion
    })
    setNewAccordions(updatedAccordions)
  }

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer {...sectionSettings?.containerSettings}>
        <ContentWithImageOrg
          content={
            <>
              {!isEmpty(header) && (
                <PushOrg bottomSpace={6}>
                  <Header {...header} />
                </PushOrg>
              )}

              {newAccordions.map((accordion, index) => (
                <AccordionMol
                  key={`${accordion.title}-${index}`}
                  title={accordion.title}
                  text={<MarkdownText>{accordion.text}</MarkdownText>}
                  isOpen={
                    accordion.isOpen ||
                    (activeElement === index && isFirstElementOpen)
                  }
                  onClick={() => handleOnClick(accordion)}
                  footer={
                    accordion.ctaElements ? (
                      <PushOrg topSpace={4}>
                        <CtaElement ctaElement={accordion.ctaElements} />
                      </PushOrg>
                    ) : undefined
                  }
                />
              ))}
            </>
          }
          image={
            responsiveImages ? (
              <ResponsiveImage images={responsiveImages} />
            ) : (
              accordionImages.length > 0 && (
                <ContentToggleMol
                  content={accordionImages}
                  activeIndex={activeElement}
                />
              )
            )
          }
          isReversedOnLarge={isReversed}
          isStickyToTop={!hasCenteredImage}
        />
      </BaseContainer>
    </BaseSection>
  )
}

export default AccordionSection
