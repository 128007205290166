export enum ContentType {
  VIDEO = "Video",
  CTA_ELEMENT = "CtaElement",
  COUNTER = "Counter",
  SALE_CONFIGURATION = "MarketplaceSaleConfiguration",
  STATIC_PRICE = "StaticPrice",
  CLOUDBLUE_DOMAIN = "CloudblueDomain",
  PRICE_TYPE_CONTEXT_TEXT = "PriceTypeContextText",
}

export const getRegExpBySchemaId = (schemaIds: string) =>
  new RegExp(`/api/content/[a-zA-Z0-9-]+/(?:${schemaIds})/(?<id>[a-z0-9\\-]+)`)
