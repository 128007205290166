import type { ReactElement } from "react"
import { useContext } from "react"
import React from "react"
import _t from "@core/i18n"
import ArrowRight from "@onestore-icons/arrow-right.svg"
import { ArrowShort } from "@onestore/hel/dist/components/atoms/ArrowLinkAtm"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import OnColorContext from "@onestore/hel/dist/contexts/OnColorContext"
import { sendPremiumButtonEvent } from "~/lib/analytics"

type PremiumButtonProps = {
  url: string
  domainName: string
  showTextOnMobile?: boolean
}

function PremiumButton({
  url,
  domainName,
  showTextOnMobile,
}: PremiumButtonProps): ReactElement<PremiumButtonProps> {
  const onColor = useContext(OnColorContext)

  const buttonWithText = (
    <ButtonAtm
      href={url}
      openInNewTab
      title={_t("domainSearch.goToDomainMarket")}
      onClick={() => sendPremiumButtonEvent(domainName)}
      color="dark"
      variant="outlined"
      size="small"
      isWider
    >
      {_t("domainSearch.goToDomainMarket")}

      <ArrowShort
        $arrowColor="primary"
        $arrowDirection="right"
        $hasHover={false}
        $onColor={onColor}
      />
    </ButtonAtm>
  )

  if (showTextOnMobile) {
    return buttonWithText
  }

  return (
    <>
      <BreakpointVisibilityOrg from="small" to="medium">
        <ButtonAtm
          href={url}
          title={_t("domainSearch.goToDomainMarket")}
          color="dark"
          variant="outlined"
        >
          <ArrowRight />
        </ButtonAtm>
      </BreakpointVisibilityOrg>

      <BreakpointVisibilityOrg from="medium">
        {buttonWithText}
      </BreakpointVisibilityOrg>
    </>
  )
}

export default PremiumButton
