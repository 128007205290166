import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import TooltipMol from "@onestore/hel/dist/components/molecules/TooltipMol"
import { zIndex } from "@onestore/hel/dist/settings"
import type { ConfigDrawerPeriodTopLabel } from "@gatsby-plugin-generic-page/fragments/configDrawerPeriodTopLabel"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import isEmpty from "~/lib/isEmpty"
import type { SavingPriceResults } from "../../helpers/getSavingPrice"

export type TopLabelProps = {
  data?: ConfigDrawerPeriodTopLabel[]
  savingPrice?: SavingPriceResults | null
}

export function TopLabel({
  data,
  savingPrice,
}: TopLabelProps): ReactElement<TopLabelProps> | null {
  const { currentPriceType } = usePriceTypeContext()
  const labelData = !isEmpty(data) ? data[0].flatData : null

  const pricePrefix =
    labelData?.prefix || _t("configDrawer.topLabel.pricePrefix")

  const priceTypeText = _t(`prices.${currentPriceType}`)

  const textElement = labelData?.text

  const savingText = !isEmpty(savingPrice) ? (
    <>
      <span>{pricePrefix}</span>{" "}
      <TextAtm typography="tiny3">{savingPrice.savingPriceFormatted}</TextAtm>{" "}
      {priceTypeText}
    </>
  ) : null

  const tooltipText =
    !isEmpty(labelData) && !isEmpty(labelData.tooltip)
      ? labelData.tooltip[0].flatData.text
      : savingPrice?.savingTooltip

  const tooltipElement = !isEmpty(tooltipText) ? (
    <TooltipMol
      iconSize="small"
      iconTouchAreaSpace={0.5}
      zIndex={zIndex.FloatingAside}
    >
      {tooltipText}
    </TooltipMol>
  ) : null

  return (
    <>
      <TextAtm typography="nano2">{textElement || savingText}</TextAtm>

      {tooltipElement}
    </>
  )
}
