import type { PriceType } from "@core/types"
import type { BasketPatchItemHookResult } from "@gatsby-plugin-basket/hooks/useBasketActions"
import { getDomainsPoolId } from "@gatsby-plugin-generic-page/helpers/getDomainsPoolId"
import { getUpsellData } from "@gatsby-plugin-generic-page/helpers/getUpsellData"
import type { SaleConfiguration } from "~/fragments/saleConfigurations"
import url from "~/lib/url"

export const addProductToCartWithRedirect = (
  saleConfiguration: SaleConfiguration,
  addProductToBasket: BasketPatchItemHookResult["addProductToBasket"],
  priceType: PriceType
) => {
  const skipBonusUrl = saleConfiguration.flatData.skipBonusScreen

  if (skipBonusUrl) {
    addProductToBasket(() => (document.location.href = url.getBasketUrl()))
  }

  const bonusUrl = url.generateBonusUrl({
    ax: getUpsellData(saleConfiguration.flatData.upsell),
    domainSearch: getDomainsPoolId(saleConfiguration),
    priceType,
  })

  addProductToBasket(() => (document.location.href = bonusUrl))
}
