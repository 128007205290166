import Cookies from "js-cookie"
import { isTacosEnabled } from "~/lib/config"
import log from "~/lib/log"
import SessionStorage from "~/lib/storage/SessionStorage"
import { CM_VENDOR_1AND1, CM_VENDOR_BRAND } from "./consentManager"

const MARKET = process.env.GATSBY_TACOS_MARKET ?? ""

const URL = "https://shop-api.ionos.com/api/v1/saleschannel"
const SESSION_SALES_CHANNEL_DATA = `ost.sc`

// For homecloud brand see ./homecloud/README.md in root folder

type TrackingCodeAttr = {
  channelcode?: string | null
  productcode?: string | null
  campaigncode?: string | null
}

type SalesChannelData = {
  mediumCode?: string | null
  actionCode?: string | null
  salesChannelNames?: string | null
  productCode?: string | null
  channelCode?: string | null
  campaignCode?: string | null
}

export type CachedSalesChannel = {
  salesChannelData?: SalesChannelData
  lastAc?: string | null
  lastMc?: string | null
  lastItc?: string | null
  lastCookiesSaved?: boolean
  lastSessionSaved?: boolean
}

async function getSalesTrackingCodes(
  isExistingCustomer: boolean = false
): Promise<SalesChannelData> {
  return new Promise((resolve) => {
    log("starting", "tacos")

    const {
      salesChannelData: cachedSalesData,
      lastAc,
      lastMc,
      lastItc,
      lastCookiesSaved,
      lastSessionSaved,
    } = SessionStorage.getSerialized<CachedSalesChannel>(
      SESSION_SALES_CHANNEL_DATA,
      {}
    )

    const cmpData = window.__cmp("getCMPData")

    const currentAc = _getQueryParam("ac", lastAc)
    const currentMc = _getQueryParam("mc", lastMc)
    const currentItc = _getQueryParam("itc", lastItc)

    // use cache only if the request params and vendor 1&1 have not changed
    if (
      cachedSalesData &&
      lastAc === currentAc &&
      lastMc === currentMc &&
      lastItc === currentItc &&
      lastCookiesSaved === !!cmpData.vendorConsents[CM_VENDOR_1AND1] &&
      lastSessionSaved === !!cmpData.vendorConsents[CM_VENDOR_BRAND]
    ) {
      log("has session data", "tacos")
      resolve(cachedSalesData)

      return
    }

    const header = {
      "Content-Type": "application/json",
    }

    // parse cookies
    const cookieArr: { name: string; value: string }[] = []
    document.cookie.split(";").forEach((c) => {
      const [key, value] = c.trim().split("=")
      cookieArr.push({ name: key, value: value })
    })

    // parse get params
    const crudeParams = window.location.search.split("&")
    const params = {}

    if (crudeParams[0] !== "") {
      crudeParams.forEach((e) => {
        const [key, value] = e.replace("?", "").split("=")
        params[key] = value
      })
    }

    const body = {
      path: window.location.pathname,
      origin: MARKET.toUpperCase(),
      requestParameter: params,
      cookies: cookieArr,
      referer: document.referrer,
      isExistingCustomer: isExistingCustomer ? true : undefined,
    }

    log(["request body", body], "tacos")

    let salesChannelData: SalesChannelData = {}
    fetch(URL, { method: "post", headers: header, body: JSON.stringify(body) })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText)
        }

        return response.json()
      })
      .then((jsonData) => {
        let actionCodeAttr = {}
        let mediumCodeAttr = {}
        let trackingCodeAttr: TrackingCodeAttr = {}
        let salesChannelNames: string[] = []
        jsonData.evaluatedSalesChannels.forEach((es) => {
          salesChannelNames.push(es.name)
        })

        jsonData.actions.forEach((ac) => {
          if (ac.action === "ADD_ACTIONCODE") {
            actionCodeAttr = ac.actionAttributes
          }

          if (ac.action === "ADD_MEDIUMCODE") {
            mediumCodeAttr = ac.actionAttributes
          }

          if (ac.action === "ADD_TRACKINGCODE_COMP") {
            trackingCodeAttr = ac.actionAttributes
          }

          //TACOS-81
          if (
            ac.action === "ADD_COOKIE" &&
            !!cmpData.vendorConsents[CM_VENDOR_1AND1]
          ) {
            const ts = new Date()
            ts.setSeconds(
              ts.getSeconds() + parseInt(ac.actionAttributes["cookie.lifetime"])
            )

            Cookies.set(
              ac.actionAttributes["cookie.name"],
              ac.actionAttributes["cookie.value"],
              {
                expires: ts,
                domain: process.env.GATSBY_STORE_DOMAIN ?? undefined,
                path: "/",
              }
            )
          }
        })

        if (actionCodeAttr["actioncode.code"]) {
          salesChannelData.actionCode = actionCodeAttr["actioncode.code"]
        }

        if (mediumCodeAttr["mediumcode.code"]) {
          salesChannelData.mediumCode = mediumCodeAttr["mediumcode.code"]
        }

        if (salesChannelNames) {
          salesChannelData.salesChannelNames = salesChannelNames.join("|")
        }

        if (trackingCodeAttr.campaigncode) {
          salesChannelData.campaignCode = trackingCodeAttr.campaigncode
        }

        if (trackingCodeAttr.productcode) {
          salesChannelData.productCode = trackingCodeAttr.productcode
        }

        if (trackingCodeAttr.channelcode) {
          salesChannelData.channelCode = trackingCodeAttr.channelcode
        }

        //TACOS-81
        if (!!cmpData.vendorConsents[CM_VENDOR_BRAND]) {
          SessionStorage.setSerialized<CachedSalesChannel>(
            SESSION_SALES_CHANNEL_DATA,
            {
              salesChannelData: salesChannelData,
              lastAc: currentAc,
              lastMc: currentMc,
              lastItc: currentItc,
              lastCookiesSaved: !!cmpData.vendorConsents[CM_VENDOR_1AND1],
              lastSessionSaved: true,
            }
          )
        }

        log(["returned remote data", salesChannelData], "tacos")

        resolve(salesChannelData)
      })
      .catch((e) => {
        log(e.message, "tacos")
      })
  })
}

export function getStoredTacosValue(): string | undefined {
  if (isTacosEnabled()) {
    const salesChannel = SessionStorage.getSerialized<CachedSalesChannel>(
      SESSION_SALES_CHANNEL_DATA,
      {}
    )

    return JSON.stringify(salesChannel.salesChannelData || {}) || undefined
  }

  return undefined
}

function _getQueryParam(
  queryKey: string,
  defaultValue: string | null
): string | null {
  const urlParams = new URLSearchParams(window.location.search)

  return urlParams.get(queryKey) || defaultValue
}

export function initTacos() {
  if (!isTacosEnabled()) {
    log("disabled", "tacos")

    return
  }
  getSalesTrackingCodes()
    .then((salesChannelData: SalesChannelData) => {
      log(salesChannelData, "tacos")
    })
    .catch((e) => {
      log(e, "tacos")
    })
}
