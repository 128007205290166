import type { ReactElement } from "react"
import React, { useState } from "react"
import _t from "@core/i18n"
import type { DomainCheck } from "@onestore/api/domainSearch"
import LoaderAtm from "@onestore/hel/dist/components/atoms/LoaderAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import AXContainerOrg from "@onestore/hel/dist/components/organisms/AXContainerOrg"
import { isResponseNone } from "@onestore/onestore-store-common/http"
import type { DomainBundleHook } from "@gatsby-plugin-domain-search/hooks/useMainDomainBundleQuery"
import type { DomainSearchBundleState } from "@gatsby-plugin-domain-search/store/selectors"
import { md } from "~/lib/i18n"
import Content from "./Content"
import SubmitField from "./SubmitField"

export function DomainsBonusBoxPlaceholder() {
  return (
    <AlignContentOrg horizontalAlign="center">
      <LoaderAtm size="medium" />
    </AlignContentOrg>
  )
}

interface DomainsBonusBoxProps {
  disabled?: boolean
  title?: string
  domainSearchUrl?: boolean
  results: DomainCheck.Result[]
  requestStatus: number
  onBasketAdd: {
    (
      domain: string,
      domainId: number,
      buttonId: string,
      ignoreHack: boolean
    ): void
  }
  basketDomains: string[]
  pool: number
  onNextStepClick?: { (): void } | undefined
  nextStep?: boolean | null
  hasProduct: boolean
  bundleDomainsExtensions?: DomainBundleHook[]
  bundleDomainsResult?: DomainSearchBundleState[]
  onSearchClicked: {
    (
      phrase: string,
      pool: number,
      bundleDomainsExtensions?: DomainBundleHook[]
    ): void
  }
}

export default function DomainsBonusBox({
  disabled = false,
  title = _t("bonus.defaultSearchTitle"),
  onNextStepClick = undefined,
  nextStep = false,
  requestStatus,
  domainSearchUrl = false,
  results,
  basketDomains,
  hasProduct,
  onBasketAdd,
  onSearchClicked,
  pool,
  bundleDomainsExtensions,
  bundleDomainsResult,
}: DomainsBonusBoxProps): ReactElement | null {
  const [phrase, setPhrase] = useState("")

  return (
    <AXContainerOrg
      content={
        <>
          <TextAtm htmlTag="h4" typography="large3" pushSpace={2}>
            {title}
          </TextAtm>

          <TextAtm typography="medium1_s" pushSpace={3}>
            {md("bonus.defaultSubtitleText")}
          </TextAtm>

          <SubmitField
            onSearchClicked={onSearchClicked}
            phrase={phrase}
            setPhrase={setPhrase}
            disabled={disabled}
            requestStatus={requestStatus}
            pool={pool}
            nextStep={nextStep}
            onNextStepClick={onNextStepClick}
            bundleDomainsExtensions={bundleDomainsExtensions}
          />
        </>
      }
      aside={
        isResponseNone(requestStatus) || !results ? undefined : (
          <Content
            results={results}
            onBasketAdd={onBasketAdd}
            onNextStepClick={onNextStepClick}
            basketDomains={basketDomains}
            hasProduct={hasProduct}
            requestStatus={requestStatus}
            domainSearchUrl={domainSearchUrl}
            phrase={phrase}
            bundleDomainsResult={bundleDomainsResult}
          />
        )
      }
    />
  )
}
