import type { ReactElement } from "react"
import { useEffect } from "react"
import React from "react"
import type { Props as ModalProps } from "@onestore/hel/dist/components/templates/ModalTemplate"
import ModalTemplate from "@onestore/hel/dist/components/templates/ModalTemplate"
import { sendToplayerShowEvent } from "~/lib/analytics"

function ModalTemplateWrapper({
  isOpen,
  ...modalProps
}: ModalProps): ReactElement<ModalProps> {
  useEffect(() => {
    if (isOpen) {
      sendToplayerShowEvent()
    }
  }, [isOpen])

  return <ModalTemplate isOpen={isOpen} {...modalProps} />
}

export default ModalTemplateWrapper
