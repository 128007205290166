import type { ReactElement } from "react"
import React from "react"
import ProductLinkMol from "@onestore/hel/dist/components/molecules/ProductLinkMol"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import type { CoreProduct } from "@gatsby-plugin-generic-page/fragments/coreProductsSection"
import { useLinkReplacer } from "@gatsby-plugin-generic-page/hooks/useLinkReplacer"
import { SquidexProductTileLinkTypes } from "@gatsby-plugin-generic-page/types/productTileTypes"
import { sendGaEvent } from "~/lib/analytics"

export type ProductTileProps = CoreProduct & {
  hasSiblingWithLabel?: boolean
}

export default function ProductTile({
  title,
  linkType,
  href,
  pages,
  priceText,
  labelText,
  labelVariant = "notify",
  gaEvent,
  hasSiblingWithLabel,
}: ProductTileProps): ReactElement<ProductTileProps> {
  const to =
    linkType === SquidexProductTileLinkTypes.INTERNAL_LINK &&
    pages &&
    pages[0].flatData.url
      ? pages[0].flatData.url
      : undefined
  const linkHref = useLinkReplacer(href)

  return (
    <ProductLinkMol
      title={title}
      labelText={labelText}
      labelVariant={labelVariant}
      price={
        <MarkdownText
          typography={{ bold: "xsmall3", default: "xsmall2" }}
          emphasis="medium"
        >
          {priceText}
        </MarkdownText>
      }
      hrefTitle={title}
      hasSiblingWithLabel={hasSiblingWithLabel}
      to={to}
      href={
        linkType === SquidexProductTileLinkTypes.EXTERNAL_LINK && linkHref
          ? linkHref
          : undefined
      }
      onClick={() => sendGaEvent(gaEvent)}
    />
  )
}
