import type { PeriodInfo } from "@core/period-info"
import { getSelectedPeriodInfo } from "@core/period-info"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import isEmpty from "~/lib/isEmpty"

export const getSmallestPeriodInfoByPeriodName = (
  planData: CloudBluePlan["flatData"],
  allPeriodNames: string[],
  defaultPerioInfo: PeriodInfo
): PeriodInfo => {
  let smallestPeriodInfo = defaultPerioInfo

  allPeriodNames.forEach((periodName) => {
    const periodInfo = getSelectedPeriodInfo(planData.periods, periodName)

    if (isEmpty(periodInfo)) {
      return
    }

    const priceType = periodInfo.getPeriodType()
    const smallestPriceType = smallestPeriodInfo.getPeriodType()

    if (priceType === null || smallestPriceType === null) {
      return
    }

    if (priceType < smallestPriceType) {
      smallestPeriodInfo = periodInfo

      return
    }

    const priceLength = periodInfo.getPeriodLength()
    const smallestPriceLength = smallestPeriodInfo.getPeriodLength()

    if (priceLength < smallestPriceLength) {
      smallestPeriodInfo = periodInfo
    }
  })

  return smallestPeriodInfo
}
