import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import { isEmpty } from "lodash"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import UnderlineLinkAtm from "@onestore/hel/dist/components/atoms/UnderlineLinkAtm"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import { ResourceCategoryDisplayType } from "@gatsby-plugin-generic-page/hooks/useTableConfigs"
import { useConfigsHookContext } from "../.."

export default function FiltersLabels(): ReactElement | null {
  const {
    configsValues,
    getConfigResource,
    getResourceCategory,
    handleResourcesReset,
  } = useConfigsHookContext() ?? {}

  if (isEmpty(configsValues) || !getConfigResource || !handleResourcesReset) {
    return null
  }

  const resources = configsValues.resources

  if (isEmpty(resources)) {
    return null
  }

  const resourcesLabels = resources.map((resource) => {
    const resourceCategory = getResourceCategory
      ? getResourceCategory(resource.categoryId)
      : null

    const isCounter = !isEmpty(resourceCategory)
      ? resourceCategory.display_type === ResourceCategoryDisplayType.COUNTERS
      : false

    return (
      <PushOrg leftSpace={1.5}>
        <LabelAtm variant="successLight" size="small" borderRadius="huge">
          <TextAtm emphasis="medium" typography="tiny1">
            {resource.name}

            {isCounter ? (
              <TextAtm
                typography="tiny3"
                emphasis="medium"
              >{`: ${resource.value}`}</TextAtm>
            ) : null}
          </TextAtm>
        </LabelAtm>
      </PushOrg>
    )
  })

  return (
    <FlexContainerOrg alignItems="center">
      {resourcesLabels}

      <PushOrg leftSpace={2} hasNoReset>
        <UnderlineLinkAtm
          title={_t("vps.clear")}
          typography="tiny3"
          onClick={handleResourcesReset}
        >
          {_t("vps.clear")}
        </UnderlineLinkAtm>
      </PushOrg>
    </FlexContainerOrg>
  )
}
