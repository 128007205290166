import type { ReactElement } from "react"
import React from "react"
import type { ButtonAtmProps } from "@onestore/hel/dist/components/atoms/ButtonAtm"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import type { CtaAXLink } from "@gatsby-plugin-generic-page/fragments/ctaAXLink"
import { getDomainsPoolId } from "@gatsby-plugin-generic-page/helpers/getDomainsPoolId"
import { getUpsellData } from "@gatsby-plugin-generic-page/helpers/getUpsellData"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import isEmpty from "~/lib/isEmpty"
import url from "~/lib/url"

type AXLinkButtonProps = ButtonAtmProps & {
  saleConfiguration: CtaAXLink["saleConfiguration"]
}

function AXLinkButton({
  onClick,
  title,
  text,
  color,
  variant,
  size,
  saleConfiguration,
  rel,
  ...restProps
}: AXLinkButtonProps): ReactElement<AXLinkButtonProps> | null {
  const { currentPriceType } = usePriceTypeContext()

  if (isEmpty(saleConfiguration)) return null

  const { defaultCloudBluePlan, bundlePlan, defaultPlanPeriod, upsell } =
    saleConfiguration[0].flatData
  const defaultPlanAlias = !isEmpty(defaultCloudBluePlan)
    ? defaultCloudBluePlan[0].flatData.alias
    : undefined
  const bundlePlanAlias = !isEmpty(bundlePlan)
    ? bundlePlan[0].flatData.alias
    : undefined

  const href = url.generateBonusUrl({
    p: defaultPlanAlias,
    b: bundlePlanAlias,
    ax: getUpsellData(upsell),
    period: defaultPlanPeriod?.period,
    domainSearch: getDomainsPoolId(saleConfiguration[0]),
    priceType: currentPriceType,
  })

  return (
    <ButtonAtm
      onClick={onClick}
      href={href}
      title={title}
      color={color}
      variant={variant}
      size={size}
      rel={rel}
      {...restProps}
    >
      {text}
    </ButtonAtm>
  )
}

export default AXLinkButton
