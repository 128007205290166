import { graphql } from "gatsby"
import type { EmbeddedAssetSquidexMetadata } from "~/fragments/image"
import type { SaleConfiguration } from "~/fragments/saleConfigurations"
import type { CloudblueResourceCategory } from "./cloudblueResourceCategory"
import type { Label } from "./label"

export const enum CellType {
  NAME = "Nazwa",
  PRICE = "Cena",
  TEXT = "Tekst",
  RESOURCE_CATEGORY = "Kategoria zasobu",
}

export type Cell = {
  type: CellType
  icon?: EmbeddedAssetSquidexMetadata[]
  value?: string
  resourceCategory?: CloudblueResourceCategory[]
  sortWeight?: number
}

export type HorizontalProductBox = {
  id: string
  flatData: {
    saleConfiguration: SaleConfiguration[]
    category?: { flatData: { name: string } }[]
    cells: Cell[]
    labelElement?: Label
    promoText?: string
  }
}

export const query = graphql`
  fragment HorizontalProductBox on GraphCmsSquidex_HorizontalProductBox {
    id
    flatData {
      saleConfiguration {
        ...SaleConfigurations
      }
      category {
        flatData {
          name
        }
      }
      cells {
        type
        icon {
          ...Image
        }
        value
        resourceCategory {
          ...CloudblueResourceCategory
        }
        sortWeight
      }
      labelElement {
        ...Label
      }
      promoText
    }
  }
`

export const FRAGMENT_HORIZONTAL_PRODUCT_BOX = `
  fragment HorizontalProductBox on HorizontalProductBox {
    id
    flatData {
      saleConfiguration {
        ...SaleConfigurations
      }
      category {
        flatData {
          name
        }
      }
      cells {
        type
        icon {
          ...Image
        }
        value
        resourceCategory {
          ...CloudblueResourceCategory
        }
        sortWeight
      }
      labelElement {
        ...Label
      }
      promoText
    }
  }
`
