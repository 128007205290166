import type { ReactElement, SyntheticEvent } from "react"
import React from "react"
import PlainLinkAtm from "@onestore/hel/dist/components/atoms/PlainLinkAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import SideDarkModalOrg from "@onestore/hel/dist/components/organisms/SideDarkModalOrg"
import ModalButtonWrapper from "@onestore/hel/dist/components/organisms/SideDarkModalOrg/ButtonWrapper"
import ModalTitle from "@onestore/hel/dist/components/organisms/SideDarkModalOrg/Title"
import BaseMarkdown from "@gatsby-plugin-generic-page/components/Markdown/Base"
import ModalItem from "@gatsby-plugin-generic-page/components/ModalItem"
import type { SideModalFlatData } from "@gatsby-plugin-generic-page/fragments/sideModal"
import getLinkProps from "@gatsby-plugin-generic-page/helpers/getLinkProps"
import isEmpty from "~/lib/isEmpty"

type Props = SideModalFlatData & {
  isOpen: boolean
  onCloseButtonClick: (event: SyntheticEvent) => void
  categoryName: string
}

function SideModal({
  isOpen,
  title,
  text,
  link,
  products,
  onCloseButtonClick,
  categoryName,
}: Props): ReactElement<Props> {
  const linkProps = !isEmpty(link) ? getLinkProps(link) : null

  return (
    <SideDarkModalOrg
      isOpen={isOpen}
      onCloseButtonClick={onCloseButtonClick}
      header={
        <GridOrg
          columns={{ small: 1, medium: 1, large: 2 }}
          gutterSpace={{ small: 2, medium: 4, large: 8 }}
        >
          <BaseMarkdown
            options={{
              wrapper: ({ children }) => <ModalTitle>{children}</ModalTitle>,
              forceWrapper: true,
            }}
          >
            {title}
          </BaseMarkdown>

          <div>
            <BaseMarkdown
              options={{
                wrapper: ({ children }) => (
                  <TextAtm
                    typography={{
                      small: "small1",
                      medium: "medium1_h",
                      large: "medium1_h",
                    }}
                    hasInheritedColor
                    pushSpace={{ bottom: 2 }}
                  >
                    {children}
                  </TextAtm>
                ),
                forceWrapper: true,
              }}
            >
              {text}
            </BaseMarkdown>

            {!isEmpty(link) && !isEmpty(linkProps) ? (
              <ModalButtonWrapper>
                <PlainLinkAtm
                  {...linkProps}
                  typography="xsmall2"
                  type="plain"
                  color="white"
                >
                  {linkProps.text}
                </PlainLinkAtm>
              </ModalButtonWrapper>
            ) : null}
          </div>
        </GridOrg>
      }
      content={products.map((product, index) => (
        <PushOrg bottomSpace={5} key={`${product.flatData.title}-${index}`}>
          <ModalItem {...product.flatData} categoryName={categoryName} />
        </PushOrg>
      ))}
    />
  )
}

export default SideModal
