import type { SyntheticEvent } from "react"
import { useState } from "react"
import type { GaEvent } from "~/fragments/gaEvent"
import { sendToplayerShowEvent, sendGaEvent } from "~/lib/analytics"

function useSectionsModal(gaEvent?: GaEvent) {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpenOverlay = (
    event?: SyntheticEvent,
    topLayerGAEventName?: string
  ) => {
    if (event) {
      event.preventDefault()
    }

    sendToplayerShowEvent(topLayerGAEventName)

    sendGaEvent(gaEvent)

    setIsOpen((value) => !value)
  }

  const handleCloseOverlay = () => setIsOpen(false)

  return {
    isOpen,
    handleOpenOverlay,
    handleCloseOverlay,
  }
}

export default useSectionsModal
