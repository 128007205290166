import type { ReactElement } from "react"
import React from "react"
import { getSelectedPeriodInfo } from "@core/period-info"
import useBasketActions from "@gatsby-plugin-basket/hooks/useBasketActions"
import { BasketActionSource } from "@gatsby-plugin-basket/store/actions"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import { usePriceTypeContext } from "~/context/PriceTypeContext"
import type { SaleConfiguration } from "~/fragments/saleConfigurations"
import isEmpty from "~/lib/isEmpty"
import { getSelectedPeriod } from "~/lib/plan"
import { addProductToCartWithRedirect } from "../../helpers/addProductToCartWithRedirect"
import { SummaryVisualElement } from "../SummaryVisualElement"

export type ProductSummaryProps = {
  activePeriodName: string
  plan: CloudBluePlan
  saleConfiguration: SaleConfiguration
}

export function ProductSummary({
  activePeriodName,
  plan,
  saleConfiguration,
}: ProductSummaryProps): ReactElement<ProductSummaryProps> | null {
  const { currentPriceType } = usePriceTypeContext()

  const periodInfo = getSelectedPeriodInfo(
    plan.flatData.periods,
    activePeriodName
  )

  const period = getSelectedPeriod(plan.flatData, activePeriodName)

  const { addProductToBasket, isLoading, isDone } = useBasketActions(
    plan,
    undefined,
    undefined,
    period.id,
    true,
    BasketActionSource.CONFIG_DRAWER
  )

  if (isEmpty(periodInfo)) {
    return null
  }

  const productName = plan.flatData.name

  const handleOnClick = () =>
    addProductToCartWithRedirect(
      saleConfiguration,
      addProductToBasket,
      currentPriceType
    )

  return (
    <SummaryVisualElement
      data-testid="product-summary"
      productName={productName}
      periodInfo={periodInfo}
      onClick={handleOnClick}
      isLoading={isLoading}
      isDone={isDone}
    />
  )
}
